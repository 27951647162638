import React, { useState } from "react";
import styles from "./ExitPosition.module.css";
import {
  formatTimeToSeconds,
  getCurrentDateFormatted,
  mapTypeToString,
} from "../../../helpers";
import TradeCard from "../tradeCard/TradeCard";
import { useSelector } from "react-redux";
import {
  sortData,
  filterDataByDate,
} from "../../../helpers/SortingAndFiltering";
import { useQueryClient } from "@tanstack/react-query";
import Transactions from "./Transactions";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";

const ExitPositionBox = () => {
  const queryClient = useQueryClient();
  const [showSubContainerIndex, setShowSubContainerIndex] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showT, setShowT] = useState(false);

  const exitPositionData = useSelector(
    (state) => state.onLoad.userClosedPositions
  );
  const transactions = queryClient.getQueryData(["transactions"]);
  const user = queryClient.getQueryData(["user"]);

  const handleShowT = () => {
    setShowT((prev) => !prev);
  };

  const deposit =
    transactions?.message
      ?.filter((t) => Number(t.amount) > 0)
      ?.reduce((sum, t) => sum + Number(t.amount), 0) || 0;

  const withdrawal =
    transactions?.message
      ?.filter((t) => Number(t.amount) < 0)
      ?.reduce((sum, t) => sum + Number(t.amount), 0) || 0;

  const handleOnClick = (index) => {
    setShowSubContainerIndex(index === showSubContainerIndex ? null : index);
  };

  // Sorting and filtering using utility functions
  const sortedData = sortData(exitPositionData, sortBy);
  const filteredData = filterDataByDate(sortedData, startDate, endDate);

  return (
    <div>
      <TradeCard
        data={calculatePositionAggregate(
          exitPositionData,
          deposit,
          withdrawal,
          user
        )}
      />
      {!exitPositionData && <p className={styles.error}>Loading...</p>}
      <div className="p-2 border-b   border-gray-200 cursor pointer font-semibold ">
        <div className="flex justify-between" onClick={handleShowT}>
          <p className="text-base">Transactions</p>
          {showT ? (
            <RiArrowDropUpLine size={20} />
          ) : (
            <RiArrowDropDownLine size={20} />
          )}
        </div>
        {showT && <Transactions transactions={transactions} />}
      </div>
      {exitPositionData?.length < 1 && (
        <p className={styles.error}>No closed position available!</p>
      )}

      {exitPositionData && exitPositionData.length > 0 && (
        <div className={styles.sortAndFilter}>
          <select
            onChange={(e) => setSortBy(e.target.value)}
            className="p-2 border rounded-md"
          >
            <option value="">Sort By</option>
            <option value="symbol">Symbol</option>
            <option value="ticket">Ticket</option>
            <option value="type">Type</option>
            <option value="volume">Volume</option>
            <option value="openTime">Open Time</option>
            <option value="closeTime">Close Time</option>
            <option value="profit">Profit</option>
          </select>
          <div className="flex space-x-2 mt-2">
            <input
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              defaultValue="2020-01-01"
              className="p-2 border rounded-md"
            />
            <input
              type="date"
              defaultValue={getCurrentDateFormatted()}
              onChange={(e) => setEndDate(e.target.value)}
              className="p-2 border rounded-md"
            />
          </div>
        </div>
      )}

      <div className={styles.scroll}>
        {filteredData?.map((data, index) => (
          <div className={styles.container} key={data._id}>
            <div className={styles.main} onClick={() => handleOnClick(index)}>
              <div className={styles.flexBox}>
                <div className={styles.gap}>
                  <span>{data.symbol}</span>
                  <span
                    className={data.type === 1 ? styles.rising : styles.falling}
                  >
                    {mapTypeToString(data.type)},
                  </span>
                  <span
                    className={data.type === 1 ? styles.rising : styles.falling}
                  >
                    {data.volume}
                  </span>
                </div>
                <div>{formatTimeToSeconds(data?.closeTime)}</div>
              </div>
              <div className={styles.flexBox}>
                <div className={styles.gap}>
                  <span>{data.price}</span>
                  <span>---&gt;</span>
                  <span>{data.closePrice}</span>
                </div>
                <div
                  className={data.profit > 0 ? styles.rising : styles.falling}
                >
                  {data.profit?.toFixed(2)}
                </div>
              </div>
            </div>
            {showSubContainerIndex === index && (
              <div className={styles.subContainer}>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>S/L:</span>
                    <span>{data.stopLoss}</span>
                  </div>
                  <div className={styles.gap}>
                    <span>T/P:</span>
                    <span>{data.takeProfit}</span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>Open:</span>
                    <span>{formatTimeToSeconds(data.createdAt)}</span>
                  </div>
                  <div className={styles.gap}>
                    <span>Swap:</span>
                    <span>{data?.swap ? data.swap.toFixed(2) : data.swap}</span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>Id:</span>
                    <span>#{data.ticket}</span>
                  </div>
                  <div className={styles.gap}>
                    <span>Commission:</span>
                    <span>
                      {data.commission
                        ? data.commission.toFixed(2)
                        : data.commission}
                    </span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>Comment:</span>
                    <span>{data.comment}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExitPositionBox;

const calculatePositionAggregate = (data, deposit, withdrawal, user) => {
  let profit = 0;
  let swap = 0;
  let commission = 0;

  for (let i = 0; i < data.length; i++) {
    profit += Number(data[i]?.profit || 0);
    swap += Number(data[i]?.swap || 0);
    commission += Number(data[i]?.commission || 0);
  }

  const depositAmount = Number(deposit || 0);
  const withdrawalAmount = Number(withdrawal || 0);

  return {
    Profit: profit.toFixed(2),
    Deposit: depositAmount,
    Swap: swap.toFixed(2),
    Commission: commission.toFixed(2),
    Balance: (user?.user?.balance + swap + commission).toFixed(2),
    Withdrawal: withdrawalAmount,
  };
};
