import React, { useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Overlay from "../specifications/Specification";
import TradeForm from "../../trade/tradeForm/TradeForm";
import WatchListItem from "./WatchListItem";
import ContextMenu from "./ContextMenu";
import styles from "./WatchList.module.css";
import { IoClose } from "react-icons/io5";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import {
  changeShowOptions,
  changeShowTradeForm,
} from "../../../store/watchlistSlice";
import {
  changeSymbol,
  fetchChartData,
  changeDescription,
} from "../../../store/chartSlice";
import { changeTab } from "../../../store/mobileViewSlice";
import { refreshWatchlist } from "../../../store/appSlice";
import { deleteSymbol } from "../../../api/queryFunctions";

const WatchList = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const showOptions = useSelector((state) => state.watchlist.showOptions);
  const watchlistData = queryClient.getQueryData(["getWatchlistData"]);

  const { mutate: deleteS } = useMutation({
    mutationFn: (data) => deleteSymbol(data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["getWatchlistData"] }),
  });

  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [showSpecs, setShowSpecs] = useState(false);
  const [showTradeForm, setShowTradeForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [icon, setIcon] = useState(true);
  const [iconChangeValue, setIconChangeValue] = useState(true);
  const [iconBidValue, setIconBidValue] = useState(true);
  const [iconAskValue, setIconAskValue] = useState(true);

  const initialShowMobOpt = useMemo(
    () => new Array(watchlistData?.length).fill(false),
    [watchlistData?.length]
  );
  const [showMobOpt, setShowMobOpt] = useState(initialShowMobOpt);

  const handleNewChart = useCallback(() => {
    if (selectedItem) {
      dispatch(changeSymbol(selectedItem.symbol));
      dispatch(changeDescription(selectedItem.description));
      dispatch(changeTab("chart"));
      dispatch(fetchChartData());
      dispatch(refreshWatchlist());
    }
  }, [dispatch, selectedItem]);

  const handleNewOrder = useCallback(() => {
    if (selectedItem) {
      dispatch(changeSymbol(selectedItem.symbol));
      if (window.innerWidth <= 768) {
        setShowTradeForm((prev) => !prev);
      } else {
        dispatch(changeShowTradeForm(true));
      }
    }
  }, [dispatch, selectedItem]);

  const handleSpecification = useCallback(() => setShowSpecs(true), []);
  const handleCloseSpecs = useCallback(() => setShowSpecs(false), []);

  const handleHide = useCallback(() => {
    if (selectedItem) {
      deleteS({ symbol: selectedItem.symbol });
      dispatch(changeShowOptions(false));
      setShowMobOpt(new Array(watchlistData.length).fill(false));
    }
  }, [deleteS, dispatch, selectedItem, watchlistData]);

  const handleContextMenu = useCallback(
    (event, item) => {
      event.preventDefault();
      setContextMenuPosition({ x: event.clientX, y: event.clientY });
      setSelectedItem(item);
      dispatch(changeShowOptions(true));
    },
    [dispatch]
  );

  const hideContextMenu = useCallback(
    () => dispatch(changeShowOptions(false)),
    [dispatch]
  );

  const handleMobOptions = useCallback((index) => {
    if (window.innerWidth <= 768) {
      setShowMobOpt((prevShowMobOpt) => {
        const newShowMobOpt = [...prevShowMobOpt];
        newShowMobOpt[index] = !prevShowMobOpt[index];
        return newShowMobOpt;
      });
    }
  }, []);

  const handleSort = (field, ascending) => {
    const sortedData = [...watchlistData].sort((a, b) => {
      if (ascending) {
        if (typeof a[field] === "string") {
          return a[field].localeCompare(b[field]);
        } else {
          return a[field] - b[field];
        }
      } else {
        if (typeof a[field] === "string") {
          return b[field].localeCompare(a[field]);
        } else {
          return b[field] - a[field];
        }
      }
    });

    switch (field) {
      case "symbol":
        setIcon(ascending);
        break;
      case "changePercent":
        setIconChangeValue(ascending);
        break;
      case "bid":
        setIconBidValue(ascending);
        break;
      case "ask":
        setIconAskValue(ascending);
        break;
      default:
        break;
    }

    queryClient.setQueryData(["getWatchlistData"], sortedData);
  };

  return (
    <div className="p-5 rounded-md md:text-xs mb-4 relative h-full">
      {!watchlistData ? (
        <div className="flex justify-center items-center h-96 w-full">
          <AiOutlineLoading3Quarters
            size={24}
            color="green"
            className="animate-spin"
          />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-4 font-semibold py-2 px-2 border-b border-gray-200">
            <span
              className="flex flex-row cursor-pointer items-center"
              onClick={() => handleSort("symbol", !icon)}
            >
              SYMBOL{" "}
              {icon ? (
                <MdArrowDropDown size={20} className=" text-blue-600" />
              ) : (
                <MdArrowDropUp size={20} className=" text-blue-600" />
              )}
            </span>

            <span
              className="flex flex-row cursor-pointer items-center"
              onClick={() => handleSort("changePercent", !iconChangeValue)}
            >
              CHANGE{" "}
              {iconChangeValue ? (
                <MdArrowDropDown size={20} className=" text-blue-600" />
              ) : (
                <MdArrowDropUp size={20} className=" text-blue-600" />
              )}
            </span>

            <span
              className="flex flex-row cursor-pointer items-center"
              onClick={() => handleSort("bid", !iconBidValue)}
            >
              BID{" "}
              {iconBidValue ? (
                <MdArrowDropDown size={20} className=" text-blue-600" />
              ) : (
                <MdArrowDropUp size={20} className=" text-blue-600" />
              )}
            </span>

            <span
              className="flex flex-row cursor-pointer items-center"
              onClick={() => handleSort("ask", !iconAskValue)}
            >
              ASK{" "}
              {iconAskValue ? (
                <MdArrowDropDown size={20} className=" text-blue-600" />
              ) : (
                <MdArrowDropUp size={20} className=" text-blue-600" />
              )}
            </span>
          </div>
          <div className="overflow-y-auto scrollbar-thin h-full md:h-3/4">
            {watchlistData.map((currency, index) => (
              <WatchListItem
                key={currency.symbol}
                currency={currency}
                index={index}
                handleContextMenu={handleContextMenu}
                handleMobOptions={handleMobOptions}
                showMobOpt={showMobOpt[index]}
                setSelectedItem={setSelectedItem}
                handleSpecification={handleSpecification}
                handleNewChart={handleNewChart}
                handleHide={handleHide}
                handleNewOrder={handleNewOrder}
              />
            ))}
          </div>
        </>
      )}
      {showOptions && (
        <ContextMenu
          position={contextMenuPosition}
          hideContextMenu={hideContextMenu}
          handleNewOrder={handleNewOrder}
          handleNewChart={handleNewChart}
          handleSpecification={handleSpecification}
          handleHide={handleHide}
        />
      )}
      {showSpecs && (
        <Overlay
          closeHandler={handleCloseSpecs}
          symbolName={selectedItem.symbol}
        />
      )}
      {showTradeForm && (
        <div className={styles.newOrder}>
          <div
            className={styles.closeForm}
            onClick={() => setShowTradeForm(!showTradeForm)}
          >
            <IoClose size={24} />
          </div>
          <div className={styles.centeredTradeForm}>
            <TradeForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default WatchList;
