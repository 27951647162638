import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "BB",
    period: 20,
    source: "close",
    timeFrame: 1,
    styles: {
        upper: {
            styles: {
                lineVisible: true,
                priceLineVisible: false,
                color: "skyblue",
                lineStyle: 0,
                lineWidth: 1,
                lineType: 0,
                pointMarkersVisible: false,
                pointMarkersRadius: undefined,
                lastPriceAnimation: 1,
                lastValueVisible: true,
            }
        },
        middle: {
            styles: {
                lineVisible: true,
                priceLineVisible: false,
                color: "orange",
                lineStyle: 0,
                lineWidth: 1,
                lineType: 0,
                pointMarkersVisible: false,
                pointMarkersRadius: undefined,
                lastPriceAnimation: 1,
                lastValueVisible: true,
            }
        },
        lower: {
            styles: {
                lineVisible: true,
                priceLineVisible: false,
                color: "blue",
                lineStyle: 0,
                lineWidth: 1,
                lineType: 0,
                pointMarkersVisible: false,
                pointMarkersRadius: undefined,
                lastPriceAnimation: 1,
                lastValueVisible: true,
            }
        }
    }

}

const bbSlice = createSlice({
    name: "bb",
    initialState,
    reducers: {
        setPeriod: (state, action) => {
            const newPeriod = action.payload
            state.period = newPeriod
        },
        setSource: (state, action) => {
            const newSource = action.payload
            state.source = newSource
        },
        setTimeFrame: (state, action) => {
            const newTimeFrame = action.payload
            state.timeFrame = newTimeFrame
        },
        setUpperBandColor: (state, action) => {
            const newColor = action.payload
            state.styles.upper.styles.color = newColor
        },
        setUpperBandThickness: (state, action) => {
            const newThickeness = action.payload
            state.styles.upper.styles.lineWidth = newThickeness
        },
        setMiddleBandColor: (state, action) => {
            const newColor = action.payload
            state.styles.middle.styles.color = newColor
        },
        setMiddleBandThickness: (state, action) => {
            const newThickeness = action.payload
            state.styles.middle.styles.lineWidth = newThickeness
        },
        setLowerBandColor: (state, action) => {
            const newColor = action.payload
            state.styles.lower.styles.color = newColor
        },
        setLowerBandThickness: (state, action) => {
            const newThickeness = action.payload
            state.styles.lower.styles.lineWidth = newThickeness
        },
        setUpperBandLineStyle: (state, action) => {
            state.styles.upper.styles.lineStyle = action.payload
        },
        setMiddleBandLineStyle: (state, action) => {
            state.styles.middle.styles.lineStyle = action.payload
        },
        setLowerBandLineStyle: (state, action) => {
            state.styles.lower.styles.lineStyle = action.payload
        },
        resetBb: () => initialState
    }
})

export const { setLowerBandColor, setLowerBandThickness, setMiddleBandColor, setMiddleBandThickness, setPeriod, setSource, setTimeFrame, setUpperBandColor, setUpperBandThickness, setLowerBandLineStyle, setMiddleBandLineStyle, setUpperBandLineStyle, resetBb } = bbSlice.actions
export default bbSlice.reducer