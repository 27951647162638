import React, { useEffect, useState } from "react";
import ExitPositionBox from "../closedPositions/ExitPositionBox";
import OrderBox from "../orders/OrderBox";
import DealsBox from "../deals/DealsBox";
import { changeMode } from "../../../store/modeSlice";
import { useDispatch, useSelector } from "react-redux";
import styles from "../detail/Detail.module.css";

const MobileHistory = () => {
  const mode = useSelector((state) => state.mode.mode);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("closedPosition");

  const handleTabClick = (newMode) => {
    setActiveTab(newMode);
    dispatch(changeMode(newMode));
  };

  useEffect(() => {
  
        dispatch(changeMode("closedPosition")) 
 
  }, [])

  return (
   <div>
     <div className={styles.tabContainer}>
      <div className={styles.tabs}>
        <span
          className={activeTab === "closedPosition" ? styles.tabClicked : ""}
          onClick={() => handleTabClick("closedPosition")}
        >
          Postions
        </span>
        <span
          className={activeTab === "orders" ? styles.tabClicked : ""}
          onClick={() => handleTabClick("orders")}
        >
          Orders
        </span>
        <span
          className={activeTab === "deals" ? styles.tabClicked : ""}
          onClick={() => handleTabClick("deals")}
        >
          Deals
        </span>
      </div>
    </div>
    <div>
        {mode === "closedPosition" && <ExitPositionBox />}
        {mode === "deals" && <DealsBox />}
        {mode === "orders" && <OrderBox />}
      </div>
   </div>
  );
};

export default MobileHistory;
