import React from "react";
import SignUpForm from "./components/auth/signup/SignUp";
import Login from "./components/auth/login/LogIn";
import Home from "./components/home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Cont from "./components/chart/trail/Cont";
import Try from "./components/trade/Try";
import { useQuery } from "@tanstack/react-query";
import { getWatchlistData } from "./api/queryFunctions";
import { setUserCredentials } from "./helpers";

const App = () => {
  const { data } = useQuery({
    queryKey: ["getWatchlistData"],
    queryFn: getWatchlistData,
  });

  if (JSON.parse(localStorage.getItem("AUTH-CRED")) !== null) {
    setUserCredentials(JSON.parse(localStorage.getItem("AUTH-CRED")));
    localStorage.removeItem("AUTH-CRED");
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUpForm />} />
        <Route path="/" element={<Home />} />
        <Route path="/trail" element={<Cont />} />
        <Route path="/detail" element={<Try />} />
      </Routes>
    </Router>
  );
};

export default App;
