import React, { useEffect, useState } from "react";
import styles from "./Detail.module.css";
import {
  sortData,
  filterDataByDate,
} from "../../../helpers/SortingAndFiltering";

import {
  mapDealsData,
  mapOrderData,
  dealsField,
  orderFields,
  positionFields,
  mapClosedPosition,
  closedPositionField,
  mapPendingPositions,
  mapRunningPositions,
  fixedNumber,
  getCurrentDateFormatted,
} from "../../../helpers";
import socket from "../../../socket";
import { useSelector, useDispatch } from "react-redux";
import ModifyPositionForm from "../../trade/modifyPosition/ModifyPositionForm";
import { AiFillEdit } from "react-icons/ai";
import useLivePriceSocket from "../../../socket/livePriceSocket";
import {
  fetchUserClosedPositions,
  fetchUserDeals,
  fetchUserOrders,
  fetchUserPositions,
} from "../../../store/onLoadSlice";
import PPC from "../../trade/partialPositionClose/PPC";
import { fetchInitialWalletInfo } from "../../../store/userWalletAction";
import { setChartHeight } from "../../../store/resizableSlice";

const Detail = ({ mode }) => {
  const { abcData } = useLivePriceSocket();
  const {
    userOrders,
    userDeals,
    userPositions,
    runningPositions,
    pendingPositions,
    userClosedPositions,
  } = useSelector((state) => state.onLoad);

  const userId = useSelector((state) => state.user.id);
  const chartHeight = useSelector((state) => state.resizable.chartHeight);
  const isDetailVisible = useSelector((state) => state.mode.show);
  const dispatch = useDispatch();
  // const { postData, data: res } = useApiPost();
  const [finalData, setFinalData] = useState([]);
  const [modify, setModify] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showPPC, setShowPPC] = useState(false);

  // resizableHeight starts
  const [dragging, setDragging] = useState(false);

  const handlePointerDown = (e) => {
    setDragging(true);
    e.target.setPointerCapture(e.pointerId); // Capture the pointer
  };

  const handlePointerUp = (e) => {
    setDragging(false);
    e.target.releasePointerCapture(e.pointerId); // Release the pointer capture
  };

  const handlePointerMove = (e) => {
    if (!dragging) return;
    const newHeight = Math.max(
      40,
      Math.min(85, 85 - (e.clientY / window.innerHeight) * 45)
    );
    // console.log(newHeight);

    dispatch(setChartHeight(100 - newHeight));
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener("pointermove", handlePointerMove);
      document.addEventListener("pointerup", handlePointerUp);
    } else {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
    }

    return () => {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
    };
  }, [dragging]);

  // resizableHeight ends

  const handleExit = async (rowData) => {
    setShowPPC(true);
    setModify(false);
    setSelectedPosition(rowData);
  };

  const [selectedPosition, setSelectedPosition] = useState(null);

  const handleModifyClick = (rowData) => {
    setSelectedPosition(rowData);
    setShowPPC(false);
    setModify(true);
  };

  const sortedData = sortData(finalData, sortBy);
  const filteredData = filterDataByDate(sortedData, startDate, endDate);

  useEffect(() => {
    if (userId) {
      socket.emit("userpositions", JSON.stringify({ userId: userId }));
    }
  }, [userPositions, userId]);

  useEffect(() => {
    const mapData = () => {
      switch (mode) {
        case "runningPositions":
          setFinalData(mapRunningPositions(runningPositions || []));
          break;
        case "deals":
          setFinalData(mapDealsData(userDeals || []));
          break;
        case "orders":
          setFinalData(mapOrderData(userOrders || []));
          break;
        case "closedPositions":
          const cp = mapClosedPosition(userClosedPositions || []);
          setFinalData(cp);
          // console.log(cp);
          break;
        case "pendingPositions":
          setFinalData(mapPendingPositions(pendingPositions || []));
          break;
        default:
          break;
      }
    };

    mapData();
  }, [
    mode,
    dispatch,
    userClosedPositions,
    userDeals,
    userOrders,
    userPositions,
    runningPositions,
    pendingPositions,
  ]);

  useEffect(() => {
    if (mode === "runningPositions") {
      if (userId) {
        socket.emit("userpositions", JSON.stringify({ userId: userId }));
      }
      const handlePosProfit = (updatedData) => {
        setFinalData((prevData) => {
          const newData = prevData?.map((data) => {
            if (data.id === updatedData.positionId) {
              return {
                ...data,
                profit:
                  updatedData.status === 1
                    ? updatedData.profit?.toFixed(2)
                    : prevData.profit,
                currentPrice: fixedNumber(updatedData.currentPrice),
              };
            }
            return data;
          });
          return newData;
        });
      };
      socket.on("closePosition", (data) => {
        dispatch(fetchUserClosedPositions());
        dispatch(fetchUserDeals());
        dispatch(fetchUserOrders());
        dispatch(fetchUserPositions());
        dispatch(fetchInitialWalletInfo());
      });
      socket.on("posProfit", handlePosProfit);
      return () => {
        socket.off("posProfit", handlePosProfit);
        socket.off("closePosition");
      };
    }
  }, [mode, finalData, userId, dispatch]);

  useEffect(() => {
    if (mode === "pendingPositions") {
      setFinalData((prevData) => {
        const newData = prevData?.map((data) => {
          if (data.symbol === abcData?.newMessage?.symbol) {
            return {
              ...data,
              currentPrice: [1, 2, 4, 6].includes(data.type)
                ? fixedNumber(abcData?.newMessage.bid)
                : fixedNumber(abcData?.newMessage.ask),
            };
          }
          return data;
        });
        return newData;
      });
    }
  }, [abcData, mode]);

  const renderTable = (fields, data) => (
    <div
      className="font-semibold text-xs text-gray-500 "
      style={{
        height: isDetailVisible ? `${100 - chartHeight - 8}vh` : "",
      }}
    >
      <div
        className={`w-full h-1 cursor-row-resize bg-slate-100 ${
          dragging ? "sticky" : ""
        }`}
        onPointerDown={handlePointerDown}
      />

      <div className={styles.sortAndFilter}>
        <select onChange={(e) => setSortBy(e.target.value)}>
          <option value="">Sort By</option>
          <option value="symbol">Symbol</option>
          <option value="ticket">Ticket</option>
          <option value="type">Type</option>
          <option value="volume">Volume</option>
          <option value="openTime">Open Time</option>
          <option value="closeTime">Close Time</option>
          <option value="profit">Profit</option>
        </select>
        <div>
          <input
            type="date"
            placeholder="From Date"
            defaultValue="2020-01-01"
            onChange={(e) => setStartDate(e.target.value)}
          />
          <input
            type="date"
            placeholder="To Date"
            defaultValue={getCurrentDateFormatted()}
            onChange={(e) => setEndDate(e.target.value)}
          />{" "}
        </div>{" "}
      </div>
      <div className={styles.scrollTable}>
        {/* ..... */}
        <table className={styles.table}>
          <thead>
            <tr>
              {fields.map((field, index) =>
                index === 0 ? null : <th key={index}>{field}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((rowData, rowIndex) => (
              <tr
                key={rowIndex}
                className="border-b border-slate-200 hover:text-blue-600 hover:bg-slate-100"
              >
                {Object.keys(rowData).map((key, cellIndex) =>
                  cellIndex === 0 ? null : (
                    <td key={cellIndex}>{rowData[key]}</td>
                  )
                )}
                {mode === "runningPositions" || mode === "pendingPositions" ? (
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <span
                        className={styles.redCross}
                        onClick={() => handleExit(rowData)}
                      >
                        &#10060;
                      </span>
                      <span>
                        <AiFillEdit
                          size={16}
                          className="text-blue-700 hover:text-blue-500 hover:cursor-pointer"
                          onClick={() => handleModifyClick(rowData)}
                        />

                        {modify && selectedPosition && (
                          <div className={styles.modifyOverlay}>
                            <ModifyPositionForm
                              closeHandler={() => {
                                setModify(false);
                                setSelectedPosition(null);
                              }}
                              posData={{
                                symbol: selectedPosition.symbol,
                                positionId: selectedPosition.id,
                                type: selectedPosition.type,
                                ticket: selectedPosition.ticket,
                                price: selectedPosition.price,
                                stopLoss: selectedPosition.stopLoss,
                                takeProfit: selectedPosition.takeProfit,
                              }}
                            />
                          </div>
                        )}

                        {showPPC && (
                          <div className={styles.modifyOverlay}>
                            <PPC
                              data={selectedPosition}
                              onClose={() => setShowPPC(false)}
                            />
                          </div>
                        )}
                      </span>
                    </div>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  let tableComponent;
  switch (mode) {
    case "runningPositions":
      tableComponent = renderTable(positionFields, finalData || []);
      break;
    case "orders":
      tableComponent = renderTable(orderFields, finalData || []);
      break;
    case "deals":
      tableComponent = renderTable(dealsField, finalData || []);
      break;
    case "closedPositions":
      tableComponent = renderTable(closedPositionField, finalData || []);
      break;
    case "pendingPositions":
      tableComponent = renderTable(positionFields, finalData || []);
      break;
    default:
      tableComponent = <p className="text-center"> Refresh</p>;
  }

  return <div>{tableComponent}</div>;
};

export default Detail;
