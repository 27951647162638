import { CrosshairMode, LineStyle } from "lightweight-charts";
import { fixedNumber } from "../../../helpers";

export const indicatorChartOptionsFunc = (chartContainerRef) => {
  const options = {
    // autoSize: true,
    width: chartContainerRef.current.clientWidth,
    height: chartContainerRef.current.clientHeight * 0.4,
    localization: {
      fixedNumber
    },
    rightPriceScale: {
      scaleMargins: {
        top: 0.3,
        bottom: 0.3,
      },
      borderVisible: true,
      borderColor: "#f2f2f2"
    },

    layout: {
      background: {
        type: "solid",
        color: "#FFF",
      },
      textColor: "#000",
    },
    grid: {
      vertLines: {
        // color: "rgba(197, 203, 206, 0.5)",
        color: "#fff",
      },
      horzLines: {
        // color: "rgba(197, 203, 206, 0.5)",
        color: "#fff",
      },
    },
    timeScale: {
      borderColor: "#f2f2f2",

      visible: true,
      timeVisible: true,
      secondsVisible: false,
      rightOffset: 30,
      tickMarkFormatter: (time, tickMarkType, locale) => {
        const date = new Date(time * 1000);
        const day = date.getDate();
        const month = date.toLocaleString(locale, { month: "short" });
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        return month + " " + day + " " + hours + ":" + minutes;
      },
    },
    crosshair: {
      mode: CrosshairMode.Normal,
      vertLine: {
        style: LineStyle.Solid,
      },

      horzLine: {
        style: LineStyle.Solid,
      },
    },
  }

  return options
}