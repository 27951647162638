import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "CCI",
    timeFrame: 1,
    period: 4,
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "red",
        lineStyle: 0,
        lineWidth: 1,   
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
};

const cciSlice = createSlice({
    name: "CCI",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
        setColor: (state, action) => {
            state.styles.color = action.payload;
        },
        setThickness: (state, action) => {
            state.styles.lineWidth = action.payload;
        },
        resetCci: () => {return initialState}
    }
});

export const {
    setTimeFrame,
    setPeriod,
    setColor,
    setThickness,
    resetCci
} = cciSlice.actions;

export default cciSlice.reducer;
