import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSmaCalcSource,
  setSmaColor,
  setSmaLineStyle,
  setSmaLineType,
  setSmaLineWidth,
  setSmaPeriod,
  setSmaTimeFrame,
  setSmaPointMarkerVisible,
  toggleSmaLineVisibility,
  toggleSmaPriceLineVisibility,
  resetSmaSettings,
} from '../../../store/indicators/smaSlice';
import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../wrappers/IndicatorSettingWrapper';
import { changeTime, fetchChartData } from '../../../store/chartSlice';

const SMAComponent = ({onClose}) => {
  const dispatch = useDispatch();
  const smaState = useSelector((state) => state.sma);
  const [activeTab, setActiveTab] = useState('general');

  const handleInputChange = (e, actionCreator) => {
    const value = e.target.type === 'number' ? Math.max(Number(e.target.min), Number(e.target.value)) : e.target.value;
    dispatch(actionCreator(value));
    if (actionCreator === setSmaTimeFrame) {
      dispatch(changeTime(value));
      dispatch(fetchChartData());
    }
  };

  const handleCheckboxChange = (e, actionCreator) => {
    dispatch(actionCreator(e.target.checked));
  };

  return (
    <IndicatorSettingWrapper onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.tabs}>
          <div
            className={`${styles.tab} ${activeTab === 'general' ? styles.active : ''}`}
            onClick={() => setActiveTab('general')}
          >
            General Settings
          </div>
          <div
            className={`${styles.tab} ${activeTab === 'appearance' ? styles.active : ''}`}
            onClick={() => setActiveTab('appearance')}
          >
            Appearance Settings
          </div>
          <div
            className={`${styles.tab} ${activeTab === 'visibility' ? styles.active : ''}`}
            onClick={() => setActiveTab('visibility')}
          >
            Visibility Settings
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>
          <div className={styles.setting}>
            <label>Period:</label>
            <input
              type="number"
              value={smaState.period}
              onChange={(e) => handleInputChange(e, setSmaPeriod)}
            />
          </div>
          <div className={styles.setting}>
            <label>Time Frame:</label>
            <select
              value={smaState.timeFrame}
              onChange={(e) => handleInputChange(e, setSmaTimeFrame)}
            >
              <option value={1}>1 minute</option>
              <option value={5}>5 minutes</option>
              <option value={15}>15 minutes</option>
              <option value={30}>30 minutes</option>
              <option value={60}>1 hour</option>
              <option value={240}>4 hours</option>
              <option value={1440}>1 day</option>
            </select>
          </div>
          <div className={styles.setting}>
            <label>Source:</label>
            <select
              value={smaState.source}
              onChange={(e) => handleInputChange(e, setSmaCalcSource)}
            >
              <option value="open">Open</option>
              <option value="close">Close</option>
              <option value="high">High</option>
              <option value="low">Low</option>
            </select>
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'appearance' ? styles.active : ''}`}>
          <div className={styles.setting}>
            <label>Color:</label>
            <input
              type="color"
              value={smaState.styles.color}
              onChange={(e) => handleInputChange(e, setSmaColor)}
            />
          </div>
          <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={smaState.styles.lineStyle}
              onChange={(e) => handleInputChange(e, setSmaLineStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
              <option value={3}>Large Dashed</option>
              <option value={4}>Sparse Dotted</option>
            </select>
          </div>
          {/* <div className={styles.setting}>
            <label>Line Type:</label>
            <select
              value={smaState.styles.lineType}
              onChange={(e) => handleInputChange(e, setSmaLineType)}
            >
              <option value={0}>Simple</option>
              <option value={1}>With Steps</option>
              <option value={2}>Curved</option>
            </select>
          </div> */}
          <div className={styles.setting}>
            <label>Line Width:</label>
            <input
              type="number"
              value={smaState.styles.lineWidth}
              onChange={(e) => handleInputChange(e, setSmaLineWidth)}
            />
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'visibility' ? styles.active : ''}`}>
          <div className={styles.setting}>
            <label>
              <input
                type="checkbox"
                checked={smaState.styles.pointMarkersVisible}
                onChange={(e) => handleCheckboxChange(e, setSmaPointMarkerVisible)}
              />
              Point Markers Visible
            </label>
          </div>
          <div className={styles.setting}>
            <label>
              <input
                type="checkbox"
                checked={smaState.styles.lineVisible}
                onChange={() => dispatch(toggleSmaLineVisibility())}
              />
              Line Visible
            </label>
          </div>
          <div className={styles.setting}>
            <label>
              <input
                type="checkbox"
                checked={smaState.styles.priceLineVisible}
                onChange={() => dispatch(toggleSmaPriceLineVisibility())}
              />
              Price Line Visible
            </label>
          </div>
        </div>

        <button onClick={() => dispatch(resetSmaSettings())}>Reset</button>
      </div>
    </IndicatorSettingWrapper>
  );
};

export default SMAComponent;
