import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "PSAR",
    timeFrame: 1,
    step: 0.02,
    max: 0.2,
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "blue",
        lineStyle: 2,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
};

const psarSlice = createSlice({
    name: "PSAR",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload
        },
        setStep: (state, action) => {
            state.step = action.payload;
        },
        setMax: (state, action) => {
            state.max = action.payload;
        },
        setColor: (state, action) => {
            state.styles.color = action.payload;
        },
        setLineStyle: (state, action) => {
            state.styles.lineStyle = action.payload;
        },
        setLineWidth: (state, action) => {
            state.styles.lineWidth = action.payload;
        },
        resetPsar: () => { return initialState }
    }
});

export const {
    setStep,
    setMax,
    setColor,
    setLineStyle,
    setLineWidth,
    setTimeFrame,
    resetPsar
} = psarSlice.actions;

export default psarSlice.reducer;
