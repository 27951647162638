import React, { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveIndicator,
  setIndicatorAsNewChart,
  setShowSingleIndicator,
  setVisibility,
} from "../../store/indicators/indicatorsSlice";
import { setIndicator } from "../../store/indicators/activeIndicator";

const IndicatorsSAA = ({ onClose }) => {
  const indicatorsName = useSelector((state) => state.indicators.names);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleShowIndicator = (indicator) => {
    dispatch(setIndicator(indicator.name));
    dispatch(setVisibility(true));
    dispatch(setShowSingleIndicator(indicator.name));
    dispatch(setActiveIndicator(indicator.name));
  };

  const clearSearchTerm = () => {
    dispatch(setShowSingleIndicator("none"));
    dispatch(setVisibility(false));
    dispatch(setIndicatorAsNewChart(false));
  };

  const filteredItems = Object.values(indicatorsName).filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="fixed top-20 md:left-1/4 left-0 md:w-1/2  w-full h-3/4 bg-white flex flex-col z-50 p-4 rounded-lg shadow-md">
      <div className="flex justify-between items-center w-full mb-4">
        <h2 className="text-xl font-bold">Indicators</h2>
        <button className="p-2 rounded hover:bg-gray-300" onClick={onClose}>
          <IoCloseOutline size={24} />
        </button>
      </div>

      <button
        className="bg-gray-800 text-white py-2 px-4 rounded-lg mb-4 hover:bg-gray-900"
        onClick={clearSearchTerm}
      >
        Clear Indicator
      </button>

      <div className="flex items-center border border-gray-300 rounded-lg bg-gray-100 mb-4">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 rounded-lg outline-none bg-gray-100 w-full"
        />
        <button className="bg-gray-100 p-2 rounded-lg ">
          <IoIosSearch size={24} />
        </button>
      </div>

      <ul className="list-none p-0 mt-4 flex-1 overflow-y-auto scrollbar-thin">
        {filteredItems.map((item, index) => (
          <li
            key={index}
            className="p-2 mb-2 bg-white rounded-lg transition-colors duration-300 hover:bg-gray-200 cursor-pointer "
            onClick={() => handleShowIndicator(item)}
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IndicatorsSAA;
