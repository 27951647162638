import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import socket from "../../../socket";
import toast, { Toaster } from "react-hot-toast";

import {
  fetchUserDeals,
  fetchUserOrders,
  updatePendingPositions,
  updateRunningPositions,
} from "../../../store/onLoadSlice";
import { getAuthToken } from "../../../helpers";
import { refreshWallet } from "../../../store/appSlice";
import { addMarker } from "../../../store/chartSlice";

const InstantOrder = () => {
  const symbol = useSelector((state) => state.chart.symbol);
  const dispatch = useDispatch();
  const [livePrice, setlivePrice] = useState({ ask: "", bid: "" });
  const [successful, setSuccesful] = useState(false);
  const [quantity, setQuantity] = useState(0.01);

  const handleOrderResponse = (newDataPoint) => {
    console.log(newDataPoint, "here");
    if (newDataPoint?.getOrder?.valid === true) {
      dispatch(updatePendingPositions(newDataPoint?.getOrder?.position));
      dispatch(updateRunningPositions(newDataPoint?.getOrder?.position));
      toast.success(
        `Placed: ${newDataPoint?.getOrder?.valid} ${newDataPoint?.getOrder?.message}`
      );
    } else {
      toast.error(
        `Placed: ${newDataPoint?.getOrder?.valid} ${newDataPoint?.getOrder?.message}`
      );
    }

    dispatch(fetchUserDeals());
    dispatch(fetchUserOrders());
    dispatch(refreshWallet());
  };

  const handleBuy = () => {
    let post = {
      symbol: symbol,
      type: 1,
      qty: quantity,
      exc_type: 0,
      price: "",
      limit: "",
      stop_loss: "",
      take_profit: "",
      expiration: "",
      comment: "",
      token: getAuthToken(),
    };
    if (!successful) {
      // dispatch(
      //   addMarker({
      //     position: 'belowBar',
      //     color: '#2196F3',
      //     shape: 'arrowUp',
      //     text: "Buy @" + livePrice.ask,
      //     time: Date.now(),
      //   })
      // );
      socket.emit("newOrder", JSON.stringify(post));
    }
    socket.once("getOrder", handleOrderResponse);
    setSuccesful(false);
  };

  const handleSell = () => {
    let post = {
      symbol: symbol,
      type: 0,
      qty: quantity,
      exc_type: 0,
      price: "",
      limit: "",
      stop_loss: "",
      take_profit: "",
      expiration: "",
      comment: "",
      token: getAuthToken(),
    };

    if (!successful) {
      // dispatch(
      //   addMarker({`
      //     position: 'aboveBar',
      //     color: '#e91e63',
      //     shape: 'arrowDown',
      //     text: "Sell @" + livePrice.ask,
      //     time: Date.now(),
      //   })
      // );
      socket.emit("newOrder", JSON.stringify(post));
    }
    socket.once("getOrder", handleOrderResponse);

    setSuccesful(false);
  };

  useEffect(() => {
    socket.on("abc", (abcData) => {
      const data = abcData?.newMessage;
      setlivePrice((prev) =>
        data?.symbol === symbol ? { ask: data.ask, bid: data.bid } : prev
      );
    });

    return () => {
      socket.off("abc");
    };
  }, [symbol]);

  return (
    <div className="flex items-center 	">
      <button
        className="w-36 p-1 border-green-600 text-white cursor-pointer bg-green-600 rounded-tl-sm rounded-bl-sm hover:text-green-600 hover:bg-white border  disabled:cursor-not-allowed"
        onClick={handleBuy}
      >
        Buy {livePrice.ask}
      </button>
      <input
        type="number"
        min="0.01"
        step="0.01"
        placeholder="Quantity"
        value={quantity}
        onChange={(e) => setQuantity(Number(e.target.value))}
        className="p-1 border border-gray-300 border-l-0 border-r-0 text-center outline-none w-20"
      />
      <button
        className="w-36 p-1 border-red-600 text-white cursor-pointer bg-red-600 rounded-tr-sm rounded-br-sm hover:text-red-600 hover:bg-white border  disabled:cursor-not-allowed"
        onClick={handleSell}
      >
        Sell {livePrice.bid}
      </button>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </div>
  );
};

export default InstantOrder;
