import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { setAuthCred, setUserChangeTo } from "../../../helpers";
import { signUp } from "../../../api/queryFunctions";

const SignUp = () => {
  const [isProceedVisible, setIsProceedVisible] = useState(false);
  const {
    data: response,
    isSuccess,
    mutate: post,
    isError: error,
  } = useMutation({ mutationKey: "signup", mutationFn: () => signUp() });
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    post();
  };

  useEffect(() => {
    if (response?.data) {
      setAuthCred(response.data);
      console.log("response", response);
      setUserChangeTo({
        userId: response.data?.user?.userId,
        password: response.data?.user?.password,
        name: response.data?.user?.name,
      });
      setIsProceedVisible(true);
    }
  }, [response]);

  const handleProceed = () => {
    navigate("/");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-300 to-blue-700 text-white">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md fade-in">
        <h2 className="text-2xl font-bold mb-8 text-center text-blue-700">
          Sign Up
        </h2>
        <form onSubmit={handleSubmit}>
          {error && (
            <p className="text-red-500 text-center mb-4">
              Error Occurred, try again
            </p>
          )}
          {isProceedVisible && (
            <div className="mb-4 p-4 rounded-lg bg-gradient-to-r from-gray-200 to-gray-400 text-black">
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <p className="font-semibold">User ID:</p>
                  <span>{response?.data?.user?.userId}</span>
                </div>
                <div className="flex justify-between">
                  <p className="font-semibold">Password:</p>
                  <span>{response?.data?.user?.password}</span>
                </div>
                <div className="flex justify-between">
                  <p className="font-semibold">Balance:</p>
                  <span>{response?.data?.user?.balance}</span>
                </div>
              </div>
            </div>
          )}
          {!isProceedVisible && (
            <div className="text-center mb-4">
              <a
                href="https://betatrader11.com/client/register"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full py-3 rounded-lg bg-gradient-to-r from-purple-400 to-pink-500 text-white hover:from-purple-500 hover:to-pink-600 transition duration-300 ease-in-out"
              >
                Open A Live Account
              </a>
            </div>
          )}
          {!isProceedVisible && (
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white py-3 rounded-lg hover:from-green-500 hover:to-blue-600 transition duration-300 ease-in-out"
              id="signup"
              disabled={isSuccess}
            >
              Open A Demo Account
            </button>
          )}
          {isProceedVisible && (
            <button
              type="button"
              className="w-full mt-4 bg-green-500 text-white py-3 rounded-lg hover:bg-green-600 transition duration-300 ease-in-out"
              onClick={handleProceed}
            >
              Proceed To Login
            </button>
          )}
        </form>
        <div className="text-center mt-4">
          <Link
            className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out"
            to="/login"
          >
            Login Here
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
