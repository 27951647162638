import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import socket from "../../../socket";
import { refreshComponent } from "../../../store/modeSlice";
import { fetchUserPositions } from "../../../store/onLoadSlice";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { updatePosition } from "../../../api/queryFunctions";

const ModifyPositionForm = ({ closeHandler, posData }) => {
  const { symbol, positionId, type, ticket, price, stopLoss, takeProfit } =
    posData;

  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const { mutate: postUpdatePosition, data: res } = useMutation({
    mutationKey: "updateposition",
    mutationFn: (data) => updatePosition(data),
    onSettled: (data) => {
      dispatch(fetchUserPositions());
      toast.loading(data.data?.message);
      setSuccess(true);
    },
  });

  const [focusedField, setFocusedField] = useState();
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [socketCalled, setSocketCalled] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    stopLoss: stopLoss || "",
    takeProfit: takeProfit || "",
    price: "",
  });

  const validateForm = () => {
    const { stopLoss, takeProfit, price } = formData;
    let isDisabled = false;

    const stopLossValidation = () => {
      if ([0, 3, 5, 7].includes(type)) {
        isDisabled =
          stopLoss && price && parseFloat(stopLoss) <= parseFloat(price);
      } else {
        isDisabled =
          stopLoss && price && parseFloat(stopLoss) >= parseFloat(price);
      }
    };

    const takeProfitValidation = () => {
      if ([0, 3, 5, 7].includes(type)) {
        isDisabled =
          takeProfit && price && parseFloat(takeProfit) >= parseFloat(price);
      } else {
        isDisabled =
          takeProfit && price && parseFloat(takeProfit) <= parseFloat(price);
      }
    };

    const stopLossTakeProfitValidation = () => {
      if (
        stopLoss &&
        takeProfit &&
        parseFloat(stopLoss) >= parseFloat(takeProfit)
      ) {
        isDisabled = true;
      }
    };

    stopLossValidation();
    takeProfitValidation();
    stopLossTakeProfitValidation();

    setDisabled(isDisabled);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateForm();
  };

  const handleSLFocus = (e) => {
    setFocusedField(e.target.name);
    setSocketCalled(false);
  };

  const handleTPFocus = (e) => {
    setFocusedField(e.target.name);
    setSocketCalled(false);
  };

  const handleDecrease = (name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: (parseFloat(prevData[name]) - 0.01).toFixed(2),
    }));
  };

  const handleIncrease = (name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: (parseFloat(prevData[name]) + 0.01).toFixed(2),
    }));
  };

  const handleModifyPosition = (e) => {
    e.preventDefault();

    const { stopLoss, takeProfit } = formData;
    if (takeProfit && stopLoss) {
      postUpdatePosition({
        positionId: positionId,
        stopLoss: stopLoss,
        takeProfit: takeProfit,
      });
    }
    if (takeProfit && !stopLoss) {
      postUpdatePosition({
        positionId: positionId,
        takeProfit: takeProfit,
      });
    }
    if (stopLoss && !takeProfit) {
      postUpdatePosition({
        positionId: positionId,
        stopLoss: stopLoss,
      });
    }
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  useEffect(() => {
    dispatch(refreshComponent());
  }, [dispatch]);

  useEffect(() => {
    const socketListener = (datapoint) => {
      if (datapoint.newMessage.symbol === symbol) {
        setFormData((prev) => ({ ...prev, price: datapoint.newMessage.bid }));
        if (
          focusedField === "stopLoss" &&
          !socketCalled &&
          !formData.stopLoss
        ) {
          setSocketCalled(true);
          setFormData((prevData) => ({
            ...prevData,
            [focusedField]: datapoint.newMessage.bid,
          }));
        }

        if (
          focusedField === "takeProfit" &&
          !socketCalled &&
          !formData.takeProfit
        ) {
          setSocketCalled(true);
          setFormData((prevData) => ({
            ...prevData,
            [focusedField]: datapoint.newMessage.bid,
          }));
        }
      }
    };

    socket.on("abc", socketListener);

    return () => {
      socket.off("abc", socketListener);
    };
  }, [symbol, focusedField, socketCalled]);

  return (
    <div className="w-full max-w-md mx-auto  bg-white rounded-md border border-gray-200">
      <div className="text-blue-500 p-2 grid grid-cols-2  font-semibold mb-4 place-items-center w-full border-b border-gray-200">
        <div>
          {symbol}, {type}
        </div>
        <span>Current Price: {formData.price}</span>
        <span>#{ticket}</span>
        <span>Price: {price}</span>
      </div>

      {!success ? (
        <form>
          <div className="mb-4 p-2">
            <div className="flex flex-col mb-3">
              <div className="flex items-center mb-2">
                <p className="text-sm font-medium mr-2 w-1/4">Stop Loss</p>
                <input
                  type="number"
                  name="stopLoss"
                  placeholder="Set S/L"
                  value={formData.stopLoss}
                  onChange={handleChange}
                  onFocus={handleSLFocus}
                  className="flex-1 p-1 border border-gray-200 text-sm rounded-sm outline-none"
                />
                <button
                  className=" p-1 bg-blue-500 text-white text-sm border border-blue-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDecrease("stopLoss");
                  }}
                >
                  -
                </button>
                <button
                  className=" p-1 bg-blue-500 text-white text-sm border border-blue-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleIncrease("stopLoss");
                  }}
                >
                  +
                </button>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center mb-2">
                <p className="text-sm font-medium mr-2 w-1/4">Take Profit</p>
                <input
                  placeholder="Set T/P"
                  type="number"
                  name="takeProfit"
                  value={formData.takeProfit}
                  onChange={handleChange}
                  onFocus={handleTPFocus}
                  className="flex-1 p-1 border border-gray-200 text-sm rounded-sm outline-none"
                />
                <button
                  className=" p-1 bg-blue-500 text-white text-sm border border-blue-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleIncrease("takeProfit");
                  }}
                >
                  -
                </button>
                <button
                  className=" p-1 bg-blue-500 text-white text-sm border border-blue-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleIncrease("takeProfit");
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <div className="mb-4 text-sm text-blue-600 text-center">
            {validationErrorMessage}
          </div>
          <div className="text-sm text-blue-600 text-center mb-4">
            {res?.data?.message}
          </div>
          {!success && (
            <div className="flex p-2 justify-around">
              <button
                className=" p-2 bg-purple-600 text-white text-sm rounded-md  disabled:bg-slate-400"
                disabled={disabled}
                onClick={handleModifyPosition}
              >
                Modify
              </button>
              <button
                className=" p-2 bg-red-600 text-white text-sm rounded-md"
                type="button"
                onClick={closeHandler}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      ) : (
        <div className="flex justify-center">
          <button
            className="p-2 bg-purple-600 text-white text-sm rounded-sm mb-4"
            disabled={disabled}
            onClick={closeHandler}
          >
            Ok
          </button>
        </div>
      )}
    </div>
  );
};

export default ModifyPositionForm;
