import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setStep,
    setMax,
    setColor,
    setLineStyle,
    setLineWidth,
    setTimeFrame,
    resetPsar,
    
} from '../../../store/indicators/psarSlice';
import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../wrappers/IndicatorSettingWrapper';
import { changeTime, fetchChartData } from '../../../store/chartSlice';

const PSARComponent = ( {onClose}) => {
    const dispatch = useDispatch();
    const psarState = useSelector(state => state.pSAR);
    const [activeTab, setActiveTab] = useState('general');
    const handleInputChange = (e, actionCreator) => {
        const value = e.target.type === 'number' ? Math.max(Number(e.target.min), Number(e.target.value)) : e.target.value;
        dispatch(actionCreator(value));
        if (actionCreator === setTimeFrame) {
          dispatch(changeTime(value));
          dispatch(fetchChartData());
        }
      };

    return (
        <IndicatorSettingWrapper onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <div
                        className={`${styles.tab} ${activeTab === 'general' ? styles.active : ''}`}
                        onClick={() => setActiveTab('general')}
                    >
                        General Settings
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === 'style' ? styles.active : ''}`}
                        onClick={() => setActiveTab('style')}
                    >
                        Style Settings
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>
                  
                <div className={styles.setting}>
            <label>Time Frame:</label>
            <select
              value={psarState.timeFrame}
              onChange={(e) => handleInputChange(e, setTimeFrame)}
            >
              <option value={1}>1 minute</option>
              <option value={5}>5 minutes</option>
              <option value={15}>15 minutes</option>
              <option value={30}>30 minutes</option>
              <option value={60}>1 hour</option>
              <option value={240}>4 hours</option>
              <option value={1440}>1 day</option>N
            </select>
          </div>
                    <div className={styles.setting}>
                        <label>Step:</label>
                        <input
                            type="number"
                            step="0.01"
                            value={psarState.step}
                            onChange={(e) => handleInputChange(e, setStep)}
                        />
                    </div>
                    <div className={styles.setting}>
                        <label>Max:</label>
                        <input
                            type="number"
                            step="0.01"
                            value={psarState.max}
                            onChange={(e) => handleInputChange(e, setMax)}
                        />
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'style' ? styles.active : ''}`}>
                    <div className={styles.setting}>
                        <label>Color:</label>
                        <input
                            type="color"
                            value={psarState.styles.color}
                            onChange={(e) => handleInputChange(e, setColor)}
                        />
                    </div>
                    <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={psarState.styles.lineStyle}
              onChange={(e) => handleInputChange(e, setLineStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
              <option value={3}>Large Dashed</option>
              <option value={4}>Sparse Dotted</option>
            </select>
          </div>
                    <div className={styles.setting}>
                        <label>Line Width:</label>
                        <input
                            type="number"
                            value={psarState.styles.lineWidth}
                            onChange={(e) => handleInputChange(e, setLineWidth)}
                        />
                    </div>
                </div>
                <button onClick={() => dispatch(resetPsar())}>Reset</button>

            </div>
        </IndicatorSettingWrapper>
    );
};

export default PSARComponent;
