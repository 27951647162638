import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "KC",
  period: 20,
  timeFrame: 1,
  styles: {
    upper: {
      styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "skyblue",
        lineStyle: 0,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
      }
    },
    middle: {
      styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "skyblue",
        lineStyle: 0,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
      }
    },
    lower: {
      styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "skyblue",
        lineStyle: 0,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
      }
    }
  }

}

const kcSlice = createSlice({
  name: "KC",
  initialState,
  reducers: {
    setPeriod: (state, action) => {
      const newPeriod = action.payload
      state.period = newPeriod
    },
    setTimeFrame: (state, action) => {
      const newTimeFrame = action.payload
      state.timeFrame = newTimeFrame
    },
    setUpperBandColor: (state, action) => {
      const newColor = action.payload
      state.styles.upper.styles.color = newColor
    },
    setUpperBandLineStyle: (state, action) => {
      state.styles.upper.styles.lineStyle = action.payload
    },
    setUpperBandThickness: (state, action) => {
      const newThickeness = action.payload
      state.styles.upper.styles.lineWidth = newThickeness
    },
    setMiddleBandColor: (state, action) => {
      const newColor = action.payload
      state.styles.middle.styles.color = newColor
    },
    setMiddleBandLineStyle: (state, action) => {
      state.styles.middle.styles.lineStyle = action.payload
    },
    setMiddleBandThickness: (state, action) => {
      const newThickeness = action.payload
      state.styles.middle.styles.lineWidth = newThickeness
    },
    setLowerBandColor: (state, action) => {
      const newColor = action.payload
      state.styles.lower.styles.color = newColor
    },
    setLowerBandLineStyle: (state, action) => {
      state.styles.lower.styles.lineStyle = action.payload
    },
    setLowerBandThickness: (state, action) => {
      const newThickeness = action.payload
      state.styles.lower.styles.lineWidth = newThickeness
    },
    resetKc: () => initialState
  }
})

export const { setLowerBandColor, setLowerBandThickness, setMiddleBandColor, setMiddleBandThickness, setPeriod, setSource, setTimeFrame, setUpperBandColor, setUpperBandThickness, setLowerBandLineStyle, setMiddleBandLineStyle, setUpperBandLineStyle, resetKc } = kcSlice.actions
export default kcSlice.reducer