import { useEffect, useState } from "react";
import socket from ".";


const useLivePriceSocket = () => {
  const [abcData, setAbcData] = useState(null);
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    const handleNewMessage = (data) => {
      // console.log("lp")
      setAbcData({ ...data });    };

    const handleDisconnect = () => {
      setIsConnected(false);
      socket.off("abc", handleNewMessage);
    };

    socket.on("connect", () => setIsConnected(true));
    socket.on("disconnect", handleDisconnect);
    socket.on("abc", handleNewMessage);

    return () => {
      socket.off("connect");
      socket.off("disconnect", handleDisconnect);
      socket.off("abc", handleNewMessage);
    };
  }, []);

  return { abcData, isConnected };
};

export default useLivePriceSocket;