import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../api/apiClient";

const initialState = {
  userData: null,
  watchListData: null,
  userPositions: null,
  runningPositions: null,
  pendingPositions: null,
  userClosedPositions: null,
  userOrders: null,
  userDeals: null,
  allSymbols: null,
  loop: true
  
};

export const fetchUserData = createAsyncThunk(
  "onLoad/fetchUserData",
  async () => {
    const response = await apiClient.get("user");
    return response.data;
  }
); 

export const fetchSymbols = createAsyncThunk(
  "onLoad/fetchSymbols",
  async () => {
    const response = await apiClient.get("get-group-symbols");
    return response.data.symbolData;
  }
);

export const fetchWatchListData = createAsyncThunk(
  "onLoad/fetchwatchListData",
  async () => {
    const response = await apiClient.get("get-watchlist-data");
    return response.data.message;
  }
);

export const fetchUserPositions = createAsyncThunk(
  "onLoad/fetchUserPositions",
  async () => {
    const response = await apiClient.get("get-positions");
    return response.data.positions;
  }
);

export const fetchUserClosedPositions = createAsyncThunk(
  "onLoad/fetchUserClosedPositions",
  async () => {
    const response = await apiClient.get("get-closed-positions");
    return response.data;
  }
);

export const fetchUserOrders = createAsyncThunk(
  "onLoad/fetchUserOrders",
  async () => {
    const response = await apiClient.get("get-user-orders");
    return response.data;
  }
);

export const fetchUserDeals = createAsyncThunk(
  "onLoad/fetchUserDeals",
  async () => {
    const response = await apiClient.get("get-user-deals");
    return response.data;
  }
);

const onLoadSlice = createSlice({
  name: "onLoad",
  initialState,
  reducers: {
    resetState: (state) => {
      state = {
        userData: null,
        watchListData: null,
        userPositions: null,
        userClosedPositions: null,
        userOrders: null,
        userDeals: null,
        allSymbols: null,
        runningPositions: null,
        pendingPositions: null,
      };
    },

    updateRunningPositions: (state, action) => {
      if (action.payload.status === 1) {
        const newData = [...state.runningPositions, action.payload];
        state.runningPositions = newData;
      }
    },
    updatePendingPositions: (state, action) => {
      if (action.payload.status === 0) {
        const newData = [...state.pendingPositions, action.payload];
        state.pendingPositions = newData;
      }
    },

    removingPendingPositions: (state, action) => {
      state.pendingPositions = state.pendingPositions.filter(
        (item) => item._id !== action.payload
      );
    },
    removeRunningPositions: (state, action) => {
      state.runningPositions = state.runningPositions.filter(
        (item) => item._id !== action.payload
      );
    },

    updateUserPositions: (state, action) => {
      const newData = [...state.userPositions, action.payload];
      state.userPositions = newData;
    },
    removeUserPosition: (state, action) => {
      const newData = state.userPositions.filter(
        (item) => item._id !== action.payload
      );
      state.userPositions = newData;
    },
    resetOnLoad: (state) => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.userData = action.payload;
      })

      .addCase(fetchWatchListData.fulfilled, (state, action) => {
        state.watchListData = action.payload;
      })

      .addCase(fetchUserPositions.fulfilled, (state, action) => {
        state.userPositions = action.payload;
        const positions = action.payload;

        // Separate positions based on status
        const runningPositions = positions.filter(
          (position) => position.status === 1
        );
        const pendingPositions = positions.filter(
          (position) => position.status === 0
        );

        state.runningPositions = runningPositions;
        state.pendingPositions = pendingPositions;
      })

      .addCase(fetchUserClosedPositions.fulfilled, (state, action) => {
        state.userClosedPositions = action.payload;
      })

      .addCase(fetchUserOrders.fulfilled, (state, action) => {
        state.userOrders = action.payload;
      })

      .addCase(fetchUserDeals.fulfilled, (state, action) => {
        state.userDeals = action.payload;
      })

      .addCase(fetchSymbols.fulfilled, (state, action) => {
        state.allSymbols = action.payload;
      });
  },
});

export const {
  updateUserPositions,
  resetState: resetOnLoadState,
  removeUserPosition,
  removeRunningPositions,
  removingPendingPositions,
  updatePendingPositions,
  updateRunningPositions
} = onLoadSlice.actions;

export default onLoadSlice.reducer;
