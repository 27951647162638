import { calculateFibonacciLevels } from "../../store/drawingsOnChart/fibR";
import { calculateRegressionChannel } from "../../store/drawingsOnChart/regressionLineSlice";
import styles from "./Chart.module.css";

export const Tooltip = (type, symbol, open, high, low, close, value) => {
  return type !== "area"
    ? `
      <div class=${styles.tooltipContent}>
        <div class=${styles.tooltipRow}><span class=${styles.tooltipLabel} style="color: blue;">Symbol:</span> ${symbol}</div>
        <div class=${styles.tooltipRow}><span class=${styles.tooltipLabel}>Open:</span> ${open}</div>
        <div class=${styles.tooltipRow}><span class=${styles.tooltipLabel}>High:</span> ${high}</div>
        <div class=${styles.tooltipRow}><span class=${styles.tooltipLabel}>Low:</span> ${low}</div>
        <div class=${styles.tooltipRow}><span class=${styles.tooltipLabel}>Close:</span> ${close}</div>
      </div>
  
      
    `
    : `<div class=${styles.tooltipContent}>
    <div class=${styles.tooltipRow}><span class=${
        styles.tooltipLabel
      } style="color: blue;">Symbol:</span> ${symbol}</div>
    <div class=${styles.tooltipRow}><span class=${
        styles.tooltipLabel
      }>Value:</span> ${value.toFixed(6)}</div>
  </div>`;
};

export const drawRegressionLine = (
  channel,
  chart,
  regressionChannelStart,
  regressionChannelEnd,
  ohlcData
) => {
  if (channel && regressionChannelStart && regressionChannelEnd) {
    let start = regressionChannelStart;
    let end = regressionChannelEnd;
    if (regressionChannelStart.time > regressionChannelEnd.time) {
      start = regressionChannelEnd;
      end = regressionChannelStart;
    }
    const { regressionLine, upperChannel, lowerChannel } =
      calculateRegressionChannel(start, end, ohlcData);

    if (regressionLine && upperChannel && lowerChannel) {
      const regressionSeries = chart.addLineSeries({
        color: "blue",
        lineStyle: 0, // solid:0, dotted: 1, dashed:2, LargeDashed:3, sparseDotted:4
        lineWidth: 2,
        lineType: 0, // simple:0, WithSteps:1, curved:2
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 4,
        lineVisible: true,
        priceLineVisible: false,
        lastPriceAnimation: 0,
        lastValueVisible: true,
      });
      regressionSeries.setData(regressionLine);

      const upperSeries = chart.addLineSeries({
        color: "blue",
        lineStyle: 0, // solid:0, dotted: 1, dashed:2, LargeDashed:3, sparseDotted:4
        lineWidth: 2,
        lineType: 0, // simple:0, WithSteps:1, curved:2
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 4,
        lineVisible: true,
        priceLineVisible: false,
        lastPriceAnimation: 0,
        lastValueVisible: true,
      });
      upperSeries.setData(upperChannel);

      const lowerSeries = chart.addLineSeries({
        color: "blue",
        lineStyle: 0, // solid:0, dotted: 1, dashed:2, LargeDashed:3, sparseDotted:4
        lineWidth: 2,
        lineType: 0, // simple:0, WithSteps:1, curved:2
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 4,
        lineVisible: true,
        priceLineVisible: false,
        lastPriceAnimation: 0,
        lastValueVisible: true,
      });
      lowerSeries.setData(lowerChannel);
    }
  }
};

// export const drawFibonacciRetracement = (
//   fibonacciR,
//   fibStart,
//   fibEnd,
//   chart
// ) => {
//   if (fibonacciR && fibStart && fibEnd) {
//     let start = fibStart;
//     let end = fibEnd;
//     if (fibStart.time > fibEnd.time) {
//       start = fibEnd;
//       end = fibStart;
//     }
//     const fibLevels = calculateFibonacciLevels(start, end);
//     const colors = [
//       "rgba(255, 0, 0, 0.3)",
//       "rgba(0, 255, 0, 0.3)",
//       "rgba(0, 0, 255, 0.3)",
//       "rgba(255, 255, 0, 0.3)",
//       "rgba(255, 0, 255, 0.3)",
//       "rgba(0, 255, 255, 0.3)",
//     ];

//     fibLevels.forEach((level, index) => {
//       const baseValue =
//         index < fibLevels.length - 1 ? fibLevels[index + 1].value : level.value;

//       const baselineSeries = chart.addBaselineSeries({
//         baseValue: {
//           type: "price",
//           price: baseValue,
//         },
//         lineWidth: 2,
//         lineStyle: 0,
//         topLineColor: colors[index % colors.length],
//         bottomLineColor: "rgba(0,0,0,0)",
//         topFillColor: colors[index % colors.length],
//         bottomFillColor: "rgba(0,0,0,0)",
//       });

//       baselineSeries.setData([
//         { time: start.time, value: level.value },
//         { time: end.time, value: level.value },
//       ]);
//     });
//   }
// };

export const drawFibonacciRetracement = (
  fibonacciR,
  fibStart,
  fibEnd,
  chart
) => {
  if (fibonacciR && fibStart && fibEnd) {
    let start = fibStart;
    let end = fibEnd;
    if (fibStart.time > fibEnd.time) {
      start = fibEnd;
      end = fibStart;
    }
    const fibLevels = calculateFibonacciLevels(start, end);
    const colors = [
      "rgba(255, 0, 0, 0.5)", // Red
      "rgba(0, 255, 0, 0.5)", // Green
      "rgba(0, 0, 255, 0.5)", // Blue
      "rgba(255, 255, 0, 0.5)", // Yellow
      "rgba(255, 0, 255, 0.5)", // Magenta
      "rgba(0, 255, 255, 0.5)", // Cyan
    ];

    fibLevels.forEach((level, index) => {
      const baseValue =
        index < fibLevels.length - 1 ? fibLevels[index + 1].value : level.value;

      const baselineSeries = chart.addBaselineSeries({
        baseValue: {
          type: "price",
          price: baseValue,
        },
        lineWidth: 2,
        lineStyle: 0,
        topLineColor: colors[index % colors.length],
        bottomLineColor: "rgba(0,0,0,0)",
        topFillColor: colors[index % colors.length],
        bottomFillColor: "rgba(0,0,0,0)",
      });

      baselineSeries.setData([
        { time: start.time, value: level.value },
        { time: end.time, value: level.value },
      ]);
    });
  }
};
