import React from "react";
import {
  AiOutlineFundView,
  AiOutlineLineChart,
  AiOutlineInfoCircle,
  AiOutlineWallet,
  AiOutlineUser,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../../../store/mobileViewSlice";

const BottomTab = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.mobileView);

  const tabs = [
    {
      name: "watchlist",
      icon: <AiOutlineFundView size={24} />,
      label: "WatchList",
    },
    { name: "chart", icon: <AiOutlineLineChart size={24} />, label: "Chart" },
    {
      name: "detail",
      icon: <AiOutlineInfoCircle size={24} />,
      label: "History",
    },
    { name: "wallet", icon: <AiOutlineWallet size={24} />, label: "Trade" },
    { name: "account", icon: <AiOutlineUser size={24} />, label: "Account" },
  ];

  return (
    <div className="fixed bottom-0 w-full h-16 bg-white border-t-2 border-gray-300 shadow-lg flex justify-around items-center z-50 rounded-t-lg">
      {tabs.map((tab) => (
        <div
          key={tab.name}
          className={`flex flex-col items-center justify-center transition-colors ease-in-out duration-300 ${
            activeTab[tab.name]
              ? "text-blue-500"
              : "text-green-700 hover:text-blue-500"
          }`}
          onClick={() => dispatch(changeTab(tab.name))}
        >
          {tab.icon}
          <span className="text-sm">{tab.label}</span>
        </div>
      ))}
    </div>
  );
};

export default BottomTab;
