import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "APO",
    timeFrame: 1,
    fast: 14,
    slow: 30,
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "violet",
        lineStyle: 0,
        source: "open",
        lineWidth: 2,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
};

const apoSlice = createSlice({
    name: "APO",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setFast: (state, action) => {
            state.fast = action.payload;
        },
        setSlow: (state, action) => {
            state.slow = action.payload;
        },
        setColor: (state, action) => {
            state.styles.color = action.payload;
        },
        setLineStyle: (state, action) => {
            state.styles.lineStyle = action.payload;
        },
        setSource: (state, action) => {
            state.styles.source = action.payload;
        },
        setLineWidth: (state, action) => {
            state.styles.lineWidth = action.payload;
        },
        resetApo: () => {
            return initialState
        }
    }
});

export const {
    setTimeFrame,
    setFast,
    setSlow,
    setColor,
    setLineStyle,
    setSource,
    setLineWidth,
    resetApo
} = apoSlice.actions;

export default apoSlice.reducer;
