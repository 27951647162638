import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "qstick",
    timeFrame: 1,
    period: 14,
    styles: {
        baseValue: { type: 'value', value: 0 },
        topLineColor: 'blue',
        topFillColor1: 'rgba(38, 166, 154, 0.5)',
        topFillColor2: 'rgba(38, 166, 154, 0.5)',
        bottomLineColor: 'blue',
        bottomFillColor1: 'rgba(239, 83, 80, 0.5)',
        bottomFillColor2: 'rgba(239, 83, 80, 0.05)',
        lineWidth: 1,
    }
};

const qstickSlice = createSlice({
    name: "qstick",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
        setBaseValue: (state, action) => {
            state.styles.baseValue.value = action.payload;
        },
        setTopLineColor: (state, action) => {
            state.styles.topLineColor = action.payload;
        },
        setTopFillColor1: (state, action) => {
            state.styles.topFillColor1 = action.payload;
        },
        setTopFillColor2: (state, action) => {
            state.styles.topFillColor2 = action.payload;
        },
        setBottomLineColor: (state, action) => {
            state.styles.bottomLineColor = action.payload;
        },
        setBottomFillColor1: (state, action) => {
            state.styles.bottomFillColor1 = action.payload;
        },
        setBottomFillColor2: (state, action) => {
            state.styles.bottomFillColor2 = action.payload;
        },
        setLineWidth: (state, action) => {
            state.styles.lineWidth = action.payload;
        },
        resetQstick: () =>  initialState
    }
});

export const {
    setTimeFrame,
    setPeriod,
    setBaseValue,
    setTopLineColor,
    setTopFillColor1,
    setTopFillColor2,
    setBottomLineColor,
    setBottomFillColor1,
    setBottomFillColor2,
    setLineWidth,
    resetQstick
} = qstickSlice.actions;

export default qstickSlice.reducer;
