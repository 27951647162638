import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  period: 9,
  source: "open",
  timeFrame: 1,
  styles: {
    color: "#87CEEB",
    lineStyle: 0, // solid:0, dotted: 1, dashed:2, LargeDashed:3, sparseDotted:4
    lineWidth: 1,
    lineType: 0, // simple:0, WithSteps:1, curved:2
    pointMarkersVisible: false,
    pointMarkersRadius: undefined,
    crosshairMarkerVisible: true,
    crosshairMarkerRadius: 4,
    lineVisible: true,
    priceLineVisible: false,
    lastPriceAnimation: 1,
    lastValueVisible: true,
  },
};

const emaSlice = createSlice({
  name: "ema",
  initialState,
  reducers: {
    setTimeFrame: (state, action) => {
      state.timeFrame = action.payload;
    },
    setEmaColor: (state, action) => {
      state.styles.color = action.payload;
    },
    setEmaPeriod: (state, action) => {
      state.period = action.payload;
    },
    setEmaCalcSource: (state, action) => {
      state.source = action.payload;
    },
    setEmaLineStyle: (state, action) => {
      state.styles.lineStyle = action.payload;
    },
    setEmaLineType: (state, action) => {
      state.styles.lineType = action.payload;
    },
    setEmaLineWidth: (state, action) => {
      state.styles.lineWidth = action.payload;
    },
    setEmaPointMarkerVisible: (state, action) => {
      state.styles.pointMarkersVisible = action.payload;
    },
    resetEmaSettings: (state) => {
      return initialState;
    },
    toggleEmaLineVisibility: (state) => {
      state.styles.lineVisible = !state.styles.lineVisible;
    },
    toggleEmaPriceLineVisibility: (state) => {
      state.styles.priceLineVisible = !state.styles.priceLineVisible;
    },
  },
});

export const {
  setTimeFrame,
  setEmaPeriod,
  setEmaCalcSource,
  setEmaLineStyle,
  setEmaLineType,
  setEmaLineWidth,
  setEmaPointMarkerVisible,
  resetEmaSettings,
  toggleEmaLineVisibility,
  toggleEmaPriceLineVisibility,
  setEmaColor,
} = emaSlice.actions;

export default emaSlice.reducer;
