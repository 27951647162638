import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserCredentials,
  removeAuthToken,
  setUserChangeTo,
  setUserCredentials,
} from "../../helpers";
import { useNavigate } from "react-router-dom";
import socket from "../../socket";
import { resetMobileViewState } from "../../store/mobileViewSlice";
import { resetSymbols } from "../../store/symbolsSlice";
import { resetUserState } from "../../store/userSlice";
import { resetWatchlistState } from "../../store/watchlistSlice";
import { resetOnLoadState } from "../../store/onLoadSlice";
import { resetMode } from "../../store/modeSlice";
import { resetActiveIndicator } from "../../store/indicators/activeIndicator";
import { resetIndicators } from "../../store/indicators/indicatorsSlice";
import { resetApp } from "../../store/appSlice";

const Account = () => {
  const { email, name } = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = getUserCredentials()?.filter((user) => user.userId !== email);

  const runOnce = () => {
    dispatch(resetApp());
    dispatch(resetOnLoadState());
    dispatch(resetMobileViewState());
    dispatch(resetMode());
    dispatch(resetWatchlistState());
    dispatch(resetSymbols());
    dispatch(resetActiveIndicator());
    dispatch(resetIndicators());
    dispatch(resetUserState());
    removeAuthToken("AUTH-TOKEN");
  };

  const handleLogOut = () => {
    runOnce();
    socket.disconnect();
    navigate("/login");
  };

  const handleLogIn = (id, password) => {
    runOnce();
    setUserCredentials({ userId: id, password: password, name: name });
    setUserChangeTo({
      userId: id,
      password: password,
      name: name,
    });
    navigate("/login");
  };

  return (
    <div className="flex flex-col md:flex-row bg-gray-100 p-6 space-y-6 md:space-y-0 md:space-x-6">
      <div className=" relative m-5 p-5 flex flex-col items-center text-black border border-gray-200 rounded-lg gap-5 md:w-48 bg-white shadow-lg transition duration-500 ease-in-out transform hover:scale-105">
        <div
          className={` absolute top-0 left-0 text-xs font-bold py-1 px-3 rounded-sm text-white mb-2 transition-transform duration-300 self-start ${
            name === "DEMO USER" ? "bg-yellow-500 " : "bg-blue-500  "
          }`}
        >
          {name === "DEMO USER" ? "DEMO USER" : "LIVE ACCOUNT"}
        </div>
        <div>
          <img
            className="rounded-full"
            height={100}
            width={100}
            src="https://cdn.vectorstock.com/i/1000v/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.avif"
            alt="user"
          />
        </div>

        <div className="text-base font-semibold text-gray-800">{name}</div>
        <div className="font-semibold text-gray-600">{email}</div>
        <button
          className="outline-none py-1 px-3 text-lg text-white bg-red-700 border border-white rounded-lg font-medium transition duration-300 ease-in-out transform hover:bg-red-600 hover:scale-105"
          onClick={handleLogOut}
        >
          Logout
        </button>
      </div>
      {users.length > 0 && (
        <div className="p-5 flex flex-col items-center text-black border border-gray-200 rounded-lg gap-5 h-96 overflow-auto w-full bg-white shadow-lg transition duration-500 ease-in-out transform hover:scale-105">
          {users?.map((user) => (
            <div
              key={user.userId}
              className=" relative p-5 w-full flex flex-col items-start text-black border border-gray-200 rounded-lg gap-5 bg-white shadow-md transition duration-300 ease-in-out transform hover:shadow-xl"
            >
              <div
                className={` absolute top-0 left-0 text-xs font-bold py-1 px-3 rounded-sm text-white mb-2 transition-transform duration-300 self-start ${
                  name === "DEMO USER" ? "bg-yellow-500 " : "bg-blue-500  "
                }`}
              >
                {name === "DEMO USER" ? "DEMO USER" : "LIVE ACCOUNT"}
              </div>
              <div className="flex justify-between w-full">
                <img
                  className="rounded-full"
                  height={50}
                  width={50}
                  src="https://cdn.vectorstock.com/i/1000v/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.avif"
                  alt="user"
                />
                <div className="self-center text-gray-800">
                  <div className="text-base font-semibold">{user.userId}</div>
                  <div className="text-base font-semibold">{user.name}</div>
                </div>
              </div>
              <button
                className="outline-none py-1 px-3 text-sm text-white bg-green-700 border border-white rounded-lg font-medium self-center transition duration-300 ease-in-out transform hover:bg-green-600 hover:scale-105"
                onClick={() => handleLogIn(user.userId, user.password, user.name)}
              >
                Login
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Account;
