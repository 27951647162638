import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setShort,
  setMedium,
  setLong,
  setClose,
  setPeriod,
  setTenkanSenStyle,
  setKijunSenStyle,
  setSenkouSpanAStyle,
  setSenkouSpanBStyle,
  setChikouSpanStyle,
  resetIchimokuCloudSettings,
} from '../../../store/indicators/ichimokuCloudSlice';
import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../wrappers/IndicatorSettingWrapper';

const IchimokuCloudComponent = ({ onClose }) => {
  const dispatch = useDispatch();
  const ichimokuCloudState = useSelector((state) => state.imc);
  const [activeTab, setActiveTab] = useState('general');

  const handleInputChange = (e, actionCreator) => {
    dispatch(actionCreator(parseInt(e.target.value)));
  };

  const handleColorChange = (e, actionCreator) => {
    dispatch(actionCreator({ color: e.target.value }));
  };

  const handleSelectChange = (e, actionCreator) => {
    dispatch(actionCreator({ lineStyle: parseInt(e.target.value) }));
  };



  return (
    <IndicatorSettingWrapper onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.tabHeader}>
          <button
            className={activeTab === 'general' ? styles.activeTab : ''}
            onClick={() => setActiveTab('general')}
          >
            General Settings
          </button>
          <select
            className={styles.tabs}
            value={activeTab !== 'general' ? activeTab : ''}
            onChange={(e) => setActiveTab(e.target.value)}
          >
            <option value="">Select Series</option>
            <option value="tenkanSen">Tenkan-Sen Series</option>
            <option value="kijunSen">Kijun-Sen Series</option>
            <option value="senkouSpanA">Senkou Span A Series</option>
            <option value="senkouSpanB">Senkou Span B Series</option>
            <option value="chikouSpan">Chikou Span Series</option>
          </select>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>
          <h3>General Settings</h3>
          <div className={styles.setting}>
            <label>Short:</label>
            <input
              type="number"
              value={ichimokuCloudState.short}
              onChange={(e) => handleInputChange(e, setShort)}
            />
          </div>
          <div className={styles.setting}>
            <label>Medium:</label>
            <input
              type="number"
              value={ichimokuCloudState.medium}
              onChange={(e) => handleInputChange(e, setMedium)}
            />
          </div>
          <div className={styles.setting}>
            <label>Long:</label>
            <input
              type="number"
              value={ichimokuCloudState.long}
              onChange={(e) => handleInputChange(e, setLong)}
            />
          </div>
          <div className={styles.setting}>
            <label>Close:</label>
            <input
              type="number"
              value={ichimokuCloudState.close}
              onChange={(e) => handleInputChange(e, setClose)}
            />
          </div>
          <div className={styles.setting}>
            <label>Period:</label>
            <input
              type="number"
              value={ichimokuCloudState.period}
              onChange={(e) => handleInputChange(e, setPeriod)}
            />
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'tenkanSen' ? styles.active : ''}`}>
          <h3>Tenkan-Sen Series</h3>
          <div className={styles.setting}>
            <label>Color:</label>
            <input
              type="color"
              value={ichimokuCloudState.tenkanSenSeries.styles.color}
              onChange={(e) => handleColorChange(e, setTenkanSenStyle)}
            />
          </div>
          <div className={styles.setting}>
            <label>Line Width:</label>
            <input
              type="number"
              value={ichimokuCloudState.tenkanSenSeries.styles.lineWidth}
              onChange={(e) =>
                handleInputChange(e, (value) => setTenkanSenStyle({ lineWidth: value }))
              }
            />
          </div>
          <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={ichimokuCloudState.tenkanSenSeries.styles.lineStyle}
              onChange={(e) => handleSelectChange(e, setTenkanSenStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
            </select>
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'kijunSen' ? styles.active : ''}`}>
          <h3>Kijun-Sen Series</h3>
          <div className={styles.setting}>
            <label>Color:</label>
            <input
              type="color"
              value={ichimokuCloudState.kijunSenSeries.styles.color}
              onChange={(e) => handleColorChange(e, setKijunSenStyle)}
            />
          </div>
          <div className={styles.setting}>
            <label>Line Width:</label>
            <input
              type="number"
              value={ichimokuCloudState.kijunSenSeries.styles.lineWidth}
              onChange={(e) =>
                handleInputChange(e, (value) => setKijunSenStyle({ lineWidth: value }))
              }
            />
          </div>
          <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={ichimokuCloudState.kijunSenSeries.styles.lineStyle}
              onChange={(e) => handleSelectChange(e, setKijunSenStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
            </select>
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'senkouSpanA' ? styles.active : ''}`}>
          <h3>Senkou Span A Series</h3>
          <div className={styles.setting}>
            <label>Color:</label>
            <input
              type="color"
              value={ichimokuCloudState.senkouSpanASeries.styles.color}
              onChange={(e) => handleColorChange(e, setSenkouSpanAStyle)}
            />
          </div>
          <div className={styles.setting}>
            <label>Line Width:</label>
            <input
              type="number"
              value={ichimokuCloudState.senkouSpanASeries.styles.lineWidth}
              onChange={(e) =>
                handleInputChange(e, (value) => setSenkouSpanAStyle({ lineWidth: value }))
              }
            />
          </div>
          <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={ichimokuCloudState.senkouSpanASeries.styles.lineStyle}
              onChange={(e) => handleSelectChange(e, setSenkouSpanAStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
            </select>
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'senkouSpanB' ? styles.active : ''}`}>
          <h3>Senkou Span B Series</h3>
          <div className={styles.setting}>
            <label>Color:</label>
            <input
              type="color"
              value={ichimokuCloudState.senkouSpanBSeries.styles.color}
              onChange={(e) => handleColorChange(e, setSenkouSpanBStyle)}
            />
          </div>
          <div className={styles.setting}>
            <label>Line Width:</label>
            <input
              type="number"
              value={ichimokuCloudState.senkouSpanBSeries.styles.lineWidth}
              onChange={(e) =>
                handleInputChange(e, (value) => setSenkouSpanBStyle({ lineWidth: value }))
              }
            />
          </div>
          <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={ichimokuCloudState.senkouSpanBSeries.styles.lineStyle}
              onChange={(e) => handleSelectChange(e, setSenkouSpanBStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
            </select>
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'chikouSpan' ? styles.active : ''}`}>
          <h3>Chikou Span Series</h3>
          <div className={styles.setting}>
            <label>Color:</label>
            <input
              type="color"
              value={ichimokuCloudState.chikouSpanSeries.styles.color}
              onChange={(e) => handleColorChange(e, setChikouSpanStyle)}
            />
          </div>
          <div className={styles.setting}>
            <label>Line Width:</label>
            <input
              type="number"
              value={ichimokuCloudState.chikouSpanSeries.styles.lineWidth}
              onChange={(e) =>
                handleInputChange(e, (value) => setChikouSpanStyle({ lineWidth: value }))
              }
            />
          </div>
          <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={ichimokuCloudState.chikouSpanSeries.styles.lineStyle}
              onChange={(e) => handleSelectChange(e, setChikouSpanStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
            </select>
          </div>
        </div>

        <button onClick={() => dispatch(resetIchimokuCloudSettings())}>Reset</button>
      </div>
    </IndicatorSettingWrapper>
  );
};

export default IchimokuCloudComponent;
