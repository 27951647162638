import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";

import { useSelector, useDispatch } from "react-redux";
import socket from "../../../socket";
import { refreshComponent } from "../../../store/modeSlice";
import {
  fetchUserDeals,
  fetchUserOrders,
  fetchUserPositions,
  // updateUserPositions,
} from "../../../store/onLoadSlice";
import { getAuthToken, mapTypeToString } from "../../../helpers";
import { changeShowTradeForm } from "../../../store/watchlistSlice";
import useLivePriceSocket from "../../../socket/livePriceSocket";
import toast from "react-hot-toast";
import { addMarker } from "../../../store/chartSlice";

const TradeForm = () => {
  const { abcData } = useLivePriceSocket();
  const { symbol } = useSelector((state) => state.chart);
  const dispatch = useDispatch();
  // const { data, loading, postData } = useApiPost();
  const [focusedField, setFocusedField] = useState();
  const [socketCalled, setSocketCalled] = useState(false);
  // const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [livePrice, setlivePrice] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  const [response, setResponse] = useState("");
  const [formData, setFormData] = useState({
    executionType: "1",
    volume: 0.01,
    stopLoss: "",
    takeProfit: "",
    comment: "",
    price: "",
    limit: "",
    expiration: "gtc",
    date: "",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = (e) => {
    setIsDisabled(false);
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFocus = (e) => {
    setFocusedField(e.target.name);
    setSocketCalled(false);
  };

  const handleDecrease = (name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: (parseFloat(prevData[name]) - 0.01).toFixed(2),
    }));
  };

  const handleIncrease = (name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: (parseFloat(prevData[name]) + 0.01).toFixed(2),
    }));
  };

  const handleSubmitBuy = async (e) => {
    e.preventDefault();
    const createOrder = {
      symbol: symbol,
      type: 1,
      qty: formData.volume,
      exc_type: formData.executionType === "1" ? "0" : formData.executionType,
      price: "",
      limit: "",
      stop_loss: formData.stopLoss || "",
      take_profit: formData.takeProfit || "",
      expiration: "",
      token: getAuthToken(),
    };

    socket.emit("newOrder", JSON.stringify(createOrder));
    dispatch(
      addMarker({
        position: "belowBar",
        color: "#2196F3",
        shape: "arrowUp",
        text: "Buy @" + livePrice.ask,
        time: Date.now(),
      })
    );
    socket.once("getOrder", (newDataPoint) => {
      setResponse({
        symbol: newDataPoint?.getOrder?.position?.symbol,
        price: newDataPoint?.getOrder?.position?.price,
        ticket: newDataPoint?.getOrder?.position?.ticket,
        type: newDataPoint?.getOrder?.position?.type,
        volume: newDataPoint?.getOrder?.position?.volume,
      });
      setShowResponse(true);
      dispatch(fetchUserPositions());

      console.log("go", newDataPoint);

      if (newDataPoint?.getOrder?.valid === true) {
        toast.success(
          `Placed : ${newDataPoint?.getOrder?.valid} ${newDataPoint?.getOrder?.message}`
        );
      } else if (!newDataPoint?.getOrder?.valid) {
        toast.error(
          `Placed : ${newDataPoint?.getOrder?.valid} ${newDataPoint?.getOrder?.message}`
        );
      }

      dispatch(fetchUserDeals());
      dispatch(fetchUserOrders());
    });
  };

  const handleSubmitSell = async (e) => {
    e.preventDefault();
    const createOrder = {
      symbol: symbol,
      type: 0,
      qty: formData.volume,
      exc_type: formData.executionType === "1" ? "0" : formData.executionType,
      price: "",
      limit: "",
      stop_loss: formData.stopLoss || "",
      take_profit: formData.takeProfit || "",
      expiration: "",
      token: getAuthToken(),
    };
    socket.emit("newOrder", JSON.stringify(createOrder));
    dispatch(
      addMarker({
        position: "aboveBar",
        color: "#f68410",
        shape: "arrowDown",
        text: "Sell @" + livePrice.ask,
        time: Date.now(),
      })
    );
    socket.once("getOrder", (newDataPoint) => {
      console.log("go", newDataPoint);
      setResponse({
        symbol: newDataPoint?.getOrder?.position?.symbol,
        price: newDataPoint?.getOrder?.position?.price,
        ticket: newDataPoint?.getOrder?.position?.ticket,
        type: newDataPoint?.getOrder?.position?.type,
        volume: newDataPoint?.getOrder?.position?.volume,
      });
      setShowResponse(true);
      dispatch(fetchUserPositions());
      if (newDataPoint?.getOrder?.valid === true) {
        toast.success(
          `Placed : ${newDataPoint?.getOrder?.valid} ${newDataPoint?.getOrder?.message}`
        );
      } else if (!newDataPoint?.getOrder?.valid) {
        toast.error(
          `Placed : ${newDataPoint?.getOrder?.valid} ${newDataPoint?.getOrder?.message}`
        );
      }
    });
    dispatch(fetchUserDeals());
    dispatch(fetchUserOrders());
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    const createOrder = {
      symbol: symbol,
      type: Number(formData.executionType),
      exc_type: formData.executionType === "1" ? "0" : formData.executionType,
      qty: Number(formData.volume),
      price: Number(formData.price),
      limit: Number(formData.limit),
      stop_loss: formData.stopLoss || "",
      take_profit: formData.takeProfit || "",
      expiration:
        formData.executionType === "6" || formData.executionType === "7"
          ? formData.date
          : "",
      token: getAuthToken(),
    };
    console.log(createOrder, "createOrder");
    dispatch(
      addMarker({
        position: "aboveBar",
        color: "#f68410",
        shape: "arrowDown",
        text: "Traded @" + livePrice.ask,
        time: Date.now(),
      })
    );
    socket.emit("newOrder", JSON.stringify(createOrder));
    socket.once("getOrder", (newDataPoint) => {
      console.log("go", newDataPoint);
      setResponse({
        symbol: newDataPoint?.getOrder?.position?.symbol,
        price: newDataPoint?.getOrder?.position?.price,
        ticket: newDataPoint?.getOrder?.position?.ticket,
        type: newDataPoint?.getOrder?.position?.type,
        volume: newDataPoint?.getOrder?.position?.volume,
      });
      setShowResponse(true);

      dispatch(fetchUserPositions());
      if (newDataPoint.getOrder.valid === true) {
        toast.success(
          `Placed : ${newDataPoint?.getOrder?.valid} ${newDataPoint?.getOrder?.message}`
        );
      } else if (!newDataPoint?.getOrder?.valid) {
        toast.error(
          `Placed : ${newDataPoint?.getOrder?.valid} ${newDataPoint?.getOrder?.message}`
        );
      }
    });
    dispatch(fetchUserDeals());
    dispatch(fetchUserOrders());
  };

  useEffect(() => {
    dispatch(refreshComponent());
  }, [dispatch]);

  useEffect(() => {
    const validateForm = () => {
      const {
        type,
        executionType: exc_type,
        price,
        limit,
        stop_loss,
        take_profit,
      } = formData;

      if (exc_type === "1") {
        if (type === 0) {
          if (stop_loss > 0 && stop_loss < livePrice.bid) {
            setIsDisabled(true);
          }
          if (take_profit > 0 && take_profit > livePrice.bid) {
            setIsDisabled(true);
          }
        } else if (type === 1) {
          if (stop_loss > 0 && stop_loss > livePrice.bid) {
            setIsDisabled(true);
          }
          if (take_profit > 0 && take_profit < livePrice.bid) {
            setIsDisabled(true);
          }
        }
      } else if (exc_type === "2") {
        if (stop_loss > 0 && stop_loss > livePrice.bid) {
          setIsDisabled(true);
        }
        if (take_profit > 0 && take_profit < livePrice.bid) {
          setIsDisabled(true);
        }

        if (price > livePrice.bid) {
          setIsDisabled(true);
        }
      } else if (exc_type === "3") {
        if (stop_loss > 0 && stop_loss < livePrice.bid) {
          setIsDisabled(true);
        }
        if (take_profit > 0 && take_profit > livePrice.bid) {
          setIsDisabled(true);
        }

        if (price < livePrice.bid) {
          setIsDisabled(true);
        }
      } else if (exc_type === "4") {
        if (stop_loss > 0 && stop_loss > livePrice.bid) {
          setIsDisabled(true);
        }
        if (take_profit > 0 && take_profit < livePrice.bid) {
          setIsDisabled(true);
        }

        if (price < livePrice.bid) {
          setIsDisabled(true);
        }
      } else if (exc_type === "5") {
        if (stop_loss > 0 && stop_loss < livePrice.bid) {
          setIsDisabled(true);
        }
        if (take_profit > 0 && take_profit > livePrice.bid) {
          setIsDisabled(true);
        }

        if (price > livePrice.bid) {
          setIsDisabled(true);
        }
      } else if (exc_type === "6") {
        if (stop_loss > 0 && stop_loss > livePrice.bid) {
          setIsDisabled(true);
        }
        if (take_profit > 0 && take_profit < livePrice.bid) {
          setIsDisabled(true);
        }
        if (price < livePrice.bid) {
          setIsDisabled(true);
        }
        if (limit > price) {
          setIsDisabled(true);
        }
      } else if (exc_type === "7") {
        if (stop_loss > 0 && stop_loss < livePrice.bid) {
          setIsDisabled(true);
        }
        if (take_profit > 0 && take_profit > livePrice.bid) {
          setIsDisabled(true);
        }
        if (price > livePrice.bid) {
          setIsDisabled(true);
        }
        if (limit < price) {
          setIsDisabled(true);
        }
      } else {
        setIsDisabled(false);
      }
    };
    validateForm();
  }, [livePrice, formData, handleChange]);

  useEffect(() => {
    const data = abcData?.newMessage;

    if (data?.symbol === symbol) {
      setlivePrice({
        ask: data.ask,
        bid: data.bid,
      });
      if (focusedField && !socketCalled) {
        setSocketCalled(true);
        setFormData((prevData) => ({
          ...prevData,
          [focusedField]: data.bid,
        }));
      }
    }
  }, [symbol, focusedField, socketCalled, abcData]);

  return (
    <div className="flex flex-col p-4 max-w-xl border rounded-sm border-gray-300 bg-white">
      {showResponse && (
        <div className="p-10 flex flex-col items-center gap-5 text-violet-800 font-semibold">
          <IoCheckmarkDoneCircleSharp className="text-green-500" size={40} />
          <div className="flex justify-between m-2 gap-2">
            <div>Symbol: {response.symbol}</div>
            <div>Price: {response.price}</div>
          </div>
          <div>Ticket: #{response.ticket}</div>
          <div className="flex items-center gap-10">
            <div>Type: {mapTypeToString(response.type)}</div>
            <div>Volume: {response.volume}</div>
          </div>
          <button
            className="mt-4 bg-blue-500 text-white rounded-lg px-4 py-2 focus:outline-none hover:bg-blue-600"
            onClick={() => dispatch(changeShowTradeForm(false))}
          >
            OK
          </button>
        </div>
      )}
      {!showResponse && (
        <form>
          {/* Execution Type and Volume */}
          <div className="flex gap-5">
            <div className="flex-1">
              <p className="font-semibold">Execution Type</p>
              <select
                className="w-full p-2 border border-gray-300 rounded-sm outline-none"
                name="executionType"
                value={formData.executionType}
                onChange={handleChange}
              >
                <option value="1">Instant</option>
                <option value="2">Buy Limit</option>
                <option value="3">Sell Limit</option>
                <option value="4">Buy Stop</option>
                <option value="5">Sell Stop</option>
                <option value="6">Buy Stop Limit</option>
                <option value="7">Sell Stop Limit</option>
              </select>
            </div>
            <div className="flex-1 flex flex-col">
              <div className="flex justify-between items-center">
                <p className="font-semibold">Volume</p>
                <p className="font-semibold text-blue-500">
                  {(formData.volume * 100000).toFixed(2)} {symbol}
                </p>
              </div>
              <div className="border border-[#ccc] flex justify-between items-center rounded-sm">
                <span
                  className="px-2 cursor-pointer"
                  onClick={() => handleDecrease("volume")}
                >
                  <FaMinus size={14} />
                </span>
                <input
                  type="number"
                  name="volume"
                  min="0.01"
                  step="0.1"
                  placeholder="Volume"
                  value={formData.volume}
                  onChange={handleChange}
                  className="w-full p-2 text-center border-none outline-none"
                />
                <span
                  className="px-2 cursor-pointer"
                  onClick={() => handleIncrease("volume")}
                >
                  <FaPlus size={14} />
                </span>
              </div>
            </div>
          </div>
          {/* Stop Loss and Take Profit */}
          <div className="flex justify-between gap-5">
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <p className="font-semibold">Stop Loss</p>
                <p className="font-semibold">{formData.stopLoss}</p>
              </div>
              <div className="border border-[#ccc] flex justify-between items-center rounded-sm">
                <span
                  className="px-2 cursor-pointer"
                  onClick={() => handleDecrease("stopLoss")}
                >
                  <FaMinus size={14} />
                </span>
                <input
                  type="number"
                  name="stopLoss"
                  min="0.1"
                  step="0.1"
                  placeholder="Set S/L"
                  value={formData.stopLoss}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  className="w-full p-2 text-center border-none outline-none"
                />
                <span
                  className="px-2 cursor-pointer"
                  onClick={() => handleIncrease("stopLoss")}
                >
                  <FaPlus size={14} />
                </span>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <p className="font-semibold">Take Profit</p>
                <p className="font-semibold">{formData.takeProfit}</p>
              </div>
              <div className="border border-[#ccc] flex justify-between items-center rounded-sm">
                <span
                  className="px-2 cursor-pointer"
                  onClick={() => handleDecrease("takeProfit")}
                >
                  <FaMinus size={14} />
                </span>
                <input
                  type="number"
                  name="takeProfit"
                  min="0.1"
                  step="0.1"
                  placeholder="Set T/P"
                  value={formData.takeProfit}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  className="w-full p-2 text-center border-none outline-none"
                />
                <span
                  className="px-2 cursor-pointer"
                  onClick={() => handleIncrease("takeProfit")}
                >
                  <FaPlus size={14} />
                </span>
              </div>
            </div>
          </div>
          {/* Price and Limit */}
          <div className="flex justify-between gap-5">
            {formData.executionType !== "1" && (
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  <p className="font-semibold">Price</p>
                  <p className="font-semibold">{formData.price}</p>
                </div>
                <div className="border border-[#ccc] flex justify-between items-center rounded-sm">
                  <span
                    className="px-2 cursor-pointer"
                    onClick={() => handleDecrease("price")}
                  >
                    <FaMinus size={14} />
                  </span>
                  <input
                    type="number"
                    name="price"
                    min="0.1"
                    step="0.1"
                    placeholder="Price"
                    value={formData.price}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    className="w-full p-2 text-center border-none outline-none"
                  />
                  <span
                    className="px-2 cursor-pointer"
                    onClick={() => handleIncrease("price")}
                  >
                    <FaPlus size={14} />
                  </span>
                </div>
              </div>
            )}
            {(formData.executionType === "6" ||
              formData.executionType === "7") && (
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  <p className="font-semibold">Limit</p>
                  <p className="font-semibold">{formData.limit}</p>
                </div>
                <div className="border border-[#ccc] flex justify-between items-center rounded-sm">
                  <span
                    className="px-2 cursor-pointer"
                    onClick={() => handleDecrease("limit")}
                  >
                    <FaMinus size={14} />
                  </span>
                  <input
                    type="number"
                    name="limit"
                    min="0.1"
                    step="0.1"
                    placeholder="Set Limit"
                    value={formData.limit}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    className="w-full p-2 text-center border-none outline-none"
                  />
                  <span
                    className="px-2 cursor-pointer"
                    onClick={() => handleIncrease("limit")}
                  >
                    <FaPlus size={14} />
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* Expiration Day Selection */}
          {formData.executionType !== "1" && (
            <div className="border border-[#ccc] flex justify-between items-center rounded-sm mt-4">
              <select
                name="expiration"
                className="w-full p-2 border-none outline-none"
                value={formData.expiration}
                onChange={handleChange}
              >
                <option value="gtc">GTC</option>
                <option value="today">Today</option>
                <option value="specified">Specified</option>
                <option value="specified-day">Specified Day</option>
              </select>
            </div>
          )}
          {/* Date Picker */}
          {formData.executionType !== "1" &&
            (formData.expiration === "specified" ||
              formData.expiration === "specified-day") && (
              <div className="flex justify-between mt-2">
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  className="w-full p-2 border border-[#ccc] outline-none"
                />
              </div>
            )}
          {/* Comment */}
          <div className="border border-[#ccc] rounded-sm mt-2">
            <textarea
              rows="1"
              className="w-full p-2 resize-none border-none outline-none"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              placeholder="Comment"
            />
          </div>
          {/* Live Price Display */}
          <div className="flex justify-between items-center text-blue-700 p-4">
            <div>Ask: {livePrice.ask}</div>
            <div>Bid: {livePrice.bid}</div>
          </div>
          {/* Buy and Sell Buttons */}
          {formData.executionType === "1" && (
            <div className="flex justify-between gap-10">
              <button
                className="w-1/2 p-2 rounded-sm cursor-pointer bg-green-500 text-white outline-none hover:bg-green-600"
                disabled={isDisabled}
                onClick={handleSubmitBuy}
              >
                Buy
              </button>
              <button
                className="w-1/2 p-2 rounded-sm cursor-pointer bg-red-500 text-white outline-none hover:bg-red-600"
                disabled={isDisabled}
                onClick={handleSubmitSell}
              >
                Sell
              </button>
            </div>
          )}
          {/* Place Order Button */}
          {formData.executionType !== "1" && (
            <div className="flex justify-center mt-3">
              <button
                onClick={handlePlaceOrder}
                className={`bg-green-500 rounded-sm px-4 py-2 text-white font-semibold 
                     ${
                       isDisabled
                         ? "bg-gray-400 cursor-not-allowed"
                         : "hover:bg-green-600"
                     }`}
                disabled={isDisabled}
              >
                Place Order
              </button>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default TradeForm;
