import React, { useState } from "react";
import PropTypes from "prop-types";
import { IoCloseOutline } from "react-icons/io5";

const IndicatorSettingWrapper = ({ children, onClose }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    // Call the onClose function if provided
    if (onClose) {
      onClose();
    }
  };

  return (
    show && (
      <div className="fixed top-1/2  left-1/2 max-h-screen max-w-screen transform -translate-x-1/2 -translate-y-1/2 shadow-sm flex justify-center items-center rounded-md pt-5  bg-white border border-slate-200 z-50">
        <div className="bg-white p-5 rounded-md">{children}</div>
        <button
          className="absolute top-2.5 right-2.5 bg-transparent border-none cursor-pointer hover:bg-gray-300 rounded-md"
          onClick={handleClose}
        >
          <IoCloseOutline size={24} />
        </button>
      </div>  
    )
  );
};

IndicatorSettingWrapper.propTypes = {
  children: PropTypes.node.isRequired, // Content inside the overlay
  onClose: PropTypes.func, // Function to handle overlay close
};

export default IndicatorSettingWrapper;
