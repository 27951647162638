import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "Aroon",
    timeFrame: 1,
    show: false,
    period: 25,
    up: {
        styles: {
            lineVisible: true,
            priceLineVisible: false,
            color: "orange",
            lineStyle: 0,
            lineWidth: 1,
            lineType: 0,
            pointMarkersVisible: false,
            pointMarkersRadius: undefined,
            lastPriceAnimation: 1,
            lastValueVisible: true,
        }
    },
    down: {
        styles: {
            lineVisible: true,
            priceLineVisible: false,
            color: "blue",
            lineStyle: 0,
            lineWidth: 1,
            lineType: 0,
            pointMarkersVisible: false,
            pointMarkersRadius: undefined,
            lastPriceAnimation: 1,
            lastValueVisible: true,
        }
    }
};

const aroonSlice = createSlice({
    name: "Aroon",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setShow: (state, action) => {
            state.show = action.payload;
        },
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
        setUpColor: (state, action) => {
            state.up.styles.color = action.payload;
        },
        setUpLineStyle: (state, action) => {
            state.up.styles.lineStyle = action.payload;
        },
        setUpLineWidth: (state, action) => {
            state.up.styles.lineWidth = action.payload;
        },
        setDownColor: (state, action) => {
            state.down.styles.color = action.payload;
        },
        setDownLineStyle: (state, action) => {
            state.down.styles.lineStyle = action.payload;
        },
        setDownLineWidth: (state, action) => {
            state.down.styles.lineWidth = action.payload;
        },
        resetAroon : () => {return initialState}
    }
});

export const {
    setTimeFrame,
    setShow,
    setPeriod,
    setUpColor,
    setUpLineStyle,
    setUpLineWidth,
    setDownColor,
    setDownLineStyle,
    setDownLineWidth,
    resetAroon
    
} = aroonSlice.actions;

export default aroonSlice.reducer;
