import React, { useState } from "react";
import styles from "../closedPositions/ExitPosition.module.css";
import {
  mapTypeToString,
  formatTimeToSeconds,
  getCurrentDateFormatted,
} from "../../../helpers";
import TradeCard from "../tradeCard/TradeCard";
import { useSelector } from "react-redux";
import {
  sortData,
  filterDataByDate,
} from "../../../helpers/SortingAndFiltering";

const OrderBox = () => {
  const [showSubContainerIndex, setShowSubContainerIndex] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null); // State for end date
  const userOrdersData = useSelector((state) => state.onLoad.userOrders);

  const handleOnClick = (index) => {
    setShowSubContainerIndex(index === showSubContainerIndex ? null : index);
  };

  // Sorting and filtering using utility functions
  const sortedData = sortData(userOrdersData, sortBy);
  const filteredData = filterDataByDate(sortedData, startDate, endDate);

  return (
    <div>
      {!userOrdersData && <p className={styles.error}>Loading...</p>}
      {userOrdersData && (
        <TradeCard data={calcOrdersAggregate(userOrdersData)} />
      )}

      {userOrdersData?.length < 1 && (
        <p className={styles.error}>No Orders available!</p>
      )}

      {userOrdersData?.length > 1 && (
        <div className={styles.sortAndFilter}>
          <select onChange={(e) => setSortBy(e.target.value)}>
            <option value="">Sort By</option>
            <option value="symbol">Symbol</option>
            <option value="ticket">Ticket</option>
            <option value="type">Type</option>
            <option value="volume">Volume</option>
            <option value="openTime">Open Time</option>
            <option value="closeTime">Close Time</option>
            <option value="profit">Profit</option>
          </select>
          <div>
            <input
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              defaultValue="2020-01-01"
            />
            <input
              type="date"
              defaultValue={getCurrentDateFormatted()}
              onChange={(e) => setEndDate(e.target.value)}
            />{" "}
          </div>{" "}
        </div>
      )}

      <div className={styles.scroll}>
        {filteredData?.map((data, index) => (
          <div className={styles.container} key={data._id}>
            <div className={styles.main} onClick={() => handleOnClick(index)}>
              <div className={styles.flexBox}>
                <div className={styles.gap}>
                  <span>{data.symbol}</span>
                  <span
                    className={data.type === 1 ? styles.rising : styles.falling}
                  >
                    {mapTypeToString(data.type)},
                  </span>
                </div>
              </div>
              <div className={styles.flexBox}>
                <div className={styles.gap}>
                  <span>{data.currentVolume}</span>
                  <span>/</span>
                  <span>{data.initialVolume}</span>
                  <span>at</span>
                  <span>{data.price}</span>
                </div>
                <div>
                  <span style={{ color: "gray" }}>
                    {data.status === 1 ? "Filled" : "Cancelled"}
                  </span>
                </div>
              </div>
            </div>
            {showSubContainerIndex === index && (
              <div className={styles.subContainer}>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>{formatTimeToSeconds(data.createdAt)}</span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>S/L:</span>
                    <span>{data.stopLoss}</span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>T/P:</span>
                    <span>{data.takeProfit}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderBox;

const calcOrdersAggregate = (data) => {
  let filled = 0;
  let cancelled = 0;
  for (let i = 0; i < data.length; i++) {
    filled += data[i].status ? 1 : 0;
    cancelled += data[i].status ? 0 : 1;
  }
  return {
    Filled: filled,
    Cancelled: cancelled,
    Total: filled + cancelled,
  };
};
