import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "TEMA",
    timeFrame: 1,
    source: "close",
    period: 9,
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "#007bff",
        lineStyle: 0,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
}

const temaSlice = createSlice({
    name: "TEMA",
    initialState,
    reducers: {
        setSource: (state, action) => {
            state.source = action.payload;
        },
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
        setColor: (state, action) => {
            state.styles.color = action.payload;
        },
        setThickness: (state, action) => {
            state.styles.lineWidth = action.payload;
        },
        setLineStyle: (state, action) => {
            state.styles.lineStyle = action.payload
        },
        resetTema: () => initialState
    }
})

export const { setSource, setTimeFrame, setPeriod, setColor, setThickness, resetTema, setLineStyle} = temaSlice.actions;

export default temaSlice.reducer;
