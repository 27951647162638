import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setTimeFrame,
  setPeriod,
  setPlusColor,
  setPlusThickness,
  setMinusColor,
  setMinusThickness,
  setMinusLineStyle,
  setPlusLineStyle,
  resetVi,
} from "../../../store/indicators/viSlice";
import styles from "../index.module.css";
import IndicatorSettingWrapper from "../../wrappers/IndicatorSettingWrapper";
import { changeTime, fetchChartData } from "../../../store/chartSlice";

const VIComponent = ({ onClose }) => {
  const dispatch = useDispatch();
  const viState = useSelector((state) => state.vi);
  const [activeTab, setActiveTab] = useState("general");

  const handleInputChange = (e, actionCreator) => {
    const value =
      e.target.type === "number"
        ? Math.max(Number(e.target.min), Number(e.target.value))
        : e.target.value;
    dispatch(actionCreator(value));
    if (actionCreator === setTimeFrame) {
      dispatch(changeTime(value));
      dispatch(fetchChartData());
    }
  };

  return (
    <IndicatorSettingWrapper onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.tabs}>
          <div
            className={`${styles.tab} ${
              activeTab === "general" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("general")}
          >
            General Settings
          </div>
          <div
            className={`${styles.tab} ${
              activeTab === "style" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("style")}
          >
            Style Settings
          </div>
        </div>

        <div
          className={`${styles.tabContent} ${
            activeTab === "general" ? styles.active : ""
          }`}
        >
          <div className={styles.setting}>
            <label>Time Frame:</label>
            <select
              value={viState.timeFrame}
              onChange={(e) => handleInputChange(e, setTimeFrame)}
            >
              <option value="1">1 min</option>
              <option value="5">5 min</option>
              <option value="15">15 min</option>
              <option value="30">30 min</option>
              <option value="60">1 hour</option>
              <option value="D">1 day</option>
            </select>
          </div>
          <div className={styles.setting}>
            <label>Period:</label>
            <input
              min="1"
              type="number"
              value={viState.period}
              onChange={(e) => handleInputChange(e, setPeriod)}
            />
          </div>
        </div>

        <div
          className={`${styles.tabContent} ${
            activeTab === "style" ? styles.active : ""
          }`}
        >
          <div className={styles["flex-row"]}>
            <div className={styles.setting}>
              <label>Plus Line Color:</label>
              <input
                min="1"
                type="color"
                value={viState.styles.plus.styles.color}
                onChange={(e) => handleInputChange(e, setPlusColor)}
              />
            </div>
            <div className={styles.setting}>
              <label>Minus Line Color:</label>
              <input
                min="1"
                type="color"
                value={viState.styles.minus.styles.color}
                onChange={(e) => handleInputChange(e, setMinusColor)}
              />
            </div>
          </div>

          <div className={styles["flex-row"]}>
            <div className={styles.setting}>
              <label> Plus Line Style:</label>
              <select
                value={viState.styles.lineStyle}
                onChange={(e) => handleInputChange(e, setPlusLineStyle)}
              >
                <option value={0}>Solid</option>
                <option value={1}>Dotted</option>
                <option value={2}>Dashed</option>
                <option value={3}>Large Dashed</option>
                <option value={4}>Sparse Dotted</option>
              </select>
            </div>

            <div className={styles.setting}>
              <label>Minus Line Style:</label>
              <select
                value={viState.styles.lineStyle}
                onChange={(e) => handleInputChange(e, setMinusLineStyle)}
              >
                <option value={0}>Solid</option>
                <option value={1}>Dotted</option>
                <option value={2}>Dashed</option>
                <option value={3}>Large Dashed</option>
                <option value={4}>Sparse Dotted</option>
              </select>
            </div>
          </div>

          <div className={styles["flex-row"]}>
            <div className={styles.setting}>
              <label>Plus Line Thickness:</label>
              <input
                min="1"
                type="number"
                value={viState.styles.plus.styles.lineWidth}
                onChange={(e) => handleInputChange(e, setPlusThickness)}
              />
            </div>

            <div className={styles.setting}>
              <label>Minus Line Thickness:</label>
              <input
                min="1"
                type="number"
                value={viState.styles.minus.styles.lineWidth}
                onChange={(e) => handleInputChange(e, setMinusThickness)}
              />
            </div>
          </div>
        </div>
        <button onClick={() => dispatch(resetVi())}>Reset</button>
      </div>
    </IndicatorSettingWrapper>
  );
};

export default VIComponent;
