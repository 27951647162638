import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setTimeFrame,
    setPeriod,
    setBaseValue,
    setTopLineColor,
    setTopFillColor1,
    setTopFillColor2,
    setBottomLineColor,
    setBottomFillColor1,
    setBottomFillColor2,
    setLineWidth,
    resetQstick
} from '../../../store/indicators/qstickSlice';
import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../wrappers/IndicatorSettingWrapper';

const QStickComponent = ({onClose}) => {
    const dispatch = useDispatch();
    const qstickState = useSelector(state => state.qStick);
    const [activeTab, setActiveTab] = useState('general');

    const handleInputChange = (e, actionCreator) => {
        dispatch(actionCreator(e.target.value));
    };

    return (
        <IndicatorSettingWrapper onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <div
                        className={`${styles.tab} ${activeTab === 'general' ? styles.active : ''}`}
                        onClick={() => setActiveTab('general')}
                    >
                        General Settings
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === 'style' ? styles.active : ''}`}
                        onClick={() => setActiveTab('style')}
                    >
                        Style Settings
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>
                    <div className={styles.setting}>
                        <label>Time Frame:</label>
                        <select
                            value={qstickState.timeFrame}
                            onChange={(e) => handleInputChange(e, setTimeFrame)}
                        >
                            <option value="1">1 min</option>
                            <option value="5">5 min</option>
                            <option value="15">15 min</option>
                            <option value="30">30 min</option>
                            <option value="60">1 hour</option>
                            <option value="D">1 day</option>
                            {/* Add other time frames as needed */}
                        </select>
                    </div>
                    <div className={styles.setting}>
                        <label>Period:</label>
                        <input
                            type="number"
                            value={qstickState.period}
                            onChange={(e) => handleInputChange(e, setPeriod)}
                        />
                    </div>
                   
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'style' ? styles.active : ''}`}>
                   <div className={styles['flex-row']}>
                   <div className={styles.setting}>
                        <label>Top Line Color</label>
                        <input
                            type="color"
                            value={qstickState.styles.topLineColor}
                            onChange={(e) => handleInputChange(e, setTopLineColor)}
                        />
                    </div>
                   
                    <div className={styles.setting}>
                        <label>Top Fill Color</label>
                        <input
                            type="color"
                            value={qstickState.styles.topFillColor2}
                            onChange={(e) => handleInputChange(e, setTopFillColor2)}
                        />
                    </div>
                   </div>
                   <div  className={styles['flex-row']}>
                   <div className={styles.setting}>
                        <label>Bottom LineColor</label>
                        <input
                            type="color"
                            value={qstickState.styles.bottomLineColor}
                            onChange={(e) => handleInputChange(e, setBottomLineColor)}
                        />
                    </div>
                    <div className={styles.setting}>
                        <label>Bottom FillColor</label>
                        <input
                            type="color"
                            value={qstickState.styles.bottomFillColor1}
                            onChange={(e) => handleInputChange(e, setBottomFillColor1)}
                        />
                    </div>
                   </div>
                   
                    <div className={styles.setting}>
                        <label>Line Width</label>
                        <input
                            type="number"
                            value={qstickState.styles.lineWidth}
                            onChange={(e) => handleInputChange(e, setLineWidth)}
                        />
                    </div>
                </div>
                <button onClick={() => dispatch(resetQstick())}>Reset</button>

            </div>
        </IndicatorSettingWrapper>
    );
};

export default QStickComponent;
