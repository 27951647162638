import React from "react";
import WalletInfo from "../../account/WalletInfo";
import PositionBox from "../../history/positions/PositionBox";

const MobileTrade = () => {
  return (
    <div>
      <WalletInfo />
      <PositionBox />
    </div>
  );
};

export default MobileTrade;
