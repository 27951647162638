import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: "runningPositions",
    show: true,
    refresh : false

}

const modeSlice = createSlice({
    name: "mode",
    initialState: initialState,
    reducers: {
        changeMode: (state, action) => {
            state.mode = action.payload;
        },
        changeVisibility : (state,action) =>  {
            state.show = !state.show
        },
        refreshComponent : (state) => {
            state.refresh = !state.refresh
        },
        setIsDetailVisible: (state, action) => {
            state.show = action.payload
        },
        resetMode : (state) => {
            return initialState
        }

    }
})

export const { changeMode, changeVisibility, refreshComponent, resetMode, setIsDetailVisible } = modeSlice.actions;
export default modeSlice.reducer;
