import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  period: 2,
  short: 9,
   medium: 26, 
   long: 52, 
   close: 26,
  tenkanSenSeries: {
    styles: {
      lineVisible: true,
      priceLineVisible: false,
      color: "purple",
      lineStyle: 0,
      period: 1,
      source: "open",
      lineWidth: 1,
      lineType: 0,
      pointMarkersVisible: false,
      pointMarkersRadius: undefined,
      lastPriceAnimation: 1,
      lastValueVisible: true,
    },
  },
  kijunSenSeries: {
    styles: {
      lineVisible: true,
      priceLineVisible: false,
      color: "red",
      lineStyle: 0,
      period: 1,
      source: "open",
      lineWidth: 1,
      lineType: 0,
      pointMarkersVisible: false,
      pointMarkersRadius: undefined,
      lastPriceAnimation: 1,
      lastValueVisible: true,
    },
  },
  senkouSpanASeries: {
    styles: {
      lineVisible: true,
      priceLineVisible: false,
      color: "violet",
      lineStyle: 0,
      period: 1,
      source: "open",
      lineWidth: 1,
      lineType: 0,
      pointMarkersVisible: false,
      pointMarkersRadius: undefined,
      lastPriceAnimation: 1,
      lastValueVisible: true,
    },
  },
  senkouSpanBSeries: {
    styles: {
      lineVisible: true,
      priceLineVisible: false,
      color: "blueviolet",
      lineStyle: 0,
      period: 1,
      source: "open",
      lineWidth: 1,
      lineType: 0,
      pointMarkersVisible: false,
      pointMarkersRadius: undefined,
      lastPriceAnimation: 1,
      lastValueVisible: true,
    },
  },
  chikouSpanSeries: {
    styles: {
      lineVisible: true,
      priceLineVisible: false,
      color: "red",
      lineStyle: 0,
      period: 1,
      source: "open",
      lineWidth: 1,
      lineType: 0,
      pointMarkersVisible: false,
      pointMarkersRadius: undefined,
      lastPriceAnimation: 1,
      lastValueVisible: true,
    },
  },
};

const ichimokuCloudSlice = createSlice({
  name: "ichimokuCloud",
  initialState,
  reducers: {

    setShort: (state, action) => {
        state.short = action.payload
    },
    setMedium: (state, action) => {
        state.medium = action.payload
    },setLong: (state, action) => {
        state.long = action.payload
    },setClose: (state, action) => {
        state.close = action.payload
    },
    setPeriod: (state, action) => {
      state.period = action.payload;
    },
    setTenkanSenStyle: (state, action) => {
      state.tenkanSenSeries.styles = { ...state.tenkanSenSeries.styles, ...action.payload };
    },
    setKijunSenStyle: (state, action) => {
      state.kijunSenSeries.styles = { ...state.kijunSenSeries.styles, ...action.payload };
    },
    setSenkouSpanAStyle: (state, action) => {
      state.senkouSpanASeries.styles = { ...state.senkouSpanASeries.styles, ...action.payload };
    },
    setSenkouSpanBStyle: (state, action) => {
      state.senkouSpanBSeries.styles = { ...state.senkouSpanBSeries.styles, ...action.payload };
    },
    setChikouSpanStyle: (state, action) => {
      state.chikouSpanSeries.styles = { ...state.chikouSpanSeries.styles, ...action.payload };
    },
    resetIchimokuCloudSettings: () => initialState,
  },
});

export const {
  setShow,
  setPeriod,
  setTenkanSenStyle,
  setKijunSenStyle,
  setSenkouSpanAStyle,
  setSenkouSpanBStyle,
  setChikouSpanStyle,
  resetIchimokuCloudSettings,
  setClose, setLong,setMedium, setShort
} = ichimokuCloudSlice.actions;

export default ichimokuCloudSlice.reducer;
