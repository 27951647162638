import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "MI",
    timeFrame: 1,
    period: {
        emaPeriod: 9,
        miPeriod: 25
    },
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "green",
        lineStyle: 0,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
};

const miSlice = createSlice({
    name: "MI",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setEmaPeriod: (state, action) => {
            state.period.emaPeriod = action.payload;
        },
        setMiPeriod: (state, action) => {
            state.period.miPeriod = action.payload;
        },
        setColor: (state, action) => {
            state.styles.color = action.payload;
        },
        setThickness: (state, action) => {
            state.styles.lineWidth = action.payload;
        },
        setLineStyle: (state, action) => {
            state.styles.lineStyle = action.payload;
        },
        resetMi : () => {
            return initialState
        }
    }
});

export const {
    setTimeFrame,
    setEmaPeriod,
    setMiPeriod,
    setColor,
    setThickness,
    setLineStyle,
    resetMi
} = miSlice.actions;

export default miSlice.reducer;
