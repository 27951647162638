import { CrosshairMode, LineStyle } from "lightweight-charts";
import { fixedNumber } from "../../../helpers";

export const volumeChartOptionsFunc = (chartContainerRef) => {
  const options = {
    // autoSize: true,
    width: chartContainerRef.current.clientWidth * 0.96,
    height: chartContainerRef.current.clientHeight * 0.2,

    rightPriceScale: {
      visible: false,
      scaleMargins: {
        top: 0.3,
        bottom: 0,
      },
      borderVisible: false,
    },
    localization: {
      priceFormatter: fixedNumber,
    },
    layout: {
      background: {
        type: "solid",
        color: "rgba(255, 255, 255, 0)",
      },
      textColor: "#000",
    },
    grid: {
      vertLines: {
        color: "rgba(255, 255, 255, 0)",
      },
      horzLines: {
        color: "rgba(255, 255, 255, 0)",
      },
    },
    timeScale: {
      visible: false,
      timeVisible: true,
      secondsVisible: false,
      rightOffset: 30,
      tickMarkFormatter: (time, tickMarkType, locale) => {
        const date = new Date(time * 1000);
        const day = date.getDate();
        const month = date.toLocaleString(locale, { month: "short" });
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        return month + " " + day + " " + hours + ":" + minutes;
      },
    },
    crosshair: {
      mode: CrosshairMode.Normal,
      vertLine: {
        style: LineStyle.Solid,
      },

      horzLine: {
        style: LineStyle.Solid,
      },
    },
  }

  return options
}