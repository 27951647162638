import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getActiveUser,
  setAuthCred,
  setAuthToken,
  setUserCredentials,
} from "../../../helpers";
import { useMutation } from "@tanstack/react-query";
import { postLogin } from "../../../api/queryFunctions";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";

const Login = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const {
    mutate: login,
    data: res,
    isLoading,
  } = useMutation({
    mutationKey: "login",
    mutationFn: (data) => postLogin(data),
  });
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (userId?.trim()?.length > 0 && password?.trim()?.length > 0) {
      login({ userId, password });
    }
  };

  useEffect(() => {
    const cred = getActiveUser();
    if (cred) {
      setUserId(cred?.userId);
      setPassword(cred?.password);
    }
  }, []);

  const handleChange = (e, action) => {
    action(e.target.value);
    setError("");
  };

  useEffect(() => {
    if (res?.data?.token) {
      setAuthToken(res.data.token);
      setUserCredentials({
        userId: res.data.user.email,
        password: password,
        name: res.data.user.name,
      });
      navigate("/");
    }
    if (res?.data?.status === false) {
      setError("Invalid Credentials");
    }
  }, [navigate, res,]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-300 to-blue-700 text-white ">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md fade-in">
        <h2 className="text-2xl font-bold mb-8 text-center text-blue-700">
          Login
        </h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="username">
              User ID
            </label>
            <input
              type="text"
              id="username"
              value={userId}
              onChange={(e) => handleChange(e, setUserId)}
              autoComplete="username"
              className="w-full p-3 border border-gray-300 rounded focus:outline-none  focus:border-blue-300 transition duration-300 text-black"
            />
          </div>
          <div className="mb-6 relative">
            <label className="block text-gray-700 mb-2" htmlFor="password">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => handleChange(e, setPassword)}
              autoComplete="current-password"
              className="w-full p-3 border border-gray-300 rounded focus:outline-none  focus:border-blue-300 transition duration-300 text-black"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-3 top-1/2 focus:outline-none"
            >
              {showPassword ? (
                <VscEye color="black" size={16} />
              ) : (
                <VscEyeClosed color="black" size={16} />
              )}
            </button>
          </div>
          {error && (
            <p className="text-red-500 text-center mb-4">Invalid Credentials</p>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white py-3 rounded-lg hover:from-green-500 hover:to-blue-600 transition duration-300 ease-in-out"
          >
            {isLoading ? "Wait..." : "Login"}
          </button>
        </form>
        <div className="text-center mt-4">
          <span className="text-gray-700">Don't have an account?</span>
          <Link
            to="/signup"
            className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out ml-2"
          >
            Register Here
          </Link>
        </div>

        <div className="text-center mt-4">
          <Link
            to="https://www.tradingview.com/" target="_blank"
            className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out ml-2"
          >
            Chart By TradingView
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
