import { CrosshairMode, LineStyle } from "lightweight-charts";
import { fixedNumber } from "../../../helpers";
export const chartOptionsFunc = (chartContainerRef) => {
  const chartOptions = {
    // autoSize: true,
    // width: chartContainerRef.current.clientWidth,
    // height: chartContainerRef.current.clientHeight,
    localization: {
      priceFormatter: fixedNumber,
    },
    leftPriceScale: {
    },
    rightPriceScale: {
      scaleMargins: {
        top: 0.3,
        bottom: 0.3,
      },
      // borderVisible: false,
      borderColor: "#f2f2f2"
    },

    layout: {
      background: {
        type: "solid",
        color: "#FFF",
      },
      textColor: "#000",
    },
    grid: {
      vertLines: {
        // color: "rgba(197, 203, 206, 0.5)",
        color: "#f2f2f2",
      },
      horzLines: {

        // color: "rgba(197, 203, 206, 0.5)",
        color: "#f2f2f2",
      },
      visible: true
    },
    timeScale: {
      visible: true,
      timeVisible: true,
      secondsVisible: false,
      rightOffset: 30,
      tickMarkFormatter: (time, locale) => {
        const date = new Date(time * 1000);
        const day = date.getUTCDate();
        const month = date.toLocaleString(locale, { month: "short", timeZone: "UTC" });
        const hours = date.getUTCHours().toString().padStart(2, "0");
        const minutes = date.getUTCMinutes().toString().padStart(2, "0");

        return `${month} ${day} ${hours}:${minutes}`;
      },
      // borderVisible: false
      borderColor: "#f2f2f2"

    },


    crosshair: {
      mode: CrosshairMode.Normal,
      vertLine: {
        style: LineStyle.Solid,
        labelBackgroundColor: "#fccb06",

      },

      horzLine: {
        style: LineStyle.Solid,
        labelBackgroundColor: "#fccb06",


      },

    },

  };

  return chartOptions

}

export const candleStickSeriesOptions = {
  upColor: "#26a69a",
  downColor: "#ef5350",
  borderVisible: false,
  wickUpColor: "#26a69a",
  wickDownColor: "#ef5350",
  lineWidth: 2,
  priceLineStyle: 0,
}


export const areaSeriesOptions = {
  lineColor: "#2962FF",
  topColor: "#2962FF",
  bottomColor: "rgba(41, 98, 255, 0.28)",
}

export const barSeriesOptions = {
  upColor: "#26a69a",
  downColor: "#ef5350",
}


