import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "VWMA",
    timeFrame: 1,
    source: "close",
    period: 20,
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "blue",
        lineStyle: 0,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
}

const vwmaSlice = createSlice({
    name: "VWMA",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
        setColor: (state, action) => {
            state.styles.color = action.payload;
        },
        setThickness: (state, action) => {
            state.styles.lineWidth = action.payload;
        },
        setLineStyle: (state, action) => {
            state.styles.lineStyle = action.payload;
        },
        setSource: (state, action) => {
            state.source = action.payload
        },
        resetVwma: () => initialState
    }
})

export const { setTimeFrame, setPeriod, setColor, setThickness, setLineStyle, resetVwma, setSource } = vwmaSlice.actions;

export default vwmaSlice.reducer;
