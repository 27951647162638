
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../api/apiClient";

const initialState = {
  data: [],
  loading: false,
  error: null,
}

export const fetchSymbols = createAsyncThunk(
  "symbols/fetchSymbols",
  async () => {
    const response = await apiClient.get("get-group-symbols");
    return response.data.symbolData;
  }
);

const symbolSlice = createSlice({
  name: "symbols",
  initialState,
  reducers: {
    resetState : (state) => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSymbols.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSymbols.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSymbols.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {resetState: resetSymbols} = symbolSlice.actions;

export default symbolSlice.reducer;
