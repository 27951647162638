import React, { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../Chart.module.css";
import { createChart } from "lightweight-charts";
import { chartOptionsFunc } from "../chartOptions/mainChartOptions";

const Main = forwardRef(({ data }, ref) => {
  const { type } = useSelector((state) => state.chart);
  const isDetailVisible = useSelector((state) => state.mode.show);
  const indicators = useSelector((state) => state.indicators);

  useEffect(() => {
    const chart = createChart(ref.current, chartOptionsFunc(ref));
    const candlestickChart = chart.addCandlestickSeries();
    const areaChart = chart.addAreaSeries();
    const barChart = chart.addBarSeries();

    switch (type) {
      case "bar":
        barChart.setData(data);

        break;
      case "area":
        areaChart.setData(
          data.ohlcData.map(({ time, high, low }) => ({
            time,
            value: (high + low) / 2,
          }))
        );
        break;

      case "candlestick":
        candlestickChart.setData(data);

        break;
      default:
        candlestickChart.setData(data);
    }

    return () => {
      chart.remove();
    };
  }, [ref, data, type]);

  return (
    <div
      ref={ref}
      id="mainChart"
      className={styles.chartContainer}
      style={{
        height: "calc(100vh - 30vh)",
        ...(indicators.indicatorAsNewChart &&
        isDetailVisible &&
        window.innerWidth > 768
          ? { height: "calc(100vh - 52vh" }
          : {}),
        border: "5px solid #ccc",
      }}
    ></div>
  );
});

export default Main;
