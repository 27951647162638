import React, { forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Chart.module.css";
import { createChart } from "lightweight-charts";
import { indicatorChartOptionsFunc } from "../chartOptions/indicatorChartOptions";
import { useChartSlices } from "../reduxSliceExport";
import { initializeIndicators } from "../intializeIndicators.js";
import { setIndicatorAsNewChart } from "../../../store/indicators/indicatorsSlice";
import { calculateIchimokuCloud } from "../../../helpers/indicators";

const Indi = forwardRef(({ data, vol }, ref) => {
  const dispatch = useDispatch();
  const indicators = useSelector((state) => state.indicators);
  const {
    ema,
    sma,
    apos,
    bops,
    aroons,
    cfos,
    ccis,
    demas,
    mis,
    macds,
    pSARs,
    qSticks,
    temas,
    trimas,
    trixs,
    vwmas,
    vis,
    cmos,
    rocs,
    rsis,
    atrs,
    kcs,
    dcs,
    aos,
    bbs,
    withVolume,
    imcs,
  } = useChartSlices();
  useEffect(() => {
    const chart = createChart(ref.current, {
      // autoSize: true,
      width: ref.current.clientWidth,
      height: ref.current.clientHeight,

      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0.3,
        },
        borderVisible: true,
        borderColor: "#f2f2f2",
      },

      layout: {
        background: {
          type: "solid",
          color: "#FFF",
        },
        textColor: "#000",
      },
      grid: {
        vertLines: {
          // color: "rgba(197, 203, 206, 0.5)",
          color: "#fff",
        },
        horzLines: {
          // color: "rgba(197, 203, 206, 0.5)",
          color: "#fff",
        },
      },
      timeScale: {
        borderColor: "#f2f2f2",

        visible: true,
        timeVisible: true,
        secondsVisible: false,
        rightOffset: 30,
        tickMarkFormatter: (time, tickMarkType, locale) => {
          const date = new Date(time * 1000);
          const day = date.getDate();
          const month = date.toLocaleString(locale, { month: "short" });
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");

          return month + " " + day + " " + hours + ":" + minutes;
        },
      },
    });
    initializeIndicators(
      chart,
      data,
      vol,
      indicators,
      dispatch,
      chart,
      ema,
      sma,
      imcs,
      calculateIchimokuCloud,
      ref,
      apos,
      withVolume,
      aroons,
      cfos,
      ccis,
      demas,
      mis,
      macds,
      pSARs,
      qSticks,
      temas,
      trimas,
      trixs,
      vwmas,
      aos,
      vis,
      cmos,
      rocs,
      rsis,
      atrs,
      kcs,
      dcs,
      bbs,
      bops,
      setIndicatorAsNewChart
    );

    return () => {
      chart.remove();
    };
  }, [
    dispatch,
    aos,
    bbs,
    atrs,
    cmos,
    dcs,
    kcs,
    rocs,
    rsis,
    apos,
    aroons,
    bops,
    ccis,
    cfos,
    demas,
    macds,
    mis,
    pSARs,
    qSticks,
    temas,
    trimas,
    trixs,
    vis,
    vwmas,
    imcs,
    data,
    ema,
    ref,
    indicators,
    sma,
    withVolume,
    vol,
  ]);
  return (
    <div
      ref={ref}
      id="indChart"
      className={styles.indicatorChartContainer}
      style={{
        display: indicators.indicatorAsNewChart ? "" : "none",
        ...(window.innerWidth <= 768 ? { marginBottom: "30px" } : {}),
      }}
    ></div>
  );
});

export default Indi;
