import React from "react";

const Options = ({ x, y, items }) => {
  return (
    <div
      className="absolute flex flex-col items-start text-black w-max z-50 bg-white rounded-md shadow-md text-xs"
      style={{
        top: `${y + 25}px`,
        left: `${x - 20}px`,
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className="p-2 pl-4 pr-4 flex items-center gap-2 w-full transition duration-200 ease-in-out cursor-pointer hover:bg-blueviolet hover:text-white hover:bg-violet-700 rounded-sm"
          onClick={item.handler}
        >
          <span>{item?.icon}</span>
          <span>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default Options;
