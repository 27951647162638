export const response = {
    "symbol": "HYPSTK",
    "data": [
      {
        "date": 1685557800000,
        "open": 0.5116,
        "high": 0.5119,
        "low": 0.5116,
        "close": 0.5118,
        "volume": 69195
      },
      {
        "date": 1685557860000,
        "open": 0.5118,
        "high": 0.5119,
        "low": 0.5116,
        "close": 0.5117,
        "volume": 120081
      },
      {
        "date": 1685557920000,
        "open": 0.5116,
        "high": 0.5117,
        "low": 0.5116,
        "close": 0.5117,
        "volume": 60682
      },
      {
        "date": 1685557980000,
        "open": 0.5116,
        "high": 0.5117,
        "low": 0.5111,
        "close": 0.5111,
        "volume": 210679
      },
      {
        "date": 1685558040000,
        "open": 0.5111,
        "high": 0.5112,
        "low": 0.5109,
        "close": 0.511,
        "volume": 59503
      },
      {
        "date": 1685558100000,
        "open": 0.5109,
        "high": 0.5114,
        "low": 0.5105,
        "close": 0.5114,
        "volume": 240301
      },
      {
        "date": 1685558160000,
        "open": 0.5114,
        "high": 0.5115,
        "low": 0.5111,
        "close": 0.5111,
        "volume": 138555
      },
      {
        "date": 1685558220000,
        "open": 0.5112,
        "high": 0.5113,
        "low": 0.5111,
        "close": 0.5111,
        "volume": 109251
      },
      {
        "date": 1685558280000,
        "open": 0.5111,
        "high": 0.5113,
        "low": 0.511,
        "close": 0.5111,
        "volume": 102326
      },
      {
        "date": 1685558340000,
        "open": 0.5112,
        "high": 0.5119,
        "low": 0.5111,
        "close": 0.5119,
        "volume": 58831
      },
      {
        "date": 1685558400000,
        "open": 0.5119,
        "high": 0.5124,
        "low": 0.5119,
        "close": 0.5124,
        "volume": 86250
      },
      {
        "date": 1685558460000,
        "open": 0.5124,
        "high": 0.5125,
        "low": 0.512,
        "close": 0.5122,
        "volume": 152958
      },
      {
        "date": 1685558520000,
        "open": 0.5122,
        "high": 0.513,
        "low": 0.5121,
        "close": 0.5129,
        "volume": 289515
      },
      {
        "date": 1685558580000,
        "open": 0.513,
        "high": 0.5138,
        "low": 0.5129,
        "close": 0.5131,
        "volume": 393039
      },
      {
        "date": 1685558640000,
        "open": 0.5131,
        "high": 0.5133,
        "low": 0.5128,
        "close": 0.5129,
        "volume": 155536
      },
      {
        "date": 1685558700000,
        "open": 0.5129,
        "high": 0.5133,
        "low": 0.5128,
        "close": 0.5128,
        "volume": 46065
      },
      {
        "date": 1685558760000,
        "open": 0.5128,
        "high": 0.5129,
        "low": 0.5122,
        "close": 0.5125,
        "volume": 196585
      },
      {
        "date": 1685558820000,
        "open": 0.5124,
        "high": 0.5129,
        "low": 0.5124,
        "close": 0.5128,
        "volume": 253864
      },
      {
        "date": 1685558880000,
        "open": 0.5127,
        "high": 0.5128,
        "low": 0.5123,
        "close": 0.5123,
        "volume": 169187
      },
      {
        "date": 1685558940000,
        "open": 0.5124,
        "high": 0.5126,
        "low": 0.5121,
        "close": 0.5125,
        "volume": 52767
      },
      {
        "date": 1685559000000,
        "open": 0.5125,
        "high": 0.5131,
        "low": 0.5119,
        "close": 0.5131,
        "volume": 511009
      },
      {
        "date": 1685559060000,
        "open": 0.5132,
        "high": 0.5133,
        "low": 0.5125,
        "close": 0.5125,
        "volume": 188524
      },
      {
        "date": 1685559120000,
        "open": 0.5125,
        "high": 0.5127,
        "low": 0.5123,
        "close": 0.5125,
        "volume": 85229
      },
      {
        "date": 1685559180000,
        "open": 0.5124,
        "high": 0.5125,
        "low": 0.5122,
        "close": 0.5124,
        "volume": 78107
      },
      {
        "date": 1685559240000,
        "open": 0.5126,
        "high": 0.5131,
        "low": 0.5125,
        "close": 0.513,
        "volume": 147255
      },
      {
        "date": 1685559300000,
        "open": 0.513,
        "high": 0.5134,
        "low": 0.513,
        "close": 0.5132,
        "volume": 172934
      },
      {
        "date": 1685559360000,
        "open": 0.5132,
        "high": 0.5133,
        "low": 0.5131,
        "close": 0.5132,
        "volume": 76526
      },
      {
        "date": 1685559420000,
        "open": 0.5133,
        "high": 0.5139,
        "low": 0.5132,
        "close": 0.5139,
        "volume": 271030
      },
      {
        "date": 1685559480000,
        "open": 0.5139,
        "high": 0.5148,
        "low": 0.5136,
        "close": 0.5145,
        "volume": 632809
      },
      {
        "date": 1685559540000,
        "open": 0.5145,
        "high": 0.5148,
        "low": 0.5139,
        "close": 0.5142,
        "volume": 385136
      },
      {
        "date": 1685559600000,
        "open": 0.5142,
        "high": 0.5147,
        "low": 0.5135,
        "close": 0.5135,
        "volume": 186416
      },
      {
        "date": 1685559660000,
        "open": 0.5135,
        "high": 0.5136,
        "low": 0.5127,
        "close": 0.5128,
        "volume": 907655
      },
      {
        "date": 1685559720000,
        "open": 0.5127,
        "high": 0.5129,
        "low": 0.5125,
        "close": 0.5127,
        "volume": 276693
      },
      {
        "date": 1685559780000,
        "open": 0.5127,
        "high": 0.5133,
        "low": 0.5127,
        "close": 0.5133,
        "volume": 64837
      },
      {
        "date": 1685559840000,
        "open": 0.5133,
        "high": 0.514,
        "low": 0.5132,
        "close": 0.5138,
        "volume": 113260
      },
      {
        "date": 1685559900000,
        "open": 0.5138,
        "high": 0.5142,
        "low": 0.5137,
        "close": 0.5137,
        "volume": 124598
      },
      {
        "date": 1685559960000,
        "open": 0.5137,
        "high": 0.5144,
        "low": 0.5135,
        "close": 0.5143,
        "volume": 320864
      },
      {
        "date": 1685560020000,
        "open": 0.5143,
        "high": 0.5143,
        "low": 0.5142,
        "close": 0.5142,
        "volume": 13815
      },
      {
        "date": 1685560080000,
        "open": 0.5142,
        "high": 0.5143,
        "low": 0.514,
        "close": 0.514,
        "volume": 86787
      },
      {
        "date": 1685560140000,
        "open": 0.514,
        "high": 0.5146,
        "low": 0.5137,
        "close": 0.5144,
        "volume": 633079
      },
      {
        "date": 1685560200000,
        "open": 0.5144,
        "high": 0.5144,
        "low": 0.5141,
        "close": 0.5144,
        "volume": 50766
      },
      {
        "date": 1685560260000,
        "open": 0.5144,
        "high": 0.5145,
        "low": 0.5138,
        "close": 0.514,
        "volume": 187377
      },
      {
        "date": 1685560320000,
        "open": 0.514,
        "high": 0.514,
        "low": 0.5135,
        "close": 0.5139,
        "volume": 120882
      },
      {
        "date": 1685560380000,
        "open": 0.5139,
        "high": 0.5139,
        "low": 0.5136,
        "close": 0.5139,
        "volume": 84279
      },
      {
        "date": 1685560440000,
        "open": 0.5139,
        "high": 0.514,
        "low": 0.5135,
        "close": 0.5139,
        "volume": 230104
      },
      {
        "date": 1685560500000,
        "open": 0.514,
        "high": 0.5142,
        "low": 0.514,
        "close": 0.5141,
        "volume": 105021
      },
      {
        "date": 1685560560000,
        "open": 0.5141,
        "high": 0.5141,
        "low": 0.513,
        "close": 0.5132,
        "volume": 221827
      },
      {
        "date": 1685560620000,
        "open": 0.5132,
        "high": 0.5134,
        "low": 0.513,
        "close": 0.513,
        "volume": 117012
      },
      {
        "date": 1685560680000,
        "open": 0.513,
        "high": 0.5132,
        "low": 0.5127,
        "close": 0.513,
        "volume": 198417
      },
      {
        "date": 1685560740000,
        "open": 0.513,
        "high": 0.5134,
        "low": 0.513,
        "close": 0.5133,
        "volume": 64763
      },
      {
        "date": 1685560800000,
        "open": 0.5133,
        "high": 0.5133,
        "low": 0.513,
        "close": 0.5132,
        "volume": 93561
      },
      {
        "date": 1685560860000,
        "open": 0.5133,
        "high": 0.5134,
        "low": 0.5128,
        "close": 0.5128,
        "volume": 128680
      },
      {
        "date": 1685560920000,
        "open": 0.5127,
        "high": 0.5127,
        "low": 0.5121,
        "close": 0.5122,
        "volume": 109694
      },
      {
        "date": 1685560980000,
        "open": 0.5123,
        "high": 0.5126,
        "low": 0.5123,
        "close": 0.5123,
        "volume": 119662
      },
      {
        "date": 1685561040000,
        "open": 0.5122,
        "high": 0.5123,
        "low": 0.5117,
        "close": 0.5119,
        "volume": 209528
      },
      {
        "date": 1685561100000,
        "open": 0.5119,
        "high": 0.5121,
        "low": 0.5118,
        "close": 0.5121,
        "volume": 115062
      },
      {
        "date": 1685561160000,
        "open": 0.5121,
        "high": 0.5121,
        "low": 0.5118,
        "close": 0.5118,
        "volume": 198238
      },
      {
        "date": 1685561220000,
        "open": 0.5118,
        "high": 0.5123,
        "low": 0.5118,
        "close": 0.5123,
        "volume": 67265
      },
      {
        "date": 1685561280000,
        "open": 0.5123,
        "high": 0.5126,
        "low": 0.5122,
        "close": 0.5123,
        "volume": 212834
      },
      {
        "date": 1685561340000,
        "open": 0.5123,
        "high": 0.5123,
        "low": 0.5118,
        "close": 0.512,
        "volume": 170072
      },
      {
        "date": 1685561400000,
        "open": 0.5121,
        "high": 0.5121,
        "low": 0.5112,
        "close": 0.5118,
        "volume": 238962
      },
      {
        "date": 1685561460000,
        "open": 0.5118,
        "high": 0.5125,
        "low": 0.5118,
        "close": 0.5125,
        "volume": 18375
      },
      {
        "date": 1685561520000,
        "open": 0.5125,
        "high": 0.5128,
        "low": 0.5124,
        "close": 0.5128,
        "volume": 80630
      },
      {
        "date": 1685561580000,
        "open": 0.5127,
        "high": 0.5132,
        "low": 0.5127,
        "close": 0.5131,
        "volume": 121953
      },
      {
        "date": 1685561640000,
        "open": 0.5132,
        "high": 0.5138,
        "low": 0.5131,
        "close": 0.5138,
        "volume": 272045
      },
      {
        "date": 1685561700000,
        "open": 0.5138,
        "high": 0.5142,
        "low": 0.5136,
        "close": 0.514,
        "volume": 187991
      },
      {
        "date": 1685561760000,
        "open": 0.5141,
        "high": 0.5142,
        "low": 0.514,
        "close": 0.5142,
        "volume": 20247
      },
      {
        "date": 1685561820000,
        "open": 0.5142,
        "high": 0.5142,
        "low": 0.5137,
        "close": 0.5137,
        "volume": 169719
      },
      {
        "date": 1685561880000,
        "open": 0.5137,
        "high": 0.5137,
        "low": 0.5127,
        "close": 0.5128,
        "volume": 44777
      },
      {
        "date": 1685561940000,
        "open": 0.5128,
        "high": 0.513,
        "low": 0.5127,
        "close": 0.5129,
        "volume": 125058
      },
      {
        "date": 1685562000000,
        "open": 0.513,
        "high": 0.513,
        "low": 0.5125,
        "close": 0.5128,
        "volume": 163687
      },
      {
        "date": 1685562060000,
        "open": 0.5128,
        "high": 0.5134,
        "low": 0.5127,
        "close": 0.5133,
        "volume": 66859
      },
      {
        "date": 1685562120000,
        "open": 0.5134,
        "high": 0.5136,
        "low": 0.5133,
        "close": 0.5134,
        "volume": 59035
      },
      {
        "date": 1685562180000,
        "open": 0.5134,
        "high": 0.5135,
        "low": 0.5133,
        "close": 0.5135,
        "volume": 76513
      },
      {
        "date": 1685562240000,
        "open": 0.5135,
        "high": 0.5139,
        "low": 0.5134,
        "close": 0.5139,
        "volume": 109296
      },
      {
        "date": 1685562300000,
        "open": 0.514,
        "high": 0.5141,
        "low": 0.5134,
        "close": 0.5136,
        "volume": 379740
      },
      {
        "date": 1685562360000,
        "open": 0.5136,
        "high": 0.5141,
        "low": 0.5135,
        "close": 0.5139,
        "volume": 145807
      },
      {
        "date": 1685562420000,
        "open": 0.514,
        "high": 0.5145,
        "low": 0.5139,
        "close": 0.5144,
        "volume": 244091
      },
      {
        "date": 1685562480000,
        "open": 0.5143,
        "high": 0.5144,
        "low": 0.5141,
        "close": 0.5144,
        "volume": 116566
      },
      {
        "date": 1685562540000,
        "open": 0.5143,
        "high": 0.515,
        "low": 0.5143,
        "close": 0.5149,
        "volume": 291233
      },
      {
        "date": 1685562600000,
        "open": 0.5148,
        "high": 0.5157,
        "low": 0.5147,
        "close": 0.5154,
        "volume": 579090
      },
      {
        "date": 1685562660000,
        "open": 0.5154,
        "high": 0.5155,
        "low": 0.5152,
        "close": 0.5154,
        "volume": 350556
      },
      {
        "date": 1685562720000,
        "open": 0.5153,
        "high": 0.5159,
        "low": 0.5153,
        "close": 0.5159,
        "volume": 140956
      },
      {
        "date": 1685562780000,
        "open": 0.5159,
        "high": 0.5163,
        "low": 0.5158,
        "close": 0.5162,
        "volume": 280732
      },
      {
        "date": 1685562840000,
        "open": 0.5162,
        "high": 0.5164,
        "low": 0.5161,
        "close": 0.5162,
        "volume": 84138
      },
      {
        "date": 1685562900000,
        "open": 0.5161,
        "high": 0.5161,
        "low": 0.5149,
        "close": 0.5152,
        "volume": 355939
      },
      {
        "date": 1685562960000,
        "open": 0.5152,
        "high": 0.5152,
        "low": 0.515,
        "close": 0.515,
        "volume": 159644
      },
      {
        "date": 1685563020000,
        "open": 0.515,
        "high": 0.5154,
        "low": 0.5149,
        "close": 0.5153,
        "volume": 211883
      },
      {
        "date": 1685563080000,
        "open": 0.5153,
        "high": 0.5159,
        "low": 0.5152,
        "close": 0.5158,
        "volume": 42212
      },
      {
        "date": 1685563140000,
        "open": 0.5159,
        "high": 0.5159,
        "low": 0.514,
        "close": 0.5141,
        "volume": 676084
      },
      {
        "date": 1685563200000,
        "open": 0.5141,
        "high": 0.5143,
        "low": 0.5135,
        "close": 0.514,
        "volume": 128117
      },
      {
        "date": 1685563260000,
        "open": 0.5141,
        "high": 0.5143,
        "low": 0.514,
        "close": 0.5141,
        "volume": 108996
      },
      {
        "date": 1685563320000,
        "open": 0.5142,
        "high": 0.5144,
        "low": 0.5141,
        "close": 0.5143,
        "volume": 101113
      },
      {
        "date": 1685563380000,
        "open": 0.5143,
        "high": 0.5148,
        "low": 0.5143,
        "close": 0.5148,
        "volume": 58850
      },
      {
        "date": 1685563440000,
        "open": 0.5149,
        "high": 0.5155,
        "low": 0.5149,
        "close": 0.5155,
        "volume": 169874
      },
      {
        "date": 1685563500000,
        "open": 0.5155,
        "high": 0.5161,
        "low": 0.5155,
        "close": 0.5158,
        "volume": 178606
      },
      {
        "date": 1685563560000,
        "open": 0.5158,
        "high": 0.5165,
        "low": 0.5157,
        "close": 0.5164,
        "volume": 142624
      },
      {
        "date": 1685563620000,
        "open": 0.5164,
        "high": 0.5165,
        "low": 0.5158,
        "close": 0.5162,
        "volume": 292072
      },
      {
        "date": 1685563680000,
        "open": 0.5161,
        "high": 0.5161,
        "low": 0.5159,
        "close": 0.516,
        "volume": 36071
      },
      {
        "date": 1685563740000,
        "open": 0.516,
        "high": 0.516,
        "low": 0.5152,
        "close": 0.5155,
        "volume": 330764
      },
      {
        "date": 1685563800000,
        "open": 0.5155,
        "high": 0.5155,
        "low": 0.5153,
        "close": 0.5154,
        "volume": 29686
      },
      {
        "date": 1685563860000,
        "open": 0.5154,
        "high": 0.516,
        "low": 0.5154,
        "close": 0.516,
        "volume": 107280
      },
      {
        "date": 1685563920000,
        "open": 0.5161,
        "high": 0.5164,
        "low": 0.5161,
        "close": 0.5163,
        "volume": 80501
      },
      {
        "date": 1685563980000,
        "open": 0.5163,
        "high": 0.5163,
        "low": 0.5162,
        "close": 0.5163,
        "volume": 36109
      },
      {
        "date": 1685564040000,
        "open": 0.5163,
        "high": 0.5163,
        "low": 0.5157,
        "close": 0.516,
        "volume": 292329
      },
      {
        "date": 1685564100000,
        "open": 0.516,
        "high": 0.5161,
        "low": 0.5159,
        "close": 0.516,
        "volume": 188734
      },
      {
        "date": 1685564160000,
        "open": 0.5161,
        "high": 0.5161,
        "low": 0.5156,
        "close": 0.5157,
        "volume": 150684
      },
      {
        "date": 1685564220000,
        "open": 0.5158,
        "high": 0.5161,
        "low": 0.5157,
        "close": 0.5158,
        "volume": 184012
      },
      {
        "date": 1685564280000,
        "open": 0.5159,
        "high": 0.516,
        "low": 0.5158,
        "close": 0.516,
        "volume": 55111
      },
      {
        "date": 1685564340000,
        "open": 0.5159,
        "high": 0.5164,
        "low": 0.5159,
        "close": 0.5163,
        "volume": 202039
      },
      {
        "date": 1685564400000,
        "open": 0.5164,
        "high": 0.5164,
        "low": 0.5156,
        "close": 0.5157,
        "volume": 170529
      },
      {
        "date": 1685564460000,
        "open": 0.5158,
        "high": 0.5158,
        "low": 0.5154,
        "close": 0.5154,
        "volume": 156847
      },
      {
        "date": 1685564520000,
        "open": 0.5154,
        "high": 0.5158,
        "low": 0.5154,
        "close": 0.5157,
        "volume": 105089
      },
      {
        "date": 1685564580000,
        "open": 0.5157,
        "high": 0.5164,
        "low": 0.5157,
        "close": 0.5162,
        "volume": 251931
      },
      {
        "date": 1685564640000,
        "open": 0.5162,
        "high": 0.5164,
        "low": 0.5162,
        "close": 0.5164,
        "volume": 74167
      },
      {
        "date": 1685564700000,
        "open": 0.5164,
        "high": 0.5164,
        "low": 0.5162,
        "close": 0.5164,
        "volume": 72333
      },
      {
        "date": 1685564760000,
        "open": 0.5164,
        "high": 0.5164,
        "low": 0.5158,
        "close": 0.5158,
        "volume": 177584
      },
      {
        "date": 1685564820000,
        "open": 0.5158,
        "high": 0.5163,
        "low": 0.5158,
        "close": 0.5163,
        "volume": 204039
      },
      {
        "date": 1685564880000,
        "open": 0.5162,
        "high": 0.5164,
        "low": 0.5162,
        "close": 0.5163,
        "volume": 14203
      },
      {
        "date": 1685564940000,
        "open": 0.5164,
        "high": 0.5165,
        "low": 0.516,
        "close": 0.5162,
        "volume": 244783
      },
      {
        "date": 1685565000000,
        "open": 0.5162,
        "high": 0.5163,
        "low": 0.516,
        "close": 0.5162,
        "volume": 145479
      },
      {
        "date": 1685565060000,
        "open": 0.5162,
        "high": 0.5166,
        "low": 0.5162,
        "close": 0.5166,
        "volume": 105168
      },
      {
        "date": 1685565120000,
        "open": 0.5166,
        "high": 0.5172,
        "low": 0.5165,
        "close": 0.5171,
        "volume": 748863
      },
      {
        "date": 1685565180000,
        "open": 0.5171,
        "high": 0.5171,
        "low": 0.5167,
        "close": 0.5167,
        "volume": 124758
      },
      {
        "date": 1685565240000,
        "open": 0.5167,
        "high": 0.5167,
        "low": 0.5162,
        "close": 0.5166,
        "volume": 195050
      },
      {
        "date": 1685565300000,
        "open": 0.5166,
        "high": 0.5167,
        "low": 0.5162,
        "close": 0.5163,
        "volume": 168539
      },
      {
        "date": 1685565360000,
        "open": 0.5162,
        "high": 0.5168,
        "low": 0.5162,
        "close": 0.5168,
        "volume": 131925
      },
      {
        "date": 1685565420000,
        "open": 0.5168,
        "high": 0.517,
        "low": 0.5168,
        "close": 0.517,
        "volume": 38450
      },
      {
        "date": 1685565480000,
        "open": 0.517,
        "high": 0.5171,
        "low": 0.5167,
        "close": 0.5168,
        "volume": 145235
      },
      {
        "date": 1685565540000,
        "open": 0.5168,
        "high": 0.5171,
        "low": 0.5167,
        "close": 0.5168,
        "volume": 243712
      },
      {
        "date": 1685565600000,
        "open": 0.5169,
        "high": 0.5169,
        "low": 0.5161,
        "close": 0.5161,
        "volume": 148447
      },
      {
        "date": 1685565660000,
        "open": 0.5161,
        "high": 0.5162,
        "low": 0.5156,
        "close": 0.5158,
        "volume": 219038
      },
      {
        "date": 1685565720000,
        "open": 0.5158,
        "high": 0.5158,
        "low": 0.5154,
        "close": 0.5155,
        "volume": 189484
      },
      {
        "date": 1685565780000,
        "open": 0.5154,
        "high": 0.5155,
        "low": 0.5151,
        "close": 0.5151,
        "volume": 230495
      },
      {
        "date": 1685565840000,
        "open": 0.5151,
        "high": 0.5151,
        "low": 0.5147,
        "close": 0.5149,
        "volume": 139120
      },
      {
        "date": 1685565900000,
        "open": 0.515,
        "high": 0.5152,
        "low": 0.5145,
        "close": 0.5148,
        "volume": 255656
      },
      {
        "date": 1685565960000,
        "open": 0.5147,
        "high": 0.5148,
        "low": 0.5141,
        "close": 0.5145,
        "volume": 317025
      },
      {
        "date": 1685566020000,
        "open": 0.5145,
        "high": 0.5148,
        "low": 0.5143,
        "close": 0.5147,
        "volume": 103883
      },
      {
        "date": 1685566080000,
        "open": 0.5148,
        "high": 0.5154,
        "low": 0.5147,
        "close": 0.5154,
        "volume": 115392
      },
      {
        "date": 1685566140000,
        "open": 0.5155,
        "high": 0.5155,
        "low": 0.5152,
        "close": 0.5153,
        "volume": 51182
      },
      {
        "date": 1685566200000,
        "open": 0.5152,
        "high": 0.5154,
        "low": 0.5152,
        "close": 0.5154,
        "volume": 49683
      },
      {
        "date": 1685566260000,
        "open": 0.5154,
        "high": 0.5158,
        "low": 0.5154,
        "close": 0.5158,
        "volume": 81860
      },
      {
        "date": 1685566320000,
        "open": 0.5158,
        "high": 0.5161,
        "low": 0.5155,
        "close": 0.5159,
        "volume": 153403
      },
      {
        "date": 1685566380000,
        "open": 0.5158,
        "high": 0.5159,
        "low": 0.5152,
        "close": 0.5152,
        "volume": 66716
      },
      {
        "date": 1685566440000,
        "open": 0.5152,
        "high": 0.5153,
        "low": 0.5149,
        "close": 0.5151,
        "volume": 84780
      },
      {
        "date": 1685566500000,
        "open": 0.5151,
        "high": 0.5152,
        "low": 0.515,
        "close": 0.515,
        "volume": 18907
      },
      {
        "date": 1685566560000,
        "open": 0.515,
        "high": 0.5151,
        "low": 0.5146,
        "close": 0.5148,
        "volume": 162580
      },
      {
        "date": 1685566620000,
        "open": 0.5149,
        "high": 0.515,
        "low": 0.5146,
        "close": 0.5148,
        "volume": 68861
      },
      {
        "date": 1685566680000,
        "open": 0.5149,
        "high": 0.5149,
        "low": 0.5146,
        "close": 0.5147,
        "volume": 131703
      },
      {
        "date": 1685566740000,
        "open": 0.5147,
        "high": 0.5148,
        "low": 0.5143,
        "close": 0.5143,
        "volume": 130925
      },
      {
        "date": 1685566800000,
        "open": 0.5144,
        "high": 0.5145,
        "low": 0.5139,
        "close": 0.5139,
        "volume": 214498
      },
      {
        "date": 1685566860000,
        "open": 0.5139,
        "high": 0.5142,
        "low": 0.5139,
        "close": 0.514,
        "volume": 64132
      },
      {
        "date": 1685566920000,
        "open": 0.5139,
        "high": 0.5146,
        "low": 0.5139,
        "close": 0.5145,
        "volume": 114663
      },
      {
        "date": 1685566980000,
        "open": 0.5145,
        "high": 0.5145,
        "low": 0.5141,
        "close": 0.5141,
        "volume": 46153
      },
      {
        "date": 1685567040000,
        "open": 0.5142,
        "high": 0.5142,
        "low": 0.5136,
        "close": 0.5137,
        "volume": 49855
      },
      {
        "date": 1685567100000,
        "open": 0.5136,
        "high": 0.5136,
        "low": 0.5131,
        "close": 0.5131,
        "volume": 368239
      },
      {
        "date": 1685567160000,
        "open": 0.5131,
        "high": 0.5135,
        "low": 0.5131,
        "close": 0.5134,
        "volume": 89718
      },
      {
        "date": 1685567220000,
        "open": 0.5133,
        "high": 0.5136,
        "low": 0.5133,
        "close": 0.5134,
        "volume": 228447
      },
      {
        "date": 1685567280000,
        "open": 0.5134,
        "high": 0.5139,
        "low": 0.5134,
        "close": 0.5139,
        "volume": 73664
      },
      {
        "date": 1685567340000,
        "open": 0.5139,
        "high": 0.5139,
        "low": 0.5134,
        "close": 0.5135,
        "volume": 40775
      },
      {
        "date": 1685567400000,
        "open": 0.5135,
        "high": 0.5143,
        "low": 0.5135,
        "close": 0.5141,
        "volume": 179770
      },
      {
        "date": 1685567460000,
        "open": 0.5142,
        "high": 0.5145,
        "low": 0.514,
        "close": 0.5141,
        "volume": 72811
      },
      {
        "date": 1685567520000,
        "open": 0.5141,
        "high": 0.5144,
        "low": 0.5139,
        "close": 0.5144,
        "volume": 92015
      },
      {
        "date": 1685567580000,
        "open": 0.5144,
        "high": 0.5146,
        "low": 0.5144,
        "close": 0.5145,
        "volume": 25330
      },
      {
        "date": 1685567640000,
        "open": 0.5145,
        "high": 0.5146,
        "low": 0.5143,
        "close": 0.5143,
        "volume": 44380
      },
      {
        "date": 1685567700000,
        "open": 0.5143,
        "high": 0.5145,
        "low": 0.5138,
        "close": 0.5142,
        "volume": 76634
      },
      {
        "date": 1685567760000,
        "open": 0.5141,
        "high": 0.5141,
        "low": 0.5138,
        "close": 0.5138,
        "volume": 79569
      },
      {
        "date": 1685567820000,
        "open": 0.5138,
        "high": 0.5144,
        "low": 0.5137,
        "close": 0.5144,
        "volume": 163579
      },
      {
        "date": 1685567880000,
        "open": 0.5144,
        "high": 0.5154,
        "low": 0.5144,
        "close": 0.5154,
        "volume": 158646
      },
      {
        "date": 1685567940000,
        "open": 0.5154,
        "high": 0.5155,
        "low": 0.5147,
        "close": 0.5149,
        "volume": 412637
      },
      {
        "date": 1685568000000,
        "open": 0.515,
        "high": 0.515,
        "low": 0.5145,
        "close": 0.5146,
        "volume": 40049
      },
      {
        "date": 1685568060000,
        "open": 0.5145,
        "high": 0.5149,
        "low": 0.5144,
        "close": 0.5149,
        "volume": 127506
      },
      {
        "date": 1685568120000,
        "open": 0.5149,
        "high": 0.5154,
        "low": 0.5148,
        "close": 0.5153,
        "volume": 78984
      },
      {
        "date": 1685568180000,
        "open": 0.5153,
        "high": 0.5154,
        "low": 0.5148,
        "close": 0.5148,
        "volume": 129732
      },
      {
        "date": 1685568240000,
        "open": 0.5148,
        "high": 0.5149,
        "low": 0.5148,
        "close": 0.5148,
        "volume": 39725
      },
      {
        "date": 1685568300000,
        "open": 0.5148,
        "high": 0.5153,
        "low": 0.5148,
        "close": 0.5151,
        "volume": 183078
      },
      {
        "date": 1685568360000,
        "open": 0.5152,
        "high": 0.5153,
        "low": 0.515,
        "close": 0.515,
        "volume": 23355
      },
      {
        "date": 1685568420000,
        "open": 0.5151,
        "high": 0.5151,
        "low": 0.5146,
        "close": 0.5146,
        "volume": 35080
      },
      {
        "date": 1685568480000,
        "open": 0.5146,
        "high": 0.5147,
        "low": 0.5144,
        "close": 0.5145,
        "volume": 169218
      },
      {
        "date": 1685568540000,
        "open": 0.5145,
        "high": 0.5146,
        "low": 0.5143,
        "close": 0.5145,
        "volume": 41044
      },
      {
        "date": 1685568600000,
        "open": 0.5145,
        "high": 0.5148,
        "low": 0.5145,
        "close": 0.5147,
        "volume": 34888
      },
      {
        "date": 1685568660000,
        "open": 0.5147,
        "high": 0.515,
        "low": 0.5147,
        "close": 0.515,
        "volume": 63861
      },
      {
        "date": 1685568720000,
        "open": 0.515,
        "high": 0.5155,
        "low": 0.5149,
        "close": 0.5151,
        "volume": 196068
      },
      {
        "date": 1685568780000,
        "open": 0.5152,
        "high": 0.5155,
        "low": 0.5151,
        "close": 0.5155,
        "volume": 79837
      },
      {
        "date": 1685568840000,
        "open": 0.5154,
        "high": 0.5157,
        "low": 0.5154,
        "close": 0.5156,
        "volume": 78652
      },
      {
        "date": 1685568900000,
        "open": 0.5156,
        "high": 0.516,
        "low": 0.5156,
        "close": 0.516,
        "volume": 119534
      },
      {
        "date": 1685568960000,
        "open": 0.516,
        "high": 0.5162,
        "low": 0.5158,
        "close": 0.5159,
        "volume": 52886
      },
      {
        "date": 1685569020000,
        "open": 0.5159,
        "high": 0.5159,
        "low": 0.5155,
        "close": 0.5155,
        "volume": 54107
      },
      {
        "date": 1685569080000,
        "open": 0.5155,
        "high": 0.5156,
        "low": 0.5153,
        "close": 0.5155,
        "volume": 76648
      },
      {
        "date": 1685569140000,
        "open": 0.5155,
        "high": 0.5159,
        "low": 0.5155,
        "close": 0.5159,
        "volume": 27944
      },
      {
        "date": 1685569200000,
        "open": 0.5158,
        "high": 0.516,
        "low": 0.5154,
        "close": 0.5154,
        "volume": 77005
      },
      {
        "date": 1685569260000,
        "open": 0.5153,
        "high": 0.5154,
        "low": 0.5152,
        "close": 0.5153,
        "volume": 47181
      },
      {
        "date": 1685569320000,
        "open": 0.5152,
        "high": 0.5154,
        "low": 0.515,
        "close": 0.5153,
        "volume": 310184
      },
      {
        "date": 1685569380000,
        "open": 0.5153,
        "high": 0.5154,
        "low": 0.5152,
        "close": 0.5152,
        "volume": 11345
      },
      {
        "date": 1685569440000,
        "open": 0.5152,
        "high": 0.5153,
        "low": 0.515,
        "close": 0.515,
        "volume": 69818
      },
      {
        "date": 1685569500000,
        "open": 0.515,
        "high": 0.5155,
        "low": 0.515,
        "close": 0.5155,
        "volume": 56515
      },
      {
        "date": 1685569560000,
        "open": 0.5155,
        "high": 0.5157,
        "low": 0.5155,
        "close": 0.5157,
        "volume": 13193
      },
      {
        "date": 1685569620000,
        "open": 0.5157,
        "high": 0.5159,
        "low": 0.5156,
        "close": 0.5159,
        "volume": 27363
      },
      {
        "date": 1685569680000,
        "open": 0.5158,
        "high": 0.516,
        "low": 0.5158,
        "close": 0.5159,
        "volume": 45316
      },
      {
        "date": 1685569740000,
        "open": 0.5159,
        "high": 0.5159,
        "low": 0.5152,
        "close": 0.5153,
        "volume": 53501
      },
      {
        "date": 1685569800000,
        "open": 0.5152,
        "high": 0.5153,
        "low": 0.515,
        "close": 0.515,
        "volume": 25950
      },
      {
        "date": 1685569860000,
        "open": 0.515,
        "high": 0.5153,
        "low": 0.515,
        "close": 0.5153,
        "volume": 91432
      },
      {
        "date": 1685569920000,
        "open": 0.5151,
        "high": 0.5152,
        "low": 0.5145,
        "close": 0.5148,
        "volume": 111591
      },
      {
        "date": 1685569980000,
        "open": 0.5148,
        "high": 0.5149,
        "low": 0.5143,
        "close": 0.5143,
        "volume": 139961
      },
      {
        "date": 1685570040000,
        "open": 0.5142,
        "high": 0.5142,
        "low": 0.513,
        "close": 0.5133,
        "volume": 222950
      },
      {
        "date": 1685570100000,
        "open": 0.5133,
        "high": 0.5134,
        "low": 0.5116,
        "close": 0.5131,
        "volume": 429363
      },
      {
        "date": 1685570160000,
        "open": 0.5131,
        "high": 0.5137,
        "low": 0.5131,
        "close": 0.5133,
        "volume": 288390
      },
      {
        "date": 1685570220000,
        "open": 0.5134,
        "high": 0.5135,
        "low": 0.513,
        "close": 0.513,
        "volume": 38709
      },
      {
        "date": 1685570280000,
        "open": 0.5129,
        "high": 0.5132,
        "low": 0.5126,
        "close": 0.5126,
        "volume": 346480
      },
      {
        "date": 1685570340000,
        "open": 0.5126,
        "high": 0.5132,
        "low": 0.5126,
        "close": 0.5132,
        "volume": 117683
      },
      {
        "date": 1685570400000,
        "open": 0.5132,
        "high": 0.5132,
        "low": 0.5125,
        "close": 0.5126,
        "volume": 39016
      },
      {
        "date": 1685570460000,
        "open": 0.5125,
        "high": 0.5134,
        "low": 0.5123,
        "close": 0.5132,
        "volume": 184805
      },
      {
        "date": 1685570520000,
        "open": 0.5131,
        "high": 0.5131,
        "low": 0.5125,
        "close": 0.5125,
        "volume": 311840
      },
      {
        "date": 1685570580000,
        "open": 0.5125,
        "high": 0.513,
        "low": 0.5125,
        "close": 0.5129,
        "volume": 187340
      },
      {
        "date": 1685570640000,
        "open": 0.513,
        "high": 0.513,
        "low": 0.5128,
        "close": 0.513,
        "volume": 95828
      },
      {
        "date": 1685570700000,
        "open": 0.5129,
        "high": 0.5132,
        "low": 0.5129,
        "close": 0.5132,
        "volume": 18784
      },
      {
        "date": 1685570760000,
        "open": 0.5132,
        "high": 0.5132,
        "low": 0.5127,
        "close": 0.5128,
        "volume": 34523
      },
      {
        "date": 1685570820000,
        "open": 0.5127,
        "high": 0.513,
        "low": 0.5127,
        "close": 0.5129,
        "volume": 12751
      },
      {
        "date": 1685570880000,
        "open": 0.5129,
        "high": 0.5132,
        "low": 0.5129,
        "close": 0.513,
        "volume": 63458
      },
      {
        "date": 1685570940000,
        "open": 0.513,
        "high": 0.5132,
        "low": 0.513,
        "close": 0.513,
        "volume": 52877
      },
      {
        "date": 1685571000000,
        "open": 0.513,
        "high": 0.5135,
        "low": 0.513,
        "close": 0.5135,
        "volume": 37537
      },
      {
        "date": 1685571060000,
        "open": 0.5136,
        "high": 0.5143,
        "low": 0.5136,
        "close": 0.5141,
        "volume": 240256
      },
      {
        "date": 1685571120000,
        "open": 0.514,
        "high": 0.5144,
        "low": 0.514,
        "close": 0.5142,
        "volume": 87619
      },
      {
        "date": 1685571180000,
        "open": 0.5142,
        "high": 0.5146,
        "low": 0.5142,
        "close": 0.5145,
        "volume": 46052
      },
      {
        "date": 1685571240000,
        "open": 0.5145,
        "high": 0.5146,
        "low": 0.5143,
        "close": 0.5144,
        "volume": 46375
      },
      {
        "date": 1685571300000,
        "open": 0.5143,
        "high": 0.5144,
        "low": 0.5139,
        "close": 0.514,
        "volume": 123064
      },
      {
        "date": 1685571360000,
        "open": 0.514,
        "high": 0.5145,
        "low": 0.514,
        "close": 0.5142,
        "volume": 139061
      },
      {
        "date": 1685571420000,
        "open": 0.5142,
        "high": 0.5144,
        "low": 0.5142,
        "close": 0.5144,
        "volume": 69337
      },
      {
        "date": 1685571480000,
        "open": 0.5144,
        "high": 0.5145,
        "low": 0.5143,
        "close": 0.5144,
        "volume": 22545
      },
      {
        "date": 1685571540000,
        "open": 0.5144,
        "high": 0.5153,
        "low": 0.5144,
        "close": 0.5153,
        "volume": 216111
      },
      {
        "date": 1685571600000,
        "open": 0.5152,
        "high": 0.5152,
        "low": 0.5143,
        "close": 0.5147,
        "volume": 90790
      },
      {
        "date": 1685571660000,
        "open": 0.5147,
        "high": 0.5148,
        "low": 0.5144,
        "close": 0.5145,
        "volume": 19660
      },
      {
        "date": 1685571720000,
        "open": 0.5145,
        "high": 0.515,
        "low": 0.5145,
        "close": 0.515,
        "volume": 43606
      },
      {
        "date": 1685571780000,
        "open": 0.5149,
        "high": 0.515,
        "low": 0.5147,
        "close": 0.5147,
        "volume": 20075
      },
      {
        "date": 1685571840000,
        "open": 0.5147,
        "high": 0.5148,
        "low": 0.5147,
        "close": 0.5148,
        "volume": 1572
      },
      {
        "date": 1685571900000,
        "open": 0.5148,
        "high": 0.5152,
        "low": 0.5147,
        "close": 0.5152,
        "volume": 45742
      },
      {
        "date": 1685571960000,
        "open": 0.5152,
        "high": 0.5158,
        "low": 0.5151,
        "close": 0.5158,
        "volume": 155402
      },
      {
        "date": 1685572020000,
        "open": 0.5158,
        "high": 0.5165,
        "low": 0.5157,
        "close": 0.5163,
        "volume": 320326
      },
      {
        "date": 1685572080000,
        "open": 0.5163,
        "high": 0.5164,
        "low": 0.5154,
        "close": 0.5154,
        "volume": 288795
      },
      {
        "date": 1685572140000,
        "open": 0.5154,
        "high": 0.5157,
        "low": 0.5152,
        "close": 0.5156,
        "volume": 75300
      },
      {
        "date": 1685572200000,
        "open": 0.5156,
        "high": 0.516,
        "low": 0.5155,
        "close": 0.5156,
        "volume": 184218
      },
      {
        "date": 1685572260000,
        "open": 0.5156,
        "high": 0.516,
        "low": 0.5156,
        "close": 0.516,
        "volume": 25473
      },
      {
        "date": 1685572320000,
        "open": 0.516,
        "high": 0.5164,
        "low": 0.516,
        "close": 0.5163,
        "volume": 131691
      },
      {
        "date": 1685572380000,
        "open": 0.5164,
        "high": 0.5166,
        "low": 0.5164,
        "close": 0.5165,
        "volume": 48516
      },
      {
        "date": 1685572440000,
        "open": 0.5165,
        "high": 0.5171,
        "low": 0.5165,
        "close": 0.5168,
        "volume": 582641
      },
      {
        "date": 1685572500000,
        "open": 0.5168,
        "high": 0.5169,
        "low": 0.5163,
        "close": 0.5165,
        "volume": 55001
      },
      {
        "date": 1685572560000,
        "open": 0.5165,
        "high": 0.5169,
        "low": 0.5164,
        "close": 0.5167,
        "volume": 117535
      },
      {
        "date": 1685572620000,
        "open": 0.5167,
        "high": 0.5173,
        "low": 0.5167,
        "close": 0.5169,
        "volume": 902619
      },
      {
        "date": 1685572680000,
        "open": 0.5168,
        "high": 0.5172,
        "low": 0.5168,
        "close": 0.5171,
        "volume": 306548
      },
      {
        "date": 1685572740000,
        "open": 0.5172,
        "high": 0.5175,
        "low": 0.5168,
        "close": 0.5171,
        "volume": 428460
      },
      {
        "date": 1685572800000,
        "open": 0.517,
        "high": 0.5171,
        "low": 0.5164,
        "close": 0.5164,
        "volume": 54445
      },
      {
        "date": 1685572860000,
        "open": 0.5164,
        "high": 0.5164,
        "low": 0.5157,
        "close": 0.5158,
        "volume": 225332
      },
      {
        "date": 1685572920000,
        "open": 0.5158,
        "high": 0.516,
        "low": 0.5158,
        "close": 0.5159,
        "volume": 13852
      },
      {
        "date": 1685572980000,
        "open": 0.5159,
        "high": 0.5159,
        "low": 0.5152,
        "close": 0.5153,
        "volume": 148402
      },
      {
        "date": 1685573040000,
        "open": 0.5153,
        "high": 0.5154,
        "low": 0.515,
        "close": 0.5151,
        "volume": 79246
      },
      {
        "date": 1685573100000,
        "open": 0.5151,
        "high": 0.5151,
        "low": 0.5144,
        "close": 0.5146,
        "volume": 64457
      },
      {
        "date": 1685573160000,
        "open": 0.5146,
        "high": 0.5151,
        "low": 0.5145,
        "close": 0.5151,
        "volume": 109107
      },
      {
        "date": 1685573220000,
        "open": 0.5151,
        "high": 0.5158,
        "low": 0.5151,
        "close": 0.5157,
        "volume": 254368
      },
      {
        "date": 1685573280000,
        "open": 0.5158,
        "high": 0.5165,
        "low": 0.5158,
        "close": 0.5165,
        "volume": 196067
      },
      {
        "date": 1685573340000,
        "open": 0.5165,
        "high": 0.5166,
        "low": 0.5164,
        "close": 0.5165,
        "volume": 37178
      },
      {
        "date": 1685573400000,
        "open": 0.5165,
        "high": 0.5174,
        "low": 0.5165,
        "close": 0.5172,
        "volume": 405818
      },
      {
        "date": 1685573460000,
        "open": 0.5171,
        "high": 0.5174,
        "low": 0.5169,
        "close": 0.517,
        "volume": 311193
      },
      {
        "date": 1685573520000,
        "open": 0.5171,
        "high": 0.5171,
        "low": 0.5167,
        "close": 0.5171,
        "volume": 168007
      },
      {
        "date": 1685573580000,
        "open": 0.517,
        "high": 0.5171,
        "low": 0.5169,
        "close": 0.517,
        "volume": 154801
      },
      {
        "date": 1685573640000,
        "open": 0.517,
        "high": 0.517,
        "low": 0.5169,
        "close": 0.5169,
        "volume": 62520
      },
      {
        "date": 1685573700000,
        "open": 0.5169,
        "high": 0.5198,
        "low": 0.5169,
        "close": 0.5194,
        "volume": 2107283
      },
      {
        "date": 1685573760000,
        "open": 0.5193,
        "high": 0.5194,
        "low": 0.5184,
        "close": 0.5185,
        "volume": 318680
      },
      {
        "date": 1685573820000,
        "open": 0.5186,
        "high": 0.52,
        "low": 0.5184,
        "close": 0.5198,
        "volume": 666337
      },
      {
        "date": 1685573880000,
        "open": 0.5199,
        "high": 0.5199,
        "low": 0.5191,
        "close": 0.5192,
        "volume": 258823
      },
      {
        "date": 1685573940000,
        "open": 0.5191,
        "high": 0.5192,
        "low": 0.5188,
        "close": 0.519,
        "volume": 263037
      },
      {
        "date": 1685574000000,
        "open": 0.5189,
        "high": 0.519,
        "low": 0.5176,
        "close": 0.5178,
        "volume": 366287
      },
      {
        "date": 1685574060000,
        "open": 0.5178,
        "high": 0.5183,
        "low": 0.5175,
        "close": 0.518,
        "volume": 368417
      },
      {
        "date": 1685574120000,
        "open": 0.518,
        "high": 0.518,
        "low": 0.5177,
        "close": 0.5177,
        "volume": 99299
      },
      {
        "date": 1685574180000,
        "open": 0.5177,
        "high": 0.5181,
        "low": 0.5166,
        "close": 0.5169,
        "volume": 512156
      },
      {
        "date": 1685574240000,
        "open": 0.5168,
        "high": 0.5176,
        "low": 0.5168,
        "close": 0.5176,
        "volume": 80997
      },
      {
        "date": 1685574300000,
        "open": 0.5176,
        "high": 0.5177,
        "low": 0.5174,
        "close": 0.5174,
        "volume": 75878
      },
      {
        "date": 1685574360000,
        "open": 0.5175,
        "high": 0.5179,
        "low": 0.5173,
        "close": 0.5179,
        "volume": 93741
      },
      {
        "date": 1685574420000,
        "open": 0.518,
        "high": 0.5182,
        "low": 0.5178,
        "close": 0.518,
        "volume": 159565
      },
      {
        "date": 1685574480000,
        "open": 0.518,
        "high": 0.518,
        "low": 0.5174,
        "close": 0.5174,
        "volume": 128310
      },
      {
        "date": 1685574540000,
        "open": 0.5173,
        "high": 0.5174,
        "low": 0.517,
        "close": 0.5172,
        "volume": 109617
      },
      {
        "date": 1685574600000,
        "open": 0.5171,
        "high": 0.5177,
        "low": 0.5171,
        "close": 0.5177,
        "volume": 331832
      },
      {
        "date": 1685574660000,
        "open": 0.5178,
        "high": 0.5178,
        "low": 0.5176,
        "close": 0.5178,
        "volume": 131409
      },
      {
        "date": 1685574720000,
        "open": 0.5178,
        "high": 0.5184,
        "low": 0.5177,
        "close": 0.518,
        "volume": 230785
      },
      {
        "date": 1685574780000,
        "open": 0.5181,
        "high": 0.5188,
        "low": 0.518,
        "close": 0.5187,
        "volume": 165288
      },
      {
        "date": 1685574840000,
        "open": 0.5187,
        "high": 0.5187,
        "low": 0.5184,
        "close": 0.5184,
        "volume": 134850
      },
      {
        "date": 1685574900000,
        "open": 0.5184,
        "high": 0.5189,
        "low": 0.5182,
        "close": 0.5189,
        "volume": 325430
      },
      {
        "date": 1685574960000,
        "open": 0.5189,
        "high": 0.5191,
        "low": 0.5188,
        "close": 0.5189,
        "volume": 143422
      },
      {
        "date": 1685575020000,
        "open": 0.5189,
        "high": 0.5193,
        "low": 0.5188,
        "close": 0.5192,
        "volume": 62265
      },
      {
        "date": 1685575080000,
        "open": 0.5192,
        "high": 0.5193,
        "low": 0.5187,
        "close": 0.5191,
        "volume": 290096
      },
      {
        "date": 1685575140000,
        "open": 0.519,
        "high": 0.5191,
        "low": 0.519,
        "close": 0.5191,
        "volume": 84782
      },
      {
        "date": 1685575200000,
        "open": 0.519,
        "high": 0.5194,
        "low": 0.519,
        "close": 0.5191,
        "volume": 207757
      },
      {
        "date": 1685575260000,
        "open": 0.5191,
        "high": 0.5196,
        "low": 0.5191,
        "close": 0.5194,
        "volume": 282143
      },
      {
        "date": 1685575320000,
        "open": 0.5194,
        "high": 0.5199,
        "low": 0.5191,
        "close": 0.5191,
        "volume": 532884
      },
      {
        "date": 1685575380000,
        "open": 0.519,
        "high": 0.5193,
        "low": 0.5185,
        "close": 0.5185,
        "volume": 142276
      },
      {
        "date": 1685575440000,
        "open": 0.5185,
        "high": 0.5186,
        "low": 0.5183,
        "close": 0.5185,
        "volume": 127034
      },
      {
        "date": 1685575500000,
        "open": 0.5184,
        "high": 0.5189,
        "low": 0.5184,
        "close": 0.5186,
        "volume": 127266
      },
      {
        "date": 1685575560000,
        "open": 0.5186,
        "high": 0.5188,
        "low": 0.5183,
        "close": 0.5187,
        "volume": 326190
      },
      {
        "date": 1685575620000,
        "open": 0.5187,
        "high": 0.519,
        "low": 0.5186,
        "close": 0.519,
        "volume": 137228
      },
      {
        "date": 1685575680000,
        "open": 0.5189,
        "high": 0.5198,
        "low": 0.5189,
        "close": 0.5194,
        "volume": 460626
      },
      {
        "date": 1685575740000,
        "open": 0.5194,
        "high": 0.5197,
        "low": 0.5194,
        "close": 0.5197,
        "volume": 63046
      },
      {
        "date": 1685575800000,
        "open": 0.5197,
        "high": 0.52,
        "low": 0.5194,
        "close": 0.5198,
        "volume": 446959
      },
      {
        "date": 1685575860000,
        "open": 0.5199,
        "high": 0.5199,
        "low": 0.5196,
        "close": 0.5199,
        "volume": 146898
      },
      {
        "date": 1685575920000,
        "open": 0.5199,
        "high": 0.5199,
        "low": 0.5197,
        "close": 0.5198,
        "volume": 134808
      },
      {
        "date": 1685575980000,
        "open": 0.5198,
        "high": 0.5199,
        "low": 0.5195,
        "close": 0.5195,
        "volume": 266188
      },
      {
        "date": 1685576040000,
        "open": 0.5195,
        "high": 0.5199,
        "low": 0.5195,
        "close": 0.5196,
        "volume": 123455
      },
      {
        "date": 1685576100000,
        "open": 0.5196,
        "high": 0.5196,
        "low": 0.5191,
        "close": 0.5191,
        "volume": 67166
      },
      {
        "date": 1685576160000,
        "open": 0.5191,
        "high": 0.5191,
        "low": 0.5185,
        "close": 0.5189,
        "volume": 314282
      },
      {
        "date": 1685576220000,
        "open": 0.519,
        "high": 0.5191,
        "low": 0.5188,
        "close": 0.5189,
        "volume": 191037
      },
      {
        "date": 1685576280000,
        "open": 0.5189,
        "high": 0.5189,
        "low": 0.5184,
        "close": 0.5188,
        "volume": 189624
      },
      {
        "date": 1685576340000,
        "open": 0.5187,
        "high": 0.5189,
        "low": 0.5185,
        "close": 0.5188,
        "volume": 130014
      },
      {
        "date": 1685576400000,
        "open": 0.5188,
        "high": 0.5189,
        "low": 0.5187,
        "close": 0.5188,
        "volume": 29224
      },
      {
        "date": 1685576460000,
        "open": 0.5188,
        "high": 0.519,
        "low": 0.5188,
        "close": 0.5189,
        "volume": 34247
      },
      {
        "date": 1685576520000,
        "open": 0.5189,
        "high": 0.5191,
        "low": 0.5189,
        "close": 0.5191,
        "volume": 125485
      },
      {
        "date": 1685576580000,
        "open": 0.519,
        "high": 0.5197,
        "low": 0.5187,
        "close": 0.5191,
        "volume": 596094
      },
      {
        "date": 1685576640000,
        "open": 0.5192,
        "high": 0.5194,
        "low": 0.5191,
        "close": 0.5192,
        "volume": 50449
      },
      {
        "date": 1685576700000,
        "open": 0.5192,
        "high": 0.5193,
        "low": 0.5188,
        "close": 0.5189,
        "volume": 113000
      },
      {
        "date": 1685576760000,
        "open": 0.5189,
        "high": 0.519,
        "low": 0.5182,
        "close": 0.5184,
        "volume": 386208
      },
      {
        "date": 1685576820000,
        "open": 0.5184,
        "high": 0.5184,
        "low": 0.5178,
        "close": 0.518,
        "volume": 1236195
      },
      {
        "date": 1685576880000,
        "open": 0.5179,
        "high": 0.5182,
        "low": 0.5175,
        "close": 0.518,
        "volume": 1252246
      },
      {
        "date": 1685576940000,
        "open": 0.5179,
        "high": 0.5179,
        "low": 0.5176,
        "close": 0.5179,
        "volume": 218910
      },
      {
        "date": 1685577000000,
        "open": 0.5178,
        "high": 0.5179,
        "low": 0.5174,
        "close": 0.5177,
        "volume": 296194
      },
      {
        "date": 1685577060000,
        "open": 0.5176,
        "high": 0.5185,
        "low": 0.5176,
        "close": 0.5185,
        "volume": 1271700
      },
      {
        "date": 1685577120000,
        "open": 0.5185,
        "high": 0.5186,
        "low": 0.518,
        "close": 0.5181,
        "volume": 343668
      },
      {
        "date": 1685577180000,
        "open": 0.518,
        "high": 0.5181,
        "low": 0.5173,
        "close": 0.5175,
        "volume": 1641294
      },
      {
        "date": 1685577240000,
        "open": 0.5175,
        "high": 0.5176,
        "low": 0.5173,
        "close": 0.5174,
        "volume": 625522
      },
      {
        "date": 1685577300000,
        "open": 0.5173,
        "high": 0.5173,
        "low": 0.5166,
        "close": 0.5167,
        "volume": 461129
      },
      {
        "date": 1685577360000,
        "open": 0.5167,
        "high": 0.5167,
        "low": 0.5163,
        "close": 0.5163,
        "volume": 490974
      },
      {
        "date": 1685577420000,
        "open": 0.5164,
        "high": 0.5165,
        "low": 0.5163,
        "close": 0.5165,
        "volume": 195739
      },
      {
        "date": 1685577480000,
        "open": 0.5165,
        "high": 0.5173,
        "low": 0.5164,
        "close": 0.5169,
        "volume": 2176618
      },
      {
        "date": 1685577540000,
        "open": 0.5168,
        "high": 0.5169,
        "low": 0.5166,
        "close": 0.5166,
        "volume": 390432
      },
      {
        "date": 1685577600000,
        "open": 0.5167,
        "high": 0.5167,
        "low": 0.5159,
        "close": 0.5159,
        "volume": 477627
      },
      {
        "date": 1685577660000,
        "open": 0.5158,
        "high": 0.5158,
        "low": 0.5155,
        "close": 0.5158,
        "volume": 199345
      },
      {
        "date": 1685577720000,
        "open": 0.5158,
        "high": 0.516,
        "low": 0.5156,
        "close": 0.5157,
        "volume": 807098
      },
      {
        "date": 1685577780000,
        "open": 0.5156,
        "high": 0.516,
        "low": 0.5153,
        "close": 0.5158,
        "volume": 794891
      },
      {
        "date": 1685577840000,
        "open": 0.5157,
        "high": 0.5159,
        "low": 0.5154,
        "close": 0.5156,
        "volume": 451806
      },
      {
        "date": 1685577900000,
        "open": 0.5156,
        "high": 0.5158,
        "low": 0.5148,
        "close": 0.5149,
        "volume": 1111195
      },
      {
        "date": 1685577960000,
        "open": 0.5149,
        "high": 0.515,
        "low": 0.5142,
        "close": 0.5149,
        "volume": 685101
      },
      {
        "date": 1685578020000,
        "open": 0.5149,
        "high": 0.5153,
        "low": 0.5147,
        "close": 0.515,
        "volume": 957276
      },
      {
        "date": 1685578080000,
        "open": 0.515,
        "high": 0.5157,
        "low": 0.515,
        "close": 0.5156,
        "volume": 550820
      },
      {
        "date": 1685578140000,
        "open": 0.5155,
        "high": 0.516,
        "low": 0.5155,
        "close": 0.516,
        "volume": 2499911
      },
      {
        "date": 1685578200000,
        "open": 0.516,
        "high": 0.5163,
        "low": 0.5158,
        "close": 0.516,
        "volume": 2261265
      },
      {
        "date": 1685578260000,
        "open": 0.5161,
        "high": 0.5161,
        "low": 0.515,
        "close": 0.5154,
        "volume": 2429199
      },
      {
        "date": 1685578320000,
        "open": 0.5154,
        "high": 0.5156,
        "low": 0.5148,
        "close": 0.515,
        "volume": 1695987
      },
      {
        "date": 1685578380000,
        "open": 0.515,
        "high": 0.5156,
        "low": 0.5149,
        "close": 0.5152,
        "volume": 880919
      },
      {
        "date": 1685578440000,
        "open": 0.5153,
        "high": 0.5155,
        "low": 0.5153,
        "close": 0.5155,
        "volume": 106358
      },
      {
        "date": 1685578500000,
        "open": 0.5154,
        "high": 0.5155,
        "low": 0.5152,
        "close": 0.5153,
        "volume": 274715
      },
      {
        "date": 1685578560000,
        "open": 0.5152,
        "high": 0.5152,
        "low": 0.5146,
        "close": 0.5147,
        "volume": 751950
      },
      {
        "date": 1685578620000,
        "open": 0.5147,
        "high": 0.5147,
        "low": 0.514,
        "close": 0.5142,
        "volume": 442622
      },
      {
        "date": 1685578680000,
        "open": 0.5142,
        "high": 0.5142,
        "low": 0.5136,
        "close": 0.5138,
        "volume": 375130
      },
      {
        "date": 1685578740000,
        "open": 0.5138,
        "high": 0.5138,
        "low": 0.5123,
        "close": 0.5132,
        "volume": 865610
      },
      {
        "date": 1685578800000,
        "open": 0.5133,
        "high": 0.5138,
        "low": 0.5133,
        "close": 0.5135,
        "volume": 2153857
      },
      {
        "date": 1685578860000,
        "open": 0.5135,
        "high": 0.5139,
        "low": 0.5133,
        "close": 0.5134,
        "volume": 778545
      },
      {
        "date": 1685578920000,
        "open": 0.5134,
        "high": 0.5138,
        "low": 0.5133,
        "close": 0.5137,
        "volume": 1295376
      },
      {
        "date": 1685578980000,
        "open": 0.5136,
        "high": 0.5137,
        "low": 0.513,
        "close": 0.5131,
        "volume": 551789
      },
      {
        "date": 1685579040000,
        "open": 0.5131,
        "high": 0.5131,
        "low": 0.5127,
        "close": 0.5129,
        "volume": 315164
      },
      {
        "date": 1685579100000,
        "open": 0.5128,
        "high": 0.513,
        "low": 0.5123,
        "close": 0.5124,
        "volume": 1925322
      },
      {
        "date": 1685579160000,
        "open": 0.5124,
        "high": 0.513,
        "low": 0.5123,
        "close": 0.5128,
        "volume": 1634846
      },
      {
        "date": 1685579220000,
        "open": 0.5128,
        "high": 0.513,
        "low": 0.5128,
        "close": 0.513,
        "volume": 528285
      },
      {
        "date": 1685579280000,
        "open": 0.513,
        "high": 0.5131,
        "low": 0.5124,
        "close": 0.5126,
        "volume": 584319
      },
      {
        "date": 1685579340000,
        "open": 0.5127,
        "high": 0.513,
        "low": 0.5126,
        "close": 0.5129,
        "volume": 132349
      },
      {
        "date": 1685579400000,
        "open": 0.5128,
        "high": 0.5134,
        "low": 0.5123,
        "close": 0.5123,
        "volume": 927687
      },
      {
        "date": 1685579460000,
        "open": 0.5123,
        "high": 0.5123,
        "low": 0.5119,
        "close": 0.5121,
        "volume": 311504
      },
      {
        "date": 1685579520000,
        "open": 0.512,
        "high": 0.5122,
        "low": 0.5117,
        "close": 0.5117,
        "volume": 288657
      },
      {
        "date": 1685579580000,
        "open": 0.5117,
        "high": 0.5117,
        "low": 0.5112,
        "close": 0.5114,
        "volume": 226102
      },
      {
        "date": 1685579640000,
        "open": 0.5113,
        "high": 0.5115,
        "low": 0.5107,
        "close": 0.5109,
        "volume": 433813
      },
      {
        "date": 1685579700000,
        "open": 0.5108,
        "high": 0.5109,
        "low": 0.5093,
        "close": 0.5096,
        "volume": 1022173
      },
      {
        "date": 1685579760000,
        "open": 0.5095,
        "high": 0.5096,
        "low": 0.5091,
        "close": 0.5095,
        "volume": 911666
      },
      {
        "date": 1685579820000,
        "open": 0.5095,
        "high": 0.5095,
        "low": 0.5068,
        "close": 0.5076,
        "volume": 1174971
      },
      {
        "date": 1685579880000,
        "open": 0.5076,
        "high": 0.5084,
        "low": 0.5072,
        "close": 0.5083,
        "volume": 1580275
      },
      {
        "date": 1685579940000,
        "open": 0.5082,
        "high": 0.5094,
        "low": 0.5082,
        "close": 0.5089,
        "volume": 1233044
      },
      {
        "date": 1685580000000,
        "open": 0.5089,
        "high": 0.5095,
        "low": 0.5089,
        "close": 0.5093,
        "volume": 765193
      },
      {
        "date": 1685580060000,
        "open": 0.5093,
        "high": 0.5095,
        "low": 0.5087,
        "close": 0.5087,
        "volume": 389211
      },
      {
        "date": 1685580120000,
        "open": 0.5087,
        "high": 0.5088,
        "low": 0.5081,
        "close": 0.5084,
        "volume": 990883
      },
      {
        "date": 1685580180000,
        "open": 0.5085,
        "high": 0.509,
        "low": 0.5081,
        "close": 0.5082,
        "volume": 579698
      },
      {
        "date": 1685580240000,
        "open": 0.5083,
        "high": 0.5085,
        "low": 0.5081,
        "close": 0.5082,
        "volume": 96886
      },
      {
        "date": 1685580300000,
        "open": 0.5083,
        "high": 0.5084,
        "low": 0.5077,
        "close": 0.5077,
        "volume": 642577
      },
      {
        "date": 1685580360000,
        "open": 0.5078,
        "high": 0.5078,
        "low": 0.507,
        "close": 0.5075,
        "volume": 796467
      },
      {
        "date": 1685580420000,
        "open": 0.5074,
        "high": 0.5079,
        "low": 0.5073,
        "close": 0.5074,
        "volume": 951525
      },
      {
        "date": 1685580480000,
        "open": 0.5074,
        "high": 0.5078,
        "low": 0.5073,
        "close": 0.5075,
        "volume": 1002685
      },
      {
        "date": 1685580540000,
        "open": 0.5075,
        "high": 0.5076,
        "low": 0.5067,
        "close": 0.507,
        "volume": 1166399
      },
      {
        "date": 1685580600000,
        "open": 0.507,
        "high": 0.5077,
        "low": 0.5069,
        "close": 0.5075,
        "volume": 733351
      },
      {
        "date": 1685580660000,
        "open": 0.5075,
        "high": 0.508,
        "low": 0.5075,
        "close": 0.508,
        "volume": 836904
      },
      {
        "date": 1685580720000,
        "open": 0.508,
        "high": 0.5088,
        "low": 0.5077,
        "close": 0.5086,
        "volume": 1664409
      },
      {
        "date": 1685580780000,
        "open": 0.5086,
        "high": 0.5088,
        "low": 0.5081,
        "close": 0.5088,
        "volume": 989136
      },
      {
        "date": 1685580840000,
        "open": 0.5088,
        "high": 0.5091,
        "low": 0.5081,
        "close": 0.5083,
        "volume": 554757
      },
      {
        "date": 1685580900000,
        "open": 0.5082,
        "high": 0.5082,
        "low": 0.5072,
        "close": 0.5073,
        "volume": 1200527
      },
      {
        "date": 1685580960000,
        "open": 0.5072,
        "high": 0.5075,
        "low": 0.5069,
        "close": 0.5074,
        "volume": 358624
      },
      {
        "date": 1685581020000,
        "open": 0.5075,
        "high": 0.5081,
        "low": 0.5075,
        "close": 0.5076,
        "volume": 156263
      },
      {
        "date": 1685581080000,
        "open": 0.5076,
        "high": 0.5076,
        "low": 0.5074,
        "close": 0.5076,
        "volume": 120161
      },
      {
        "date": 1685581140000,
        "open": 0.5076,
        "high": 0.5082,
        "low": 0.5076,
        "close": 0.5082,
        "volume": 612445
      },
      {
        "date": 1685581200000,
        "open": 0.5081,
        "high": 0.5092,
        "low": 0.508,
        "close": 0.5091,
        "volume": 307226
      },
      {
        "date": 1685581260000,
        "open": 0.5091,
        "high": 0.5092,
        "low": 0.5087,
        "close": 0.5087,
        "volume": 204274
      },
      {
        "date": 1685581320000,
        "open": 0.5087,
        "high": 0.5088,
        "low": 0.5082,
        "close": 0.5083,
        "volume": 241355
      },
      {
        "date": 1685581380000,
        "open": 0.5083,
        "high": 0.5084,
        "low": 0.5082,
        "close": 0.5084,
        "volume": 78814
      },
      {
        "date": 1685581440000,
        "open": 0.5084,
        "high": 0.5084,
        "low": 0.508,
        "close": 0.5082,
        "volume": 149911
      },
      {
        "date": 1685581500000,
        "open": 0.5083,
        "high": 0.5084,
        "low": 0.508,
        "close": 0.5081,
        "volume": 631714
      },
      {
        "date": 1685581560000,
        "open": 0.5081,
        "high": 0.5084,
        "low": 0.508,
        "close": 0.5084,
        "volume": 403553
      },
      {
        "date": 1685581620000,
        "open": 0.5084,
        "high": 0.5089,
        "low": 0.5083,
        "close": 0.5086,
        "volume": 727904
      },
      {
        "date": 1685581680000,
        "open": 0.5085,
        "high": 0.5085,
        "low": 0.5079,
        "close": 0.5083,
        "volume": 448949
      },
      {
        "date": 1685581740000,
        "open": 0.5083,
        "high": 0.5089,
        "low": 0.5083,
        "close": 0.5085,
        "volume": 217189
      },
      {
        "date": 1685581800000,
        "open": 0.5085,
        "high": 0.5094,
        "low": 0.5085,
        "close": 0.5092,
        "volume": 158287
      },
      {
        "date": 1685581860000,
        "open": 0.5092,
        "high": 0.5095,
        "low": 0.5091,
        "close": 0.5095,
        "volume": 358536
      },
      {
        "date": 1685581920000,
        "open": 0.5095,
        "high": 0.5097,
        "low": 0.5091,
        "close": 0.5091,
        "volume": 506682
      },
      {
        "date": 1685581980000,
        "open": 0.5091,
        "high": 0.5092,
        "low": 0.5088,
        "close": 0.5088,
        "volume": 203433
      },
      {
        "date": 1685582040000,
        "open": 0.5088,
        "high": 0.5089,
        "low": 0.5087,
        "close": 0.5089,
        "volume": 120211
      },
      {
        "date": 1685582100000,
        "open": 0.5089,
        "high": 0.509,
        "low": 0.5085,
        "close": 0.5085,
        "volume": 134227
      },
      {
        "date": 1685582160000,
        "open": 0.5085,
        "high": 0.5089,
        "low": 0.5082,
        "close": 0.5082,
        "volume": 670249
      },
      {
        "date": 1685582220000,
        "open": 0.5082,
        "high": 0.5083,
        "low": 0.508,
        "close": 0.5081,
        "volume": 310893
      },
      {
        "date": 1685582280000,
        "open": 0.5081,
        "high": 0.5091,
        "low": 0.508,
        "close": 0.5091,
        "volume": 673367
      },
      {
        "date": 1685582340000,
        "open": 0.5091,
        "high": 0.5093,
        "low": 0.5088,
        "close": 0.5088,
        "volume": 259815
      },
      {
        "date": 1685582400000,
        "open": 0.5088,
        "high": 0.5091,
        "low": 0.5088,
        "close": 0.509,
        "volume": 169810
      },
      {
        "date": 1685582460000,
        "open": 0.509,
        "high": 0.5093,
        "low": 0.5089,
        "close": 0.5092,
        "volume": 299984
      },
      {
        "date": 1685582520000,
        "open": 0.5091,
        "high": 0.5091,
        "low": 0.5087,
        "close": 0.5087,
        "volume": 142459
      },
      {
        "date": 1685582580000,
        "open": 0.5087,
        "high": 0.5093,
        "low": 0.5087,
        "close": 0.5093,
        "volume": 935871
      },
      {
        "date": 1685582640000,
        "open": 0.5093,
        "high": 0.5096,
        "low": 0.5093,
        "close": 0.5095,
        "volume": 173675
      },
      {
        "date": 1685582700000,
        "open": 0.5096,
        "high": 0.5099,
        "low": 0.5093,
        "close": 0.5095,
        "volume": 200568
      },
      {
        "date": 1685582760000,
        "open": 0.5095,
        "high": 0.5095,
        "low": 0.5088,
        "close": 0.5089,
        "volume": 154903
      },
      {
        "date": 1685582820000,
        "open": 0.5089,
        "high": 0.5092,
        "low": 0.5085,
        "close": 0.5089,
        "volume": 1104406
      },
      {
        "date": 1685582880000,
        "open": 0.5089,
        "high": 0.5089,
        "low": 0.5088,
        "close": 0.5089,
        "volume": 232575
      },
      {
        "date": 1685582940000,
        "open": 0.5089,
        "high": 0.5091,
        "low": 0.5085,
        "close": 0.5088,
        "volume": 361350
      },
      {
        "date": 1685583000000,
        "open": 0.5087,
        "high": 0.509,
        "low": 0.5086,
        "close": 0.5086,
        "volume": 203768
      },
      {
        "date": 1685583060000,
        "open": 0.5086,
        "high": 0.5087,
        "low": 0.508,
        "close": 0.5081,
        "volume": 231771
      },
      {
        "date": 1685583120000,
        "open": 0.5081,
        "high": 0.5081,
        "low": 0.5073,
        "close": 0.5074,
        "volume": 511754
      },
      {
        "date": 1685583180000,
        "open": 0.5074,
        "high": 0.5074,
        "low": 0.5072,
        "close": 0.5073,
        "volume": 215088
      },
      {
        "date": 1685583240000,
        "open": 0.5074,
        "high": 0.5074,
        "low": 0.507,
        "close": 0.507,
        "volume": 423391
      },
      {
        "date": 1685583300000,
        "open": 0.5071,
        "high": 0.508,
        "low": 0.507,
        "close": 0.5079,
        "volume": 661359
      },
      {
        "date": 1685583360000,
        "open": 0.508,
        "high": 0.5083,
        "low": 0.508,
        "close": 0.5082,
        "volume": 185476
      },
      {
        "date": 1685583420000,
        "open": 0.5083,
        "high": 0.5083,
        "low": 0.5079,
        "close": 0.5079,
        "volume": 264340
      },
      {
        "date": 1685583480000,
        "open": 0.5079,
        "high": 0.508,
        "low": 0.5079,
        "close": 0.508,
        "volume": 101296
      },
      {
        "date": 1685583540000,
        "open": 0.5079,
        "high": 0.508,
        "low": 0.5074,
        "close": 0.5074,
        "volume": 524118
      },
      {
        "date": 1685583600000,
        "open": 0.5075,
        "high": 0.5076,
        "low": 0.5073,
        "close": 0.5073,
        "volume": 367674
      },
      {
        "date": 1685583660000,
        "open": 0.5073,
        "high": 0.5077,
        "low": 0.5069,
        "close": 0.5077,
        "volume": 773739
      },
      {
        "date": 1685583720000,
        "open": 0.5077,
        "high": 0.508,
        "low": 0.5075,
        "close": 0.5075,
        "volume": 179904
      },
      {
        "date": 1685583780000,
        "open": 0.5076,
        "high": 0.5076,
        "low": 0.5075,
        "close": 0.5075,
        "volume": 72640
      },
      {
        "date": 1685583840000,
        "open": 0.5076,
        "high": 0.5078,
        "low": 0.5071,
        "close": 0.5075,
        "volume": 937542
      },
      {
        "date": 1685583900000,
        "open": 0.5075,
        "high": 0.5091,
        "low": 0.5072,
        "close": 0.509,
        "volume": 758883
      },
      {
        "date": 1685583960000,
        "open": 0.5089,
        "high": 0.509,
        "low": 0.5086,
        "close": 0.5089,
        "volume": 223820
      },
      {
        "date": 1685584020000,
        "open": 0.5089,
        "high": 0.5089,
        "low": 0.5083,
        "close": 0.5085,
        "volume": 597214
      },
      {
        "date": 1685584080000,
        "open": 0.5085,
        "high": 0.5089,
        "low": 0.5084,
        "close": 0.5088,
        "volume": 540142
      },
      {
        "date": 1685584140000,
        "open": 0.5088,
        "high": 0.5089,
        "low": 0.5084,
        "close": 0.5087,
        "volume": 375477
      },
      {
        "date": 1685584200000,
        "open": 0.5087,
        "high": 0.5089,
        "low": 0.5075,
        "close": 0.5077,
        "volume": 578822
      },
      {
        "date": 1685584260000,
        "open": 0.5076,
        "high": 0.5085,
        "low": 0.5076,
        "close": 0.5085,
        "volume": 50535
      },
      {
        "date": 1685584320000,
        "open": 0.5085,
        "high": 0.5088,
        "low": 0.5082,
        "close": 0.5082,
        "volume": 168933
      },
      {
        "date": 1685584380000,
        "open": 0.5083,
        "high": 0.5083,
        "low": 0.5079,
        "close": 0.508,
        "volume": 298105
      },
      {
        "date": 1685584440000,
        "open": 0.508,
        "high": 0.5082,
        "low": 0.5073,
        "close": 0.5076,
        "volume": 896993
      },
      {
        "date": 1685584500000,
        "open": 0.5075,
        "high": 0.5082,
        "low": 0.5075,
        "close": 0.5081,
        "volume": 135028
      },
      {
        "date": 1685584560000,
        "open": 0.5081,
        "high": 0.5087,
        "low": 0.5081,
        "close": 0.5087,
        "volume": 97890
      },
      {
        "date": 1685584620000,
        "open": 0.5087,
        "high": 0.5088,
        "low": 0.5086,
        "close": 0.5087,
        "volume": 204905
      },
      {
        "date": 1685584680000,
        "open": 0.5087,
        "high": 0.5087,
        "low": 0.5086,
        "close": 0.5087,
        "volume": 23233
      },
      {
        "date": 1685584740000,
        "open": 0.5087,
        "high": 0.5088,
        "low": 0.5086,
        "close": 0.5088,
        "volume": 155578
      },
      {
        "date": 1685584800000,
        "open": 0.5087,
        "high": 0.5093,
        "low": 0.5087,
        "close": 0.5093,
        "volume": 683882
      },
      {
        "date": 1685584860000,
        "open": 0.5093,
        "high": 0.5096,
        "low": 0.5092,
        "close": 0.5094,
        "volume": 183861
      },
      {
        "date": 1685584920000,
        "open": 0.5094,
        "high": 0.5096,
        "low": 0.5092,
        "close": 0.5093,
        "volume": 303275
      },
      {
        "date": 1685584980000,
        "open": 0.5093,
        "high": 0.5094,
        "low": 0.5092,
        "close": 0.5094,
        "volume": 122449
      },
      {
        "date": 1685585040000,
        "open": 0.5093,
        "high": 0.5093,
        "low": 0.5092,
        "close": 0.5092,
        "volume": 43945
      },
      {
        "date": 1685585100000,
        "open": 0.5092,
        "high": 0.5093,
        "low": 0.509,
        "close": 0.5091,
        "volume": 86068
      },
      {
        "date": 1685585160000,
        "open": 0.509,
        "high": 0.509,
        "low": 0.5084,
        "close": 0.5086,
        "volume": 314212
      },
      {
        "date": 1685585220000,
        "open": 0.5086,
        "high": 0.5087,
        "low": 0.508,
        "close": 0.5081,
        "volume": 603024
      },
      {
        "date": 1685585280000,
        "open": 0.508,
        "high": 0.5082,
        "low": 0.5079,
        "close": 0.508,
        "volume": 492536
      },
      {
        "date": 1685585340000,
        "open": 0.5079,
        "high": 0.5085,
        "low": 0.5079,
        "close": 0.5084,
        "volume": 267731
      },
      {
        "date": 1685585400000,
        "open": 0.5085,
        "high": 0.5088,
        "low": 0.5084,
        "close": 0.5087,
        "volume": 47928
      },
      {
        "date": 1685585460000,
        "open": 0.5088,
        "high": 0.5088,
        "low": 0.508,
        "close": 0.5081,
        "volume": 766859
      },
      {
        "date": 1685585520000,
        "open": 0.5081,
        "high": 0.5081,
        "low": 0.5077,
        "close": 0.5078,
        "volume": 106709
      },
      {
        "date": 1685585580000,
        "open": 0.5078,
        "high": 0.5078,
        "low": 0.507,
        "close": 0.5074,
        "volume": 1027377
      },
      {
        "date": 1685585640000,
        "open": 0.5074,
        "high": 0.508,
        "low": 0.5073,
        "close": 0.508,
        "volume": 143851
      },
      {
        "date": 1685585700000,
        "open": 0.508,
        "high": 0.5087,
        "low": 0.5079,
        "close": 0.5087,
        "volume": 289415
      },
      {
        "date": 1685585760000,
        "open": 0.5087,
        "high": 0.5087,
        "low": 0.5083,
        "close": 0.5085,
        "volume": 211066
      },
      {
        "date": 1685585820000,
        "open": 0.5085,
        "high": 0.5086,
        "low": 0.5083,
        "close": 0.5084,
        "volume": 82384
      },
      {
        "date": 1685585880000,
        "open": 0.5083,
        "high": 0.5084,
        "low": 0.5078,
        "close": 0.5079,
        "volume": 137159
      },
      {
        "date": 1685585940000,
        "open": 0.5079,
        "high": 0.5081,
        "low": 0.5079,
        "close": 0.5081,
        "volume": 39218
      },
      {
        "date": 1685586000000,
        "open": 0.5081,
        "high": 0.5081,
        "low": 0.5079,
        "close": 0.5079,
        "volume": 18800
      },
      {
        "date": 1685586060000,
        "open": 0.5079,
        "high": 0.5079,
        "low": 0.5073,
        "close": 0.5076,
        "volume": 1048614
      },
      {
        "date": 1685586120000,
        "open": 0.5076,
        "high": 0.5079,
        "low": 0.5075,
        "close": 0.5079,
        "volume": 288626
      },
      {
        "date": 1685586180000,
        "open": 0.5079,
        "high": 0.5079,
        "low": 0.5074,
        "close": 0.5076,
        "volume": 510936
      },
      {
        "date": 1685586240000,
        "open": 0.5077,
        "high": 0.5077,
        "low": 0.5075,
        "close": 0.5076,
        "volume": 54717
      },
      {
        "date": 1685586300000,
        "open": 0.5076,
        "high": 0.5077,
        "low": 0.5075,
        "close": 0.5077,
        "volume": 19012
      },
      {
        "date": 1685586360000,
        "open": 0.5076,
        "high": 0.5077,
        "low": 0.5074,
        "close": 0.5074,
        "volume": 65577
      },
      {
        "date": 1685586420000,
        "open": 0.5074,
        "high": 0.5077,
        "low": 0.5073,
        "close": 0.5074,
        "volume": 291443
      },
      {
        "date": 1685586480000,
        "open": 0.5074,
        "high": 0.5075,
        "low": 0.5073,
        "close": 0.5074,
        "volume": 384356
      },
      {
        "date": 1685586540000,
        "open": 0.5074,
        "high": 0.5075,
        "low": 0.5073,
        "close": 0.5075,
        "volume": 74365
      },
      {
        "date": 1685586600000,
        "open": 0.5075,
        "high": 0.5081,
        "low": 0.5075,
        "close": 0.5076,
        "volume": 448187
      },
      {
        "date": 1685586660000,
        "open": 0.5075,
        "high": 0.5078,
        "low": 0.5073,
        "close": 0.5077,
        "volume": 288024
      },
      {
        "date": 1685586720000,
        "open": 0.5077,
        "high": 0.5079,
        "low": 0.5073,
        "close": 0.5074,
        "volume": 171061
      },
      {
        "date": 1685586780000,
        "open": 0.5075,
        "high": 0.5075,
        "low": 0.5072,
        "close": 0.5072,
        "volume": 296423
      },
      {
        "date": 1685586840000,
        "open": 0.5072,
        "high": 0.5075,
        "low": 0.5072,
        "close": 0.5073,
        "volume": 257110
      },
      {
        "date": 1685586900000,
        "open": 0.5073,
        "high": 0.5075,
        "low": 0.5073,
        "close": 0.5073,
        "volume": 219237
      },
      {
        "date": 1685586960000,
        "open": 0.5073,
        "high": 0.5075,
        "low": 0.5072,
        "close": 0.5074,
        "volume": 183120
      },
      {
        "date": 1685587020000,
        "open": 0.5074,
        "high": 0.5074,
        "low": 0.5061,
        "close": 0.5066,
        "volume": 1371865
      },
      {
        "date": 1685587080000,
        "open": 0.5066,
        "high": 0.5069,
        "low": 0.5063,
        "close": 0.5064,
        "volume": 331222
      },
      {
        "date": 1685587140000,
        "open": 0.5063,
        "high": 0.5065,
        "low": 0.5062,
        "close": 0.5065,
        "volume": 163498
      },
      {
        "date": 1685587200000,
        "open": 0.5065,
        "high": 0.5065,
        "low": 0.5058,
        "close": 0.5061,
        "volume": 265506
      },
      {
        "date": 1685587260000,
        "open": 0.506,
        "high": 0.5062,
        "low": 0.5055,
        "close": 0.5056,
        "volume": 323639
      },
      {
        "date": 1685587320000,
        "open": 0.5056,
        "high": 0.5059,
        "low": 0.5048,
        "close": 0.5052,
        "volume": 580855
      },
      {
        "date": 1685587380000,
        "open": 0.5052,
        "high": 0.5058,
        "low": 0.5052,
        "close": 0.5058,
        "volume": 142896
      },
      {
        "date": 1685587440000,
        "open": 0.5058,
        "high": 0.5062,
        "low": 0.5054,
        "close": 0.5061,
        "volume": 712978
      },
      {
        "date": 1685587500000,
        "open": 0.5061,
        "high": 0.5069,
        "low": 0.5061,
        "close": 0.5066,
        "volume": 307024
      },
      {
        "date": 1685587560000,
        "open": 0.5065,
        "high": 0.5068,
        "low": 0.5064,
        "close": 0.5067,
        "volume": 131984
      },
      {
        "date": 1685587620000,
        "open": 0.5067,
        "high": 0.5067,
        "low": 0.5061,
        "close": 0.5062,
        "volume": 172984
      },
      {
        "date": 1685587680000,
        "open": 0.5062,
        "high": 0.5063,
        "low": 0.5061,
        "close": 0.5062,
        "volume": 290446
      },
      {
        "date": 1685587740000,
        "open": 0.5062,
        "high": 0.5062,
        "low": 0.5034,
        "close": 0.5043,
        "volume": 2356757
      },
      {
        "date": 1685587800000,
        "open": 0.5042,
        "high": 0.5043,
        "low": 0.5027,
        "close": 0.5038,
        "volume": 2384064
      },
      {
        "date": 1685587860000,
        "open": 0.5037,
        "high": 0.5043,
        "low": 0.5024,
        "close": 0.5039,
        "volume": 1039305
      },
      {
        "date": 1685587920000,
        "open": 0.5039,
        "high": 0.5046,
        "low": 0.5032,
        "close": 0.5044,
        "volume": 634968
      },
      {
        "date": 1685587980000,
        "open": 0.5046,
        "high": 0.5046,
        "low": 0.5038,
        "close": 0.5041,
        "volume": 316834
      },
      {
        "date": 1685588040000,
        "open": 0.504,
        "high": 0.5041,
        "low": 0.5033,
        "close": 0.504,
        "volume": 218531
      },
      {
        "date": 1685588100000,
        "open": 0.504,
        "high": 0.5047,
        "low": 0.5039,
        "close": 0.5046,
        "volume": 207333
      },
      {
        "date": 1685588160000,
        "open": 0.5047,
        "high": 0.5048,
        "low": 0.5031,
        "close": 0.5031,
        "volume": 370345
      },
      {
        "date": 1685588220000,
        "open": 0.503,
        "high": 0.5042,
        "low": 0.5028,
        "close": 0.504,
        "volume": 423714
      },
      {
        "date": 1685588280000,
        "open": 0.504,
        "high": 0.5048,
        "low": 0.504,
        "close": 0.5045,
        "volume": 265993
      },
      {
        "date": 1685588340000,
        "open": 0.5046,
        "high": 0.5049,
        "low": 0.5044,
        "close": 0.5049,
        "volume": 209895
      },
      {
        "date": 1685588400000,
        "open": 0.5048,
        "high": 0.5058,
        "low": 0.5048,
        "close": 0.5055,
        "volume": 445429
      },
      {
        "date": 1685588460000,
        "open": 0.5055,
        "high": 0.5057,
        "low": 0.5052,
        "close": 0.5053,
        "volume": 359208
      },
      {
        "date": 1685588520000,
        "open": 0.5053,
        "high": 0.5055,
        "low": 0.5053,
        "close": 0.5054,
        "volume": 88303
      },
      {
        "date": 1685588580000,
        "open": 0.5054,
        "high": 0.5055,
        "low": 0.5047,
        "close": 0.5047,
        "volume": 407592
      },
      {
        "date": 1685588640000,
        "open": 0.5047,
        "high": 0.5047,
        "low": 0.5031,
        "close": 0.504,
        "volume": 1180573
      },
      {
        "date": 1685588700000,
        "open": 0.5041,
        "high": 0.5043,
        "low": 0.5034,
        "close": 0.5039,
        "volume": 587638
      },
      {
        "date": 1685588760000,
        "open": 0.5039,
        "high": 0.5048,
        "low": 0.5038,
        "close": 0.5045,
        "volume": 112991
      },
      {
        "date": 1685588820000,
        "open": 0.5045,
        "high": 0.5051,
        "low": 0.5042,
        "close": 0.5048,
        "volume": 469137
      },
      {
        "date": 1685588880000,
        "open": 0.5048,
        "high": 0.5054,
        "low": 0.5048,
        "close": 0.5053,
        "volume": 135476
      },
      {
        "date": 1685588940000,
        "open": 0.5053,
        "high": 0.5057,
        "low": 0.5052,
        "close": 0.5055,
        "volume": 235496
      },
      {
        "date": 1685589000000,
        "open": 0.5055,
        "high": 0.5059,
        "low": 0.5054,
        "close": 0.5057,
        "volume": 445057
      },
      {
        "date": 1685589060000,
        "open": 0.5058,
        "high": 0.506,
        "low": 0.5057,
        "close": 0.506,
        "volume": 167392
      },
      {
        "date": 1685589120000,
        "open": 0.506,
        "high": 0.5065,
        "low": 0.5059,
        "close": 0.5064,
        "volume": 181130
      },
      {
        "date": 1685589180000,
        "open": 0.5064,
        "high": 0.5068,
        "low": 0.5064,
        "close": 0.5068,
        "volume": 108924
      },
      {
        "date": 1685589240000,
        "open": 0.5068,
        "high": 0.5069,
        "low": 0.5065,
        "close": 0.5067,
        "volume": 193953
      },
      {
        "date": 1685589300000,
        "open": 0.5068,
        "high": 0.5071,
        "low": 0.5066,
        "close": 0.5071,
        "volume": 266270
      },
      {
        "date": 1685589360000,
        "open": 0.5071,
        "high": 0.5072,
        "low": 0.5064,
        "close": 0.5064,
        "volume": 149697
      },
      {
        "date": 1685589420000,
        "open": 0.5064,
        "high": 0.5067,
        "low": 0.5063,
        "close": 0.5063,
        "volume": 102520
      },
      {
        "date": 1685589480000,
        "open": 0.5063,
        "high": 0.5066,
        "low": 0.5061,
        "close": 0.5065,
        "volume": 188266
      },
      {
        "date": 1685589540000,
        "open": 0.5066,
        "high": 0.507,
        "low": 0.5065,
        "close": 0.507,
        "volume": 108338
      },
      {
        "date": 1685589600000,
        "open": 0.5068,
        "high": 0.5071,
        "low": 0.5068,
        "close": 0.5068,
        "volume": 93069
      },
      {
        "date": 1685589660000,
        "open": 0.5069,
        "high": 0.5073,
        "low": 0.5068,
        "close": 0.5072,
        "volume": 173874
      },
      {
        "date": 1685589720000,
        "open": 0.5072,
        "high": 0.5072,
        "low": 0.5065,
        "close": 0.5066,
        "volume": 603770
      },
      {
        "date": 1685589780000,
        "open": 0.5065,
        "high": 0.5067,
        "low": 0.5063,
        "close": 0.5064,
        "volume": 291671
      },
      {
        "date": 1685589840000,
        "open": 0.5064,
        "high": 0.5066,
        "low": 0.5064,
        "close": 0.5065,
        "volume": 327262
      },
      {
        "date": 1685589900000,
        "open": 0.5066,
        "high": 0.5068,
        "low": 0.5065,
        "close": 0.5066,
        "volume": 95384
      },
      {
        "date": 1685589960000,
        "open": 0.5066,
        "high": 0.5067,
        "low": 0.5061,
        "close": 0.5062,
        "volume": 202640
      },
      {
        "date": 1685590020000,
        "open": 0.506,
        "high": 0.5065,
        "low": 0.506,
        "close": 0.5065,
        "volume": 164175
      },
      {
        "date": 1685590080000,
        "open": 0.5065,
        "high": 0.5067,
        "low": 0.5065,
        "close": 0.5067,
        "volume": 137765
      },
      {
        "date": 1685590140000,
        "open": 0.5067,
        "high": 0.5067,
        "low": 0.5062,
        "close": 0.5066,
        "volume": 597801
      },
      {
        "date": 1685590200000,
        "open": 0.5066,
        "high": 0.507,
        "low": 0.5065,
        "close": 0.5069,
        "volume": 286491
      },
      {
        "date": 1685590260000,
        "open": 0.5069,
        "high": 0.5069,
        "low": 0.5068,
        "close": 0.5069,
        "volume": 122740
      },
      {
        "date": 1685590320000,
        "open": 0.5069,
        "high": 0.5074,
        "low": 0.5068,
        "close": 0.5072,
        "volume": 398619
      },
      {
        "date": 1685590380000,
        "open": 0.5072,
        "high": 0.5073,
        "low": 0.5072,
        "close": 0.5072,
        "volume": 51839
      },
      {
        "date": 1685590440000,
        "open": 0.5072,
        "high": 0.5076,
        "low": 0.5072,
        "close": 0.5075,
        "volume": 406997
      },
      {
        "date": 1685590500000,
        "open": 0.5075,
        "high": 0.5076,
        "low": 0.507,
        "close": 0.5073,
        "volume": 500158
      },
      {
        "date": 1685590560000,
        "open": 0.5074,
        "high": 0.5074,
        "low": 0.5073,
        "close": 0.5074,
        "volume": 31699
      },
      {
        "date": 1685590620000,
        "open": 0.5073,
        "high": 0.5074,
        "low": 0.5071,
        "close": 0.5073,
        "volume": 346097
      },
      {
        "date": 1685590680000,
        "open": 0.5074,
        "high": 0.5082,
        "low": 0.5073,
        "close": 0.508,
        "volume": 389073
      },
      {
        "date": 1685590740000,
        "open": 0.5081,
        "high": 0.5081,
        "low": 0.5079,
        "close": 0.5079,
        "volume": 160689
      },
      {
        "date": 1685590800000,
        "open": 0.5079,
        "high": 0.5081,
        "low": 0.5078,
        "close": 0.5079,
        "volume": 260816
      },
      {
        "date": 1685590860000,
        "open": 0.5078,
        "high": 0.5082,
        "low": 0.5078,
        "close": 0.5081,
        "volume": 156784
      },
      {
        "date": 1685590920000,
        "open": 0.5081,
        "high": 0.5081,
        "low": 0.5079,
        "close": 0.508,
        "volume": 138808
      },
      {
        "date": 1685590980000,
        "open": 0.508,
        "high": 0.5082,
        "low": 0.5079,
        "close": 0.5079,
        "volume": 160013
      },
      {
        "date": 1685591040000,
        "open": 0.5079,
        "high": 0.508,
        "low": 0.5079,
        "close": 0.508,
        "volume": 17256
      },
      {
        "date": 1685591100000,
        "open": 0.5079,
        "high": 0.5082,
        "low": 0.5079,
        "close": 0.5079,
        "volume": 309138
      },
      {
        "date": 1685591160000,
        "open": 0.5079,
        "high": 0.508,
        "low": 0.5079,
        "close": 0.5079,
        "volume": 44130
      },
      {
        "date": 1685591220000,
        "open": 0.5079,
        "high": 0.5079,
        "low": 0.5077,
        "close": 0.5077,
        "volume": 414863
      },
      {
        "date": 1685591280000,
        "open": 0.5077,
        "high": 0.5078,
        "low": 0.5077,
        "close": 0.5077,
        "volume": 22221
      },
      {
        "date": 1685591340000,
        "open": 0.5078,
        "high": 0.508,
        "low": 0.5077,
        "close": 0.5079,
        "volume": 482548
      },
      {
        "date": 1685591400000,
        "open": 0.5078,
        "high": 0.508,
        "low": 0.5078,
        "close": 0.508,
        "volume": 80973
      },
      {
        "date": 1685591460000,
        "open": 0.508,
        "high": 0.5084,
        "low": 0.508,
        "close": 0.5083,
        "volume": 47526
      },
      {
        "date": 1685591520000,
        "open": 0.5083,
        "high": 0.5085,
        "low": 0.5082,
        "close": 0.5084,
        "volume": 39649
      },
      {
        "date": 1685591580000,
        "open": 0.5084,
        "high": 0.5088,
        "low": 0.5084,
        "close": 0.5085,
        "volume": 202994
      },
      {
        "date": 1685591640000,
        "open": 0.5085,
        "high": 0.5086,
        "low": 0.5085,
        "close": 0.5085,
        "volume": 36901
      },
      {
        "date": 1685591700000,
        "open": 0.5086,
        "high": 0.5086,
        "low": 0.5082,
        "close": 0.5083,
        "volume": 361549
      },
      {
        "date": 1685591760000,
        "open": 0.5083,
        "high": 0.5085,
        "low": 0.5083,
        "close": 0.5085,
        "volume": 144609
      },
      {
        "date": 1685591820000,
        "open": 0.5085,
        "high": 0.5087,
        "low": 0.5085,
        "close": 0.5086,
        "volume": 90865
      },
      {
        "date": 1685591880000,
        "open": 0.5087,
        "high": 0.5087,
        "low": 0.5085,
        "close": 0.5087,
        "volume": 81928
      },
      {
        "date": 1685591940000,
        "open": 0.5086,
        "high": 0.5086,
        "low": 0.5082,
        "close": 0.5083,
        "volume": 208893
      },
      {
        "date": 1685592000000,
        "open": 0.5082,
        "high": 0.5083,
        "low": 0.508,
        "close": 0.508,
        "volume": 227281
      },
      {
        "date": 1685592060000,
        "open": 0.5081,
        "high": 0.5082,
        "low": 0.5078,
        "close": 0.508,
        "volume": 208139
      },
      {
        "date": 1685592120000,
        "open": 0.5081,
        "high": 0.5087,
        "low": 0.5081,
        "close": 0.5086,
        "volume": 240320
      },
      {
        "date": 1685592180000,
        "open": 0.5086,
        "high": 0.5088,
        "low": 0.5086,
        "close": 0.5087,
        "volume": 47929
      },
      {
        "date": 1685592240000,
        "open": 0.5087,
        "high": 0.5088,
        "low": 0.5086,
        "close": 0.5086,
        "volume": 58213
      },
      {
        "date": 1685592300000,
        "open": 0.5087,
        "high": 0.5089,
        "low": 0.5087,
        "close": 0.5087,
        "volume": 108512
      },
      {
        "date": 1685592360000,
        "open": 0.5088,
        "high": 0.5088,
        "low": 0.5087,
        "close": 0.5087,
        "volume": 5563
      },
      {
        "date": 1685592420000,
        "open": 0.5088,
        "high": 0.5089,
        "low": 0.5087,
        "close": 0.5088,
        "volume": 71530
      },
      {
        "date": 1685592480000,
        "open": 0.5087,
        "high": 0.5089,
        "low": 0.5086,
        "close": 0.5088,
        "volume": 224417
      },
      {
        "date": 1685592540000,
        "open": 0.5089,
        "high": 0.509,
        "low": 0.5088,
        "close": 0.509,
        "volume": 74979
      },
      {
        "date": 1685592600000,
        "open": 0.509,
        "high": 0.509,
        "low": 0.5084,
        "close": 0.5087,
        "volume": 243509
      },
      {
        "date": 1685592660000,
        "open": 0.5087,
        "high": 0.5087,
        "low": 0.5083,
        "close": 0.5083,
        "volume": 223510
      },
      {
        "date": 1685592720000,
        "open": 0.5083,
        "high": 0.5085,
        "low": 0.5083,
        "close": 0.5083,
        "volume": 102588
      },
      {
        "date": 1685592780000,
        "open": 0.5083,
        "high": 0.5087,
        "low": 0.5083,
        "close": 0.5086,
        "volume": 128998
      },
      {
        "date": 1685592840000,
        "open": 0.5086,
        "high": 0.5087,
        "low": 0.5083,
        "close": 0.5086,
        "volume": 134792
      },
      {
        "date": 1685592900000,
        "open": 0.5086,
        "high": 0.5088,
        "low": 0.5084,
        "close": 0.5084,
        "volume": 88324
      },
      {
        "date": 1685592960000,
        "open": 0.5084,
        "high": 0.5085,
        "low": 0.5083,
        "close": 0.5084,
        "volume": 83962
      },
      {
        "date": 1685593020000,
        "open": 0.5084,
        "high": 0.5086,
        "low": 0.5083,
        "close": 0.5085,
        "volume": 69706
      },
      {
        "date": 1685593080000,
        "open": 0.5085,
        "high": 0.5086,
        "low": 0.5085,
        "close": 0.5085,
        "volume": 33802
      },
      {
        "date": 1685593140000,
        "open": 0.5086,
        "high": 0.5088,
        "low": 0.5085,
        "close": 0.5085,
        "volume": 140898
      },
      {
        "date": 1685593200000,
        "open": 0.5085,
        "high": 0.5087,
        "low": 0.5085,
        "close": 0.5086,
        "volume": 29010
      },
      {
        "date": 1685593260000,
        "open": 0.5086,
        "high": 0.5086,
        "low": 0.5083,
        "close": 0.5083,
        "volume": 96407
      },
      {
        "date": 1685593320000,
        "open": 0.5083,
        "high": 0.5084,
        "low": 0.5082,
        "close": 0.5082,
        "volume": 183377
      },
      {
        "date": 1685593380000,
        "open": 0.5082,
        "high": 0.5082,
        "low": 0.5072,
        "close": 0.5073,
        "volume": 671522
      },
      {
        "date": 1685593440000,
        "open": 0.5072,
        "high": 0.5072,
        "low": 0.507,
        "close": 0.5071,
        "volume": 95326
      },
      {
        "date": 1685593500000,
        "open": 0.5071,
        "high": 0.5072,
        "low": 0.507,
        "close": 0.5071,
        "volume": 59826
      },
      {
        "date": 1685593560000,
        "open": 0.5071,
        "high": 0.5075,
        "low": 0.5071,
        "close": 0.5075,
        "volume": 185565
      },
      {
        "date": 1685593620000,
        "open": 0.5075,
        "high": 0.5076,
        "low": 0.5074,
        "close": 0.5076,
        "volume": 110281
      },
      {
        "date": 1685593680000,
        "open": 0.5075,
        "high": 0.5076,
        "low": 0.5075,
        "close": 0.5076,
        "volume": 14820
      },
      {
        "date": 1685593740000,
        "open": 0.5076,
        "high": 0.5076,
        "low": 0.5074,
        "close": 0.5075,
        "volume": 38864
      },
      {
        "date": 1685593800000,
        "open": 0.5075,
        "high": 0.5079,
        "low": 0.5075,
        "close": 0.5079,
        "volume": 365704
      },
      {
        "date": 1685593860000,
        "open": 0.5078,
        "high": 0.5081,
        "low": 0.5076,
        "close": 0.5081,
        "volume": 255665
      },
      {
        "date": 1685593920000,
        "open": 0.5081,
        "high": 0.5083,
        "low": 0.5078,
        "close": 0.5083,
        "volume": 159772
      },
      {
        "date": 1685593980000,
        "open": 0.5082,
        "high": 0.5087,
        "low": 0.5082,
        "close": 0.5086,
        "volume": 154889
      },
      {
        "date": 1685594040000,
        "open": 0.5087,
        "high": 0.5089,
        "low": 0.5086,
        "close": 0.5087,
        "volume": 284748
      },
      {
        "date": 1685594100000,
        "open": 0.5087,
        "high": 0.5088,
        "low": 0.5086,
        "close": 0.5086,
        "volume": 98281
      },
      {
        "date": 1685594160000,
        "open": 0.5086,
        "high": 0.5086,
        "low": 0.5078,
        "close": 0.508,
        "volume": 423666
      },
      {
        "date": 1685594220000,
        "open": 0.508,
        "high": 0.5082,
        "low": 0.5078,
        "close": 0.5079,
        "volume": 243013
      },
      {
        "date": 1685594280000,
        "open": 0.5079,
        "high": 0.5079,
        "low": 0.5073,
        "close": 0.5073,
        "volume": 249022
      },
      {
        "date": 1685594340000,
        "open": 0.5073,
        "high": 0.5078,
        "low": 0.5073,
        "close": 0.5077,
        "volume": 215579
      },
      {
        "date": 1685594400000,
        "open": 0.5078,
        "high": 0.5079,
        "low": 0.5069,
        "close": 0.5071,
        "volume": 662539
      },
      {
        "date": 1685594460000,
        "open": 0.507,
        "high": 0.507,
        "low": 0.5068,
        "close": 0.5068,
        "volume": 187882
      },
      {
        "date": 1685594520000,
        "open": 0.5068,
        "high": 0.5074,
        "low": 0.5067,
        "close": 0.5074,
        "volume": 483183
      },
      {
        "date": 1685594580000,
        "open": 0.5074,
        "high": 0.5075,
        "low": 0.5073,
        "close": 0.5074,
        "volume": 60867
      },
      {
        "date": 1685594640000,
        "open": 0.5073,
        "high": 0.5078,
        "low": 0.5073,
        "close": 0.5074,
        "volume": 187604
      },
      {
        "date": 1685594700000,
        "open": 0.5073,
        "high": 0.5076,
        "low": 0.5072,
        "close": 0.5075,
        "volume": 111075
      },
      {
        "date": 1685594760000,
        "open": 0.5075,
        "high": 0.5076,
        "low": 0.5072,
        "close": 0.5073,
        "volume": 137622
      },
      {
        "date": 1685594820000,
        "open": 0.5072,
        "high": 0.5073,
        "low": 0.5069,
        "close": 0.507,
        "volume": 226873
      },
      {
        "date": 1685594880000,
        "open": 0.507,
        "high": 0.507,
        "low": 0.5069,
        "close": 0.5069,
        "volume": 77356
      },
      {
        "date": 1685594940000,
        "open": 0.507,
        "high": 0.5071,
        "low": 0.5069,
        "close": 0.5071,
        "volume": 183441
      },
      {
        "date": 1685595000000,
        "open": 0.507,
        "high": 0.507,
        "low": 0.5061,
        "close": 0.5063,
        "volume": 730992
      },
      {
        "date": 1685595060000,
        "open": 0.5063,
        "high": 0.5066,
        "low": 0.5062,
        "close": 0.5065,
        "volume": 260725
      },
      {
        "date": 1685595120000,
        "open": 0.5066,
        "high": 0.5067,
        "low": 0.5065,
        "close": 0.5066,
        "volume": 43966
      },
      {
        "date": 1685595180000,
        "open": 0.5066,
        "high": 0.5067,
        "low": 0.5065,
        "close": 0.5067,
        "volume": 128835
      },
      {
        "date": 1685595240000,
        "open": 0.5067,
        "high": 0.507,
        "low": 0.5067,
        "close": 0.507,
        "volume": 146663
      },
      {
        "date": 1685595300000,
        "open": 0.5069,
        "high": 0.507,
        "low": 0.5068,
        "close": 0.5069,
        "volume": 34031
      },
      {
        "date": 1685595360000,
        "open": 0.5068,
        "high": 0.5069,
        "low": 0.5067,
        "close": 0.5069,
        "volume": 119556
      },
      {
        "date": 1685595420000,
        "open": 0.5069,
        "high": 0.5071,
        "low": 0.5069,
        "close": 0.5071,
        "volume": 57125
      },
      {
        "date": 1685595480000,
        "open": 0.5071,
        "high": 0.5071,
        "low": 0.5069,
        "close": 0.507,
        "volume": 222265
      },
      {
        "date": 1685595540000,
        "open": 0.507,
        "high": 0.5071,
        "low": 0.5069,
        "close": 0.5069,
        "volume": 210630
      },
      {
        "date": 1685595600000,
        "open": 0.507,
        "high": 0.5071,
        "low": 0.507,
        "close": 0.507,
        "volume": 156334
      },
      {
        "date": 1685595660000,
        "open": 0.5069,
        "high": 0.507,
        "low": 0.5062,
        "close": 0.5065,
        "volume": 317047
      },
      {
        "date": 1685595720000,
        "open": 0.5065,
        "high": 0.5066,
        "low": 0.5061,
        "close": 0.5062,
        "volume": 266718
      },
      {
        "date": 1685595780000,
        "open": 0.5063,
        "high": 0.5063,
        "low": 0.5058,
        "close": 0.506,
        "volume": 332287
      },
      {
        "date": 1685595840000,
        "open": 0.5059,
        "high": 0.5059,
        "low": 0.5053,
        "close": 0.5057,
        "volume": 1054820
      },
      {
        "date": 1685595900000,
        "open": 0.5057,
        "high": 0.5058,
        "low": 0.5054,
        "close": 0.5058,
        "volume": 393133
      },
      {
        "date": 1685595960000,
        "open": 0.5058,
        "high": 0.5058,
        "low": 0.5055,
        "close": 0.5058,
        "volume": 228760
      },
      {
        "date": 1685596020000,
        "open": 0.5058,
        "high": 0.5064,
        "low": 0.5057,
        "close": 0.5064,
        "volume": 106402
      },
      {
        "date": 1685596080000,
        "open": 0.5063,
        "high": 0.5064,
        "low": 0.5061,
        "close": 0.5064,
        "volume": 183697
      },
      {
        "date": 1685596140000,
        "open": 0.5065,
        "high": 0.5068,
        "low": 0.5064,
        "close": 0.5067,
        "volume": 48559
      },
      {
        "date": 1685596200000,
        "open": 0.5067,
        "high": 0.5069,
        "low": 0.5066,
        "close": 0.5068,
        "volume": 191159
      },
      {
        "date": 1685596260000,
        "open": 0.5068,
        "high": 0.5069,
        "low": 0.5065,
        "close": 0.5068,
        "volume": 196150
      },
      {
        "date": 1685596320000,
        "open": 0.5068,
        "high": 0.5071,
        "low": 0.5067,
        "close": 0.5068,
        "volume": 439834
      },
      {
        "date": 1685596380000,
        "open": 0.5068,
        "high": 0.507,
        "low": 0.5067,
        "close": 0.5067,
        "volume": 161616
      },
      {
        "date": 1685596440000,
        "open": 0.5068,
        "high": 0.508,
        "low": 0.5068,
        "close": 0.5076,
        "volume": 983325
      },
      {
        "date": 1685596500000,
        "open": 0.5076,
        "high": 0.5079,
        "low": 0.5075,
        "close": 0.5078,
        "volume": 23819
      },
      {
        "date": 1685596560000,
        "open": 0.5079,
        "high": 0.5088,
        "low": 0.5078,
        "close": 0.5087,
        "volume": 342005
      },
      {
        "date": 1685596620000,
        "open": 0.5088,
        "high": 0.5088,
        "low": 0.5085,
        "close": 0.5086,
        "volume": 153121
      },
      {
        "date": 1685596680000,
        "open": 0.5086,
        "high": 0.5087,
        "low": 0.5082,
        "close": 0.5084,
        "volume": 451985
      },
      {
        "date": 1685596740000,
        "open": 0.5084,
        "high": 0.5085,
        "low": 0.5083,
        "close": 0.5084,
        "volume": 61526
      },
      {
        "date": 1685596800000,
        "open": 0.5084,
        "high": 0.5085,
        "low": 0.5082,
        "close": 0.5082,
        "volume": 136907
      },
      {
        "date": 1685596860000,
        "open": 0.5082,
        "high": 0.5085,
        "low": 0.5082,
        "close": 0.5084,
        "volume": 85877
      },
      {
        "date": 1685596920000,
        "open": 0.5084,
        "high": 0.5086,
        "low": 0.5084,
        "close": 0.5085,
        "volume": 51971
      },
      {
        "date": 1685596980000,
        "open": 0.5085,
        "high": 0.5088,
        "low": 0.5085,
        "close": 0.5085,
        "volume": 210011
      },
      {
        "date": 1685597040000,
        "open": 0.5085,
        "high": 0.5086,
        "low": 0.5076,
        "close": 0.5078,
        "volume": 934188
      },
      {
        "date": 1685597100000,
        "open": 0.5078,
        "high": 0.5079,
        "low": 0.5077,
        "close": 0.5078,
        "volume": 57432
      },
      {
        "date": 1685597160000,
        "open": 0.5077,
        "high": 0.5078,
        "low": 0.5076,
        "close": 0.5077,
        "volume": 78080
      },
      {
        "date": 1685597220000,
        "open": 0.5076,
        "high": 0.5078,
        "low": 0.5075,
        "close": 0.5077,
        "volume": 162837
      },
      {
        "date": 1685597280000,
        "open": 0.5077,
        "high": 0.5078,
        "low": 0.5074,
        "close": 0.5075,
        "volume": 194503
      },
      {
        "date": 1685597340000,
        "open": 0.5076,
        "high": 0.5078,
        "low": 0.5075,
        "close": 0.5078,
        "volume": 143366
      },
      {
        "date": 1685597400000,
        "open": 0.5078,
        "high": 0.5079,
        "low": 0.5075,
        "close": 0.5075,
        "volume": 139076
      },
      {
        "date": 1685597460000,
        "open": 0.5075,
        "high": 0.5079,
        "low": 0.5075,
        "close": 0.5079,
        "volume": 93658
      },
      {
        "date": 1685597520000,
        "open": 0.5078,
        "high": 0.5079,
        "low": 0.5075,
        "close": 0.5075,
        "volume": 112804
      },
      {
        "date": 1685597580000,
        "open": 0.5075,
        "high": 0.5078,
        "low": 0.5075,
        "close": 0.5076,
        "volume": 73416
      },
      {
        "date": 1685597640000,
        "open": 0.5076,
        "high": 0.5076,
        "low": 0.5075,
        "close": 0.5075,
        "volume": 58760
      },
      {
        "date": 1685597700000,
        "open": 0.5075,
        "high": 0.5076,
        "low": 0.5075,
        "close": 0.5076,
        "volume": 29558
      },
      {
        "date": 1685597760000,
        "open": 0.5076,
        "high": 0.5076,
        "low": 0.5075,
        "close": 0.5076,
        "volume": 77701
      },
      {
        "date": 1685597820000,
        "open": 0.5076,
        "high": 0.5076,
        "low": 0.5075,
        "close": 0.5075,
        "volume": 42345
      },
      {
        "date": 1685597880000,
        "open": 0.5075,
        "high": 0.5076,
        "low": 0.5075,
        "close": 0.5075,
        "volume": 55295
      },
      {
        "date": 1685597940000,
        "open": 0.5075,
        "high": 0.5075,
        "low": 0.5074,
        "close": 0.5075,
        "volume": 99942
      },
      {
        "date": 1685598000000,
        "open": 0.5074,
        "high": 0.5075,
        "low": 0.5074,
        "close": 0.5074,
        "volume": 98814
      },
      {
        "date": 1685598060000,
        "open": 0.5074,
        "high": 0.5075,
        "low": 0.5073,
        "close": 0.5074,
        "volume": 250078
      },
      {
        "date": 1685598120000,
        "open": 0.5074,
        "high": 0.5074,
        "low": 0.5072,
        "close": 0.5072,
        "volume": 247651
      },
      {
        "date": 1685598180000,
        "open": 0.5073,
        "high": 0.5073,
        "low": 0.5072,
        "close": 0.5072,
        "volume": 106045
      },
      {
        "date": 1685598240000,
        "open": 0.5072,
        "high": 0.5073,
        "low": 0.5071,
        "close": 0.5071,
        "volume": 49979
      },
      {
        "date": 1685598300000,
        "open": 0.5071,
        "high": 0.5075,
        "low": 0.507,
        "close": 0.5075,
        "volume": 397716
      },
      {
        "date": 1685598360000,
        "open": 0.5076,
        "high": 0.5078,
        "low": 0.5075,
        "close": 0.5078,
        "volume": 50868
      },
      {
        "date": 1685598420000,
        "open": 0.5078,
        "high": 0.508,
        "low": 0.5077,
        "close": 0.508,
        "volume": 125497
      },
      {
        "date": 1685598480000,
        "open": 0.508,
        "high": 0.5081,
        "low": 0.5078,
        "close": 0.5078,
        "volume": 164426
      },
      {
        "date": 1685598540000,
        "open": 0.5079,
        "high": 0.5079,
        "low": 0.507,
        "close": 0.507,
        "volume": 994094
      },
      {
        "date": 1685598600000,
        "open": 0.507,
        "high": 0.5072,
        "low": 0.507,
        "close": 0.5071,
        "volume": 86889
      },
      {
        "date": 1685598660000,
        "open": 0.5072,
        "high": 0.5073,
        "low": 0.5071,
        "close": 0.5072,
        "volume": 36316
      },
      {
        "date": 1685598720000,
        "open": 0.5073,
        "high": 0.5073,
        "low": 0.5071,
        "close": 0.5071,
        "volume": 84366
      },
      {
        "date": 1685598780000,
        "open": 0.5071,
        "high": 0.5072,
        "low": 0.507,
        "close": 0.507,
        "volume": 43597
      },
      {
        "date": 1685598840000,
        "open": 0.507,
        "high": 0.5071,
        "low": 0.5068,
        "close": 0.5068,
        "volume": 39839
      },
      {
        "date": 1685598900000,
        "open": 0.5068,
        "high": 0.5069,
        "low": 0.5067,
        "close": 0.5068,
        "volume": 148399
      },
      {
        "date": 1685598960000,
        "open": 0.5067,
        "high": 0.5068,
        "low": 0.5067,
        "close": 0.5067,
        "volume": 65694
      },
      {
        "date": 1685599020000,
        "open": 0.5067,
        "high": 0.507,
        "low": 0.5064,
        "close": 0.5069,
        "volume": 778557
      },
      {
        "date": 1685599080000,
        "open": 0.5069,
        "high": 0.5069,
        "low": 0.5068,
        "close": 0.5068,
        "volume": 32552
      },
      {
        "date": 1685599140000,
        "open": 0.5069,
        "high": 0.5069,
        "low": 0.5068,
        "close": 0.5069,
        "volume": 6791
      },
      {
        "date": 1685599200000,
        "open": 0.5069,
        "high": 0.5069,
        "low": 0.5062,
        "close": 0.5062,
        "volume": 713337
      },
      {
        "date": 1685599260000,
        "open": 0.5063,
        "high": 0.5066,
        "low": 0.5062,
        "close": 0.5066,
        "volume": 121270
      },
      {
        "date": 1685599320000,
        "open": 0.5066,
        "high": 0.5069,
        "low": 0.5065,
        "close": 0.5069,
        "volume": 77445
      },
      {
        "date": 1685599380000,
        "open": 0.5069,
        "high": 0.507,
        "low": 0.5068,
        "close": 0.5069,
        "volume": 110805
      },
      {
        "date": 1685599440000,
        "open": 0.5069,
        "high": 0.5073,
        "low": 0.5069,
        "close": 0.5072,
        "volume": 168718
      },
      {
        "date": 1685599500000,
        "open": 0.5073,
        "high": 0.5074,
        "low": 0.5068,
        "close": 0.5068,
        "volume": 373685
      },
      {
        "date": 1685599560000,
        "open": 0.5068,
        "high": 0.5068,
        "low": 0.5062,
        "close": 0.5063,
        "volume": 387968
      },
      {
        "date": 1685599620000,
        "open": 0.5063,
        "high": 0.5067,
        "low": 0.5062,
        "close": 0.5064,
        "volume": 458051
      },
      {
        "date": 1685599680000,
        "open": 0.5064,
        "high": 0.5065,
        "low": 0.5062,
        "close": 0.5063,
        "volume": 203668
      },
      {
        "date": 1685599740000,
        "open": 0.5063,
        "high": 0.5063,
        "low": 0.5057,
        "close": 0.5061,
        "volume": 1014083
      },
      {
        "date": 1685599800000,
        "open": 0.5062,
        "high": 0.5063,
        "low": 0.5061,
        "close": 0.5062,
        "volume": 84254
      },
      {
        "date": 1685599860000,
        "open": 0.5061,
        "high": 0.5062,
        "low": 0.506,
        "close": 0.5062,
        "volume": 413889
      },
      {
        "date": 1685599920000,
        "open": 0.5061,
        "high": 0.5062,
        "low": 0.506,
        "close": 0.506,
        "volume": 64146
      },
      {
        "date": 1685599980000,
        "open": 0.506,
        "high": 0.5061,
        "low": 0.5058,
        "close": 0.5058,
        "volume": 418538
      },
      {
        "date": 1685600040000,
        "open": 0.5059,
        "high": 0.5059,
        "low": 0.5053,
        "close": 0.5056,
        "volume": 1281725
      },
      {
        "date": 1685600100000,
        "open": 0.5055,
        "high": 0.5059,
        "low": 0.5055,
        "close": 0.5056,
        "volume": 253241
      },
      {
        "date": 1685600160000,
        "open": 0.5056,
        "high": 0.5056,
        "low": 0.5049,
        "close": 0.505,
        "volume": 305858
      },
      {
        "date": 1685600220000,
        "open": 0.5049,
        "high": 0.505,
        "low": 0.5045,
        "close": 0.505,
        "volume": 842205
      },
      {
        "date": 1685600280000,
        "open": 0.5051,
        "high": 0.5053,
        "low": 0.5047,
        "close": 0.5048,
        "volume": 439911
      },
      {
        "date": 1685600340000,
        "open": 0.5048,
        "high": 0.5048,
        "low": 0.5043,
        "close": 0.5044,
        "volume": 579977
      },
      {
        "date": 1685600400000,
        "open": 0.5044,
        "high": 0.505,
        "low": 0.5043,
        "close": 0.5045,
        "volume": 698384
      },
      {
        "date": 1685600460000,
        "open": 0.5045,
        "high": 0.5047,
        "low": 0.5044,
        "close": 0.5046,
        "volume": 289664
      },
      {
        "date": 1685600520000,
        "open": 0.5046,
        "high": 0.5047,
        "low": 0.504,
        "close": 0.5041,
        "volume": 500796
      },
      {
        "date": 1685600580000,
        "open": 0.5041,
        "high": 0.5041,
        "low": 0.5039,
        "close": 0.5039,
        "volume": 82148
      },
      {
        "date": 1685600640000,
        "open": 0.504,
        "high": 0.5043,
        "low": 0.5039,
        "close": 0.5041,
        "volume": 523806
      },
      {
        "date": 1685600700000,
        "open": 0.5041,
        "high": 0.5041,
        "low": 0.5037,
        "close": 0.5038,
        "volume": 317473
      },
      {
        "date": 1685600760000,
        "open": 0.5038,
        "high": 0.5038,
        "low": 0.503,
        "close": 0.5034,
        "volume": 852424
      },
      {
        "date": 1685600820000,
        "open": 0.5034,
        "high": 0.5035,
        "low": 0.5028,
        "close": 0.503,
        "volume": 801983
      },
      {
        "date": 1685600880000,
        "open": 0.503,
        "high": 0.5036,
        "low": 0.5029,
        "close": 0.5036,
        "volume": 604329
      },
      {
        "date": 1685600940000,
        "open": 0.5036,
        "high": 0.504,
        "low": 0.5036,
        "close": 0.5038,
        "volume": 511709
      },
      {
        "date": 1685601000000,
        "open": 0.5038,
        "high": 0.5046,
        "low": 0.5036,
        "close": 0.5043,
        "volume": 651092
      },
      {
        "date": 1685601060000,
        "open": 0.5044,
        "high": 0.5044,
        "low": 0.5039,
        "close": 0.5043,
        "volume": 463407
      },
      {
        "date": 1685601120000,
        "open": 0.5042,
        "high": 0.5046,
        "low": 0.5042,
        "close": 0.5044,
        "volume": 95966
      },
      {
        "date": 1685601180000,
        "open": 0.5045,
        "high": 0.5045,
        "low": 0.5038,
        "close": 0.5039,
        "volume": 139989
      },
      {
        "date": 1685601240000,
        "open": 0.5039,
        "high": 0.5039,
        "low": 0.5038,
        "close": 0.5038,
        "volume": 85549
      },
      {
        "date": 1685601300000,
        "open": 0.5039,
        "high": 0.5043,
        "low": 0.5038,
        "close": 0.5042,
        "volume": 240677
      },
      {
        "date": 1685601360000,
        "open": 0.5042,
        "high": 0.5045,
        "low": 0.5039,
        "close": 0.504,
        "volume": 288214
      },
      {
        "date": 1685601420000,
        "open": 0.504,
        "high": 0.5041,
        "low": 0.5039,
        "close": 0.5041,
        "volume": 261102
      },
      {
        "date": 1685601480000,
        "open": 0.504,
        "high": 0.5041,
        "low": 0.5034,
        "close": 0.5034,
        "volume": 318612
      },
      {
        "date": 1685601540000,
        "open": 0.5034,
        "high": 0.5037,
        "low": 0.5033,
        "close": 0.5037,
        "volume": 388696
      },
      {
        "date": 1685601600000,
        "open": 0.5037,
        "high": 0.5038,
        "low": 0.5035,
        "close": 0.5036,
        "volume": 140213
      },
      {
        "date": 1685601660000,
        "open": 0.5035,
        "high": 0.5035,
        "low": 0.5031,
        "close": 0.5032,
        "volume": 304607
      },
      {
        "date": 1685601720000,
        "open": 0.5032,
        "high": 0.5033,
        "low": 0.5032,
        "close": 0.5032,
        "volume": 98059
      },
      {
        "date": 1685601780000,
        "open": 0.5032,
        "high": 0.5034,
        "low": 0.503,
        "close": 0.5033,
        "volume": 340960
      },
      {
        "date": 1685601840000,
        "open": 0.5034,
        "high": 0.5037,
        "low": 0.5032,
        "close": 0.5033,
        "volume": 404953
      },
      {
        "date": 1685601900000,
        "open": 0.5033,
        "high": 0.5036,
        "low": 0.5033,
        "close": 0.5034,
        "volume": 242004
      },
      {
        "date": 1685601960000,
        "open": 0.5033,
        "high": 0.5037,
        "low": 0.5033,
        "close": 0.5037,
        "volume": 170883
      },
      {
        "date": 1685602020000,
        "open": 0.5037,
        "high": 0.5041,
        "low": 0.5037,
        "close": 0.5041,
        "volume": 314240
      },
      {
        "date": 1685602080000,
        "open": 0.504,
        "high": 0.5043,
        "low": 0.504,
        "close": 0.5041,
        "volume": 467941
      },
      {
        "date": 1685602140000,
        "open": 0.5041,
        "high": 0.5042,
        "low": 0.504,
        "close": 0.5041,
        "volume": 102823
      },
      {
        "date": 1685602200000,
        "open": 0.504,
        "high": 0.5041,
        "low": 0.5036,
        "close": 0.5039,
        "volume": 612663
      },
      {
        "date": 1685602260000,
        "open": 0.504,
        "high": 0.504,
        "low": 0.5037,
        "close": 0.5038,
        "volume": 113645
      },
      {
        "date": 1685602320000,
        "open": 0.5038,
        "high": 0.5038,
        "low": 0.5034,
        "close": 0.5035,
        "volume": 219781
      },
      {
        "date": 1685602380000,
        "open": 0.5036,
        "high": 0.5036,
        "low": 0.5033,
        "close": 0.5034,
        "volume": 255448
      },
      {
        "date": 1685602440000,
        "open": 0.5034,
        "high": 0.5036,
        "low": 0.5033,
        "close": 0.5034,
        "volume": 329383
      },
      {
        "date": 1685602500000,
        "open": 0.5034,
        "high": 0.5034,
        "low": 0.503,
        "close": 0.5032,
        "volume": 384104
      },
      {
        "date": 1685602560000,
        "open": 0.5032,
        "high": 0.5032,
        "low": 0.5031,
        "close": 0.5031,
        "volume": 97883
      },
      {
        "date": 1685602620000,
        "open": 0.5031,
        "high": 0.5033,
        "low": 0.5028,
        "close": 0.5031,
        "volume": 397506
      },
      {
        "date": 1685602680000,
        "open": 0.503,
        "high": 0.5031,
        "low": 0.5028,
        "close": 0.503,
        "volume": 344543
      },
      {
        "date": 1685602740000,
        "open": 0.5031,
        "high": 0.5034,
        "low": 0.5029,
        "close": 0.5034,
        "volume": 192498
      },
      {
        "date": 1685602800000,
        "open": 0.5034,
        "high": 0.5038,
        "low": 0.5033,
        "close": 0.5038,
        "volume": 167535
      },
      {
        "date": 1685602860000,
        "open": 0.5038,
        "high": 0.504,
        "low": 0.5033,
        "close": 0.5033,
        "volume": 125171
      },
      {
        "date": 1685602920000,
        "open": 0.5034,
        "high": 0.5034,
        "low": 0.5023,
        "close": 0.5026,
        "volume": 611957
      },
      {
        "date": 1685602980000,
        "open": 0.5026,
        "high": 0.5032,
        "low": 0.5023,
        "close": 0.5032,
        "volume": 179476
      },
      {
        "date": 1685603040000,
        "open": 0.5031,
        "high": 0.5031,
        "low": 0.5023,
        "close": 0.5029,
        "volume": 633266
      },
      {
        "date": 1685603100000,
        "open": 0.5029,
        "high": 0.5029,
        "low": 0.5024,
        "close": 0.5025,
        "volume": 114765
      },
      {
        "date": 1685603160000,
        "open": 0.5025,
        "high": 0.5027,
        "low": 0.5024,
        "close": 0.5025,
        "volume": 207086
      },
      {
        "date": 1685603220000,
        "open": 0.5025,
        "high": 0.503,
        "low": 0.5024,
        "close": 0.503,
        "volume": 451013
      },
      {
        "date": 1685603280000,
        "open": 0.503,
        "high": 0.503,
        "low": 0.5027,
        "close": 0.5029,
        "volume": 87867
      },
      {
        "date": 1685603340000,
        "open": 0.5028,
        "high": 0.5029,
        "low": 0.5025,
        "close": 0.5026,
        "volume": 138617
      },
      {
        "date": 1685603400000,
        "open": 0.5025,
        "high": 0.503,
        "low": 0.5025,
        "close": 0.5029,
        "volume": 80469
      },
      {
        "date": 1685603460000,
        "open": 0.503,
        "high": 0.5031,
        "low": 0.5028,
        "close": 0.503,
        "volume": 136286
      },
      {
        "date": 1685603520000,
        "open": 0.503,
        "high": 0.5035,
        "low": 0.5029,
        "close": 0.5034,
        "volume": 97948
      },
      {
        "date": 1685603580000,
        "open": 0.5034,
        "high": 0.5039,
        "low": 0.5033,
        "close": 0.5038,
        "volume": 76791
      },
      {
        "date": 1685603640000,
        "open": 0.5039,
        "high": 0.5041,
        "low": 0.5038,
        "close": 0.504,
        "volume": 62891
      },
      {
        "date": 1685603700000,
        "open": 0.5039,
        "high": 0.5041,
        "low": 0.5034,
        "close": 0.504,
        "volume": 315584
      },
      {
        "date": 1685603760000,
        "open": 0.504,
        "high": 0.5044,
        "low": 0.5038,
        "close": 0.5043,
        "volume": 395266
      },
      {
        "date": 1685603820000,
        "open": 0.5044,
        "high": 0.5049,
        "low": 0.5043,
        "close": 0.5049,
        "volume": 192027
      },
      {
        "date": 1685603880000,
        "open": 0.5049,
        "high": 0.505,
        "low": 0.5047,
        "close": 0.5048,
        "volume": 336785
      },
      {
        "date": 1685603940000,
        "open": 0.5048,
        "high": 0.505,
        "low": 0.5046,
        "close": 0.5049,
        "volume": 546563
      },
      {
        "date": 1685604000000,
        "open": 0.5049,
        "high": 0.505,
        "low": 0.5046,
        "close": 0.5048,
        "volume": 131039
      },
      {
        "date": 1685604060000,
        "open": 0.5049,
        "high": 0.5049,
        "low": 0.5046,
        "close": 0.5048,
        "volume": 101399
      },
      {
        "date": 1685604120000,
        "open": 0.5048,
        "high": 0.5048,
        "low": 0.5047,
        "close": 0.5048,
        "volume": 74417
      },
      {
        "date": 1685604180000,
        "open": 0.5047,
        "high": 0.5047,
        "low": 0.5046,
        "close": 0.5046,
        "volume": 119782
      },
      {
        "date": 1685604240000,
        "open": 0.5046,
        "high": 0.5051,
        "low": 0.5046,
        "close": 0.505,
        "volume": 114105
      },
      {
        "date": 1685604300000,
        "open": 0.505,
        "high": 0.505,
        "low": 0.5047,
        "close": 0.505,
        "volume": 333296
      },
      {
        "date": 1685604360000,
        "open": 0.505,
        "high": 0.5051,
        "low": 0.5049,
        "close": 0.5051,
        "volume": 64276
      },
      {
        "date": 1685604420000,
        "open": 0.5051,
        "high": 0.5058,
        "low": 0.5051,
        "close": 0.5057,
        "volume": 575689
      },
      {
        "date": 1685604480000,
        "open": 0.5057,
        "high": 0.5058,
        "low": 0.5053,
        "close": 0.5056,
        "volume": 291095
      },
      {
        "date": 1685604540000,
        "open": 0.5057,
        "high": 0.5057,
        "low": 0.5055,
        "close": 0.5056,
        "volume": 53054
      },
      {
        "date": 1685604600000,
        "open": 0.5056,
        "high": 0.5058,
        "low": 0.5056,
        "close": 0.5058,
        "volume": 105739
      },
      {
        "date": 1685604660000,
        "open": 0.5057,
        "high": 0.5058,
        "low": 0.5057,
        "close": 0.5058,
        "volume": 26495
      },
      {
        "date": 1685604720000,
        "open": 0.5058,
        "high": 0.5059,
        "low": 0.5055,
        "close": 0.5057,
        "volume": 436476
      },
      {
        "date": 1685604780000,
        "open": 0.5057,
        "high": 0.5057,
        "low": 0.5056,
        "close": 0.5057,
        "volume": 74561
      },
      {
        "date": 1685604840000,
        "open": 0.5056,
        "high": 0.5059,
        "low": 0.5053,
        "close": 0.5058,
        "volume": 538965
      },
      {
        "date": 1685604900000,
        "open": 0.5059,
        "high": 0.5059,
        "low": 0.5055,
        "close": 0.5055,
        "volume": 164382
      },
      {
        "date": 1685604960000,
        "open": 0.5056,
        "high": 0.5056,
        "low": 0.505,
        "close": 0.505,
        "volume": 293232
      },
      {
        "date": 1685605020000,
        "open": 0.5051,
        "high": 0.5057,
        "low": 0.505,
        "close": 0.5054,
        "volume": 263271
      },
      {
        "date": 1685605080000,
        "open": 0.5054,
        "high": 0.506,
        "low": 0.5054,
        "close": 0.506,
        "volume": 180269
      },
      {
        "date": 1685605140000,
        "open": 0.506,
        "high": 0.5067,
        "low": 0.506,
        "close": 0.5066,
        "volume": 253813
      },
      {
        "date": 1685605200000,
        "open": 0.5065,
        "high": 0.5067,
        "low": 0.5064,
        "close": 0.5065,
        "volume": 51923
      },
      {
        "date": 1685605260000,
        "open": 0.5065,
        "high": 0.5067,
        "low": 0.5065,
        "close": 0.5066,
        "volume": 113003
      },
      {
        "date": 1685605320000,
        "open": 0.5066,
        "high": 0.5067,
        "low": 0.5059,
        "close": 0.5061,
        "volume": 532571
      },
      {
        "date": 1685605380000,
        "open": 0.5061,
        "high": 0.5062,
        "low": 0.5059,
        "close": 0.5059,
        "volume": 86048
      },
      {
        "date": 1685605440000,
        "open": 0.5059,
        "high": 0.506,
        "low": 0.5058,
        "close": 0.506,
        "volume": 63138
      },
      {
        "date": 1685605500000,
        "open": 0.5059,
        "high": 0.5064,
        "low": 0.5058,
        "close": 0.5063,
        "volume": 48354
      },
      {
        "date": 1685605560000,
        "open": 0.5062,
        "high": 0.5062,
        "low": 0.5059,
        "close": 0.506,
        "volume": 86537
      },
      {
        "date": 1685605620000,
        "open": 0.5059,
        "high": 0.5061,
        "low": 0.5059,
        "close": 0.506,
        "volume": 132818
      },
      {
        "date": 1685605680000,
        "open": 0.506,
        "high": 0.506,
        "low": 0.5054,
        "close": 0.5056,
        "volume": 325067
      },
      {
        "date": 1685605740000,
        "open": 0.5056,
        "high": 0.5057,
        "low": 0.5054,
        "close": 0.5054,
        "volume": 57646
      },
      {
        "date": 1685605800000,
        "open": 0.5055,
        "high": 0.5055,
        "low": 0.5053,
        "close": 0.5053,
        "volume": 68935
      },
      {
        "date": 1685605860000,
        "open": 0.5052,
        "high": 0.5059,
        "low": 0.5051,
        "close": 0.5058,
        "volume": 218738
      },
      {
        "date": 1685605920000,
        "open": 0.5058,
        "high": 0.5058,
        "low": 0.5055,
        "close": 0.5056,
        "volume": 151303
      },
      {
        "date": 1685605980000,
        "open": 0.5056,
        "high": 0.5058,
        "low": 0.5054,
        "close": 0.5055,
        "volume": 112463
      },
      {
        "date": 1685606040000,
        "open": 0.5054,
        "high": 0.5055,
        "low": 0.505,
        "close": 0.5051,
        "volume": 83025
      },
      {
        "date": 1685606100000,
        "open": 0.5051,
        "high": 0.5052,
        "low": 0.5049,
        "close": 0.5049,
        "volume": 84443
      },
      {
        "date": 1685606160000,
        "open": 0.5049,
        "high": 0.5054,
        "low": 0.5049,
        "close": 0.5052,
        "volume": 103369
      },
      {
        "date": 1685606220000,
        "open": 0.5052,
        "high": 0.5054,
        "low": 0.5051,
        "close": 0.5054,
        "volume": 32354
      },
      {
        "date": 1685606280000,
        "open": 0.5054,
        "high": 0.5055,
        "low": 0.5051,
        "close": 0.5052,
        "volume": 220754
      },
      {
        "date": 1685606340000,
        "open": 0.5052,
        "high": 0.5052,
        "low": 0.5046,
        "close": 0.5047,
        "volume": 142245
      },
      {
        "date": 1685606400000,
        "open": 0.5046,
        "high": 0.505,
        "low": 0.5044,
        "close": 0.5049,
        "volume": 423137
      },
      {
        "date": 1685606460000,
        "open": 0.5049,
        "high": 0.5049,
        "low": 0.5046,
        "close": 0.5047,
        "volume": 82770
      },
      {
        "date": 1685606520000,
        "open": 0.5047,
        "high": 0.5047,
        "low": 0.5042,
        "close": 0.5042,
        "volume": 239464
      },
      {
        "date": 1685606580000,
        "open": 0.5042,
        "high": 0.5042,
        "low": 0.5037,
        "close": 0.504,
        "volume": 372995
      },
      {
        "date": 1685606640000,
        "open": 0.504,
        "high": 0.5042,
        "low": 0.5039,
        "close": 0.5041,
        "volume": 209223
      },
      {
        "date": 1685606700000,
        "open": 0.504,
        "high": 0.5042,
        "low": 0.503,
        "close": 0.5035,
        "volume": 1474018
      },
      {
        "date": 1685606760000,
        "open": 0.5035,
        "high": 0.5041,
        "low": 0.5033,
        "close": 0.504,
        "volume": 384390
      },
      {
        "date": 1685606820000,
        "open": 0.504,
        "high": 0.5043,
        "low": 0.5038,
        "close": 0.5039,
        "volume": 161789
      },
      {
        "date": 1685606880000,
        "open": 0.5039,
        "high": 0.5043,
        "low": 0.5038,
        "close": 0.5043,
        "volume": 83809
      },
      {
        "date": 1685606940000,
        "open": 0.5043,
        "high": 0.505,
        "low": 0.5042,
        "close": 0.505,
        "volume": 130234
      },
      {
        "date": 1685607000000,
        "open": 0.505,
        "high": 0.5053,
        "low": 0.5049,
        "close": 0.5053,
        "volume": 171811
      },
      {
        "date": 1685607060000,
        "open": 0.5053,
        "high": 0.5053,
        "low": 0.5051,
        "close": 0.5051,
        "volume": 86545
      },
      {
        "date": 1685607120000,
        "open": 0.5052,
        "high": 0.5052,
        "low": 0.5048,
        "close": 0.505,
        "volume": 241544
      },
      {
        "date": 1685607180000,
        "open": 0.505,
        "high": 0.505,
        "low": 0.5044,
        "close": 0.5044,
        "volume": 192479
      },
      {
        "date": 1685607240000,
        "open": 0.5044,
        "high": 0.5045,
        "low": 0.504,
        "close": 0.504,
        "volume": 505252
      },
      {
        "date": 1685607300000,
        "open": 0.504,
        "high": 0.5041,
        "low": 0.5036,
        "close": 0.5038,
        "volume": 517736
      },
      {
        "date": 1685607360000,
        "open": 0.5038,
        "high": 0.5038,
        "low": 0.5033,
        "close": 0.5037,
        "volume": 538486
      },
      {
        "date": 1685607420000,
        "open": 0.5036,
        "high": 0.5039,
        "low": 0.5036,
        "close": 0.5038,
        "volume": 174097
      },
      {
        "date": 1685607480000,
        "open": 0.5039,
        "high": 0.5039,
        "low": 0.5036,
        "close": 0.5036,
        "volume": 194604
      },
      {
        "date": 1685607540000,
        "open": 0.5036,
        "high": 0.5036,
        "low": 0.5032,
        "close": 0.5032,
        "volume": 207603
      },
      {
        "date": 1685607600000,
        "open": 0.5032,
        "high": 0.5032,
        "low": 0.503,
        "close": 0.5031,
        "volume": 363538
      },
      {
        "date": 1685607660000,
        "open": 0.5031,
        "high": 0.5031,
        "low": 0.5028,
        "close": 0.503,
        "volume": 216510
      },
      {
        "date": 1685607720000,
        "open": 0.5029,
        "high": 0.5034,
        "low": 0.5029,
        "close": 0.5033,
        "volume": 125173
      },
      {
        "date": 1685607780000,
        "open": 0.5033,
        "high": 0.5033,
        "low": 0.503,
        "close": 0.5031,
        "volume": 198339
      },
      {
        "date": 1685607840000,
        "open": 0.5031,
        "high": 0.5032,
        "low": 0.503,
        "close": 0.5032,
        "volume": 37915
      },
      {
        "date": 1685607900000,
        "open": 0.5032,
        "high": 0.5034,
        "low": 0.5031,
        "close": 0.5034,
        "volume": 124541
      },
      {
        "date": 1685607960000,
        "open": 0.5034,
        "high": 0.5034,
        "low": 0.5031,
        "close": 0.5032,
        "volume": 246438
      },
      {
        "date": 1685608020000,
        "open": 0.5031,
        "high": 0.5032,
        "low": 0.5028,
        "close": 0.503,
        "volume": 310987
      },
      {
        "date": 1685608080000,
        "open": 0.503,
        "high": 0.5032,
        "low": 0.503,
        "close": 0.5031,
        "volume": 79652
      },
      {
        "date": 1685608140000,
        "open": 0.5031,
        "high": 0.5037,
        "low": 0.503,
        "close": 0.5031,
        "volume": 384495
      },
      {
        "date": 1685608200000,
        "open": 0.5031,
        "high": 0.5038,
        "low": 0.503,
        "close": 0.5036,
        "volume": 217798
      },
      {
        "date": 1685608260000,
        "open": 0.5036,
        "high": 0.5036,
        "low": 0.5035,
        "close": 0.5036,
        "volume": 49774
      },
      {
        "date": 1685608320000,
        "open": 0.5036,
        "high": 0.5037,
        "low": 0.5034,
        "close": 0.5034,
        "volume": 165814
      },
      {
        "date": 1685608380000,
        "open": 0.5035,
        "high": 0.5035,
        "low": 0.5031,
        "close": 0.5033,
        "volume": 242688
      },
      {
        "date": 1685608440000,
        "open": 0.5032,
        "high": 0.5041,
        "low": 0.5032,
        "close": 0.5041,
        "volume": 163000
      },
      {
        "date": 1685608500000,
        "open": 0.504,
        "high": 0.5046,
        "low": 0.504,
        "close": 0.5046,
        "volume": 190465
      },
      {
        "date": 1685608560000,
        "open": 0.5046,
        "high": 0.5047,
        "low": 0.5039,
        "close": 0.5042,
        "volume": 338686
      },
      {
        "date": 1685608620000,
        "open": 0.5043,
        "high": 0.5043,
        "low": 0.5035,
        "close": 0.5036,
        "volume": 181096
      },
      {
        "date": 1685608680000,
        "open": 0.5036,
        "high": 0.5039,
        "low": 0.5035,
        "close": 0.5035,
        "volume": 327518
      },
      {
        "date": 1685608740000,
        "open": 0.5035,
        "high": 0.5036,
        "low": 0.5034,
        "close": 0.5034,
        "volume": 91325
      },
      {
        "date": 1685608800000,
        "open": 0.5034,
        "high": 0.5035,
        "low": 0.503,
        "close": 0.5031,
        "volume": 339900
      },
      {
        "date": 1685608860000,
        "open": 0.5031,
        "high": 0.5036,
        "low": 0.503,
        "close": 0.5033,
        "volume": 550182
      },
      {
        "date": 1685608920000,
        "open": 0.5034,
        "high": 0.5034,
        "low": 0.5033,
        "close": 0.5033,
        "volume": 128359
      },
      {
        "date": 1685608980000,
        "open": 0.5032,
        "high": 0.5033,
        "low": 0.503,
        "close": 0.5033,
        "volume": 187603
      },
      {
        "date": 1685609040000,
        "open": 0.5032,
        "high": 0.5033,
        "low": 0.5032,
        "close": 0.5033,
        "volume": 11277
      },
      {
        "date": 1685609100000,
        "open": 0.5033,
        "high": 0.5037,
        "low": 0.5031,
        "close": 0.5033,
        "volume": 306260
      },
      {
        "date": 1685609160000,
        "open": 0.5033,
        "high": 0.504,
        "low": 0.5033,
        "close": 0.5039,
        "volume": 379685
      },
      {
        "date": 1685609220000,
        "open": 0.5039,
        "high": 0.5041,
        "low": 0.5038,
        "close": 0.5038,
        "volume": 69552
      },
      {
        "date": 1685609280000,
        "open": 0.5039,
        "high": 0.5039,
        "low": 0.5034,
        "close": 0.5034,
        "volume": 181458
      },
      {
        "date": 1685609340000,
        "open": 0.5035,
        "high": 0.5035,
        "low": 0.5032,
        "close": 0.5035,
        "volume": 219373
      },
      {
        "date": 1685609400000,
        "open": 0.5034,
        "high": 0.5035,
        "low": 0.5032,
        "close": 0.5034,
        "volume": 274833
      },
      {
        "date": 1685609460000,
        "open": 0.5033,
        "high": 0.5035,
        "low": 0.5032,
        "close": 0.5033,
        "volume": 235128
      },
      {
        "date": 1685609520000,
        "open": 0.5033,
        "high": 0.5035,
        "low": 0.5031,
        "close": 0.5031,
        "volume": 281792
      },
      {
        "date": 1685609580000,
        "open": 0.5032,
        "high": 0.5032,
        "low": 0.5031,
        "close": 0.5031,
        "volume": 24370
      },
      {
        "date": 1685609640000,
        "open": 0.5031,
        "high": 0.5032,
        "low": 0.5031,
        "close": 0.5032,
        "volume": 133719
      },
      {
        "date": 1685609700000,
        "open": 0.5032,
        "high": 0.5032,
        "low": 0.5031,
        "close": 0.5031,
        "volume": 107934
      },
      {
        "date": 1685609760000,
        "open": 0.5032,
        "high": 0.5037,
        "low": 0.5031,
        "close": 0.5037,
        "volume": 353000
      },
      {
        "date": 1685609820000,
        "open": 0.5037,
        "high": 0.5037,
        "low": 0.5031,
        "close": 0.5033,
        "volume": 233408
      },
      {
        "date": 1685609880000,
        "open": 0.5033,
        "high": 0.5034,
        "low": 0.5032,
        "close": 0.5034,
        "volume": 165722
      },
      {
        "date": 1685609940000,
        "open": 0.5033,
        "high": 0.5039,
        "low": 0.5033,
        "close": 0.5038,
        "volume": 228033
      },
      {
        "date": 1685610000000,
        "open": 0.5039,
        "high": 0.5042,
        "low": 0.5038,
        "close": 0.5042,
        "volume": 74480
      },
      {
        "date": 1685610060000,
        "open": 0.5042,
        "high": 0.5042,
        "low": 0.5041,
        "close": 0.5042,
        "volume": 26489
      },
      {
        "date": 1685610120000,
        "open": 0.5041,
        "high": 0.5042,
        "low": 0.5035,
        "close": 0.5038,
        "volume": 350844
      },
      {
        "date": 1685610180000,
        "open": 0.5038,
        "high": 0.5038,
        "low": 0.5035,
        "close": 0.5037,
        "volume": 378604
      },
      {
        "date": 1685610240000,
        "open": 0.5038,
        "high": 0.5038,
        "low": 0.5036,
        "close": 0.5036,
        "volume": 67038
      },
      {
        "date": 1685610300000,
        "open": 0.5036,
        "high": 0.5037,
        "low": 0.5036,
        "close": 0.5036,
        "volume": 82973
      },
      {
        "date": 1685610360000,
        "open": 0.5036,
        "high": 0.5037,
        "low": 0.5034,
        "close": 0.5037,
        "volume": 474056
      },
      {
        "date": 1685610420000,
        "open": 0.5037,
        "high": 0.5042,
        "low": 0.5037,
        "close": 0.504,
        "volume": 247869
      },
      {
        "date": 1685610480000,
        "open": 0.5041,
        "high": 0.5044,
        "low": 0.504,
        "close": 0.5044,
        "volume": 48794
      },
      {
        "date": 1685610540000,
        "open": 0.5044,
        "high": 0.5046,
        "low": 0.5044,
        "close": 0.5046,
        "volume": 132770
      },
      {
        "date": 1685610600000,
        "open": 0.5045,
        "high": 0.5048,
        "low": 0.5044,
        "close": 0.5046,
        "volume": 336092
      },
      {
        "date": 1685610660000,
        "open": 0.5045,
        "high": 0.505,
        "low": 0.5045,
        "close": 0.505,
        "volume": 160030
      },
      {
        "date": 1685610720000,
        "open": 0.505,
        "high": 0.5055,
        "low": 0.505,
        "close": 0.5055,
        "volume": 108861
      },
      {
        "date": 1685610780000,
        "open": 0.5054,
        "high": 0.5058,
        "low": 0.5054,
        "close": 0.5058,
        "volume": 225928
      },
      {
        "date": 1685610840000,
        "open": 0.5058,
        "high": 0.5058,
        "low": 0.5054,
        "close": 0.5054,
        "volume": 149863
      },
      {
        "date": 1685610900000,
        "open": 0.5055,
        "high": 0.5055,
        "low": 0.505,
        "close": 0.5052,
        "volume": 336144
      },
      {
        "date": 1685610960000,
        "open": 0.5052,
        "high": 0.5057,
        "low": 0.5052,
        "close": 0.5056,
        "volume": 184625
      },
      {
        "date": 1685611020000,
        "open": 0.5057,
        "high": 0.506,
        "low": 0.5056,
        "close": 0.506,
        "volume": 59162
      },
      {
        "date": 1685611080000,
        "open": 0.506,
        "high": 0.506,
        "low": 0.5054,
        "close": 0.5055,
        "volume": 318631
      },
      {
        "date": 1685611140000,
        "open": 0.5056,
        "high": 0.506,
        "low": 0.5055,
        "close": 0.5059,
        "volume": 268950
      },
      {
        "date": 1685611200000,
        "open": 0.5059,
        "high": 0.5059,
        "low": 0.5056,
        "close": 0.5057,
        "volume": 288378
      },
      {
        "date": 1685611260000,
        "open": 0.5057,
        "high": 0.5057,
        "low": 0.5054,
        "close": 0.5057,
        "volume": 494380
      },
      {
        "date": 1685611320000,
        "open": 0.5057,
        "high": 0.5062,
        "low": 0.5056,
        "close": 0.5061,
        "volume": 159025
      },
      {
        "date": 1685611380000,
        "open": 0.5061,
        "high": 0.5062,
        "low": 0.5061,
        "close": 0.5062,
        "volume": 76134
      },
      {
        "date": 1685611440000,
        "open": 0.5062,
        "high": 0.5062,
        "low": 0.5061,
        "close": 0.5061,
        "volume": 33006
      },
      {
        "date": 1685611500000,
        "open": 0.5061,
        "high": 0.5064,
        "low": 0.5061,
        "close": 0.5064,
        "volume": 55932
      },
      {
        "date": 1685611560000,
        "open": 0.5064,
        "high": 0.5066,
        "low": 0.5063,
        "close": 0.5065,
        "volume": 168021
      },
      {
        "date": 1685611620000,
        "open": 0.5065,
        "high": 0.5065,
        "low": 0.5062,
        "close": 0.5065,
        "volume": 97697
      },
      {
        "date": 1685611680000,
        "open": 0.5066,
        "high": 0.5066,
        "low": 0.5063,
        "close": 0.5065,
        "volume": 194056
      },
      {
        "date": 1685611740000,
        "open": 0.5066,
        "high": 0.5074,
        "low": 0.5066,
        "close": 0.5074,
        "volume": 186179
      },
      {
        "date": 1685611800000,
        "open": 0.5074,
        "high": 0.5074,
        "low": 0.5071,
        "close": 0.5074,
        "volume": 285122
      },
      {
        "date": 1685611860000,
        "open": 0.5073,
        "high": 0.5077,
        "low": 0.5073,
        "close": 0.5074,
        "volume": 129117
      },
      {
        "date": 1685611920000,
        "open": 0.5074,
        "high": 0.5075,
        "low": 0.5072,
        "close": 0.5072,
        "volume": 153203
      },
      {
        "date": 1685611980000,
        "open": 0.5072,
        "high": 0.5072,
        "low": 0.5067,
        "close": 0.5068,
        "volume": 741057
      },
      {
        "date": 1685612040000,
        "open": 0.5067,
        "high": 0.5069,
        "low": 0.5066,
        "close": 0.5069,
        "volume": 104652
      },
      {
        "date": 1685612100000,
        "open": 0.507,
        "high": 0.5076,
        "low": 0.5069,
        "close": 0.5075,
        "volume": 300732
      },
      {
        "date": 1685612160000,
        "open": 0.5075,
        "high": 0.5078,
        "low": 0.5075,
        "close": 0.5076,
        "volume": 224824
      },
      {
        "date": 1685612220000,
        "open": 0.5076,
        "high": 0.5076,
        "low": 0.5071,
        "close": 0.5071,
        "volume": 93990
      },
      {
        "date": 1685612280000,
        "open": 0.5071,
        "high": 0.5071,
        "low": 0.5062,
        "close": 0.5062,
        "volume": 109506
      },
      {
        "date": 1685612340000,
        "open": 0.5062,
        "high": 0.5066,
        "low": 0.506,
        "close": 0.5065,
        "volume": 224519
      },
      {
        "date": 1685612400000,
        "open": 0.5065,
        "high": 0.5069,
        "low": 0.5062,
        "close": 0.5069,
        "volume": 306235
      },
      {
        "date": 1685612460000,
        "open": 0.5069,
        "high": 0.507,
        "low": 0.5065,
        "close": 0.5066,
        "volume": 117972
      },
      {
        "date": 1685612520000,
        "open": 0.5065,
        "high": 0.507,
        "low": 0.5065,
        "close": 0.507,
        "volume": 29819
      },
      {
        "date": 1685612580000,
        "open": 0.507,
        "high": 0.507,
        "low": 0.5068,
        "close": 0.5069,
        "volume": 53888
      },
      {
        "date": 1685612640000,
        "open": 0.5069,
        "high": 0.5073,
        "low": 0.5068,
        "close": 0.5073,
        "volume": 255970
      },
      {
        "date": 1685612700000,
        "open": 0.5073,
        "high": 0.5073,
        "low": 0.5071,
        "close": 0.5071,
        "volume": 95571
      },
      {
        "date": 1685612760000,
        "open": 0.5072,
        "high": 0.5073,
        "low": 0.5068,
        "close": 0.5068,
        "volume": 275505
      },
      {
        "date": 1685612820000,
        "open": 0.5069,
        "high": 0.507,
        "low": 0.5068,
        "close": 0.5069,
        "volume": 117887
      },
      {
        "date": 1685612880000,
        "open": 0.5069,
        "high": 0.507,
        "low": 0.5067,
        "close": 0.5067,
        "volume": 113456
      },
      {
        "date": 1685612940000,
        "open": 0.5067,
        "high": 0.5069,
        "low": 0.5064,
        "close": 0.5067,
        "volume": 220672
      },
      {
        "date": 1685613000000,
        "open": 0.5068,
        "high": 0.5069,
        "low": 0.5067,
        "close": 0.5068,
        "volume": 20668
      },
      {
        "date": 1685613060000,
        "open": 0.5069,
        "high": 0.5069,
        "low": 0.5068,
        "close": 0.5068,
        "volume": 56622
      },
      {
        "date": 1685613120000,
        "open": 0.5068,
        "high": 0.5071,
        "low": 0.5068,
        "close": 0.5069,
        "volume": 183942
      },
      {
        "date": 1685613180000,
        "open": 0.5068,
        "high": 0.5069,
        "low": 0.5068,
        "close": 0.5068,
        "volume": 77942
      },
      {
        "date": 1685613240000,
        "open": 0.5069,
        "high": 0.5071,
        "low": 0.5069,
        "close": 0.5071,
        "volume": 59915
      },
      {
        "date": 1685613300000,
        "open": 0.507,
        "high": 0.5071,
        "low": 0.507,
        "close": 0.507,
        "volume": 31893
      },
      {
        "date": 1685613360000,
        "open": 0.507,
        "high": 0.5071,
        "low": 0.5066,
        "close": 0.5068,
        "volume": 359744
      },
      {
        "date": 1685613420000,
        "open": 0.5068,
        "high": 0.5068,
        "low": 0.5063,
        "close": 0.5065,
        "volume": 96567
      },
      {
        "date": 1685613480000,
        "open": 0.5064,
        "high": 0.5067,
        "low": 0.5064,
        "close": 0.5065,
        "volume": 67785
      },
      {
        "date": 1685613540000,
        "open": 0.5065,
        "high": 0.5066,
        "low": 0.5064,
        "close": 0.5064,
        "volume": 30282
      },
      {
        "date": 1685613600000,
        "open": 0.5064,
        "high": 0.5064,
        "low": 0.5063,
        "close": 0.5064,
        "volume": 31431
      },
      {
        "date": 1685613660000,
        "open": 0.5063,
        "high": 0.5068,
        "low": 0.5063,
        "close": 0.5067,
        "volume": 292218
      },
      {
        "date": 1685613720000,
        "open": 0.5067,
        "high": 0.5068,
        "low": 0.5066,
        "close": 0.5066,
        "volume": 30307
      },
      {
        "date": 1685613780000,
        "open": 0.5066,
        "high": 0.5067,
        "low": 0.5065,
        "close": 0.5066,
        "volume": 77704
      },
      {
        "date": 1685613840000,
        "open": 0.5065,
        "high": 0.5069,
        "low": 0.5065,
        "close": 0.5067,
        "volume": 161937
      },
      {
        "date": 1685613900000,
        "open": 0.5068,
        "high": 0.5068,
        "low": 0.5065,
        "close": 0.5065,
        "volume": 45899
      },
      {
        "date": 1685613960000,
        "open": 0.5065,
        "high": 0.5069,
        "low": 0.5062,
        "close": 0.5065,
        "volume": 757625
      },
      {
        "date": 1685614020000,
        "open": 0.5065,
        "high": 0.5066,
        "low": 0.506,
        "close": 0.506,
        "volume": 107083
      },
      {
        "date": 1685614080000,
        "open": 0.5061,
        "high": 0.5061,
        "low": 0.5059,
        "close": 0.5059,
        "volume": 17775
      },
      {
        "date": 1685614140000,
        "open": 0.506,
        "high": 0.506,
        "low": 0.5055,
        "close": 0.5056,
        "volume": 95155
      },
      {
        "date": 1685614200000,
        "open": 0.5055,
        "high": 0.5056,
        "low": 0.5055,
        "close": 0.5056,
        "volume": 54929
      },
      {
        "date": 1685614260000,
        "open": 0.5056,
        "high": 0.5056,
        "low": 0.5053,
        "close": 0.5055,
        "volume": 169372
      },
      {
        "date": 1685614320000,
        "open": 0.5055,
        "high": 0.5055,
        "low": 0.5053,
        "close": 0.5054,
        "volume": 117306
      },
      {
        "date": 1685614380000,
        "open": 0.5053,
        "high": 0.5055,
        "low": 0.5053,
        "close": 0.5054,
        "volume": 48357
      },
      {
        "date": 1685614440000,
        "open": 0.5055,
        "high": 0.5058,
        "low": 0.5053,
        "close": 0.5054,
        "volume": 304848
      },
      {
        "date": 1685614500000,
        "open": 0.5053,
        "high": 0.5053,
        "low": 0.5052,
        "close": 0.5052,
        "volume": 27701
      },
      {
        "date": 1685614560000,
        "open": 0.5053,
        "high": 0.5053,
        "low": 0.5045,
        "close": 0.5046,
        "volume": 513068
      },
      {
        "date": 1685614620000,
        "open": 0.5046,
        "high": 0.5046,
        "low": 0.5041,
        "close": 0.5043,
        "volume": 220969
      },
      {
        "date": 1685614680000,
        "open": 0.5043,
        "high": 0.5044,
        "low": 0.5038,
        "close": 0.5041,
        "volume": 438473
      },
      {
        "date": 1685614740000,
        "open": 0.5041,
        "high": 0.5046,
        "low": 0.5041,
        "close": 0.5045,
        "volume": 146946
      },
      {
        "date": 1685614800000,
        "open": 0.5045,
        "high": 0.5049,
        "low": 0.5045,
        "close": 0.5047,
        "volume": 264318
      },
      {
        "date": 1685614860000,
        "open": 0.5047,
        "high": 0.5048,
        "low": 0.5044,
        "close": 0.5047,
        "volume": 222795
      },
      {
        "date": 1685614920000,
        "open": 0.5046,
        "high": 0.5046,
        "low": 0.5043,
        "close": 0.5045,
        "volume": 120510
      },
      {
        "date": 1685614980000,
        "open": 0.5044,
        "high": 0.5047,
        "low": 0.5041,
        "close": 0.5046,
        "volume": 176671
      },
      {
        "date": 1685615040000,
        "open": 0.5047,
        "high": 0.5051,
        "low": 0.5046,
        "close": 0.5048,
        "volume": 138034
      },
      {
        "date": 1685615100000,
        "open": 0.5049,
        "high": 0.5049,
        "low": 0.5047,
        "close": 0.5048,
        "volume": 114497
      },
      {
        "date": 1685615160000,
        "open": 0.5049,
        "high": 0.5049,
        "low": 0.5045,
        "close": 0.5045,
        "volume": 115219
      },
      {
        "date": 1685615220000,
        "open": 0.5045,
        "high": 0.5046,
        "low": 0.5042,
        "close": 0.5044,
        "volume": 110995
      },
      {
        "date": 1685615280000,
        "open": 0.5043,
        "high": 0.5044,
        "low": 0.5042,
        "close": 0.5042,
        "volume": 23440
      },
      {
        "date": 1685615340000,
        "open": 0.5042,
        "high": 0.5045,
        "low": 0.5042,
        "close": 0.5044,
        "volume": 296590
      },
      {
        "date": 1685615400000,
        "open": 0.5044,
        "high": 0.5047,
        "low": 0.5043,
        "close": 0.5047,
        "volume": 39230
      },
      {
        "date": 1685615460000,
        "open": 0.5047,
        "high": 0.5052,
        "low": 0.5047,
        "close": 0.5051,
        "volume": 217156
      },
      {
        "date": 1685615520000,
        "open": 0.5052,
        "high": 0.5053,
        "low": 0.5051,
        "close": 0.5053,
        "volume": 40058
      },
      {
        "date": 1685615580000,
        "open": 0.5052,
        "high": 0.5053,
        "low": 0.505,
        "close": 0.5051,
        "volume": 139374
      },
      {
        "date": 1685615640000,
        "open": 0.5051,
        "high": 0.5051,
        "low": 0.5049,
        "close": 0.505,
        "volume": 34066
      },
      {
        "date": 1685615700000,
        "open": 0.505,
        "high": 0.5053,
        "low": 0.505,
        "close": 0.5051,
        "volume": 171534
      },
      {
        "date": 1685615760000,
        "open": 0.5051,
        "high": 0.5051,
        "low": 0.505,
        "close": 0.505,
        "volume": 15883
      },
      {
        "date": 1685615820000,
        "open": 0.505,
        "high": 0.5051,
        "low": 0.505,
        "close": 0.5051,
        "volume": 21155
      },
      {
        "date": 1685615880000,
        "open": 0.5052,
        "high": 0.5052,
        "low": 0.5048,
        "close": 0.5048,
        "volume": 200112
      },
      {
        "date": 1685615940000,
        "open": 0.5049,
        "high": 0.5049,
        "low": 0.5048,
        "close": 0.5048,
        "volume": 46600
      },
      {
        "date": 1685616000000,
        "open": 0.5048,
        "high": 0.5051,
        "low": 0.5048,
        "close": 0.505,
        "volume": 81351
      },
      {
        "date": 1685616060000,
        "open": 0.505,
        "high": 0.5051,
        "low": 0.5049,
        "close": 0.5049,
        "volume": 23667
      },
      {
        "date": 1685616120000,
        "open": 0.5049,
        "high": 0.505,
        "low": 0.5048,
        "close": 0.5048,
        "volume": 88695
      },
      {
        "date": 1685616180000,
        "open": 0.5048,
        "high": 0.5049,
        "low": 0.5048,
        "close": 0.5048,
        "volume": 19246
      },
      {
        "date": 1685616240000,
        "open": 0.5049,
        "high": 0.5049,
        "low": 0.5045,
        "close": 0.5048,
        "volume": 295468
      },
      {
        "date": 1685616300000,
        "open": 0.5049,
        "high": 0.5051,
        "low": 0.5049,
        "close": 0.505,
        "volume": 77781
      },
      {
        "date": 1685616360000,
        "open": 0.505,
        "high": 0.505,
        "low": 0.5047,
        "close": 0.5047,
        "volume": 34160
      },
      {
        "date": 1685616420000,
        "open": 0.5047,
        "high": 0.5049,
        "low": 0.5046,
        "close": 0.5047,
        "volume": 170979
      },
      {
        "date": 1685616480000,
        "open": 0.5047,
        "high": 0.505,
        "low": 0.5047,
        "close": 0.505,
        "volume": 78429
      },
      {
        "date": 1685616540000,
        "open": 0.505,
        "high": 0.5051,
        "low": 0.5049,
        "close": 0.5051,
        "volume": 26199
      },
      {
        "date": 1685616600000,
        "open": 0.5051,
        "high": 0.5057,
        "low": 0.505,
        "close": 0.5057,
        "volume": 77197
      },
      {
        "date": 1685616660000,
        "open": 0.5056,
        "high": 0.5057,
        "low": 0.5053,
        "close": 0.5055,
        "volume": 163470
      },
      {
        "date": 1685616720000,
        "open": 0.5055,
        "high": 0.5056,
        "low": 0.5055,
        "close": 0.5056,
        "volume": 13154
      },
      {
        "date": 1685616780000,
        "open": 0.5056,
        "high": 0.5056,
        "low": 0.5055,
        "close": 0.5056,
        "volume": 12007
      },
      {
        "date": 1685616840000,
        "open": 0.5056,
        "high": 0.5058,
        "low": 0.5056,
        "close": 0.5057,
        "volume": 71982
      },
      {
        "date": 1685616900000,
        "open": 0.5056,
        "high": 0.5057,
        "low": 0.5054,
        "close": 0.5056,
        "volume": 107988
      },
      {
        "date": 1685616960000,
        "open": 0.5057,
        "high": 0.5057,
        "low": 0.5053,
        "close": 0.5054,
        "volume": 146431
      },
      {
        "date": 1685617020000,
        "open": 0.5054,
        "high": 0.5059,
        "low": 0.5054,
        "close": 0.5059,
        "volume": 140601
      },
      {
        "date": 1685617080000,
        "open": 0.5058,
        "high": 0.5059,
        "low": 0.5058,
        "close": 0.5059,
        "volume": 9173
      },
      {
        "date": 1685617140000,
        "open": 0.5059,
        "high": 0.5061,
        "low": 0.5057,
        "close": 0.506,
        "volume": 183269
      },
      {
        "date": 1685617200000,
        "open": 0.506,
        "high": 0.5062,
        "low": 0.506,
        "close": 0.5062,
        "volume": 52854
      },
      {
        "date": 1685617260000,
        "open": 0.5062,
        "high": 0.5063,
        "low": 0.5061,
        "close": 0.5061,
        "volume": 93497
      },
      {
        "date": 1685617320000,
        "open": 0.5062,
        "high": 0.5062,
        "low": 0.5061,
        "close": 0.5062,
        "volume": 79346
      },
      {
        "date": 1685617380000,
        "open": 0.5061,
        "high": 0.5062,
        "low": 0.5059,
        "close": 0.5059,
        "volume": 49134
      },
      {
        "date": 1685617440000,
        "open": 0.5059,
        "high": 0.506,
        "low": 0.5057,
        "close": 0.5057,
        "volume": 50631
      },
      {
        "date": 1685617500000,
        "open": 0.5057,
        "high": 0.5058,
        "low": 0.5054,
        "close": 0.5054,
        "volume": 47858
      },
      {
        "date": 1685617560000,
        "open": 0.5054,
        "high": 0.5057,
        "low": 0.5052,
        "close": 0.5052,
        "volume": 212462
      },
      {
        "date": 1685617620000,
        "open": 0.5052,
        "high": 0.5052,
        "low": 0.5048,
        "close": 0.5049,
        "volume": 79209
      },
      {
        "date": 1685617680000,
        "open": 0.505,
        "high": 0.5053,
        "low": 0.5049,
        "close": 0.5053,
        "volume": 106178
      },
      {
        "date": 1685617740000,
        "open": 0.5052,
        "high": 0.5053,
        "low": 0.5052,
        "close": 0.5053,
        "volume": 71304
      }
    ]
  }
  