import React from "react";

const TradeCard = ({ data }) => {
  return (
    <div className="p-4 flex flex-col bg-zinc-100 rounded-md mb-5">
      {Object.entries(data).map(([key, value], index) => (
        <div
          className="flex justify-between items-center p-2"
          key={index}
        >
          <div className="font-medium text-gray-700">{key}:</div>
          <div className="text-gray-900">{value}</div>
        </div>
      ))}
    </div>
  );
};

export default TradeCard;
