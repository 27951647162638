import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setTimeFrame,
  setEmaPeriod,
  setEmaCalcSource,
  setEmaLineStyle,
  setEmaLineWidth,
  setEmaPointMarkerVisible,
  toggleEmaLineVisibility,
  toggleEmaPriceLineVisibility,
  setEmaColor,
  resetEmaSettings,
} from '../../../store/indicators/emaSlice';
import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../wrappers/IndicatorSettingWrapper';
import { changeTime, fetchChartData } from '../../../store/chartSlice';

const EMAComponent = ({onClose}) => {
  const dispatch = useDispatch();
  const emaState = useSelector((state) => state.ema);
  const [activeTab, setActiveTab] = useState('general');

  const handleInputChange = (e, actionCreator) => {
    const value = e.target.type === 'number' ? Math.max(Number(e.target.min), Number(e.target.value)) : e.target.value;
    dispatch(actionCreator(value));
    if (actionCreator === setTimeFrame) {
      dispatch(changeTime(value));
      dispatch(fetchChartData());
    }
  };

  const handleCheckboxChange = (e, actionCreator) => {
    dispatch(actionCreator(e.target.checked));
  };

  return (
    <IndicatorSettingWrapper onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.tabs}>
          <div
            className={`${styles.tab} ${activeTab === 'general' ? styles.active : ''}`}
            onClick={() => setActiveTab('general')}
          >
            General Settings
          </div>
          <div
            className={`${styles.tab} ${activeTab === 'appearance' ? styles.active : ''}`}
            onClick={() => setActiveTab('appearance')}
          >
            Appearance Settings
          </div>
          <div
            className={`${styles.tab} ${activeTab === 'visibility' ? styles.active : ''}`}
            onClick={() => setActiveTab('visibility')}
          >
            Visibility Settings
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>
         
          <div className={styles.setting}>
            <label>Time Frame:</label>
            <select
              value={emaState.timeFrame}
              onChange={(e) => handleInputChange(e, setTimeFrame)}
            >
              <option value={1}>1 minute</option>
              <option value={5}>5 minutes</option>
              <option value={15}>15 minutes</option>
              <option value={30}>30 minutes</option>
              <option value={60}>1 hour</option>
              <option value={240}>4 hours</option>
              <option value={1440}>1 day</option>
            </select>
          </div>

          <div className={styles.setting}>
            <label>Period:</label>
            <input
              min="1"
              type="number"
              value={emaState.period}
              onChange={(e) => handleInputChange(e, setEmaPeriod)}
            />
          </div>
          <div className={styles.setting}>
            <label>Source:</label>
            <select
              value={emaState.source}
              onChange={(e) => handleInputChange(e, setEmaCalcSource)}
            >
              <option value="open">Open</option>
              <option value="close">Close</option>
              <option value="high">High</option>
              <option value="low">Low</option>
            </select>
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'appearance' ? styles.active : ''}`}>
          <div className={styles.setting}>
            <label>Color:</label>
            <input
              type="color"
              value={emaState.styles.color}
              onChange={(e) => handleInputChange(e, setEmaColor)}
            />
          </div>
          <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={emaState.styles.lineStyle}
              onChange={(e) => handleInputChange(e, setEmaLineStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
              <option value={3}>Large Dashed</option>
              <option value={4}>Sparse Dotted</option>
            </select>
          </div>
          {/* <div className={styles.setting}>
            <label>Line Type:</label>
            <select
              value={emaState.styles.lineType}
              onChange={(e) => handleInputChange(e, setEmaLineType)}
            >
              <option value={0}>Simple</option>
              <option value={1}>With Steps</option>
              <option value={2}>Curved</option>
            </select>
          </div> */}
          <div className={styles.setting}>
            <label>Line Width:</label>
            <input
              min="1"
              type="number"
              value={emaState.styles.lineWidth}
              onChange={(e) => handleInputChange(e, setEmaLineWidth)}
            />
          </div>
        </div>

        <div className={`${styles.tabContent} ${activeTab === 'visibility' ? styles.active : ''}`}>
          <div className={styles.setting}>
            <label>
              <input
                type="checkbox"
                checked={emaState.styles.pointMarkersVisible}
                onChange={(e) => handleCheckboxChange(e, setEmaPointMarkerVisible)}
              />
              Point Markers Visible
            </label>
          </div>
          <div className={styles.setting}>
            <label>
              <input
                type="checkbox"
                checked={emaState.styles.lineVisible}
                onChange={() => dispatch(toggleEmaLineVisibility())}
              />
              Line Visible
            </label>
          </div>
          <div className={styles.setting}>
            <label>
              <input
                type="checkbox"
                checked={emaState.styles.priceLineVisible}
                onChange={() => dispatch(toggleEmaPriceLineVisibility())}
              />
              Price Line Visible
            </label>
          </div>
        </div>

        <button onClick={() => dispatch(resetEmaSettings())}>Reset</button>
      </div>
    </IndicatorSettingWrapper>
  );
};

export default EMAComponent;
