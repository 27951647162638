export  const calculateIchimokuCloud = (
    data,
    tenkanPeriod = 9,
    kijunPeriod = 26,
    senkouSpanBPeriod = 52,
    displacement = 26
  ) => {
    const ichimokuCloudValues = [];
  
    for (let i = 0; i < data.length; i++) {
      if (
        i >= tenkanPeriod - 1 &&
        i >= kijunPeriod - 1 &&
        i >= senkouSpanBPeriod - 1
      ) {
        const tenkanSenHigh = Math.max(
          ...data.slice(i - tenkanPeriod + 1, i + 1).map((item) => item.high)
        );
        const tenkanSenLow = Math.min(
          ...data.slice(i - tenkanPeriod + 1, i + 1).map((item) => item.low)
        );
        const tenkanSen = (tenkanSenHigh + tenkanSenLow) / 2;
  
        const kijunSenHigh = Math.max(
          ...data.slice(i - kijunPeriod + 1, i + 1).map((item) => item.high)
        );
        const kijunSenLow = Math.min(
          ...data.slice(i - kijunPeriod + 1, i + 1).map((item) => item.low)
        );
        const kijunSen = (kijunSenHigh + kijunSenLow) / 2;
  
        const senkouSpanA = (tenkanSen + kijunSen) / 2;
  
        const senkouSpanBHigh = Math.max(
          ...data.slice(i - senkouSpanBPeriod + 1, i + 1).map((item) => item.high)
        );
        const senkouSpanBLow = Math.min(
          ...data.slice(i - senkouSpanBPeriod + 1, i + 1).map((item) => item.low)
        );
        const senkouSpanB = (senkouSpanBHigh + senkouSpanBLow) / 2;
  
        const chikouSpan = data[i - displacement].close;
  
        ichimokuCloudValues.push({
          time: data[i].time,
          tenkanSen,
          kijunSen,
          senkouSpanA,
          senkouSpanB,
          chikouSpan,
        });
      }
    }
  
    return ichimokuCloudValues;
  };