import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "CMO",
    fast: 3,
    slow: 10,
    styles: {
      lineVisible: true,
      priceLineVisible: false,
      color: "orange",
      lineStyle: 0,
      lineWidth: 1,
      lineType: 0,
      pointMarkersVisible: false,
      pointMarkersRadius: undefined,
      lastPriceAnimation: 1,
      lastValueVisible: true,
    }
  }

  const cmoSlice = createSlice({
    name: "CMO",
    initialState,
    reducers: {
        setFast: (state, action) => {
            const newFast = action.payload
            state.fast = newFast
        },
        setSlow: (state, action) => {
            const newSlow = action.payload
            state.slow = newSlow
        },
        setColor: (state, action) => {
            const newColor = action.payload
            state.styles.color = newColor
        },
        setThickness: (state, action) => {
            const newThickness = action.payload
            state.styles.lineWidth = newThickness
        }

    }
  })

  export const {setColor, setFast, setSlow, setThickness} = cmoSlice.actions

  export default cmoSlice.reducer