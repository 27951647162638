import React, { useEffect, useState } from "react";
import styles from "../closedPositions/ExitPosition.module.css";
import { fixedNumber, mapTypeToString } from "../../../helpers";
// import useApiPost from "../../../hooks/postApiHook";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../../socket";
import { formatTimeToSeconds } from "../../../helpers";
// import { updateBalance } from "../../../store/userSlice";
import ModifyPositionForm from "../../trade/modifyPosition/ModifyPositionForm";
import {
  fetchUserClosedPositions,
  fetchUserDeals,
  fetchUserOrders,
  fetchUserPositions,
} from "../../../store/onLoadSlice";
import useLivePriceSocket from "../../../socket/livePriceSocket";
// import toast from "react-hot-toast";
import PPC from "../../trade/partialPositionClose/PPC";
import { BiEditAlt } from "react-icons/bi";
import { FaBackspace } from "react-icons/fa";
import { fetchInitialWalletInfo } from "../../../store/userWalletAction";

const PositionBox = () => {
  const { abcData } = useLivePriceSocket();
  const userId = useSelector((state) => state.user.id);
  // const { postData, data: res } = useApiPost();
  const dispatch = useDispatch();
  const { runningPositions: rp, pendingPositions: pp } = useSelector(
    (state) => state.onLoad
  );
  const [runningPositions, setRunningPositions] = useState(rp);
  const [pendingPositions, setPendingPositions] = useState(pp);
  const [id, setId] = useState("");

  const [showSubContainerIndex, setShowSubContainerIndex] = useState([]);
  const [showModifyPositionFormIndex, setShowModifyPositionFormIndex] =
    useState(null);
  const [modifyPositionData, setmodifyPositionData] = useState({
    positionId: null,
    symbol: null,
    type: null,
    ticket: null,
    price: null,
    stopLoss: null,
    takeProfit: null,
  });
  const [activeTab, setActiveTab] = useState("running");
  const [showPPC, setShowPPC] = useState(false);
  const [item, setItem] = useState("");

  const handleOnClick = (index) => {
    setShowSubContainerIndex(index === showSubContainerIndex ? null : index);
  };
  const handleModifyPosition = (index, item) => {
    setmodifyPositionData({
      positionId: item._id,
      symbol: item.symbol,
      type: mapTypeToString(item.type),
      ticket: item.ticket,
      price: item.price,
      stopLoss: item.stopLoss,
      takeProfit: item.takeProfit,
    });
    setShowModifyPositionFormIndex(index);
  };

  const handleExit = async (item) => {
    setItem(item);
    setShowPPC(true);

    setShowSubContainerIndex([]);
    setId(item._id);
  };

  useEffect(() => {
    setRunningPositions(rp);
    setPendingPositions(pp);
  }, [rp, pp]);

  useEffect(() => {
    if (userId) {
      socket.emit("userpositions", JSON.stringify({ userId: userId }));
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      socket.emit("userpositions", JSON.stringify({ userId: userId }));
    }
    const handlePosProfit = (updatedData) => {
      setRunningPositions((prevData) => {
        const newData = prevData?.map((data) => {
          if (data._id === updatedData.positionId) {
            return {
              ...data,
              profit:
                updatedData.status === 1
                  ? updatedData.profit?.toFixed(2)
                  : prevData.profit,
              currentPrice: fixedNumber(updatedData.currentPrice),
            };
          }
          return data;
        });

        return newData;
      });
    };

    socket.on("closePosition", (data) => {
      dispatch(fetchUserClosedPositions());
      dispatch(fetchUserDeals());
      dispatch(fetchUserOrders());
      dispatch(fetchUserPositions());
      dispatch(fetchInitialWalletInfo());
      // console.log("cp", data);
    });

    socket.on("posProfit", handlePosProfit);
    return () => {
      socket.off("posProfit", handlePosProfit);
      socket.off("closePosition");
    };
  }, [dispatch, userId]);

  useEffect(() => {
    setPendingPositions((prevData) => {
      const newData = prevData?.map((data) => {
        if (data.symbol === abcData?.newMessage.symbol) {
          return {
            ...data,
            currentPrice: [1, 2, 4, 6].includes(data.type)
              ? fixedNumber(abcData?.newMessage.bid)
              : fixedNumber(abcData?.newMessage.ask),
          };
        }
        return data;
      });

      return newData;
    });
  }, [abcData]);

  return (
    <div style={{ position: "relative" }}>
      <div>
        <div className={styles.tabContainer}>
          <div className={styles.tabs}>
            <span
              className={activeTab === "running" ? styles.tabClicked : ""}
              onClick={() => setActiveTab("running")}
            >
              Running
            </span>
            <span
              className={activeTab === "pending" ? styles.tabClicked : ""}
              onClick={() => setActiveTab("pending")}
            >
              Pending
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.scroll}>
          {activeTab === "running" &&
          runningPositions &&
          runningPositions.length > 0
            ? runningPositions.map((item, index) => (
                <div className={styles.container} key={item.index}>
                  <div
                    className={styles.main}
                    onClick={() => handleOnClick(index)}
                    key={item.index}
                  >
                    <div className={styles.flexBox}>
                      <div className={styles.gap}>
                        <span>{item.symbol}</span>
                        <span
                          className={
                            item.type === 1 ? styles.rising : styles.falling
                          }
                        >
                          {mapTypeToString(item.type)},
                        </span>
                        <span
                          className={
                            item.type === 1 ? styles.rising : styles.falling
                          }
                        >
                          {item.volume}
                        </span>
                      </div>
                      <div
                        className={
                          item.profit > 0 ? styles.rising : styles.falling
                        }
                      >
                        {item.status === 1
                          ? Number(item?.profit).toFixed(2)
                          : "Placed"}
                      </div>
                    </div>
                    <div className={styles.flexBox}>
                      <div className={styles.gap}>
                        <span>{item.price}</span>
                        <span>---&gt;</span>
                        <span>{item.currentPrice || item.lastPrice}</span>
                      </div>
                    </div>
                  </div>
                  {showSubContainerIndex === index && (
                    <div className={styles.subContainer}>
                      <div className={styles.flexBox}>
                        <div className={styles.gap}>
                          <span>{formatTimeToSeconds(item.createdAt)}</span>
                        </div>
                      </div>
                      <div className={styles.flexBox}>
                        <div className={styles.gap}>
                          <span>SL:</span>
                          <span>{item.stopLoss}</span>
                        </div>
                        <div className={styles.gap}>
                          <span>TP:</span>
                          <span>{item.takeProfit}</span>
                        </div>
                      </div>
                      <div className={styles.flexBox}>
                        <div className={styles.gap}>
                          <span>Swap:</span>
                          <span>{item?.swap?.toFixed(2)}</span>
                        </div>
                        <div className={styles.gap}>
                          <span>Ticket:</span>
                          <span>#{item.ticket}</span>
                        </div>
                      </div>
                      <div className={styles.buttonCenter}>
                        <div className={styles.iconWithText}>
                          <FaBackspace color="red" size={18} />
                          <button
                            className={styles.closePosition}
                            onClick={() => handleExit(item)}
                          >
                            Close
                          </button>
                        </div>

                        <div className={styles.iconWithText}>
                          <button
                            className={`${styles.closePosition} ${styles.modifyPosition}`}
                            onClick={() => handleModifyPosition(index, item)}
                          >
                            Modify
                          </button>
                          <BiEditAlt color="green" size={18} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))
            : null}
          {activeTab === "pending" &&
          pendingPositions &&
          pendingPositions.length > 0
            ? pendingPositions.map((item, index) => (
                <div className={styles.container} key={item.index}>
                  <div
                    className={styles.main}
                    onClick={() => handleOnClick(index)}
                  >
                    <div className={styles.flexBox}>
                      <div className={styles.gap}>
                        <span>{item.symbol}</span>
                        <span
                          className={
                            item.type === 1 ? styles.rising : styles.falling
                          }
                        >
                          {mapTypeToString(item.type)},
                        </span>
                        <span
                          className={
                            item.type === 1 ? styles.rising : styles.falling
                          }
                        >
                          {item.volume}
                        </span>
                      </div>
                      <div
                        className={
                          item.profit > 0 ? styles.rising : styles.falling
                        }
                      >
                        {item.status === 1
                          ? Number(item?.profit).toFixed(2)
                          : "Placed"}
                      </div>
                    </div>
                    <div className={styles.flexBox}>
                      <div className={styles.gap}>
                        <span>{item.price}</span>
                        <span>---&gt;</span>
                        <span>{item.currentPrice || item.lastPrice}</span>
                      </div>
                    </div>
                  </div>
                  {showSubContainerIndex === index && (
                    <div className={styles.subContainer}>
                      <div className={styles.flexBox}>
                        <div className={styles.gap}>
                          <span>{formatTimeToSeconds(item.createdAt)}</span>
                        </div>
                      </div>
                      <div className={styles.flexBox}>
                        <div className={styles.gap}>
                          <span>SL:</span>
                          <span>{item.stopLoss}</span>
                        </div>
                        <div className={styles.gap}>
                          <span>TP:</span>
                          <span>{item.takeProfit}</span>
                        </div>
                      </div>
                      <div className={styles.flexBox}>
                        <div className={styles.gap}>
                          <span>Swap:</span>
                          <span>{item.swap}</span>
                        </div>
                        <div className={styles.gap}>
                          <span>Ticket:</span>
                          <span>#{item.ticket}</span>
                        </div>
                      </div>
                      <div className={styles.buttonCenter}>
                        <button
                          className={styles.closePosition}
                          onClick={() => handleExit(item)}
                        >
                          Close
                        </button>

                        <button
                          className={`${styles.closePosition} ${styles.modifyPosition}`}
                          onClick={() => handleModifyPosition(index, item)}
                        >
                          Modify
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))
            : null}
        </div>

        {showModifyPositionFormIndex !== null && (
          <div className={styles.modifyForm}>
            <div className={styles.centerDiv}>
              {showModifyPositionFormIndex !== null && (
                <ModifyPositionForm
                  posData={modifyPositionData}
                  closeHandler={() => setShowModifyPositionFormIndex(null)}
                />
              )}
            </div>
          </div>
        )}

        {showPPC && item && (
          <div className={styles.modifyForm}>
            <div className={styles.centerDiv}>
              <PPC data={item} onClose={() => setShowPPC(false)} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PositionBox;
