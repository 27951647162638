import React, { useEffect, useState } from "react";
import { fixedNumber } from "../../../helpers";
import socket from "../../../socket";
import { MdArrowDropUp } from "react-icons/md";

import { MdArrowDropDown } from "react-icons/md";

const WatchListItem = ({
  currency: currencyItem,
  index,
  handleContextMenu,
  handleMobOptions,
  showMobOpt,
  setSelectedItem,
  handleSpecification,
  handleNewChart,
  handleHide,
  handleNewOrder,
}) => {
  const [currency, setCurrency] = useState(currencyItem);

  useEffect(() => {
    const handleNewMessage = (newMessageData) => {
      if (newMessageData?.newMessage.symbol === currency.symbol) {
        const updatedCurrency = {
          ...currency,
          bid: newMessageData.newMessage.bid,
          ask: newMessageData.newMessage.ask,
          changePoints: newMessageData.newMessage.changePoints,
          changePercent: newMessageData.newMessage.changePercent,
        };
        setCurrency(updatedCurrency);
      }
    };

    socket.on("newMessage", handleNewMessage);
    return () => socket.off("newMessage", handleNewMessage);
  }, [currency]);

  return (
    <div key={currency.symbol}>
      <div
        className="grid grid-cols-4 p-2 cursor-pointer hover:bg-slate-100  text-sm md:text-xs md:border-b border-gray-100 "
        onContextMenu={(e) => handleContextMenu(e, currency)}
        onMouseOver={() => setSelectedItem(currencyItem)}
        onClick={() => handleMobOptions(index)}
      >
        <span
        className="font-semibold text-zinc-800 ml-[-10px]"
          // className={
          //   currency.askChange === "rising" ? "text-green-700" : "text-red-500"
          // }
        >
          <div className="flex items-center">
            {currency.askChange === "rising" ? (
              <MdArrowDropUp size={24} className="text-green-700" />
            ) : (
              <MdArrowDropDown size={24} className="text-red-500" />
            )}
            {currency.symbol}
          </div>
        </span>
        <span
          className={
            currency.changePercent >= 0 ? "text-green-700" : "text-red-500"
          }
        >
          {currency?.changePercent?.toFixed(2)}%
        </span>
        <span
          className={
            currency.bidChange === "rising" ? "text-green-700" : "text-red-500"
          }
        >
          {fixedNumber(currency.bid)}
        </span>
        <span
          className={
            currency.askChange === "rising" ? "text-green-700" : "text-red-500"
          }
        >
          {fixedNumber(currency.ask)}
        </span>
      </div>
      {showMobOpt && (
        <div className="flex gap-2 p-2 justify-center bg-gray-100 rounded-md">
          <button
            className="text-blue-600 text-xs"
            onClick={handleSpecification}
          >
            Specifications
          </button>
          <button className="text-blue-600 text-xs" onClick={handleNewChart}>
            View Chart
          </button>
          <button className="text-blue-600 text-xs" onClick={handleHide}>
            Remove
          </button>
          <button className="text-blue-600 text-xs" onClick={handleNewOrder}>
            New Order
          </button>
        </div>
      )}
    </div>
  );
};

export default WatchListItem;
