import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setTimeFrame,
  setPeriod,
  setUpColor,
  setUpLineStyle,
  setUpLineWidth,
  setDownColor,
  setDownLineStyle,
  setDownLineWidth,
  resetAroon,
} from "../../../store/indicators/aroonSlice";
import styles from '../index.module.css';
import IndicatorSettingWrapper from "../../wrappers/IndicatorSettingWrapper";
import { changeTime, fetchChartData } from "../../../store/chartSlice";

const AroonComponent = ({onClose}) => {
  const dispatch = useDispatch();
  const aroonState = useSelector((state) => state.aroon);
  const [activeTab, setActiveTab] = useState("general");

  const handleInputChange = (e, actionCreator) => {
    const value =
      e.target.type === "number"
        ? Math.max(Number(e.target.min), Number(e.target.value))
        : e.target.value;
    dispatch(actionCreator(value));
    if (actionCreator === setTimeFrame) {
      dispatch(changeTime(value));
      dispatch(fetchChartData());
    }
  };

  return (
    <IndicatorSettingWrapper onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.tabs}>
          <div
            className={`${styles.tab} ${
              activeTab === "general" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("general")}
          >
            General Settings
          </div>
          <div
            className={`${styles.tab} ${
              activeTab === "style" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("style")}
          >
            Style Settings
          </div>
        </div>

        <div
          className={`${styles.tabContent} ${
            activeTab === "general" ? styles.active : ""
          }`}
        >
          <div className={styles.setting}>
            <label>Time Frame:</label>
            <select
              value={aroonState.timeFrame}
              onChange={(e) => handleInputChange(e, setTimeFrame)}
            >
              <option value={1}>1 minute</option>
              <option value={5}>5 minutes</option>
              <option value={15}>15 minutes</option>
              <option value={30}>30 minutes</option>
              <option value={60}>1 hour</option>
              <option value={240}>4 hours</option>
              <option value={1440}>1 day</option>
            </select>
          </div>

          <div className={styles.setting}>
            <label>Period:</label>
            <input
              min="1"
              type="number"
              value={aroonState.period}
              onChange={(e) => handleInputChange(e, setPeriod)}
            />
          </div>
        </div>

        <div
          className={`${styles.tabContent} ${
            activeTab === "style" ? styles.active : ""
          }`}
        >
        <div className={styles["flex-row"]}>
        <div className={styles.setting}>
            <label>Line1 Color:</label>
            <input
              type="color"
              value={aroonState.up.styles.color}
              onChange={(e) => handleInputChange(e, setUpColor)}
            />
            
          </div>
          <div className={styles.setting}>
            <label>Line2 Color:</label>
            <input
              type="color"
              value={aroonState.down.styles.color}
              onChange={(e) => handleInputChange(e, setDownColor)}
            />
          </div>
        </div>

        <div className={styles["flex-row"]}>
        <div className={styles.setting}>
            <label> Line1 Style:</label>
            <select
              value={aroonState.up.styles.lineStyle}
              onChange={(e) => handleInputChange(e, setUpLineStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
              <option value={3}>Large Dashed</option>
              <option value={4}>Sparse Dotted</option>
            </select>
          </div>

          <div className={styles.setting}>
            <label> Line2 Style:</label>
            <select
              value={aroonState.down.styles.lineStyle}
              onChange={(e) => handleInputChange(e, setDownLineStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
              <option value={3}>Large Dashed</option>
              <option value={4}>Sparse Dotted</option>
            </select>
          </div>
          </div>


          <div className={styles["flex-row"]}>
          <div className={styles.setting}>
            <label> Line1 Width:</label>
            <input
              type="number"
              value={aroonState.up.styles.lineWidth}
              onChange={(e) => handleInputChange(e, setUpLineWidth)}
            />
          </div>
          <div className={styles.setting}>
            <label> Line2 Width:</label>
            <input
              type="number"
              value={aroonState.down.styles.lineWidth}
              onChange={(e) => handleInputChange(e, setDownLineWidth)}
            />
          </div>
 </div>
         
          {/* <div className={styles.setting}>
            <label> Line1 Width:</label>
            <input
              type="number"
              value={aroonState.up.styles.lineWidth}
              onChange={(e) => handleInputChange(e, setUpLineWidth)}
            />
          </div>
          <div className={styles.setting}>
            <label>Line2 Color:</label>
            <input
              type="color"
              value={aroonState.down.styles.color}
              onChange={(e) => handleInputChange(e, setDownColor)}
            />
          </div>
         
          <div className={styles.setting}>
            <label> Line2 Width:</label>
            <input
              type="number"
              value={aroonState.down.styles.lineWidth}
              onChange={(e) => handleInputChange(e, setDownLineWidth)}
            />
          </div> */}
        </div>
        <button onClick={() => dispatch(resetAroon())}>Reset</button>
      </div>
    </IndicatorSettingWrapper>
  );
};

export default AroonComponent;
