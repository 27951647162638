import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    watchlistHeight: 50,
    watchlistWidth: 30,
    chartHeight: 60,
    chartWidth: 60,
    detailHeight: 20,
    detailWidth: 100,
}


const resizableSlice = createSlice({
    name: "resizable",
    initialState,
    reducers: {
        setWatchlistHeight: (state, action) => { state.watchlistHeight = action.payload; },
        setWatchlistWidth: (state, action) => { state.watchlistWidth = action.payload; },
        setChartHeight: (state, action) => { state.chartHeight = action.payload; },
        setChartWidth: (state, action) => { state.chartWidth = action.payload; },
        setDetailHeight: (state, action) => { state.detailHeight = action.payload; },
        setDetailWidth: (state, action) => { state.detailWidth = action.payload; },
        reset: () => initialState
    }
})


export const { setWatchlistHeight, setWatchlistWidth, setChartHeight, setChartWidth, setDetailHeight, setDetailWidth, reset } = resizableSlice.actions

export default resizableSlice.reducer