import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setTimeFrame,
    setColor,
    setLineStyle,
    setLineWidth,
    resetBop
} from '../../../store/indicators/bopSlice';
import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../../components/wrappers/IndicatorSettingWrapper';
import { changeTime, fetchChartData } from '../../../store/chartSlice';

const BOPComponent = ({onClose}) => {
    const dispatch = useDispatch();
    const bopState = useSelector(state => state.bop);
    const [activeTab, setActiveTab] = useState('general');

    const handleInputChange = (e, actionCreator) => {
        const value = e.target.type === 'number' ? Math.max(Number(e.target.min), Number(e.target.value)) : e.target.value;
        dispatch(actionCreator(value));
        if (actionCreator === setTimeFrame) {
          dispatch(changeTime(value));
          dispatch(fetchChartData());
        }
      };

    return (
        <IndicatorSettingWrapper onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <div
                        className={`${styles.tab} ${activeTab === 'general' ? styles.active : ''}`}
                        onClick={() => setActiveTab('general')}
                    >
                        General Settings
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === 'style' ? styles.active : ''}`}
                        onClick={() => setActiveTab('style')}
                    >
                        Style Settings
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>
                <div className={styles.setting}>
            <label>Time Frame:</label>
            <select
              value={bopState.timeFrame}
              onChange={(e) => handleInputChange(e, setTimeFrame)}
            >
              <option value={1}>1 minute</option>
              <option value={5}>5 minutes</option>
              <option value={15}>15 minutes</option>
              <option value={30}>30 minutes</option>
              <option value={60}>1 hour</option>
              <option value={240}>4 hours</option>
              <option value={1440}>1 day</option>
            </select>
          </div>
                  
                </div>

                 <div className={`${styles.tabContent} ${activeTab === 'style' ? styles.active : ''}`}>
                    <div className={styles.setting}>
                        <label>Color:</label>
                        <input
                            type="color"
                            value={bopState.styles.color}
                            onChange={(e) => handleInputChange(e, setColor)}
                        />
                    </div>
                    <div className={styles.setting}>
                        <label>Line Style:</label>
                        <select
                            value={bopState.styles.lineStyle}
                            onChange={(e) => handleInputChange(e, setLineStyle)}
                        >
                            <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
              <option value={3}>Large Dashed</option>
              <option value={4}>Sparse Dotted</option>
                     </select>
                    </div>
                    <div className={styles.setting}>
                        <label>Line Width:</label>
                        <input
                            type="number"
                            value={bopState.styles.lineWidth}
                            onChange={(e) => handleInputChange(e, setLineWidth)}
                        />
                    </div>
                </div> 
                <button onClick={() => dispatch(resetBop())}>Reset</button>

            </div>
        </IndicatorSettingWrapper>
    );
};

export default BOPComponent;
