import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setTimeFrame,
    setPeriod,
    setColor,
    setThickness,
    resetCci
} from '../../../store/indicators/cciSlice';
import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../wrappers/IndicatorSettingWrapper';
import { changeTime, fetchChartData } from '../../../store/chartSlice';

const CCIComponent = ({onClose}) => {
    const dispatch = useDispatch();
    const cciState = useSelector(state => state.cci);
    const [activeTab, setActiveTab] = useState('general');

    const handleInputChange = (e, actionCreator) => {
        const value = e.target.type === 'number' ? Math.max(Number(e.target.min), Number(e.target.value)) : e.target.value;
        dispatch(actionCreator(value));
        if (actionCreator === setTimeFrame) {
          dispatch(changeTime(value));
          dispatch(fetchChartData());
        }
      };

    return (
        <IndicatorSettingWrapper onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <div
                        className={`${styles.tab} ${activeTab === 'general' ? styles.active : ''}`}
                        onClick={() => setActiveTab('general')}
                    >
                        General Settings
                    </div>
                    {/* Add more tabs as needed */}
                    <div
                        className={`${styles.tab} ${activeTab === 'styles' ? styles.active : ''}`}
                        onClick={() => setActiveTab('styles')}
                    >
                        Styles Settings
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>
                <div className={styles.setting}>
            <label>Time Frame:</label>
            <select
              value={cciState.timeFrame}
              onChange={(e) => handleInputChange(e, setTimeFrame)}
            >
              <option value={1}>1 minute</option>
              <option value={5}>5 minutes</option>
              <option value={15}>15 minutes</option>
              <option value={30}>30 minutes</option>
              <option value={60}>1 hour</option>
              <option value={240}>4 hours</option>
              <option value={1440}>1 day</option>N
            </select>
          </div>
                    <div className={styles.setting}>
                        <label>Period:</label>
                        <input
                            type="number"
                            value={cciState.period}
                            onChange={(e) => handleInputChange(e, setPeriod)}
                        />
                    </div>
                </div>

                {/* Add more tab contents as needed */}
                <div className={`${styles.tabContent} ${activeTab === 'styles' ? styles.active : ''}`}>
                    <div className={styles.setting}>
                        <label>Color:</label>
                        <input
                            type="color"
                            value={cciState.styles.color}
                            onChange={(e) => handleInputChange(e, setColor)}
                        />
                    </div>
                    <div className={styles.setting}>
                        <label>Line Thickness:</label>
                        <input
                            type="number"
                            value={cciState.styles.lineWidth}
                            onChange={(e) => handleInputChange(e, setThickness)}
                        />
                    </div>
                </div>
                <button onClick={() => dispatch(resetCci())}>Reset</button>

            </div>
        </IndicatorSettingWrapper>
    );
};

export default CCIComponent;
