import axios from "axios";
import axiosRetry from "axios-retry";
import { getAuthToken } from "../helpers/index";

export const apiUrl = "https://app.betatrader11.com/";
// export const apiUrl = "http://93.127.172.153:8080/";

const apiClient = axios.create({
  baseURL: apiUrl,
  // timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosRetry(apiClient, {
  retries: 1,
  retryDelay: axiosRetry.exponentialDelay,
  shouldResetTimeout: true,
});

apiClient.interceptors.request.use(async (config) => {
  config.headers["Accept"] = "application/json";
  return config;
});

apiClient.interceptors.request.use(
  async (config) => {
    console.log("Request interceptor config:", config);

    const token = getAuthToken("AUTH-TOKEN");
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Authorization"] = `${token}`;
    }

    return config;
  },
  (error) => {
    console.log("REQUEST ERROR", error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.request.use(
  (config) => {
    console.log(
      `[Axios Request] ${config.method.toUpperCase()} ${config.url} `,
      config.data
    );
    return config;
  },
  (error) => {
    console.error("[Axios Request Error]", error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    console.log(
      `[Axios Response] ${response.config.method.toUpperCase()} ${
        response.config.url
      }`,
      response.data
    );
    return response;
  },
  (error) => {
    console.error("[Axios Response Error]", error);
    return Promise.reject(error);
  }
);

export default apiClient;
