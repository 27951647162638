import React from "react";
import { IoMdClose } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import { getSpecificationSymbol } from "../../../api/queryFunctions";

const Overlay = ({ closeHandler, symbolName }) => {
  const { data } = useQuery({
    queryKey: ["getSymbolSpecification"],
    queryFn: () => getSpecificationSymbol(symbolName),
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50 overflow-auto">
      <div className="bg-white shadow-md rounded-md w-5/6 sm:w-4/5 md:w-3/5 lg:w-2/5 max-h-[80%] p-6 overflow-y-auto">
        <div className="flex justify-between items-center pb-5 font-bold text-lg">
          <div>Symbol Specification</div>
          <div className="cursor-pointer" onClick={closeHandler}>
            <IoMdClose
              size={24}
              className="hover:scale-110 transition-transform duration-200"
            />
          </div>
        </div>
        {data && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Symbol:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.symbol}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Description:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.description}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Digits:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.digits}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">MinVolume:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.minVolume}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">MaxVolume:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.maxVolume}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">StepVolume:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.stepVolume}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">ContractSize:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.contractSize}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Spread:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.spread}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Source:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.source}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">StopsLevel:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.stopsLevel}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">MarginCurrency:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.marginCurrency}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">ProfitCurrency:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.profitCurrency}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Calculation:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.calculation}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">ChartMode:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.chartMode}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Trade:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.trade}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Gtc:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.gtc}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Fillings:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.fillings}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Order:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.order}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">Expiration:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.expiration}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">SwapType:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.swapType}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">SwapShort:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.swapShort}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="font-bold text-gray-700">SwapLong:</span>
              <span className="font-medium text-gray-600">
                {data.data.message?.swapLong}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Overlay;
