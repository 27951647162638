import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setTimeFrame,
  setFast,
  setSlow,
  setSignal,
  setMacdLineColor,
  setMacdLineThickness,
  setSignalLineColor,
  setSignalLineThickness,
  setSource,
  setMacdLineStyle,
  setSignalLineStyle,
  resetMacd,
} from "../../../store/indicators/macdSlice";
import styles from '../index.module.css';
import IndicatorSettingWrapper from "../../wrappers/IndicatorSettingWrapper";
import { changeTime, fetchChartData } from "../../../store/chartSlice";

const MACDComponent = ({ onClose }) => {
  const dispatch = useDispatch();
  const macdState = useSelector((state) => state.macd);
  const [activeTab, setActiveTab] = useState("general");

  const handleInputChange = (e, actionCreator) => {
    const value =
      e.target.type === "number"
        ? Math.max(Number(e.target.min), Number(e.target.value))
        : e.target.value;
    dispatch(actionCreator(value));
    if (actionCreator === setTimeFrame) {
      dispatch(changeTime(value));
      dispatch(fetchChartData());
    }
  };

  return (
    <IndicatorSettingWrapper onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.tabs}>
          <div
            className={`${styles.tab} ${
              activeTab === "general" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("general")}
          >
            General Settings
          </div>
          <div
            className={`${styles.tab} ${
              activeTab === "style" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("style")}
          >
            Style Settings
          </div>
        </div>

        <div
          className={`${styles.tabContent} ${
            activeTab === "general" ? styles.active : ""
          }`}
        >
          <div className={styles["flex-row"]}>
            <div className={styles.setting}>
              <label>Time Frame:</label>
              <select
                value={macdState.timeFrame}
                onChange={(e) => handleInputChange(e, setTimeFrame)}
              >
                <option value={1}>1 minute</option>
                <option value={5}>5 minutes</option>
                <option value={15}>15 minutes</option>
                <option value={30}>30 minutes</option>
                <option value={60}>1 hour</option>
                <option value={240}>4 hours</option>
                <option value={1440}>1 day</option>N
              </select>
            </div>

            <div className={styles.setting}>
              <label>Source:</label>
              <select
                value={macdState.source}
                onChange={(e) => handleInputChange(e, setSource)}
              >
                <option value="open">Open</option>
                <option value="close">Close</option>
                <option value="high">High</option>
                <option value="low">Low</option>
              </select>
            </div>
          </div>

          <div className={styles["flex-row"]}>
            <div className={styles.setting}>
              <label>Fast Period:</label>
              <input
                type="number"
                value={macdState.fast}
                onChange={(e) => handleInputChange(e, setFast)}
              />
            </div>
            <div className={styles.setting}>
              <label>Slow Period:</label>
              <input
                type="number"
                value={macdState.slow}
                onChange={(e) => handleInputChange(e, setSlow)}
              />
            </div>
          </div>

          <div className={styles.setting}>
            <label>Signal Period:</label>
            <input
              type="number"
              value={macdState.signal}
              onChange={(e) => handleInputChange(e, setSignal)}
            />
          </div>
        </div>

        <div
          className={`${styles.tabContent} ${
            activeTab === "style" ? styles.active : ""
          }`}
        >
          <div className={styles.setting}>
            <label>MACD Line Color:</label>
            <input
              type="color"
              value={macdState.styles.macdLine.styles.color}
              onChange={(e) => handleInputChange(e, setMacdLineColor)}
            />
          </div>
          <div className={styles["flex-row"]}>
            <div className={styles.setting}>
              <label>MACD Line Thickness:</label>
              <input
                type="number"
                value={macdState.styles.macdLine.styles.lineWidth}
                onChange={(e) => handleInputChange(e, setMacdLineThickness)}
              />
            </div>

            <div className={styles.setting}>
              <label>MACD Line Style:</label>
              <select
                value={macdState.styles.macdLine.styles.lineStyle}
                onChange={(e) => handleInputChange(e, setMacdLineStyle)}
              >
                <option value={0}>Solid</option>
                <option value={1}>Dotted</option>
                <option value={2}>Dashed</option>
                <option value={3}>Large Dashed</option>
                <option value={4}>Sparse Dotted</option>
              </select>
            </div>
          </div>

          <div className={styles.setting}>
            <label>Signal Line Color:</label>
            <input
              type="color"
              value={macdState.styles.signalLine.styles.color}
              onChange={(e) => handleInputChange(e, setSignalLineColor)}
            />
          </div>

          <div className={styles["flex-row"]}>
            <div className={styles.setting}>
              <label>Signal Line Thickness:</label>
              <input
                type="number"
                value={macdState.styles.signalLine.styles.lineWidth}
                onChange={(e) => handleInputChange(e, setSignalLineThickness)}
              />
            </div>
            <div className={styles.setting}>
              <label>Line Style:</label>
              <select
                value={macdState.styles.signalLine.styles.lineStyle}
                onChange={(e) => handleInputChange(e, setSignalLineStyle)}
              >
                <option value={0}>Solid</option>
                <option value={1}>Dotted</option>
                <option value={2}>Dashed</option>
                <option value={3}>Large Dashed</option>
                <option value={4}>Sparse Dotted</option>
              </select>
            </div>
          </div>
        </div>
        <button onClick={() => dispatch(resetMacd())}>Reset</button>
      </div>
    </IndicatorSettingWrapper>
  );
};

export default MACDComponent;
