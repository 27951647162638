import React, { useEffect, useRef, useState } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import { response } from "./data.js";

const ChartComponent = () => {
  const chartContainerRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [candlestickSeries, setCandlestickSeries] = useState(null);
  const [lineSeries, setLineSeries] = useState(null);
  const [klines, setKlines] = useState([]);
  const [xspan, setXspan] = useState(0);
  const [startPoint, setStartPoint] = useState(null);
  const [isUpdatingLine, setIsUpdatingLine] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartPoint, setDragStartPoint] = useState(null);
  const [dragStartLineData, setDragStartLineData] = useState([]);
  const [lastCrosshairPosition, setLastCrosshairPosition] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const hoverThreshold = 0.01;

  useEffect(() => {
    let newChart = null;
    let newCandlestickSeries = null;
    let newLineSeries = null;

    const initializeChart = async () => {
      const chartProperties = {
        timeScale: {
          timeVisible: true,
          secondsVisible: true,
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
      };

      newChart = createChart(chartContainerRef.current, chartProperties);
      setChart(newChart);

      newCandlestickSeries = newChart.addCandlestickSeries();
      setCandlestickSeries(newCandlestickSeries);

      newLineSeries = newChart.addLineSeries();
      setLineSeries(newLineSeries);

      // Load data and set series data
      const data = await loadData();
      newCandlestickSeries.setData(data.sort((a, b) => a.time - b.time));

      subscribeToEvents(newChart, newCandlestickSeries, newLineSeries);
    };

    initializeChart();

    return () => {
      // Clean up event listeners
      if (newChart) {
        newChart.remove();
      }
      if (chartContainerRef.current) {
        chartContainerRef.current.removeEventListener(
          "mousedown",
          handleMouseDown
        );
        chartContainerRef.current.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, []);

  const loadData = async () => {
    try {
      const data = response;
      const klinesData = data.data.map((item) => ({
        time: Math.floor(new Date(item.date).getTime() / 1000),
        open: item.open,
        high: item.high,
        low: item.low,
        close: item.close,
      }));

      setKlines(klinesData);

      const xspanData = data.data
        .map((item, index, array) =>
          index > 0 ? item.date - array[index - 1].date : 0
        )
        .reduce((a, b) => a + b);
      setXspan(xspanData);

      const prebars = [...new Array(100)].map((_, i) => ({
        time: klinesData[0].time - (i + 1) * xspanData,
      }));

      const postbars = [...new Array(100)].map((_, i) => ({
        time: klinesData[klinesData.length - 1].time + (i + 1) * xspanData,
      }));

      return [...prebars, ...klinesData, ...postbars];
    } catch (error) {
      console.error("Error fetching or parsing data:", error);
      return [];
    }
  };

  const subscribeToEvents = (chart, candlestickSeries, lineSeries) => {
    chart.subscribeClick((param) =>
      handleChartClick(param, candlestickSeries, lineSeries)
    );
    chart.subscribeCrosshairMove((param) =>
      handleCrosshairMove(param, candlestickSeries, lineSeries)
    );

    chartContainerRef.current.addEventListener("mousedown", handleMouseDown);
    chartContainerRef.current.addEventListener("mouseup", handleMouseUp);
  };

  const handleChartClick = (param, candlestickSeries, lineSeries) => {
    if (isUpdatingLine || isDragging) return;

    const xTs =
      param.time ||
      (klines.length > 0 ? klines[0].time + param.logical * xspan : 0);
    const yPrice = candlestickSeries.coordinateToPrice(param.point.y);

    isHovered
      ? startDrag(xTs, yPrice)
      : handleLineDrawing(xTs, yPrice, lineSeries);
  };

  const handleCrosshairMove = (param, candlestickSeries, lineSeries) => {
    if (isUpdatingLine) return;

    const xTs =
      param.time ||
      (klines.length > 0 ? klines[0].time + param.logical * xspan : 0);
    const yPrice = candlestickSeries.coordinateToPrice(param.point.y);

    setLastCrosshairPosition({ x: xTs, y: yPrice });

    startPoint
      ? updateLine(xTs, yPrice, lineSeries)
      : handleHoverEffect(xTs, yPrice, lineSeries);

    if (isDragging) {
      const deltaX = xTs - dragStartPoint.x;
      const deltaY = yPrice - dragStartPoint.y;

      const newLineData = dragStartLineData.map((point, i) =>
        selectedPoint !== null
          ? i === selectedPoint
            ? { time: point.time + deltaX, value: point.value + deltaY }
            : point
          : { time: point.time + deltaX, value: point.value + deltaY }
      );

      dragLine(newLineData, lineSeries);
    }
  };

  const handleMouseDown = () => {
    if (!lastCrosshairPosition) return;
    if (isHovered) {
      startDrag(lastCrosshairPosition.x, lastCrosshairPosition.y);
    }
  };

  const handleMouseUp = () => {
    endDrag();
  };

  const handleLineDrawing = (xTs, yPrice, lineSeries) => {
    if (!startPoint) {
      setStartPoint({ time: xTs, price: yPrice });
    } else {
      const newData = [
        { time: startPoint.time, value: startPoint.price },
        { time: xTs, value: yPrice },
      ];
      lineSeries.setData(newData);
      setStartPoint(null);
      setSelectedPoint(null);
    }
  };

  const handleHoverEffect = (xTs, yPrice, lineSeries) => {
    const lineData = lineSeries.data();
    if (!lineData.length) return;

    const hoverStatus = isLineHovered(xTs, yPrice, lineData[0], lineData[1]);
    if (hoverStatus && !isHovered) {
      startHover(lineSeries);
    }

    if (!hoverStatus && isHovered && !isDragging) {
      endHover(lineSeries);
    }
  };

  const startHover = (lineSeries) => {
    setIsHovered(true);
    lineSeries.applyOptions({ color: "orange" });
    chartContainerRef.current.style.cursor = "pointer";
    chart.applyOptions({ handleScroll: false, handleScale: false });
  };

  const endHover = (lineSeries) => {
    setIsHovered(false);
    lineSeries.applyOptions({ color: "dodgerblue" });
    chartContainerRef.current.style.cursor = "default";
    chart.applyOptions({ handleScroll: true, handleScale: true });
  };

  const startDrag = (xTs, yPrice) => {
    setIsDragging(true);
    setDragStartPoint({ x: xTs, y: yPrice });
    setDragStartLineData([...lineSeries.data()]);
  };

  const endDrag = () => {
    setIsDragging(false);
    setDragStartPoint(null);
    setDragStartLineData([]);
    setSelectedPoint(null);
  };

  const updateLine = (xTs, yPrice, lineSeries) => {
    setIsUpdatingLine(true);
    const newData = [
      { time: startPoint.time, value: startPoint.price },
      { time: xTs, value: yPrice },
    ];
    lineSeries.setData(newData);
    setSelectedPoint(null);
    setIsUpdatingLine(false);
  };

  const dragLine = (newCoords, lineSeries) => {
    setIsUpdatingLine(true);
    lineSeries.setData(newCoords);
    setIsUpdatingLine(false);
  };

  const isLineHovered = (xTs, yPrice, point1, point2) => {
    if (isDragging) return true;

    const isPoint1 =
      xTs === point1.time &&
      (Math.abs(yPrice - point1.value) * 100) / yPrice < hoverThreshold;
    if (isPoint1) {
      setSelectedPoint(0);
      return true;
    }

    const isPoint2 =
      xTs === point2.time &&
      (Math.abs(yPrice - point2.value) * 100) / yPrice < hoverThreshold;
    if (isPoint2) {
      setSelectedPoint(1);
      return true;
    }

    setSelectedPoint(null);
    const m = (point2.value - point1.value) / (point2.time - point1.time);
    const c = point1.value - m * point1.time;
    const estimatedY = m * xTs + c;

    return (Math.abs(yPrice - estimatedY) * 100) / yPrice < hoverThreshold;
  };

  return (
    <div
      id="tvchart"
      ref={chartContainerRef}
      style={{ position: "absolute", width: "95vw", height: "95vh" }}
    />
  );
};

export default ChartComponent;
