import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "DEMA",
    timeFrame: 1,
    period: 12,
    source: "close",
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "#8b9dc3",
        lineStyle: 0,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
};

const demaSlice = createSlice({
    name: "DEMA",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
        setSource: (state, action) => {
            state.source = action.payload;
        },
        setColor: (state, action) => {
            state.styles.color = action.payload;
        },
        setLineStyle: (state, action) => {
            state.styles.lineStyle = action.payload;

        },
        setThickness: (state, action) => {
            state.styles.lineWidth = action.payload;
        },
        resetDema: () => {
            return initialState
        }
    }
});

export const {
    setTimeFrame,
    setPeriod,
    setSource,
    setColor,
    setThickness,
    setLineStyle,
    resetDema
} = demaSlice.actions;

export default demaSlice.reducer;
