import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setFast, setSlow, setDownColor, setUpColor,setTimeFrame, resetAo
} from '../../../store/indicators/aoSlice';
import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../wrappers/IndicatorSettingWrapper';
import { changeTime, fetchChartData } from '../../../store/chartSlice';

const AOComponent = ({onClose}) => {
    const dispatch = useDispatch();
    const aoState = useSelector(state => state.ao);
    const [activeTab, setActiveTab] = useState('general');

    const handleInputChange = (e, actionCreator) => {
        const value = e.target.type === 'number' ? Math.max(Number(e.target.min), Number(e.target.value)) : e.target.value;
        dispatch(actionCreator(value));
        if (actionCreator === setTimeFrame) {
          dispatch(changeTime(value));
          dispatch(fetchChartData());
        }
      };

    return (
        <IndicatorSettingWrapper onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <div
                        className={`${styles.tab} ${activeTab === 'general' ? styles.active : ''}`}
                        onClick={() => setActiveTab('general')}
                    >
                        General Settings
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === 'style' ? styles.active : ''}`}
                        onClick={() => setActiveTab('style')}
                    >
                        Style Settings
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>

                <div className={styles.setting}>
            <label>Time Frame:</label>
            <select
              value={aoState.timeFrame}
              onChange={(e) => handleInputChange(e, setTimeFrame)}
            >
              <option value={1}>1 minute</option>
              <option value={5}>5 minutes</option>
              <option value={15}>15 minutes</option>
              <option value={30}>30 minutes</option>
              <option value={60}>1 hour</option>
              <option value={240}>4 hours</option>
              <option value={1440}>1 day</option>
            </select>
          </div>
                    <div className={styles.setting}>
                        <label>Fast Period:</label>
                        <input
                            type="number"
                            value={aoState.fast}
                            onChange={(e) => handleInputChange(e, setFast)}
                        />
                    </div>
                    <div className={styles.setting}>
                        <label>Slow Period:</label>
                        <input
                            type="number"
                            value={aoState.slow}
                            onChange={(e) => handleInputChange(e, setSlow)}
                        />
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'style' ? styles.active : ''}`}>
                    <div className={styles.setting}>
                        <label>Down Color:</label>
                        <input
                            type="color"
                            value={aoState.styles.downColor}
                            onChange={(e) => handleInputChange(e, setDownColor)}
                        />
                    </div>
                    <div className={styles.setting}>
                        <label>Up Color:</label>
                        <input
                            type="color"
                            value={aoState.styles.upColor}
                            onChange={(e) => handleInputChange(e, setUpColor)}
                        />
                    </div>
                </div>
                <button onClick={() => dispatch(resetAo())}>Reset</button>

            </div>
        </IndicatorSettingWrapper>
    );
};

export default AOComponent;
