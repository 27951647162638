import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "drawLine",
  drawLineActive: true,
  startPoint: null,
  endPoint: null,
  isUpdatingLine: false,
  isHovered: false,
  isDragging: false,
  lastCrossHairPosition: null,
  hoverThreshold: 0.01,
  dragStartLineData: null,
  styles: {
    color: "#87CEEB",
    lineStyle: 0, // solid:0, dotted: 1, dashed:2, LargeDashed:3, sparseDotted:4
    lineWidth: 1,
    lineType: 0, // simple:0, WithSteps:1, curved:2
    pointMarkersVisible: false,
    pointMarkersRadius: undefined,
    crosshairMarkerVisible: true,
    crosshairMarkerRadius: 4,
    lineVisible: true,
    priceLineVisible: false,
    lastPriceAnimation: 1,
  },
};

const lineSlice = createSlice({
  name: "line",
  initialState,
  reducers: {
    changeActive: (state) => {
      state.drawLineActive = !state.drawLineActive;
    },
    setStartingPoint: (state, action) => {
      state.startPoint = action.payload;
    },
    setEndPoint: (state, action) => {
      state.endPoint = action.payload;
    },
    changeIsUpdatingLine: (state, action) => {
      state.isUpdatingLine = action.payload || !state.isUpdatingLine;
    },
    changeIsHovered: (state, action) => {
      state.isHovered = action.payload;
    },
    setLastCrossHairPosition: (state, action) => {
      state.lastCrossHairPosition = action.payload;
    },
    setDragStartLineData: (state, action) => {
      state.dragStartLineData = action.payload;
    },
    resetLine: () => initialState,
  },
});

export const {
  changeActive,
  setStartingPoint,
  changeIsUpdatingLine,
  setEndPoint,
  changeIsHovered,
  setLastCrossHairPosition,
  setDragStartLineData,
  resetLine,
} = lineSlice.actions;

export default lineSlice.reducer;
