import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../api/apiClient";
import { add } from "lodash";

export const fetchChartData = createAsyncThunk(
  "chart/fetchChartData",
  async (_, { getState, rejectWithValue, signal }) => {
    try {
      const state = getState();
      const { symbol, time, to, from } = state.chart;

      // Create an AbortController instance
      const controller = new AbortController();
      const { signal: cancelSignal } = controller;

      // Listen for the abort event from Redux Toolkit's signal
      if (signal) {
        signal.addEventListener("abort", () => {
          controller.abort(); // Abort the fetch request
        });
      }

      const response = await apiClient.get(
        `get-data/${symbol}/${time}?from=${from}&to=${to}`,
        { signal: cancelSignal } // Pass the signal to the fetch request
      );

      const data = response.data;

      const sortedData = data.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      const ohlcData = sortedData.map((item) => ({
        time: Math.floor(new Date(item.date).getTime() / 1000),
        open: Number(item.open),
        high: Number(item.high),
        low: Number(item.low),
        close: Number(item.close),
      }));

      const volumeData = sortedData.map((item, index) => {
        const previousClose =
          index > 0 ? sortedData[index - 1].close : item.close;
        const color =
          item.close > previousClose
            ? "rgba(38, 166, 154, 0.3)"
            : "rgba(239, 83, 80, 0.3)";
        return {
          time: Math.floor(new Date(item.date).getTime() / 1000),
          value: item.volume,
          color: color,
        };
      });

      return { ohlcData: ohlcData, volumeData: volumeData };
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      }
      return rejectWithValue(error.response?.data ?? error.message);
    }
  }
);

export const fetchUpdateChartData = createAsyncThunk(
  "chart/fetchUpdateChartData",
  async (_, { getState, rejectWithValue, signal }) => {
    try {
      const state = getState();
      const {
        symbol,
        from,
        to,
        time,
        ohlcData: prevOhlcData,
        volumeData: prevVolumeData,
      } = state.chart;

      const response = await apiClient.get(
        `get-data/${symbol}/${time}?from=${from}&to=${to}`,
        { signal }
      );

      const newData = (await response.data) || {};
      const empty = (await response.data.length) <= 0;

      const formattedNewData = newData.map((item) => ({
        ...item,
        time: item.date ? Math.floor(new Date(item.date).getTime() / 1000) : 0,
      }));

      const formattedVolumeData = newData.map((item) => ({
        time: item.date ? Math.floor(new Date(item.date).getTime() / 1000) : 0,
        value: item.volume || 0,
      }));

      const combinedOhlcData = [...prevOhlcData, ...formattedNewData];

      const uniqueOhlcData = combinedOhlcData
        .sort((a, b) => a.time - b.time)
        .filter(
          (value, index, self) =>
            self.findIndex((v) => v.time === value.time) === index
        );

      const ohlcData = uniqueOhlcData.map((item) => ({
        time: item.time,
        open: Number(item.open),
        high: Number(item.high),
        low: Number(item.low),
        close: Number(item.close),
      }));

      const combinedVolumeData = [...prevVolumeData, ...formattedVolumeData];

      const sortedVolumeData = combinedVolumeData
        .sort((a, b) => a.time - b.time)
        .filter(
          (value, index, self) =>
            self.findIndex((v) => v.time === value.time) === index
        );

      const volumeData = sortedVolumeData.map((item, index) => {
        const previousClose =
          index > 0 ? ohlcData[index - 1].close : ohlcData[index].close || 0;
        const color =
          ohlcData[index].close > previousClose
            ? "rgba(38, 166, 154, 0.3)"
            : "rgba(239, 83, 80, 0.3)";
        return {
          time: item.time,
          value: item.value,
          color: color,
        };
      });

      return { ohlcData, volumeData, emptyResponse: empty };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Rest of your code remains unchanged...

const initialState = {
  symbol: localStorage.getItem("lastOpenedSymbol") || "AUDCAD",
  description:
    localStorage.getItem("lastOpenedDescription") ||
    "Australian Dollar vs Canadian Dollar",
  from: 0,
  to: 5,

  chartVisibleRange: { from: 0, to: 0 },
  withVolume: false,
  withTickVolume: false,
  type: "candlestick",
  tickVolume: false,
  showGrids: true,
  time: 1,
  ohlcData: [],
  volumeData: [],
  screenShot: false,
  fullWidth: false,
  refetch: false,
  tooltip: false,
  markers: [],
};

const chartSlice = createSlice({
  name: "chart",
  initialState: initialState,
  reducers: {
    addMarker: (state, action) => {
      state.markers = [...state.markers, action.payload].sort((a, b) => a.time - b.time);
      
    },
    setRefetchToTrue: (state) => {
      state.refetch = true;
    },

    setChartVisibleRange: (state, action) => {
      const { from, to } = action.payload;
      state.chartVisibleRange = { from: from, to: to };
    },
    changeTo: (state, action) => {
      state.to = action.payload;
    },
    changeFrom: (state, action) => {
      state.from = action.payload;
    },
    toggleShowGrids: (state) => {
      state.showGrids = !state.showGrids;
    },

    changeChartType: (state, action) => {
      state.type = action.payload;
    },

    toggleWithVolume: (state, action) => {
      state.withVolume = action.payload;
    },
    toggleTickVolume: (state, action) => {
      state.tickVolume = action.payload;
    },

    changeSymbol: (state, action) => {
      const newSymbol = action.payload.toUpperCase();
      state.symbol = newSymbol;
      localStorage.setItem("lastOpenedSymbol", newSymbol);
    },

    changeTime: (state, action) => {
      state.time = Number(action.payload);
    },

    changeGetDataParams: (state, action) => {
      const newData = action.payload;
      state.symbol = newData?.symbol;
      state.from = newData?.from || state.from;
      state.to = newData?.to || state.to;
      state.time = newData?.time || state.time;
    },
    changeDescription: (state, action) => {
      const newDescription = action.payload;
      state.description = newDescription;
      localStorage.setItem("lastOpenedDescription", newDescription);
    },
    updateOhlcData: (state, action) => {
      state.ohlcData.push(action.payload);
    },

    updateVolumeData: (state, action) => {
      state.volumeData.push(action.payload);
    },

    toggleScreenShot: (state) => {
      state.screenShot = !state.screenShot;
    },
    setFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },

    resetChart: (state) => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchChartData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChartData.fulfilled, (state, action) => {
        state.loading = false;
        state.ohlcData = action.payload.ohlcData;
        state.volumeData = action.payload.volumeData;
        // state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchChartData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUpdateChartData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUpdateChartData.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload.emptyResponse) {
          // state.totalPages = action.payload.totalPages
          state.ohlcData = action.payload.ohlcData;
          state.volumeData = action.payload.volumeData;
          state.refetch = false;
        }
      })
      .addCase(fetchUpdateChartData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  toggleShowGrids,
  toggleWithVolume,
  changeGetDataParams,
  changeChartType,
  changeSymbol,
  toggleTickVolume,
  changeTime,
  changeDescription,
  updateOhlcData,
  updateVolumeData,
  toggleScreenShot,
  setFullWidth,
  changeTo,
  changeFrom,
  setChartVisibleRange,
  setRefetchToTrue,
  setCurrentPage,
  setLimit,
  addMarker,
} = chartSlice.actions;

export default chartSlice.reducer;
