import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setFast, setSlow, setColor, setThickness
} from '../../../store/indicators/cmoSlice';
import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../wrappers/IndicatorSettingWrapper';

const CMOComponent = () => {
    const dispatch = useDispatch();
    const cmoState = useSelector(state => state.cmo);
    const [activeTab, setActiveTab] = useState('general');

    const handleInputChange = (e, actionCreator) => {
        dispatch(actionCreator(e.target.value));
    };

    return (
        <IndicatorSettingWrapper>
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <div
                        className={`${styles.tab} ${activeTab === 'general' ? styles.active : ''}`}
                        onClick={() => setActiveTab('general')}
                    >
                        General Settings
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === 'style' ? styles.active : ''}`}
                        onClick={() => setActiveTab('style')}
                    >
                        Style Settings
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>
                    <div className={styles.setting}>
                        <label>Fast Period:</label>
                        <input
                            type="number"
                            value={cmoState.fast}
                            onChange={(e) => handleInputChange(e, setFast)}
                        />
                    </div>
                    <div className={styles.setting}>
                        <label>Slow Period:</label>
                        <input
                            type="number"
                            value={cmoState.slow}
                            onChange={(e) => handleInputChange(e, setSlow)}
                        />
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'style' ? styles.active : ''}`}>
                    <div className={styles.setting}>
                        <label>Line Color:</label>
                        <input
                            type="color"
                            value={cmoState.styles.color}
                            onChange={(e) => handleInputChange(e, setColor)}
                        />
                    </div>
                    <div className={styles.setting}>
                        <label>Line Thickness:</label>
                        <input
                            type="number"
                            value={cmoState.styles.lineWidth}
                            onChange={(e) => handleInputChange(e, setThickness)}
                        />
                    </div>
                </div>
            </div>
        </IndicatorSettingWrapper>
    );
};

export default CMOComponent;
