import React, { useEffect, useLayoutEffect, useRef } from "react";
import { CrosshairMode, createChart } from "lightweight-charts";
import html2canvas from "html2canvas";

import { io } from "socket.io-client";
import { apiUrl } from "../../api/apiClient";

import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import {
  addMarker,
  changeFrom,
  changeTo,
  fetchUpdateChartData,
  setChartVisibleRange,
  setRefetchToTrue,
  toggleScreenShot,
} from "../../store/chartSlice";
import { calculateIchimokuCloud } from "../../helpers/indicators";
import { initializeIndicators } from "./intializeIndicators.js";

import {
  areaSeriesOptions,
  barSeriesOptions,
  candleStickSeriesOptions,
  chartOptionsFunc,
} from "./chartOptions/mainChartOptions";
import { indicatorChartOptionsFunc } from "./chartOptions/indicatorChartOptions";
import { volumeChartOptionsFunc } from "./chartOptions/volumeChartOptions";
import { useChartSlices } from "./reduxSliceExport";
import { setIndicatorAsNewChart } from "../../store/indicators/indicatorsSlice";
import {
  Tooltip,
  drawFibonacciRetracement,
  drawRegressionLine,
} from "./utils.js";
import {
  setEndPoint,
  setStartingPoint,
} from "../../store/drawingsOnChart/lineSlice.js";
import { debounce } from "lodash";
import { setFibEnd, setFibStart } from "../../store/drawingsOnChart/fibR.js";
import {
  setRegressionChannelEnd,
  setRegressionChannelStart,
} from "../../store/drawingsOnChart/regressionLineSlice.js";

import InstantOrder from "../trade/instantOrder/InstantOrder";
import Heading from "./heading/Heading";
import ActiveIndicator from "./options/active/ActiveIndicator";

const socket = io(apiUrl, {
  transports: ["websocket"],
  withCredentials: false,
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
});

// import socket from "../../socket/index.js";

const ChartComponent = ({ width }) => {
  const chartContainerRef = useRef();
  const volumeContainerRef = useRef();
  const indicatorsContainerRef = useRef();
  const toolTipRef = useRef();

  const dispatch = useDispatch();
  const {
    indicators,
    activeIndicator,
    ema,
    sma,
    apos,
    bops,
    aroons,
    cfos,
    ccis,
    demas,
    mis,
    macds,
    pSARs,
    qSticks,
    temas,
    trimas,
    trixs,
    vwmas,
    vis,
    cmos,
    rocs,
    rsis,
    atrs,
    kcs,
    dcs,
    aos,
    bbs,
    showInstantOrder,
    ohlcData,
    volumeData,
    symbol,
    withVolume,
    screenShot,
    range,
    type,
    chartVisibleRange,
    imcs,
    refetch,
    time: timeInterval,
    markers,
  } = useChartSlices();

  const isWatchlistVisible = useSelector((state) => state.watchlist.show);
  const isDetailVisible = useSelector((state) => state.mode.show);
  const { startPoint, endPoint, isUpdatingLine } = useSelector(
    (state) => state.line
  );
  const chartHeight = useSelector((state) => state.resizable.chartHeight);

  const { crossHair, drawLine, fibonacciR, channel } = useSelector(
    (state) => state.chartSettings
  );

  const { fibEnd, fibStart } = useSelector((state) => state.fibR);
  const { regressionChannelStart, regressionChannelEnd } = useSelector(
    (state) => state.regressionChannel
  );

  const takeScreenshot = () => {
    const chartContainer = chartContainerRef.current;

    html2canvas(chartContainer).then(function (canvas) {
      const dataURL = canvas.toDataURL("image/png");

      const downloadLink = document.createElement("a");
      downloadLink.href = dataURL;
      downloadLink.download = "chart-image.png";

      downloadLink.click();
    });
  };

  useEffect(() => {
    if (screenShot) {
      takeScreenshot();
      dispatch(toggleScreenShot());
    }
  }, [screenShot, dispatch]);

  useLayoutEffect(() => {
    const abortController = new AbortController();

    const chart = createChart(
      chartContainerRef.current,
      chartOptionsFunc(chartContainerRef)
    );

    const indicatorsChart = createChart(
      indicatorsContainerRef.current,
      indicatorChartOptionsFunc(chartContainerRef)
    );

    const volumeChart = createChart(
      volumeContainerRef.current,
      volumeChartOptionsFunc(chartContainerRef)
    );

    chart.timeScale().fitContent();
    indicatorsChart.timeScale().fitContent();
    volumeChart.timeScale().fitContent();

    if (!isDetailVisible) {
      indicatorsChart.applyOptions({
        height: chartContainerRef.current.clientHeight * 0.2,
      });
    }
    if (!indicators.singleMAVisibility) {
      dispatch(setIndicatorAsNewChart(false));
    }

    const candlestickSeries = chart.addCandlestickSeries(
      candleStickSeriesOptions
    );

    const areaSeries = chart.addAreaSeries(areaSeriesOptions);
    const barSeries = chart.addBarSeries(barSeriesOptions);

    // Initialize indicators
    initializeIndicators(
      indicatorsChart,
      ohlcData,
      volumeData,
      indicators,
      dispatch,
      chart,
      ema,
      sma,
      imcs,
      calculateIchimokuCloud,
      volumeChart,
      apos,
      withVolume,
      aroons,
      cfos,
      ccis,
      demas,
      mis,
      macds,
      pSARs,
      qSticks,
      temas,
      trimas,
      trixs,
      vwmas,
      aos,
      vis,
      cmos,
      rocs,
      rsis,
      atrs,
      kcs,
      dcs,
      bbs,
      bops,
      setIndicatorAsNewChart
    );

    // draw line start

    const onChartClick = (param) => {
      try {
        const containerRect = chartContainerRef.current.getBoundingClientRect();
        if (
          !param.point ||
          !param.time ||
          param.point.x < containerRect.left ||
          param.point.x > containerRect.right ||
          param.point.y < containerRect.top ||
          param.point.y > containerRect.bottom
        ) {
          return;
        }

        if (fibonacciR) {
          if (!fibStart) {
            dispatch(
              setFibStart({
                time: param.time,
                value: param.seriesData.get(candlestickSeries)?.close,
              })
            );
          } else if (!fibEnd) {
            dispatch(
              setFibEnd({
                time: param.time,
                value: param.seriesData.get(candlestickSeries)?.close,
              })
            );
          }
        }

        if (drawLine) {
          if (!startPoint) {
            const marker = {
              time: param.time,
              position: "aboveBar",
              color: "#f68410",
              shape: "circle",
              text: `${param.seriesData.get(candlestickSeries).close}`,
            };

            dispatch(addMarker(marker));

            dispatch(
              setStartingPoint({
                time: param.time,
                value: param.seriesData.get(candlestickSeries).close,
              })
            );
          } else if (!endPoint) {
            const marker = {
              time: param.time,
              position: "aboveBar",
              color: "#f68410",
              shape: "circle",
              text: `${param.seriesData.get(candlestickSeries).close}`,
            };
            dispatch(addMarker(marker));

            dispatch(
              setEndPoint({
                time: param.time,
                value: param.seriesData.get(candlestickSeries).close,
              })
            );
          }
        }

        if (channel) {
          if (!regressionChannelStart) {
            dispatch(
              setRegressionChannelStart({
                time: param.time,
                value: param.seriesData.get(candlestickSeries).close,
              })
            );
          } else if (!regressionChannelEnd) {
            dispatch(
              setRegressionChannelEnd({
                time: param.time,
                value: param.seriesData.get(candlestickSeries).close,
              })
            );
          }
        }
      } catch (error) {
        console.error("Error handling chart click:", error);
      }
    };

    if (markers.length > 0) {
      candlestickSeries.setMarkers(markers);
    }

    const trendLine = chart.addLineSeries({
      lineWidth: 2,
      pointMarkersVisible: true,
    });

    if (drawLine && startPoint && endPoint) {
      if (startPoint.time !== endPoint.time) {
        trendLine.setData(
          [startPoint, endPoint].sort((a, b) => a.time - b.time)
        );
      }
      // console.log(trendLine.data());
    }

    chart.subscribeClick(onChartClick);

    // draw line end

    // channel starts
    drawRegressionLine(
      channel,
      chart,
      regressionChannelStart,
      regressionChannelEnd,
      ohlcData
    );
    // channel ends

    // fibR starts
    drawFibonacciRetracement(fibonacciR, fibStart, fibEnd, chart);
    // fibR ends

    const handleCrosshairMove = (param) => {
      const containerRect = chartContainerRef.current.getBoundingClientRect();
      if (
        !param.point ||
        !param.time ||
        param.point.x < containerRect.left ||
        param.point.x > containerRect.right ||
        param.point.y < containerRect.top ||
        param.point.y > containerRect.bottom
      ) {
        toolTipRef.current.style.display = "none";
        return;
      }

      const mouseX = param.point.x - containerRect.left;
      const mouseY = param.point.y - containerRect.top + 50;

      const data =
        param.seriesData.get(candlestickSeries) ||
        param.seriesData.get(areaSeries) ||
        param.seriesData.get(barSeries);
      if (!data) return;

      const { open, close, high, low, value } = data;
      const price = close;
      const coordinate =
        candlestickSeries.priceToCoordinate(price) ||
        areaSeries.priceToCoordinate(price) ||
        barSeries.priceToCoordinate(price);

      if (coordinate === null) {
        return;
      }

      const toolTip = toolTipRef.current;
      toolTip.style.display = "block";
      toolTip.innerHTML = Tooltip(type, symbol, open, high, low, close, value);

      toolTip.style.left = `${mouseX + 10}px`;
      toolTip.style.top = `${mouseY - toolTip.offsetHeight - 10}px`;
    };

    chartContainerRef.current.addEventListener("mouseleave", () => {
      toolTipRef.current.style.display = "none";
    });

    if (crossHair) {
      chart.subscribeCrosshairMove(handleCrosshairMove);
    } else {
      chart.applyOptions({
        crosshair: {
          mode: CrosshairMode.Hidden,
        },
      });
    }
    const fetchData = async (chartName) => {
      if (type === "area") {
        const areaDataFromOhlcData = ohlcData.map(({ time, high, low }) => ({
          time,
          value: (high + low) / 2,
        }));
        chartName.setData(areaDataFromOhlcData);
      } else {
        chartName.setData(ohlcData);
      }

      const { from, to } = chartVisibleRange;
      if (from > 0 && to > 0) {
        chart.timeScale().setVisibleRange({ from, to });
      }
    };

    // sync charts starts
    const syncCharts = (timeRange) => {
      volumeChart.timeScale().setVisibleLogicalRange(timeRange);
      indicatorsChart.timeScale().setVisibleLogicalRange(timeRange);
      chart.timeScale().setVisibleLogicalRange(timeRange);
    };
    chart.timeScale().subscribeVisibleLogicalRangeChange(syncCharts);
    volumeChart.timeScale().subscribeVisibleLogicalRangeChange(syncCharts);
    indicatorsChart.timeScale().subscribeVisibleLogicalRangeChange(syncCharts);
    // sync chart ends

    if (type === "candlestick") {
      fetchData(candlestickSeries);
    }
    if (type === "area") {
      fetchData(areaSeries);
    }
    if (type === "bars") {
      fetchData(barSeries);
    }

    const handleResize = () => {
      chart.applyOptions({
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    socket.on("newMessage", (newData) => {
      const data = newData.results;
      if (data.symbol === symbol && ohlcData.length > 0) {
        const date = new Date(data?.createdAt);
        const timestamp =
          Math.floor(date.getTime() / (timeInterval * 60 * 1000)) *
          (timeInterval * 60);

        const prevReading = ohlcData[ohlcData.length - 1];

        if (prevReading.time <= timestamp) {
          const freshDataPoint = {
            time: timestamp,
            open: data.open,
            high: data.high,
            low: data.low,
            close: data.close,
          };
          if (type === "candlestick") {
            candlestickSeries.update(freshDataPoint);
          }

          if (type === "bars") {
            barSeries.update(freshDataPoint);
          }

          if (type === "area") {
            areaSeries.update({
              time: timestamp,
              value: (data.open + data.close) / 2,
            });
          }
        }
      }
    });

    const onVisibleLogicalRangeChanged = debounce((newVisibleLogicalRange) => {
      const barsInfo =
        candlestickSeries.barsInLogicalRange(newVisibleLogicalRange) ||
        areaSeries.barsInLogicalRange(newVisibleLogicalRange) ||
        barSeries.barsInLogicalRange(newVisibleLogicalRange);

      if (barsInfo !== null) {
        const { barsBefore } = barsInfo;

        if (barsBefore < 0 && !refetch) {
          const { from, to } = chart.timeScale().getVisibleRange();
          dispatch(setChartVisibleRange({ from, to }));
          dispatch(setRefetchToTrue());

          switch (timeInterval) {
            case 1440:
            case 10080:
            case 43200:
              dispatch(changeFrom(range));
              dispatch(changeTo(range + 500));
              break;
            default:
              dispatch(changeFrom(range));
              dispatch(changeTo(range + 5));
              break;
          }

          dispatch(fetchUpdateChartData({ signal: abortController.signal }));
        }
      }
    }, 3000);

    chart
      .timeScale()
      .subscribeVisibleLogicalRangeChange(onVisibleLogicalRangeChanged);

    if (chartHeight && isDetailVisible) {
      chart.applyOptions({
        height: chartHeight * 8,
      });

      if (indicators.indicatorAsNewChart) {
        chart.applyOptions({
          height: chartHeight * 6,
        });
        indicatorsChart.applyOptions({
          height: chartHeight * 2,
        });
      }
    }

    if (chartHeight && !isDetailVisible) {
      chart.applyOptions({
        height: 90 * 8,
      });

      if (indicators.indicatorAsNewChart) {
        chart.applyOptions({
          height: 90 * 6,
        });
        indicatorsChart.applyOptions({
          height: 90 * 2,
        });
      }
    }

    if (window.innerWidth < 768) {
      chart.applyOptions({
        height: window.innerHeight * 0.8,
      });
      if (indicators.indicatorAsNewChart) {
        chart.applyOptions({
          height: window.innerHeight * 0.7,
        });
        indicatorsChart.applyOptions({
          height: chartContainerRef.current.clientHeight * 0.2,
        });
      }
    }

    return () => {
      socket.off("newMessage");
      window.removeEventListener("resize", handleResize);
      chart.remove();

      volumeChart.remove();
      indicatorsChart.remove();
      abortController.abort();
    };
  }, [
    isWatchlistVisible,
    ohlcData,
    volumeData,
    type,
    withVolume,
    symbol,
    indicators,
    activeIndicator,
    sma,
    ema,
    chartVisibleRange,
    range,
    dispatch,
    aos,
    bbs,
    atrs,
    cmos,
    dcs,
    kcs,
    rocs,
    rsis,
    apos,
    aroons,
    bops,
    ccis,
    cfos,
    demas,
    macds,
    mis,
    pSARs,
    qSticks,
    temas,
    trimas,
    trixs,
    vis,
    vwmas,
    imcs,
    refetch,
    timeInterval,
    isDetailVisible,
    startPoint,
    endPoint,
    isUpdatingLine,
    crossHair,
    drawLine,
    fibEnd,
    fibStart,
    fibonacciR,
    regressionChannelEnd,
    regressionChannelStart,
    channel,
    chartHeight,
    width,
    markers,
  ]);

  return (
    <div className={`relative ml-1   z-0 `} id="container">
      {showInstantOrder && (
        <div className="absolute top-6 left-2 z-[100000]">
          <InstantOrder />
        </div>
      )}

      <div className="absolute top-1 left-1 z-[100000]">
        <Heading />
      </div>

      {indicators.visibility && <ActiveIndicator />}
      <div className="relative">
        {ohlcData.length < 1 && (
          <div className="absolute flex items-center justify-center z-10 top-0 left-0 w-full h-full">
            <AiOutlineLoading3Quarters
              size={24}
              color="green"
              className="animate-spin"
            />
          </div>
        )}
        <div ref={chartContainerRef} id="mainChart"></div>

        <div
          ref={indicatorsContainerRef}
          id="indChart"
          className={`w-full   ${
            indicators.indicatorAsNewChart ? "" : "hidden"
          } ${window.innerWidth <= 768 ? "mb-8" : ""} `}
        />

        <div
          ref={volumeContainerRef}
          className={`absolute bottom-5 ${withVolume ? "z-[100000]" : "z-0"}`}
          style={{ height: "100px" }}
        />
      </div>

      <div
        ref={toolTipRef}
        className="fixed hidden bg-white/90 p-2 z-[1000] rounded shadow-md text-xs"
      ></div>
    </div>
  );
};

export default ChartComponent;
