import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTimeFrame, setPeriod, setColor, setThickness, setLineStyle, resetVwma, setSource } from '../../../store/indicators/vmwaSlice';

import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../wrappers/IndicatorSettingWrapper';
import { changeTime, fetchChartData } from '../../../store/chartSlice';

const VWMAComponent = ({onClose}) => {
    const dispatch = useDispatch();
    const vwmaState = useSelector(state => state.vwma);
    const [activeTab, setActiveTab] = useState('general');

    const handleInputChange = (e, actionCreator) => {
        const value = e.target.type === 'number' ? Math.max(Number(e.target.min), Number(e.target.value)) : e.target.value;
        dispatch(actionCreator(value));
        if (actionCreator === setTimeFrame) {
          dispatch(changeTime(value));
          dispatch(fetchChartData());
        }
      };

    return (
        <IndicatorSettingWrapper onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <div
                        className={`${styles.tab} ${activeTab === 'general' ? styles.active : ''}`}
                        onClick={() => setActiveTab('general')}
                    >
                        General Settings
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === 'style' ? styles.active : ''}`}
                        onClick={() => setActiveTab('style')}
                    >
                        Style Settings
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>
                    <div className={styles.setting}>
                        <label>Time Frame:</label>
                        <select
                            value={vwmaState.timeFrame}
                            onChange={(e) => handleInputChange(e, setTimeFrame)}
                        >
                            <option value="1">1 min</option>
                            <option value="5">5 min</option>
                            <option value="15">15 min</option>
                            <option value="30">30 min</option>
                            <option value="60">1 hour</option>
                            <option value="D">1 day</option>
                        </select>
                    </div>
                    <div className={styles.setting}>
            <label>Source:</label>
            <select
              value={vwmaState.source}
              onChange={(e) => handleInputChange(e, setSource)}
            >
              <option value="open">Open</option>
              <option value="close">Close</option>
              <option value="high">High</option>
              <option value="low">Low</option>
            </select>
          </div>
                    <div className={styles.setting}>
                        <label>Period:</label>
                        <input
                            type="number"
                            value={vwmaState.period}
                            onChange={(e) => handleInputChange(e, setPeriod)}
                        />
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'style' ? styles.active : ''}`}>
                    <div className={styles.setting}>
                        <label>Line Color:</label>
                        <input
                            type="color"
                            value={vwmaState.styles.color}
                            onChange={(e) => handleInputChange(e, setColor)}
                        />
                    </div>
                    <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={vwmaState.styles.lineStyle}
              onChange={(e) => handleInputChange(e, setLineStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
              <option value={3}>Large Dashed</option>
              <option value={4}>Sparse Dotted</option>
            </select>
          </div>
                    <div className={styles.setting}>
                        <label>Line Thickness:</label>
                        <input
                            type="number"
                            value={vwmaState.styles.lineWidth}
                            onChange={(e) => handleInputChange(e, setThickness)}
                        />
                    </div>
                </div>
        <button onClick={() => dispatch(resetVwma())}>Reset</button>

            </div>
        </IndicatorSettingWrapper>
    );
};

export default VWMAComponent;
