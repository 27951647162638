import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { postPartaillyClosePosition } from "../../../api/queryFunctions";
import { mapTypeToString } from "../../../helpers";
import socket from "../../../socket";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const PPC = ({ data, onClose }) => {
  const [inputValue, setInputValue] = useState(data.volume);
  const [profit, setProfit] = useState("");
  const id = useSelector((state) => state.user.id);

  const { mutate: closePositionPartially } = useMutation({
    mutationKey: "PCC",
    onMutate: (data) => postPartaillyClosePosition(data),
    onSettled: (context) => {
      toast.success(context.data.message);
    },
  });

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const isButtonDisabled = () => {
    return Number(inputValue) > data?.volume || Number(inputValue) === 0;
  };

  const handleClosePosition = () => {
    closePositionPartially({
      positionId: data.id || data._id,
      volume2Close: Number(inputValue),
    });
    onClose();
  };

  useEffect(() => {
    if (id) {
      socket.emit("userpositions", JSON.stringify({ userId: id }));
    }
    socket.on("posProfit", (newData) => {
      if (newData.positionId === data.id || newData.positionId === data._id) {
        if (inputValue) {
          setProfit(newData?.profit?.toFixed(2));
        }
      }
    });

    return () => {
      socket.off("posData");
    };
  }, [id, data, inputValue]);

  return (
    <div className="p-10 md:p-6 rounded-lg border border-gray-200 flex flex-col bg-white text-base font-medium text-green-600">
      <div className="flex justify-between mb-3 md:text-sm font-bold">
        <span className="text-blue-500">{data?.symbol}</span>
        <span>
          {!isNaN(data?.type) ? mapTypeToString(data?.type) : data?.type}
        </span>
      </div>
      <div className="flex justify-between mb-3 md:text-sm font-bold">
        <span>{data?.price}</span>
        <span>{data?.volume.toFixed(3)}</span>
      </div>
      <div
        className={`flex justify-between mb-3 md:text-sm font-bold ${
          profit > 0 ? "text-green-500" : "text-red-500"
        }`}
      >
        <span>Profit: {(profit * (inputValue / data?.volume)).toFixed(2)}</span>
      </div>
      <div className="mb-3">
        <input
          type="number"
          value={inputValue}
          onChange={handleInputChange}
          step="0.001"
          min="0.001"
          className="outline-none border border-gray-300 rounded-lg text-center h-11 w-full text-base"
        />
      </div>
      <button
        className={`cursor-pointer bg-purple-700 text-white text-center rounded-lg md:py-1 md:px-2 py-2 px-3 mb-3 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-black`}
        disabled={isButtonDisabled()}
        onClick={!isButtonDisabled() ? handleClosePosition : null}
      >
        Close Position
      </button>
      <div
        className="cursor-pointer bg-purple-700 text-white text-center rounded-lg py-2 px-3 md:py-1 md:px-2"
        onClick={() => onClose()}
      >
        Cancel
      </div>
    </div>
  );
};

export default React.memo(PPC);
