import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import socket from "../../socket";

const WalletInfo = () => {
  const { userData: walletData } = useSelector((state) => state.user);
  const [data, setData] = useState(walletData);
  const walletRefresh = useSelector((state) => state.app.walletRefresh);

  useEffect(() => {
    setData(walletData);
  }, [walletData]);

  useEffect(() => {
    if (walletData?.id) {
      socket.emit("userpositions", JSON.stringify({ userId: walletData?.id }));
    }
    socket.on("posData", (newData) => {
      // console.log(newData);
      setData((prev) => {
        if (!prev) return null;
        return {
          balance: newData.balance?.toFixed(2),
          equity: newData.equity?.toFixed(2),
          credit: newData?.credit.toFixed(2) || 0,
          margin: newData.margin?.toFixed(2),
          freeMargin: (newData?.freeMargin || 0)?.toFixed(2),
          level: (newData?.level || 0)?.toFixed(2),
          change: newData?.runningProfit?.toFixed(2) || 0,
        };
      });
    });

    return () => {
      socket.off("posData");
    };
  }, [walletData, walletRefresh]);

  return (
    <div>
      <div className="bottom-0 md:pl-5 w-full  md:bg-white  flex flex-col md:flex-row gap-5  overflow-x-hidden border-t-2 border-gray-200 md:font-medium md:text-blue-600 md:absolute p-5 md:p-1 md:m-0  bg-gray-50 md:shadow-none text-black ">
        <div className="flex justify-between gap-2 md:text-xs  text-sm">
          <span>Balance:</span>
          <span>{data?.balance}</span>
        </div>
        <div className="flex justify-between gap-2 md:text-xs  text-sm ">
          <span>Equity:</span>
          <span>{data?.equity}</span>
        </div>
        <div className="flex justify-between gap-2 md:text-xs  text-sm">
          <span>Credit:</span>
          <span>{data?.credit}</span>
        </div>
        <div className="flex justify-between gap-2 md:text-xs  text-sm ">
          <span>Margin:</span>
          <span>{data?.margin}</span>
        </div>
        <div className="flex justify-between gap-2 md:text-xs  text-sm ">
          <span>Free Margin:</span>
          <span>{data?.freeMargin}</span>
        </div>
        <div className="flex justify-between gap-2 md:text-xs  text-sm">
          <span>Level:</span>
          <span>{data?.level}</span>
        </div>
        <div
          className={`text-center font-bold ${
            data?.change < 0 ? "text-red-600" : "text-green-700"
          }`}
        >
          {data?.change ? data?.change : "___"} USD
        </div>
      </div>
    </div>
  );
};

export default WalletInfo;
