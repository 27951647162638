export const removeAuthToken = () => {
  try {
    localStorage.removeItem("AUTH-TOKEN");
  } catch (error) {
    console.error("Error removing AUTH-TOKEN:", error);
  }
};

export const getAuthToken = () => {
  return localStorage.getItem("AUTH-TOKEN");
};
export const setUserChangeTo = (data) => {
  return localStorage.setItem("ACTIVE-USER", JSON.stringify(data));
};

export const getActiveUser = () => {
  return JSON.parse(localStorage.getItem("ACTIVE-USER"));
};

export const setUserCredentials = (credentials) => {
  let storedCredentials = JSON.parse(localStorage.getItem("AUTH-CARD")) || [];

  // Check if credentials with the same userId already exist
  const index = storedCredentials.findIndex(
    (cred) => cred.userId === credentials.userId
  );

  if (index !== -1) {
    // Replace the existing credentials
    storedCredentials[index] = credentials;
  } else {
    // Add new credentials to the array
    storedCredentials.push(credentials);
  }

  localStorage.setItem("AUTH-CARD", JSON.stringify(storedCredentials));
};

export const getUserCredentials = () => {
  const authCard = JSON.parse(localStorage.getItem("AUTH-CARD"));

  return authCard;
};

export const removeUserCredentials = () => {
  localStorage.removeItem("AUTH-CARD");
};

export const setAuthCred = (data) => {
  setAuthToken(data?.token);
  setUserCredentials({
    userId: data?.user?.userId,
    password: data?.user?.password,
    name: data?.user?.name,
  });
};

export const setAuthToken = (token) => {
  localStorage.setItem("AUTH-TOKEN", token);
  localStorage.setItem("isAuthenticated", true);
  if (localStorage.getItem("AUTH-TOKEN")) {
    return true;
  } else {
    return false;
  }
};

export const getCurrentDateFormatted = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const mapTypeToString = (type) => {
  switch (type) {
    case 0:
      return "Sell";
    case 1:
      return "Buy";
    case 2:
      return "Buy Limit";
    case 3:
      return "Sell Limit";
    case 4:
      return "Buy Stop";
    case 5:
      return "Sell Stop";
    case 6:
      return "Buy Stop Limit";
    default:
      return "Sell Stop Limit";
  }
};

export const stringToMapType = (str) => {
  switch (str) {
    case "Sell":
      return 0;
    case "Buy":
      return 1;
    case "Buy Limit":
      return 2;
    case "Sell Limit":
      return 3;
    case "Buy Stop":
      return 4;
    case "Sell Stop":
      return 5;
    case "Buy Stop Limit":
      return 6;
    default:
      return -1;
  }
};

export const positionFields = [
  "ID",
  "SYMBOL",
  "TICKET",
  "TIME",
  "TYPE",
  "VOLUME",
  "PRICE",
  "S/L",
  "T/P",
  "SWAP",
  "CURRENT PRICE",
  "UPDATED TIME",
  "FEE",
  "COMMENT",
  "P/L",
  "Action",
];

export const orderFields = [
  "ID",
  "SYMBOL",
  "TICKET",
  "TIME",
  "TYPE",
  "VOLUME",
  "PRICE",
  "S/L",
  "T/P",
  "UPDATED TIME",
  "COMMENT",
  "STATE",
];

export const dealsField = [
  "ID",
  "SYMBOL",
  "TICKET",
  "ORDER",
  "TIME",
  "TYPE",
  "DIRECTION",
  "COMMISSION",
  "VOLUME",
  "PRICE",
  "FEE",
  "SWAP",
  "COMMENT",
  "P/L",
];

export const closedPositionField = [
  "ID",
  "SYMBOL",
  "TICKET",
  "PRICE",
  "TYPE",
  "VOLUME",
  "CLOSED PRICE",
  "PROFIT",
  "S/L",
  "T/P",
  "SWAP",
  "CLOSE TIME",
  "FEE",
  "COMMISSION",
  "COMMENT",
];

export const mapOrderData = (data) => {
  if (data.length === 0) return null;

  const newData = data?.map((order) => ({
    id: order._id || "",
    symbol: order.symbol || "",
    ticket: order.ticket || "",
    createdAt: formatTimeToSeconds(order.createdAt) || "",
    type: mapTypeToString(order.type) || "",
    initialVolume: order.initialVolume || "",
    price: order.price || "",
    stopLoss: fixedNumber(order?.stopLoss) || "",
    takeProfit: fixedNumber(order?.takeProfit) || "",
    updatedAt: formatTimeToSeconds(order.updatedAt) || "",
    comment: order.comment || "",
    status: order.status === 1 ? "Filled" : "Cancelled",
  }));
  return newData;
};

export const mapDealsData = (data) => {
  if (data.length === 0) return null;
  const mappedDeals = data?.map((deal) => ({
    id: deal._id || "",
    symbol: deal.symbol || "",
    ticket: deal.ticket || "",
    order: deal.order || "",
    createdAt: formatTimeToSeconds(deal.createdAt) || "",
    type: mapTypeToString(deal.type) || "",
    direction: deal.action === 0 ? "OUT" : "IN",
    commission: deal.comission?.toFixed(2),
    volume: deal.volume || "",
    price: deal.price || "",
    fee: deal.fee || "",
    swap: deal.swap?.toFixed(2) || "",
    comment: deal.comment || "",
    profit: deal.profit?.toFixed(2),
  }));
  return mappedDeals;
};

export const mapRunningPositions = (data) => {
  if (data.length === 0) return null;

  const mappedRunningPositions = data
    ?.map((position) => {
      if (position?.status === 1) {
        return {
          id: position._id || "",
          symbol: position.symbol || "",
          ticket: position.ticket || "",
          createdAt: formatTimeToSeconds(position.createdAt) || "",
          type: mapTypeToString(position.type) || "",
          volume:
            position.status === 1 ? position.volume : `0.00/${position.volume}`,
          price: position.price || "",
          stopLoss: fixedNumber(position?.stopLoss) || "",
          takeProfit: fixedNumber(position?.takeProfit) || "",
          swap: position.swap?.toFixed(2) || "",
          currentPrice: fixedNumber(position.lastPrice) || " ",
          updatedAt: formatTimeToSeconds(position.updatedAt) || "",

          commission: position.comission || " ",
          comment: position.comment || " ",
          profit:
            position.status === 1 ? position.profit?.toFixed(2) : "Placed",
        };
      }
      return null;
    })
    .filter(Boolean);

  return mappedRunningPositions.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateA - dateB;
  });
};

export const mapPendingPositions = (data) => {
  if (data.length === 0) return null;

  const mappedPendingPositions = data
    ?.map((position) => {
      if (position.status === 0) {
        return {
          id: position._id || "",
          symbol: position.symbol || "",
          ticket: position.ticket || "",
          createdAt: formatTimeToSeconds(position.createdAt) || "",
          type: mapTypeToString(position.type) || "",
          volume: position.volume,
          price: position.price || "",
          stopLoss: fixedNumber(position?.stopLoss) || "",
          takeProfit: fixedNumber(position?.takeProfit) || "",
          swap: position.swap?.toFixed(2) || "",
          currentPrice: position.lastPrice,
          updatedAt: formatTimeToSeconds(position.updatedAt) || "",
          commission: position.comission || "",
          comment: position.comment || "",
          profit: "Placed",
        };
      }
      return null;
    })
    .filter(Boolean);

  return mappedPendingPositions.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateA - dateB;
  });
};

export const mapClosedPosition = (data) => {
  if (!data || data.length === 0) return [];

  const mappedClosedPosition = data.map((position) => ({
    id: position._id || "",
    symbol: position.symbol || "",
    ticket: position.ticket || "",
    price: position.price || "",
    type: mapTypeToString(position.type) || "",
    volume: position.volume || "",
    closePrice: position.closePrice || "",
    profit: position.profit?.toFixed(2) ?? "",
    stopLoss: fixedNumber(position?.stopLoss) || "",
    takeProfit: fixedNumber(position?.takeProfit) || "",
    swap: position.swap?.toFixed(2) || "",
    closeTime: formatTimeToSeconds(position.closeTime) || "",
    fee: position.fee?.toFixed(2) || "",
    commission: position.comission?.toFixed(2) || "",
    comment: position.comment || "",
  }));

  return mappedClosedPosition.sort((a, b) => {
    const dateA = new Date(a.closeTime);
    const dateB = new Date(b.closeTime);
    return dateA - dateB;
  });

  // return mappedClosedPosition
};

export const fixedNumber = (number) => {
  const integerPart = Math.floor(number);
  const beforeDecimal =
    integerPart === 0 ? 1 : Math.floor(Math.log10(Math.abs(integerPart))) + 1;
  let afterDecimal = 6 - beforeDecimal;

  // Ensure afterDecimal is within valid range for toFixed
  if (afterDecimal < 0) {
    afterDecimal = 0;
  }

  return number?.toFixed(afterDecimal);
};

export const fN = (number) => {
  const integerPart = Math.floor(number);
  const beforeDecimal =
    integerPart === 0 ? 1 : Math.floor(Math.log10(Math.abs(integerPart))) + 1;
  const afterDecimal = 8 - beforeDecimal;
  return number?.toFixed(afterDecimal);
};

export const toggleFullScreen = () => {
  const documentElement = document.documentElement;

  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    if (documentElement.requestFullscreen) {
      documentElement.requestFullscreen();
    } else if (documentElement.mozRequestFullScreen) {
      documentElement.mozRequestFullScreen();
    } else if (documentElement.webkitRequestFullscreen) {
      documentElement.webkitRequestFullscreen();
    } else if (documentElement.msRequestFullscreen) {
      documentElement.msRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
};

export const formatTimeToSeconds = (dateTimeString) => {
  if (!dateTimeString) return "";
  const date = new Date(dateTimeString);
  return date.toISOString().slice(0, 19).replace("T", " ");
};
