import React, { useState, useEffect } from "react";
import styles from "./SearchBar.module.css";

import { IoIosAdd } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { IoIosClose } from "react-icons/io";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  deleteSymbol,
  getSymbols,
  addSymbol,
} from "../../../api/queryFunctions";

const SearchBar = React.memo(() => {
  const queryClient = useQueryClient();
  const watchlistData = queryClient.getQueryData(["getWatchlistData"]);

  const { data } = useQuery({ queryKey: ["getSymbols"], queryFn: getSymbols });
  const { mutate: addS, isPending: addPending } = useMutation({
    mutationFn: (data) => addSymbol(data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["getWatchlistData"] }),
  });

  const { mutate: deleteS, isPending: deletePending } = useMutation({
    mutationFn: (data) => deleteSymbol(data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["getWatchlistData"] }),
  });

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const key = 0;
  const [searchResults, setSearchResults] = useState([]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleItemClick = (item) => {};

  const handleAddItemToWatchList = async (symbol) => {
    addS({ symbol: symbol });
  };

  const handleDeleteItemFromWatchList = async (symbol) => {
    deleteS({ symbol: symbol });
  };

  useEffect(() => {
    const symbols = data?.map((s) => ({
      symbol: s.Symbol,
      description:
        watchlistData?.find((item) => item.symbol === s.Symbol)?.description ||
        "",
      inWatchlist: watchlistData?.some(
        (watchlistItem) => watchlistItem.symbol === s.Symbol
      ),
    }));

    setSearchResults(
      symbols?.filter(
        (symbol) =>
          symbol?.symbol?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          symbol?.description
            ?.toLowerCase()
            .includes(searchQuery?.toLowerCase())
      ) ?? []
    );
  }, [data, searchQuery, watchlistData]);

  return (
    <div className={styles.search}>
      <div className="flex  bg-[#f2f2f2]  rounded-md h-8 w-full">
        <input
          type="text"
          placeholder="Search Symbol..."
          value={searchQuery}
          onChange={handleInputChange}
          className="flex-1 bg-[#f2f2f2] outline-none ps-2 rounded-lg"
        />
        {showDropdown && (
          <IoIosClose
            size={30}
            color="red"
            onClick={() => {
              setShowDropdown(false);
              setSearchQuery("");
            }}
          />
        )}
      </div>

      {/* <button className={styles.button}>Search</button> */}
      {showDropdown && searchResults.length > 0 && (
        <div className={styles.dropdown}>
          <ul key={key} className={styles.dropdownList}>
            {searchResults.map((item, index) => {
              return (
                <li
                  key={item.symbol}
                  onClick={() => handleItemClick(item.symbol, index)}
                >
                  {item.symbol}
                  {/* <small>{item.description}</small> */}
                  {item.inWatchlist ? (
                    <button
                      className={styles.remove}
                      onClick={() =>
                        handleDeleteItemFromWatchList(item.symbol, index)
                      }
                      disabled={deletePending}
                    >
                      <IoIosRemoveCircleOutline size={24} />
                    </button>
                  ) : (
                    <button
                      className={styles.add}
                      onClick={() =>
                        handleAddItemToWatchList(item.symbol, index)
                      }
                      disabled={addPending}
                    >
                      <IoIosAdd size={24} />
                    </button>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
});

export default SearchBar;
