import React, { useState, useEffect } from "react";
import ChartComponent from "../chart/Chart";
import Detail from "../history/detail/Detail";
import WalletInfo from "../account/WalletInfo";
import VerticalNavbar from "../navbars/verticalNavbar/VerticalNavbar";
import HorizontalNavbar from "../navbars/horizontalNavbar/HorizontalNavbar";
import { useDispatch, useSelector } from "react-redux";
import BottomTab from "../navbars/bottomTab/BottomTab";
import { getAuthToken } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Account from "../account/Account";
import MobileHistory from "../history/mobile/MobileHistory";
import SearchWatchListSection from "../watchlist_search/SearchWatchListSection";
import { fetchInitialWalletInfo } from "../../store/userWalletAction";
import {
  fetchUserClosedPositions,
  fetchUserDeals,
  fetchUserOrders,
  fetchUserPositions,
} from "../../store/onLoadSlice";
import { fetchChartData } from "../../store/chartSlice";
import socket from "../../socket";
import { useQuery } from "@tanstack/react-query";
import { getTransactions, getUser } from "../../api/queryFunctions";
import { refreshWallet } from "../../store/appSlice";
import MobileTrade from "./walletplusPositions/MobileTrade";

const Home = () => {
  const appRefresh = useSelector((state) => state.app.appRefresh);
  const { mode, show: showDetail } = useSelector((state) => state.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showComponent = useSelector((state) => state.mobileView);
  const [loading, setLoading] = useState(true);

  const { status, data: user } = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
  });

  const { data: transactions } = useQuery({
    queryKey: ["transactions"],
    queryFn: () => getTransactions(user?.user?._id),
    enabled: !!user?.user?._id,
  });

  const isWatchlistVisible = useSelector((state) => state.watchlist.show);

  const [dragging, setDragging] = useState(false);
  const [watchlistWidth, setWatchlistWidth] = useState(30);

  const handlePointerDown = (e) => {
    setDragging(true);
    e.target.setPointerCapture(e.pointerId);
  };

  const handlePointerUp = (e) => {
    setDragging(false);
    e.target.releasePointerCapture(e.pointerId);
  };

  useEffect(() => {
    const handlePointerMove = (e) => {
      if (!dragging) return;

      const newWidth = (e.clientX / window.innerWidth) * 100;
      setWatchlistWidth(Math.max(25, Math.min(70, newWidth))); // Adjust limits to 30-70%
    };

    if (dragging) {
      document.addEventListener("pointermove", handlePointerMove);
      document.addEventListener("pointerup", handlePointerUp);
    } else {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
    }

    return () => {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
    };
  }, [dragging]);

  useEffect(() => {
    const dispatchActions = async () => {
      dispatch(fetchChartData());
      dispatch(fetchInitialWalletInfo());
      setLoading(false);
      dispatch(fetchUserPositions());
      dispatch(fetchUserClosedPositions());
      dispatch(fetchUserOrders());
      dispatch(fetchUserDeals());
    };

    const authToken = getAuthToken();
    if (authToken) {
      dispatchActions();
    }
  }, [dispatch]);

  useEffect(() => {
    if (!getAuthToken()) {
      navigate("/login");
    }
    if (status === "error") {
      setLoading(false);
      navigate("/login");
    }
  }, [status, navigate]);

  useEffect(() => {
    socket.on("disconnect", () => {
      dispatch(fetchUserPositions());
      dispatch(fetchInitialWalletInfo());
      dispatch(refreshWallet());
    });
    return () => {
      socket.off("disconnect");
    };
  }, [appRefresh, dispatch]);

  return (
    <div>
      {loading && (
        <div className="flex items-center justify-center h-screen">
          <img
            src="/icon.png"
            width={50}
            height={50}
            alt="App Icon"
            className="animate-pulse"
          />
        </div>
      )}

      {!loading && (
        <main>
          <div className="hidden h-screen md:flex">
            <VerticalNavbar />
            <div className="flex flex-col ml-8 w-full">
              <HorizontalNavbar />
              <div
                className={`flex ${
                  !showDetail ? "h-full" : "h-4/5"
                } transition ease-in-out delay-500`}
              >
                <div
                  className="flex-start"
                  style={{
                    width: isWatchlistVisible
                      ? `${98 - watchlistWidth}vw`
                      : "98vw",
                  }}
                >
                  <ChartComponent width={100 - watchlistWidth} />
                </div>
                <div
                  className={`w-1 ${
                    !showDetail ? "h-full" : "h-4/5"
                  } cursor-col-resize overflow-hidden bg-slate-100 z-0 ${
                    dragging ? "sticky" : ""
                  }`}
                  onPointerDown={handlePointerDown}
                />
                {isWatchlistVisible && (
                  <div
                    className="flex-end"
                    style={{ width: `${watchlistWidth}vw` }}
                  >
                    <SearchWatchListSection width={watchlistWidth} />
                  </div>
                )}
              </div>
              {showDetail && (
                <div className="flex-1 w-full fixed bottom-0">
                  <Detail mode={mode} />
                </div>
              )}
              <div className="w-full fixed bottom-0">
                <WalletInfo />
              </div>
            </div>
          </div>

          <div className="flex flex-col md:hidden">
            <div>
              {showComponent.wallet && <MobileTrade />}
              {showComponent.watchlist && <SearchWatchListSection />}
              {showComponent.chart && <HorizontalNavbar />}
              {showComponent.chart && <ChartComponent />}
              {showComponent.detail && <MobileHistory />}
              {showComponent.account && <Account />}
            </div>
            <div>
              <BottomTab />
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default Home;
