import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "RSI",
    period: 14,
    source: "close",
    timeFrame: 1,
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "orange",
        lineStyle: 0,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
}

const rsiSlice = createSlice({
    name: "RSI",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            const newTimeFrame = action.payload
            state.timeFrame = newTimeFrame
        },
        setPeriod: (state, action) => {
            const newPeriod = action.payload
            state.period = newPeriod
        },
        setThickness: (state, action) => {
            const newThickness = action.payload
            state.styles.lineWidth = newThickness
        },
        setColor: (state, action) => {
            const newColor = action.payload
            state.styles.color = newColor
        },
        setLineStyle: (state, action) => {
            state.styles.lineStyle = action.payload
        },
        setSource: (state, action) => {
            state.source = action.payload
        },
        resetRsi: () => initialState
    }
})

export const { setColor, setPeriod, setThickness, setTimeFrame, setLineStyle, resetRsi, setSource } = rsiSlice.actions

export default rsiSlice.reducer