import React, { useState } from "react";
import { CiSettings } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  setVisibility,
  setShowIndicator,
  setIndicatorAsNewChart,
} from "../../../../store/indicators/indicatorsSlice";
import BBComponent from "../../../indicators/bollingerBands/bb";
import AOComponent from "../../../indicators/awesomeOscillator/ao";
import DCComponent from "../../../indicators/DonchianChannel/dc";
import KCComponent from "../../../indicators/keltnerChannel/kc";
import ATRComponent from "../../../indicators/averageTrueRange/atr";
import RSIComponent from "../../../indicators/relativeStrengthIndex/rsi";
import ROCComponent from "../../../indicators/rateofChange/roc";
import VIComponent from "../../../indicators/vortexIndicator/vi";
import CMOComponent from "../../../indicators/chaikinOscillator/cmo";
import VWMAComponent from "../../../indicators/volumeWeightedMovingAverage/vwma";
import TRIXComponent from "../../../indicators/tripleExponentialAverage/trix";
import TRIMAComponent from "../../../indicators/triangularMovingAverage/trima";
import TEMAComponent from "../../../indicators/tripleExponentialMovingAverage/tema";
import QStickComponent from "../../../indicators/qStick/qStick";
import PSARComponent from "../../../indicators/parabolicSAR/PSAR";
import MACDComponent from "../../../indicators/movingAverageConvergenceDivergence/macd";
import MIComponent from "../../../indicators/massIndex/mi";
import DEMAComponent from "../../../indicators/doubleExponentialMovingAverage/dema";
import CCIComponent from "../../../indicators/communityChannelIndex/cci";
import CFOComponent from "../../../indicators/chandeForecastOscillator/cfo";
import AroonComponent from "../../../indicators/aroon/aroon";
import APOComponent from "../../../indicators/absolutePriceOscillator/apo";
import SMAComponent from "../../../indicators/simpleMovingAverage/sma";
import EMAComponent from "../../../indicators/exponentialMovingAverage/ema";
import IchimokuCloudComponent from "../../../indicators/ichimokuCloud/imc";
import BOPComponent from "../../../indicators/balanceofPower/bop";

const ActiveIndicator = () => {
  const {
    activeIndicator: name,
    names: indicators,
    singleMAVisibility: visibility,
  } = useSelector((state) => state.indicators);

  const [showIndicatorSetting, setShowIndicatorSetting] = useState(false);
  const dispatch = useDispatch();

  const setVisibleOn = () => {
    dispatch(setShowIndicator(true));
  };

  const setVisibleOff = () => {
    dispatch(setShowIndicator(false));
    dispatch(setIndicatorAsNewChart(false));
  };

  const handleClose = () => {
    dispatch(setVisibility(false));
    dispatch(setShowIndicator(false));
    dispatch(setIndicatorAsNewChart(false));
  };

  return (
    <div className="fixed top-[13%] left-[3%] bg-white flex items-center  gap-1  z-[1000] rounded-sm shadow-md border border-teal-400 font-medium md:top-[14%] md:left-[3%]">
      <span className="p-[3px] uppercase ">{name}</span>
      <span onClick={() => setShowIndicatorSetting(true)}>
        <CiSettings size={20} />
      </span>
      {visibility ? (
        <span className="text-teal-500" onClick={setVisibleOff}>
          <MdOutlineVisibility size={20} />
        </span>
      ) : (
        <span className="text-teal-500" onClick={setVisibleOn}>
          <MdOutlineVisibilityOff size={20} />
        </span>
      )}
      <span onClick={handleClose}>
        <IoClose size={20} />
      </span>

      {showIndicatorSetting && indicators.bb.visibility && (
        <BBComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.ao.visibility && (
        <AOComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.dc.visibility && (
        <DCComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.kc.visibility && (
        <KCComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.atr.visibility && (
        <ATRComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.rsi.visibility && (
        <RSIComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.roc.visibility && (
        <ROCComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.cmo.visibility && (
        <CMOComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.vi.visibility && (
        <VIComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.vwma.visibility && (
        <VWMAComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.trix.visibility && (
        <TRIXComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.trima.visibility && (
        <TRIMAComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.tema.visibility && (
        <TEMAComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.qstick.visibility && (
        <QStickComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.psar.visibility && (
        <PSARComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.macd.visibility && (
        <MACDComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.mi.visibility && (
        <MIComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.dema.visibility && (
        <DEMAComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.cci.visibility && (
        <CCIComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.cfo.visibility && (
        <CFOComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.aroon.visibility && (
        <AroonComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.bop.visibility && (
        <BOPComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.apo.visibility && (
        <APOComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.sma.visibility && (
        <SMAComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.ema.visibility && (
        <EMAComponent onClose={() => setShowIndicatorSetting(false)} />
      )}
      {showIndicatorSetting && indicators.ichimokuCloud.visibility && (
        <IchimokuCloudComponent
          onClose={() => setShowIndicatorSetting(false)}
        />
      )}
    </div>
  );
};

export default ActiveIndicator;
