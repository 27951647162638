import { useSelector } from "react-redux";

export const useChartSlices = () => {
  const {
    ohlcData,
    volumeData,

    symbol,
    type,
    withVolume,
    refetch,
    screenShot,
    to: range,
    chartVisibleRange,
    time,
    markers,
  } = useSelector((state) => state.chart);
  const showInstantOrder = useSelector((state) => state.app.showInstantOrder);
  const bbs = useSelector((state) => state.bb);
  const aos = useSelector((state) => state.ao);
  const dcs = useSelector((state) => state.dc);
  const kcs = useSelector((state) => state.kc);
  const atrs = useSelector((state) => state.atr);
  const rsis = useSelector((state) => state.rsi);
  const rocs = useSelector((state) => state.roc);
  const cmos = useSelector((state) => state.cmo);
  const vis = useSelector((state) => state.vi);
  const vwmas = useSelector((state) => state.vwma);
  const trixs = useSelector((state) => state.trix);
  const trimas = useSelector((state) => state.trima);
  const temas = useSelector((state) => state.tema);
  const qSticks = useSelector((state) => state.qStick);
  const pSARs = useSelector((state) => state.pSAR);
  const macds = useSelector((state) => state.macd);
  const mis = useSelector((state) => state.mi);
  const demas = useSelector((state) => state.dema);
  const ccis = useSelector((state) => state.cci);
  const cfos = useSelector((state) => state.cfo);
  const aroons = useSelector((state) => state.aroon);
  const bops = useSelector((state) => state.bop);
  const apos = useSelector((state) => state.apo);
  const sma = useSelector((state) => state.sma);
  const ema = useSelector((state) => state.ema);
  const activeIndicator = useSelector((state) => state.activeIndicator);
  const indicators = useSelector((state) => state.indicators);
  const imcs = useSelector((state) => state.imc);

  return {
    indicators,
    refetch,
    activeIndicator,
    time,
    ema,
    sma,
    apos,
    bops,
    aroons,
    cfos,
    ccis,
    demas,
    mis,
    macds,
    pSARs,
    qSticks,
    temas,
    imcs,
    trimas,
    trixs,
    vwmas,
    vis,
    cmos,
    rocs,
    rsis,
    atrs,
    kcs,
    dcs,
    aos,
    bbs,
    showInstantOrder,
    ohlcData,
    volumeData,
    symbol,
    withVolume,
    screenShot,
    range,
    type,
    chartVisibleRange,
    markers,
  };
};
