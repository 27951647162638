import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "MACD",
    timeFrame: 1,
    source: "close",
    fast: 12,
    slow: 26,
    signal: 9,
    styles: {
        macdLine: {
            styles: {
                lineVisible: true,
                priceLineVisible: false,
                color: "blue",
                lineStyle: 0,
                lineWidth: 1,
                lineType: 0,
                pointMarkersVisible: false,
                pointMarkersRadius: undefined,
                lastPriceAnimation: 1,
                lastValueVisible: true,
            }
        },
        signalLine: {
            styles: {
                lineVisible: true,
                priceLineVisible: false,
                color: "purple",
                lineStyle: 0,
                lineWidth: 1,
                lineType: 0,
                pointMarkersVisible: false,
                pointMarkersRadius: undefined,
                lastPriceAnimation: 1,
                lastValueVisible: true,
            }
        },
        histogram: {
            styles: {}
        }
    }
};

const macdSlice = createSlice({
    name: "MACD",
    initialState,
    reducers: {
        setSource: (state, action) => {
            state.source = action.payload
        },
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setFast: (state, action) => {
            state.fast = action.payload;
        },
        setSlow: (state, action) => {
            state.slow = action.payload;
        },
        setSignal: (state, action) => {
            state.signal = action.payload;
        },
        setMacdLineColor: (state, action) => {
            state.styles.macdLine.styles.color = action.payload;
        },
        setMacdLineThickness: (state, action) => {
            state.styles.macdLine.styles.lineWidth = action.payload;
        },
        setMacdLineStyle: (state, action) => {
            state.styles.macdLine.styles.lineStyle = action.payload;

        },

        setSignalLineColor: (state, action) => {
            state.styles.signalLine.styles.color = action.payload;
        },
        setSignalLineThickness: (state, action) => {
            state.styles.signalLine.styles.lineWidth = action.payload;
        },
        setSignalLineStyle: (state, action) => {
            state.styles.signalLine.styles.lineStyle = action.payload;

        },
        resetMacd: () => {return initialState}
    }
});

export const {
    setTimeFrame,
    setFast,
    setSlow,
    setSignal,
    setMacdLineColor,
    setMacdLineThickness,
    setSignalLineColor,
    setSignalLineThickness,
    setMacdLineStyle,
    setSignalLineStyle,
    setSource,
    resetMacd
} = macdSlice.actions;

export default macdSlice.reducer;
