import React, { useRef } from "react";
import Main from "./Main";
import Indi from "./Indi";
import Vol from "./Vol";
import { useQuery } from "@tanstack/react-query";
import { getChartData } from "../../../api/queryFunctions";
import { useSelector } from "react-redux";

import HorizontalNavbar from "../../navbars/horizontalNavbar/HorizontalNavbar";
import VerticalNavbar from "../../navbars/verticalNavbar/VerticalNavbar";

const Cont = () => {
  const { symbol, time, from, to } = useSelector((state) => state.chart);
  const { data, isLoading } = useQuery({
    queryKey: ["ohlcData"],
    queryFn: () =>
      getChartData({ symbol: symbol, time: time, from: from, to: to }),
  });
  const mainRef = useRef();
  const indiRef = useRef();
  const volRef = useRef();
  return (
    <div>
      {isLoading ? (
        <p>Loading </p>
      ) : (
        <>
          <HorizontalNavbar />
          <VerticalNavbar />
          <Main ref={mainRef} data={data.ohlcData} vol={data.volumeData} />
          <Indi ref={indiRef} data={data.ohlcData} vol={data.volumeData} />
          <Vol ref={volRef} data={data.volumeData} />
        </>
      )}
    </div>
  );
};

export default Cont;
