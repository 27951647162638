import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "TRIMA",
    source: "close",
    timeFrame: 1,
    period: 4,
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "blue",
        lineStyle: 0,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
}

const trimaSlice = createSlice({
    name: "TRIMA",
    initialState,
    reducers: {
        setSource: (state, action) => {
            state.source = action.payload;
        },
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
        setColor: (state, action) => {
            state.styles.color = action.payload;
        },
        setLineStyle: (state, action) => {
            state.styles.lineStyle = action.payload
        },
       
        setThickness: (state, action) => {
            state.styles.lineWidth = action.payload;
        },
        resetTrima : () => initialState
      
    }
})

export const {
    setSource,
    setTimeFrame,
    setPeriod,
    setColor,
    setThickness,
    setLineStyle,
    resetTrima
} = trimaSlice.actions;

export default trimaSlice.reducer;
