import { formatTimeToSeconds } from ".";
export const sortData = (data, sortBy) => {
  return data?.slice().sort((a, b) => {
    if (sortBy === "symbol") {
      return a?.symbol?.localeCompare(b.symbol);
    } else if (sortBy === "ticket") {
      return a.ticket - b.ticket;
    } else if (sortBy === "type") {
      return a.type - b.type;
    } else if (sortBy === "volume") {
      return a.volume - b.volume;
    } else if (sortBy === "openTime") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    } else if (sortBy === "closeTime") {
      return new Date(a.closeTime) - new Date(b.closeTime);
    } else if (sortBy === "profit") {
      return a.profit - b.profit;
    }
    return 0;
  });
};

export const filterDataByDate = (data, startDate, endDate) => {
  if (!startDate && !endDate) {
    // Calculate the default start and end dates for last 3 months
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const startOfMonth = new Date(currentYear, currentMonth - 2, 1);
    const endOfMonth = new Date(currentYear, currentMonth + 1, 0);
    startDate = formatDate(startOfMonth);
    endDate = formatDate(endOfMonth);
  }

  return data?.filter((item) => {
    const itemDate = new Date(item.closeTime ? item.closeTime : item.createdAt);
    const filterStartDate = new Date(startDate);
    const filterEndDate = new Date(endDate);

    return itemDate >= filterStartDate && itemDate <= filterEndDate;
  });
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
