import React, { forwardRef, useEffect } from "react";
import { volumeChartOptionsFunc } from "../chartOptions/volumeChartOptions";
import { createChart } from "lightweight-charts";
import styles from "../Chart.module.css";

const Vol = forwardRef(({ data }, ref) => {
  useEffect(() => {
    const chart = createChart(ref.current, volumeChartOptionsFunc(ref));
    const volumeChart = chart.addHistogramSeries();
    volumeChart.setData(data);

    return () => {
      chart.remove();
    };
  }, [ref, data]);

  return (
    <div
      ref={ref}
      // className={styles.volumeChartContainer}
      style={{
        height: "30vh",
        zIndex: 100000,
      }}
    />
  );
});

export default Vol;
