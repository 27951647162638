import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   name: "AO",
   timeFrame: 1,
   fast: 5,
   slow: 34,
   styles: {
      downColor: "#99FF99",
      upColor: "#FF6666"
   },

}
const aoSlice = createSlice({
   name: "ao",
   initialState,
   reducers: {
      setTimeFrame: (state, action) => {
         state.timeFrame = action.payload
      },
      setFast: (state, action) => {
         const newFast = action.payload
         state.fast = newFast
      },
      setSlow: (state, action) => {
         const newSlow = action.payload
         state.slow = newSlow
      },

      setUpColor: (state, action) => {
         const newUpColor = action.payload
         state.styles.upColor = newUpColor
      },
      setDownColor: (state, action) => {
         const newDownColor = action.payload
         state.styles.downColor = newDownColor
      },
      resetAo: () => initialState

   }
})

export const { setFast, setSlow, setDownColor, setUpColor, resetAo, setTimeFrame} = aoSlice.actions


export default aoSlice.reducer;