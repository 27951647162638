import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../api/apiClient";

const initialState = {
  data: [],
  loading: false,
  error: null,
  show: true,
  showOptions: false,
  showTradeForm: false,
};

export const fetchWatchlistData = createAsyncThunk(
  "watchlist/fetchWatchlistData",
  async () => {
    const response = await apiClient.get("get-watchlist-data");
    return response.data.message;
  }
);

const watchlistSlice = createSlice({
  name: "watchlist",
  initialState: initialState,
  reducers: {
    toggleWatchList: (state, action) => {
      state.show = !state.show;
    },
    changeShowOptions: (state, action) => {
      state.showOptions = action.payload;
    },
    changeShowTradeForm: (state, action) => {
      if(action.payload) {
        state.showTradeForm = action.payload;
      } else {
        state.showTradeForm = !state.showTradeForm
      }
    },
    updateWatchListData: (state, action) => {
      state.data = action.payload;
    },
    addSymbolToWatchList: (state, action) => {
      const  symbol = action.payload
      const newItem = {
        ask: 0,
        askChange: "unchanged",
        bid: 0,
        bidChange: "falling",
        changePercent: 0,
        changePoints: 0,
        chartMode: "by bid price",
        contractSize: "",
        description: "",
        digits: "5",
        margin: "0",
        maxVolume: 100,
        minVolume: 0.01,
        source: "",
        spread: "Off",
        symbol: symbol,
        volume: 1,
      };

      state.data = [...state.data , newItem]
    },
    removeSymbolFromWatchList: (state, action) => {
        const symbol = action.payload
        const data = state.data.filter((item) => item.symbol !== symbol)
        state.data = data
        
    },
    resetState: (state) => {
     return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWatchlistData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWatchlistData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchWatchlistData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  toggleWatchList,
  changeShowOptions,
  updateWatchListData,
  changeShowTradeForm,
  resetState: resetWatchlistState,
  addSymbolToWatchList,
  removeSymbolFromWatchList
} = watchlistSlice.actions;

export default watchlistSlice.reducer;
