import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "ROC",
    period: 3,
    timeFrame: 1,
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "orange",
        lineStyle: 0,
        lineWidth: 1,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
}


const rocSlice = createSlice({
    name: "RSI",
    initialState,
    reducers: ({
        setTimeFrame: (state, action) => {
            const newTimeFrame = action.payload
            state.timeFrame = newTimeFrame
        },
        setPeriod: (state, action) => {
            const newPeriod = action.payload
            state.period = newPeriod
        },
        setThickness: (state, action) => {
            const newThickness = action.payload
            state.styles.lineWidth = newThickness
        },
        setColor: (state, action) => {
            const newColor = action.payload
            state.styles.color = newColor
        },
        setLineStyle: (state, action) => {
            state.styles.lineStyle = action.payload

        },
        resetRoc: () => initialState
    })
})

export const {setColor, setPeriod, setThickness, setTimeFrame, setLineStyle, resetRoc} = rocSlice.actions

export default rocSlice.reducer