import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  period: 9,
  timeFrame: 1,
  source: "open",
  styles: {
    color: "#87CEEB",
    lineStyle: 0, // solid:0, dotted: 1, dashed:2, LargeDashed:3, sparseDotted:4
    lineWidth: 1,
    lineType: 0, // simple:0, WithSteps:1, curved:2
    pointMarkersVisible: false,
    pointMarkersRadius: undefined,
    crosshairMarkerVisible: true,
    crosshairMarkerRadius: 4,
    lineVisible: true,
    priceLineVisible: false,
    lastPriceAnimation: 1,
    lastValueVisible: true,
  },
};

const smaSlice = createSlice({
  name: "sma",
  initialState,
  reducers: {
    setSmaColor: (state, action) => {
      state.styles.color = action.payload;
    },
    setSmaPeriod: (state, action) => {
      state.period = action.payload;
    },
    setSmaTimeFrame: (state, action) => {
      state.timeFrame = action.payload;
    },
    setSmaCalcSource: (state, action) => {
      state.source = action.payload;
    },
    setSmaLineStyle: (state, action) => {
      state.styles.lineStyle = action.payload;
    },
    setSmaLineType: (state, action) => {
      state.styles.lineType = action.payload;
    },
    setSmaLineWidth: (state, action) => {
      state.styles.lineWidth = action.payload;
    },
    setSmaPointMarkerVisible: (state, action) => {
      state.styles.pointMarkersVisible = action.payload;
    },
    resetSmaSettings: (state) => {
      return initialState;
    },
    toggleSmaLineVisibility: (state) => {
      state.styles.lineVisible = !state.styles.lineVisible;
    },
    toggleSmaPriceLineVisibility: (state) => {
      state.styles.priceLineVisible = !state.styles.priceLineVisible;
    },
  },
});

export const {
  setSmaCalcSource,
  setSmaColor,
  setSmaLineStyle,
  setSmaLineType,
  setSmaLineWidth,
  setSmaPeriod,
  setSmaTimeFrame,
  setSmaPointMarkerVisible,
  toggleSmaLineVisibility,
  toggleSmaPriceLineVisibility,
  resetSmaSettings,
} = smaSlice.actions;

export default smaSlice.reducer;
