import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setTimeFrame,
  setFast,
  setSlow,
  setColor,
  setLineStyle,
  setLineWidth,
  resetApo,
} from "../../../store/indicators/apoSlice";
import styles from '../index.module.css';
import IndicatorSettingWrapper from "../../wrappers/IndicatorSettingWrapper";
import { changeTime, fetchChartData } from "../../../store/chartSlice";

const APOComponent = ({ onClose }) => {
  const dispatch = useDispatch();
  const apoState = useSelector((state) => state.apo);
  const [activeTab, setActiveTab] = useState("general");

  const handleInputChange = (e, actionCreator) => {
    const value =
      e.target.type === "number"
        ? Math.max(Number(e.target.min), Number(e.target.value))
        : e.target.value;
    dispatch(actionCreator(value));
    if (actionCreator === setTimeFrame) {
      dispatch(changeTime(value));
      dispatch(fetchChartData());
    }
  };

  return (
    <IndicatorSettingWrapper onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.tabs}>
          <div
            className={`${styles.tab} ${
              activeTab === "general" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("general")}
          >
            General Settings
          </div>
          <div
            className={`${styles.tab} ${
              activeTab === "style" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("style")}
          >
            Style Settings
          </div>
        </div>

        <div
          className={`${styles.tabContent} ${
            activeTab === "general" ? styles.active : ""
          }`}
        >
          <div className={styles.setting}>
            <label>Time Frame:</label>
            <select
              value={apoState.timeFrame}
              onChange={(e) => handleInputChange(e, setTimeFrame)}
            >
              <option value={1}>1 minute</option>
              <option value={5}>5 minutes</option>
              <option value={15}>15 minutes</option>
              <option value={30}>30 minutes</option>
              <option value={60}>1 hour</option>
              <option value={240}>4 hours</option>
              <option value={1440}>1 day</option>
            </select>
          </div>
          <div className={styles.setting}>
            <label>Fast:</label>
            <input
              type="number"
              value={apoState.fast}
              onChange={(e) => handleInputChange(e, setFast)}
            />
          </div>
          <div className={styles.setting}>
            <label>Slow:</label>
            <input
              type="number"
              value={apoState.slow}
              onChange={(e) => handleInputChange(e, setSlow)}
            />
          </div>
        </div>

        <div
          className={`${styles.tabContent} ${
            activeTab === "style" ? styles.active : ""
          }`}
        >
          <div className={styles.setting}>
            <label>Color:</label>
            <input
              type="color"
              value={apoState.styles.color}
              onChange={(e) => handleInputChange(e, setColor)}
            />
          </div>
          <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={apoState.styles.lineStyle}
              onChange={(e) => handleInputChange(e, setLineStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
              <option value={3}>Large Dashed</option>
              <option value={4}>Sparse Dotted</option>
            </select>
          </div>
          <div className={styles.setting}>
            <label>Line Width:</label>
            <input
              type="number"
              value={apoState.styles.lineWidth}
              onChange={(e) => handleInputChange(e, setLineWidth)}
            />
          </div>
        </div>
        <button onClick={() => dispatch(resetApo())}>Reset</button>
      </div>
    </IndicatorSettingWrapper>
  );
};

export default APOComponent;
