import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeAuthToken } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { TbCalendarStats } from "react-icons/tb";
import { IoLogOut } from "react-icons/io5";
import { MdContactPhone } from "react-icons/md";
import { FaLanguage } from "react-icons/fa6";
import { resetOnLoadState } from "../../../store/onLoadSlice";
import { resetMobileViewState } from "../../../store/mobileViewSlice";
import { resetMode } from "../../../store/modeSlice";
import { resetWatchlistState } from "../../../store/watchlistSlice";
import { resetSymbols } from "../../../store/symbolsSlice";
import { resetActiveIndicator } from "../../../store/indicators/activeIndicator";
import { resetIndicators } from "../../../store/indicators/indicatorsSlice";
import { resetUserState } from "../../../store/userSlice";
import Account from "../../account/Account";

const Menu = () => {
  const user = useSelector((state) => state.user.userData);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = () => {
    dispatch(resetOnLoadState());
    dispatch(resetMobileViewState());
    dispatch(resetMode());
    dispatch(resetWatchlistState());
    dispatch(resetSymbols());
    dispatch(resetActiveIndicator());
    dispatch(resetIndicators());
    dispatch(resetUserState());
    removeAuthToken("AUTH-TOKEN");
    navigate("/login");
  };

  return (
    <div className="bg-white shadow-lg border-none absolute left-10 top-0 z-50 text-nowrap">
      <ul className="list-none p-0">
        <li
          className="flex items-center p-2 gap-2 transition duration-200 ease-in-out hover:bg-violet-600 hover:text-white"
          onClick={() => setShow((prev) => !prev)}
        >
          <FaUser size={18} color="green" className="mr-2" />
          <span>{user.email}</span>
        </li>
        <li className="flex items-center p-2 gap-2 transition duration-200 ease-in-out hover:bg-violet-600 hover:text-white">
          <a
            href="https://skycapmarket.com/Economic-calendar.html"
            target="_blank"
            rel="noopener noreferrer"
            className="nodecoration flex items-center"
          >
            <TbCalendarStats size={18} className="mr-2" />
            <span>Economic Calendar</span>
          </a>
        </li>
        <li className="flex items-center p-2 gap-2 transition duration-200 ease-in-out hover:bg-violet-600 hover:text-white">
          <FaLanguage size={18} className="mr-2" />
          <span>Language EN</span>
        </li>
        <li className="flex items-center p-2 gap-2 transition duration-200 ease-in-out hover:bg-violet-600 hover:text-white">
          <a
            href="https://skycapmarket.com/contact.html"
            target="_blank"
            rel="noopener noreferrer"
            className="nodecoration flex items-center"
          >
            <MdContactPhone size={18} className="mr-2" />
            <span>Contact Us</span>
          </a>
        </li>
        <li
          className="flex items-center p-2 gap-2 transition duration-200 ease-in-out hover:bg-violet-600 hover:text-white cursor-pointer"
          onClick={handleLogOut}
        >
          <IoLogOut size={18} color="red" className="mr-2" />
          <span>Logout</span>
        </li>
      </ul>
      {show && (
        <div className="z-1 absolute top-0 left-3/4 bg-white/90 p-2 rounded shadow-md text-xs">
          <Account />
        </div>
      )}
    </div>
  );
};

export default Menu;
