import {
  apo,
  aroon,
  bop,
  cci,
  cfo,
  dema,
  exponentialMovingAverage,
  mi,
  simpleMovingAverage,
  macd,
  psar,
  qstick,
  tema,
  trima,
  trix,
  vwma,
  ao,
  vortex,
  cmo,
  roc,
  rsi,
  atr,
  kc,
  dc,
  bb,
} from "indicatorts";

import { barSeriesOptions } from "./chartOptions/mainChartOptions";

export const initializeIndicators = (
  indicatorsChart,
  ohlcData,
  volumeData,
  indicators,
  dispatch,
  chart,
  ema,
  sma,
  imcs,
  calculateIchimokuCloud,
  volumeChart,
  apos,
  withVolume,
  aroons,
  cfos,
  ccis,
  demas,
  mis,
  macds,
  pSARs,
  qSticks,
  temas,
  trimas,
  trixs,
  vwmas,
  aos,
  vis,
  cmos,
  rocs,
  rsis,
  atrs,
  kcs,
  dcs,
  bbs,
  bops,
  setIndicatorAsNewChart
) => {
  const computeResult = (data) => {
    return data.map((i, index) => ({
      time: ohlcData[index].time,
      value: i,
    }));
  };
  const addLineSeriesChart = (chart, options) => {
    return chart.addLineSeries(options);
  };

  if (indicators.names.sma.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(false));
    const smas = addLineSeriesChart(chart, sma.styles);
    let sourceKey = sma.source;
    const result = simpleMovingAverage(
      ohlcData.map((i) => i[sourceKey]),
      { period: sma.period }
    );

    smas.setData(computeResult(result));
  }

  if (indicators.names.ema.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(false));

    const emas = addLineSeriesChart(chart, ema.styles);
    let sourceKey = ema.source;
    const result = exponentialMovingAverage(
      ohlcData.map((i) => i[sourceKey]),
      { period: ema.period }
    );

    emas.setData(computeResult(result));
  }

  if (
    indicators.names.ichimokuCloud.visibility &&
    indicators.singleMAVisibility
  ) {
    dispatch(setIndicatorAsNewChart(false));

    const tenkanSenSeries = addLineSeriesChart(
      chart,
      imcs.tenkanSenSeries.styles
    );
    const kijunSenSeries = addLineSeriesChart(
      chart,
      imcs.kijunSenSeries.styles
    );
    const senkouSpanASeries = addLineSeriesChart(
      chart,
      imcs.senkouSpanASeries.styles
    );
    const senkouSpanBSeries = addLineSeriesChart(
      chart,
      imcs.senkouSpanBSeries.styles
    );

    const chikouSpanSeries = addLineSeriesChart(
      chart,
      imcs.chikouSpanSeries.styles
    );

    const ichimokuCloudData = calculateIchimokuCloud(ohlcData);
    const computeICResult = (data, name) => {
      return data.map((item) => ({
        time: item.time,
        value: item[name],
      }));
    };

    tenkanSenSeries.setData(computeICResult(ichimokuCloudData, "tenkanSen"));
    kijunSenSeries.setData(computeICResult(ichimokuCloudData, "kijunSen"));
    senkouSpanASeries.setData(
      computeICResult(ichimokuCloudData, "senkouSpanA")
    );
    senkouSpanBSeries.setData(
      computeICResult(ichimokuCloudData, "senkouSpanB")
    );
    chikouSpanSeries.setData(computeICResult(ichimokuCloudData, "chikouSpan"));
  }

  const barSeries = chart.addBarSeries(barSeriesOptions);

  if (withVolume) {
    const histogram = volumeChart.addHistogramSeries({});
    histogram.setData(volumeData);
  }

  if (indicators.names.apo.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const apoi = addLineSeriesChart(indicatorsChart, apos.styles);

    let result = apo(
      ohlcData.map((i) => i.close),
      { fast: apos.fast, slow: apos.slow }
    );
    apoi.setData(computeResult(result));
  }

  // new chart
  if (indicators.names.aroon.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    let { up, down } = aroon(
      ohlcData.map((i) => i.high),
      ohlcData.map((i) => i.low),
      { period: aroons.period }
    );
    const upi = addLineSeriesChart(indicatorsChart, aroons.up.styles);

    const downi = addLineSeriesChart(indicatorsChart, aroons.down.styles);
    upi.setData(computeResult(up));
    downi.setData(computeResult(down));
  }
  // new chart
  if (indicators.names.bop.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const bopi = addLineSeriesChart(indicatorsChart, bops.styles);

    let result = bop(
      ohlcData.map((o) => o.open),
      ohlcData.map((h) => h.high),
      ohlcData.map((l) => l.low),
      ohlcData.map((c) => c.close)
    );

    bopi.setData(computeResult(result));
  }
  // new chart
  if (indicators.names.cfo.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const cfoi = addLineSeriesChart(indicatorsChart, cfos.styles);

    let sourceKey = cfos.source;
    let result = cfo(
      ohlcData.map((c) => c[sourceKey]),
      { period: cfos.period }
    );
    cfoi.setData(computeResult(result));
  }
  // new chart
  if (indicators.names.cci.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const ccii = addLineSeriesChart(indicatorsChart, ccis.styles);

    let result = cci(
      ohlcData.map((h) => h.high),
      ohlcData.map((l) => l.low),
      ohlcData.map((c) => c.close),
      { period: ccis.period }
    );
    ccii.setData(computeResult(result));
  }

  if (indicators.names.dema.visibility && indicators.singleMAVisibility) {
    const demai = addLineSeriesChart(chart, demas.styles);
    dispatch(setIndicatorAsNewChart(false));
    let sourceKey = demas.source;
    let result = dema(
      ohlcData.map((c) => c[sourceKey]),
      { period: demas.period }
    );
    demai.setData(computeResult(result));
  }
  // new chart
  if (indicators.names.mi.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const mii = addLineSeriesChart(indicatorsChart, mis.styles);

    let result = mi(
      ohlcData.map((h) => h.high),
      ohlcData.map((l) => l.low),
      {
        emaPeriod: mis.period.emaPeriod,
        miPeriod: mis.period.miPeriod,
      }
    );
    mii.setData(computeResult(result));
  }
  // new chart
  if (indicators.names.macd.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const macdLinei = addLineSeriesChart(
      indicatorsChart,
      macds.styles.macdLine.styles
    );

    const signalLinei = addLineSeriesChart(
      indicatorsChart,
      macds.styles.signalLine.styles
    );
    const histogram = indicatorsChart.addHistogramSeries(
      macds.styles.histogram.styles
    );

    let sourceKey = macds.source;
    let { macdLine, signalLine } = macd(
      ohlcData.map((c) => c[sourceKey]),
      {
        fast: macds.fast,
        slow: macds.slow,
        signal: macds.signal,
      }
    );
    macdLinei.setData(computeResult(macdLine));

    signalLinei.setData(computeResult(signalLine));

    histogram.setData(
      macdLine.map((i, index) => ({
        time: ohlcData[index].time,
        value: i - signalLine[index],
        color: i > 0 ? "red" : "green",
      }))
    );
  }

  if (indicators.names.psar.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(false));

    // const psari = chart.addLineSeries(pSARs.styles);
    const psari = addLineSeriesChart(chart, pSARs.styles);

    let { trends, psarResult } = psar(
      ohlcData.map((h) => h.high),
      ohlcData.map((l) => l.low),
      ohlcData.map((c) => c.close),

      {
        step: pSARs.step,
        max: pSARs.max,
      }
    );
    psari.setData(computeResult(psarResult));
  }
  // newChart
  if (indicators.names.qstick.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));
    const qsticki = indicatorsChart.addBaselineSeries(qSticks.styles);

    let result = qstick(
      ohlcData.map((o) => o.open),
      ohlcData.map((c) => c.close),
      { period: qSticks.period }
    );
    qsticki.setData(computeResult(result));
  }

  if (indicators.names.tema.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(false));

    const temai = addLineSeriesChart(chart, temas.styles);

    let sourceKey = temas.source;

    let result = tema(
      ohlcData.map((c) => c[sourceKey]),
      { period: temas.period }
    );
    temai.setData(computeResult(result));
  }

  if (indicators.names.trima.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(false));
    const trimai = addLineSeriesChart(chart, trimas.styles);

    let sourceKey = trimas.source;
    let result = trima(
      ohlcData.map((c) => c[sourceKey]),
      { period: trimas.period }
    );
    trimai.setData(computeResult(result));
  }

  // new chart
  if (indicators.names.trix.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const trixi = addLineSeriesChart(indicatorsChart, trixs.styles);

    let sourceKey = trixs.source;

    let result = trix(
      ohlcData.map((c) => c[sourceKey]),
      { period: trixs.period }
    );
    trixi.setData(computeResult(result));
  }

  if (indicators.names.vwma.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(false));

    const vwmai = addLineSeriesChart(chart, vwmas.styles);
    let sourceKey = vwmas.source;

    let result = vwma(
      ohlcData.map((c) => c[sourceKey]),
      volumeData.map((v) => v.value),
      { period: vwmas.period }
    );
    vwmai.setData(computeResult(result));
  }
  // new chart
  if (indicators.names.ao.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const aoi = indicatorsChart.addHistogramSeries(aos.styles);

    let result = ao(
      ohlcData.map((h) => h.high),
      ohlcData.map((l) => l.low),

      { fast: aos.fast, slow: aos.slow }
    );
    aoi.setData(
      result.map((i, index) => ({
        time: ohlcData[index].time,
        value: i,
        color: i > 0 ? aos.styles.upColor : aos.styles.downColor,
      }))
    );
  }
  // new chart
  if (indicators.names.vi.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    // document.getElementById("mainChart").style.height = "49vh";

    const plusvi = addLineSeriesChart(indicatorsChart, vis.styles.plus.styles);
    const minusvi = addLineSeriesChart(
      indicatorsChart,
      vis.styles.minus.styles
    );

    let { plus, minus } = vortex(
      ohlcData.map((h) => h.high),
      ohlcData.map((l) => l.low),
      ohlcData.map((c) => c.close),
      { period: vis.period }
    );
    plusvi.setData(computeResult(plus));
    minusvi.setData(computeResult(minus));
  }
  // new chart
  // error starts
  if (indicators.names.cmo.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    // document.getElementById("mainChart").style.height = "49vh";

    const cmoi = addLineSeriesChart(indicatorsChart, cmos.styles);
    let { adResult, cmoResult } = cmo(
      ohlcData.map((h) => h.high),
      ohlcData.map((l) => l.low),
      ohlcData.map((c) => c.close),
      volumeData.map((v) => v.value),
      { fast: cmos.fast, slow: cmos.slow }
    );

    cmoi.setData(computeResult(cmoResult));
  }
  // error ends

  // new chart
  if (indicators.names.roc.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const roci = addLineSeriesChart(indicatorsChart, rocs.styles);

    let result = roc(
      ohlcData.map((c) => c.close),
      { period: rocs.period }
    );
    roci.setData(computeResult(result));
  }
  // new chart
  // not same as in trading view - starts
  if (indicators.names.rsi.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const rsii = addLineSeriesChart(indicatorsChart, rsis.styles);
    let sourceKey = rsis.source;

    let result = rsi(
      ohlcData.map((c) => c[sourceKey]),
      { period: rsis.period }
    );
    rsii.setData(computeResult(result));
  }

  // not same as in trading view - ends

  // new chart
  //what to use atr or tr - starts
  if (indicators.names.atr.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(true));

    const atri = addLineSeriesChart(indicatorsChart, atrs.styles);

    let { trLine, atrLine } = atr(
      ohlcData.map((h) => h.high),
      ohlcData.map((l) => l.low),
      ohlcData.map((c) => c.close),
      { period: atrs.period }
    );
    atri.setData(computeResult(atrLine));
  }

  //what to use atr or tr - ends
  if (indicators.names.kc.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(false));

    const upperkc = addLineSeriesChart(chart, kcs.styles.upper.styles);
    const middlekc = addLineSeriesChart(chart, kcs.styles.middle.styles);
    const lowerkc = addLineSeriesChart(chart, kcs.styles.lower.styles);

    let { upper, middle, lower } = kc(
      ohlcData.map((h) => h.high),
      ohlcData.map((l) => l.low),
      ohlcData.map((c) => c.close),
      { period: kcs.period }
    );
    upperkc.setData(computeResult(upper));
    middlekc.setData(computeResult(middle));
    lowerkc.setData(computeResult(lower));
  }

  if (indicators.names.dc.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(false));

    const upperdc = addLineSeriesChart(chart, dcs.styles.upper.styles);
    const middledc = addLineSeriesChart(chart, dcs.styles.middle.styles);
    const lowerdc = addLineSeriesChart(chart, dcs.styles.lower.styles);

    let sourceKey = dcs.source;

    let { upper, middle, lower } = dc(
      ohlcData.map((c) => c[sourceKey]),
      { period: dcs.period }
    );
    upperdc.setData(computeResult(upper));
    middledc.setData(computeResult(middle));
    lowerdc.setData(computeResult(lower));
  }

  if (indicators.names.bb.visibility && indicators.singleMAVisibility) {
    dispatch(setIndicatorAsNewChart(false));

    const upperbb = addLineSeriesChart(chart, bbs.styles.upper.styles);
    const middlebb = addLineSeriesChart(chart, bbs.styles.middle.styles);
    const lowerbb = addLineSeriesChart(chart, bbs.styles.lower.styles);
    let sourceKey = bbs.source;

    let { upper, middle, lower } = bb(
      ohlcData.map((c) => c[sourceKey]),
      { period: bbs.period }
    );
    upperbb.setData(computeResult(upper));
    middlebb.setData(computeResult(middle));
    lowerbb.setData(computeResult(lower));
  }
};
