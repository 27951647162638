import { configureStore } from '@reduxjs/toolkit'
import userReducer from "./userSlice"
import symbolsReducer from "./symbolsSlice"
import watchlistReducer from './watchlistSlice'
import modeSliceReducer from './modeSlice'
import mobileViewReducer from './mobileViewSlice'
import chartSliceReducer from './chartSlice'
import appSliceReducer from './appSlice'
import onLoadSlice from './onLoadSlice'
import indicatorsSlice from './indicators/indicatorsSlice'
import smaSlice from "./indicators/smaSlice"
import emaSlice from './indicators/emaSlice'
import activeIndicatorSlice from './indicators/activeIndicator'
import rsiSlice from './indicators/rsiSlice'
import bbSlice from './indicators/bbSlice'
import aoSlice from './indicators/aoSlice'
import dcSlice from './indicators/dcSlice'
import kcSlice from './indicators/kcSlice'
import atrSlice from './indicators/atrSlice'
import rocSlice from './indicators/rocSlice'
import cmoSlice from './indicators/cmoSlice'
import viSlice from './indicators/viSlice'
import vwmaSlice from './indicators/vmwaSlice'
import trixSlice from './indicators/trixSlice'
import trimaSlice from './indicators/trimaSlice'
import temaSlice from './indicators/temaSlice'
import qstickSlice from './indicators/qstickSlice'
import psarSlice from './indicators/psarSlice'
import macdSlice from './indicators/macdSlice'
import miSlice from './indicators/miSlice'
import demaSlice from './indicators/demaSlice'
import cciSlice from './indicators/cciSlice'
import cfoSlice from './indicators/cfoSlice'
import aroonSlice from './indicators/aroonSlice'
import bopSlice from './indicators/bopSlice'
import apoSlice from './indicators/apoSlice'
import ichimokuCloudSlice from './indicators/ichimokuCloudSlice'
import lineSlice from './drawingsOnChart/lineSlice'
import chartSettingsReducer from './chartSettings'
import fibRReducer from './drawingsOnChart/fibR'
import regressionLineSlice from './drawingsOnChart/regressionLineSlice'
import resizableSlice from './resizableSlice'


export const store = configureStore({
  reducer: {
    onLoad: onLoadSlice,
    app: appSliceReducer,
    user: userReducer,
    symbols: symbolsReducer,
    watchlist: watchlistReducer,
    mode: modeSliceReducer,
    mobileView: mobileViewReducer,
    chart: chartSliceReducer,
    indicators: indicatorsSlice,
    sma: smaSlice,
    activeIndicator: activeIndicatorSlice,
    ema: emaSlice,
    rsi: rsiSlice,
    bb: bbSlice,
    ao: aoSlice,
    dc: dcSlice,
    kc: kcSlice,
    atr: atrSlice,
    roc: rocSlice,
    cmo: cmoSlice,
    vi: viSlice,
    vwma: vwmaSlice,
    trix: trixSlice,
    trima: trimaSlice,
    tema: temaSlice,
    qStick: qstickSlice,
    pSAR: psarSlice,
    macd: macdSlice,
    mi: miSlice,
    dema: demaSlice,
    cci: cciSlice,
    cfo: cfoSlice,
    aroon: aroonSlice,
    bop: bopSlice,
    apo: apoSlice,
    imc: ichimokuCloudSlice,
    line: lineSlice,
    chartSettings: chartSettingsReducer,
    fibR: fibRReducer,
    regressionChannel: regressionLineSlice,
    resizable: resizableSlice
  },


})