import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    regressionChannelStart: null,
    regressionChannelEnd: null,
}


const regressionLineSlice = createSlice({
    name: "regressionLine",
    initialState,
    reducers: {

        setRegressionChannelStart: (state, action) => {
            state.regressionChannelStart = action.payload;
        },
        setRegressionChannelEnd: (state, action) => {
            state.regressionChannelEnd = action.payload;
        },
        resetRegressionLine: () => initialState

    }
})

export const { setRegressionChannelEnd, setRegressionChannelStart, resetRegressionLine } = regressionLineSlice.actions


export default regressionLineSlice.reducer




export const calculateRegressionChannel = (start, end, data) => {
    // Assuming start and end are { time, value } objects
    const startIndex = data.findIndex((d) => d.time === start.time);
    const endIndex = data.findIndex((d) => d.time === end.time);

    if (startIndex === -1 || endIndex === -1) return null;

    const points = data.slice(startIndex, endIndex + 1);
    const n = points.length;

    // Use closing prices for calculations
    const sumX = points.reduce((acc, point, index) => acc + index, 0);
    const sumY = points.reduce((acc, point) => acc + point.close, 0);
    const sumXY = points.reduce((acc, point, index) => acc + index * point.close, 0);
    const sumX2 = points.reduce((acc, point, index) => acc + index * index, 0);

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    const regressionLine = points.map((point, index) => ({
        time: point.time,
        value: slope * index + intercept,
    }));

    const deviations = points.map((point, index) => point.close - (slope * index + intercept));
    const stdDev = Math.sqrt(deviations.reduce((acc, deviation) => acc + deviation * deviation, 0) / n);

    const upperChannel = regressionLine.map((point) => ({
        time: point.time,
        value: point.value + stdDev,
    }));

    const lowerChannel = regressionLine.map((point) => ({
        time: point.time,
        value: point.value - stdDev,
    }));

    return { regressionLine, upperChannel, lowerChannel };
};
