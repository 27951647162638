import React from "react";
import { useSelector } from "react-redux";
import styles from "./Heading.module.css";

const Heading = () => {
  const { description, symbol, time } = useSelector((state) => state.chart);
  const timeInString = (time) => {
    if (time === 1) {
      return "M1";
    } else if (time == 5) {
      return "M5";
    } else if (time == 15) {
      return "M15";
    } else if (time == 30) {
      return "M30";
    } else if (time == 60) {
      return "H1";
    } else if (time == 240) {
      return "H4";
    } else if (time == 1440) {
      return "D1";
    } else if (time == 10080) {
      return "W1";
    } else if (time == 43200) {
      return "MN";
    } else {
      return "";
    }
  };

  return (
    <div className={styles.heading}>
      {`${symbol}, ${timeInString(time)} : ${description}`}
    </div>
  );
};

export default Heading;
