import React from "react";
import SearchBar from "./search/SearchBar";
import WatchList from "./watchlist/WatchList";
import { useSelector } from "react-redux";

const SearchWatchListSection = ({ width }) => {
  const isDetailVisible = useSelector((state) => state.mode.show);
  const chartHeight = useSelector((state) => state.resizable.chartHeight);

  return (
    <div
    >
      <div
        className="relative mb-2 pt-2 "
        style={{
          height:
            isDetailVisible && window.innerWidth > 800
              ? `${chartHeight + 3}dvh`
              : "88vh",
        }}
      >
        <SearchBar />
        <WatchList />
      </div>
    </div>
  );
};

export default SearchWatchListSection;
