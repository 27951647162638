import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  name: "ATR",
  period: 14,
  timeFrame: 1,
  styles: {
    lineVisible: true,
    priceLineVisible: false,
    color: "red",
    lineStyle: 0,
    lineWidth: 1,
    lineType: 0,
    pointMarkersVisible: false,
    pointMarkersRadius: undefined,
    lastPriceAnimation: 1,
    lastValueVisible: true,
  }
}

const atrSlice = createSlice({
  name: "ATR",
  initialState,
  reducers: {
    setPeriod: (state, action) => {
      const newPeriod = action.payload
      state.period = newPeriod
    },
    setTimeFrame: (state, action) => {
      state.timeFrame = action.payload
    },
    setThickness: (state, action) => {
      const newThickness = action.payload
      state.styles.lineWidth = newThickness
    },
    setColor: (state, action) => {
      const newColor = action.payload
      state.styles.color = newColor
    },
    setLineStyle: (state, action) => {
      state.styles.lineStyle = action.payload
    },
    resetAtr: () => initialState
  }
})

export const { setColor, setPeriod, setThickness, setLineStyle,setTimeFrame,resetAtr } = atrSlice.actions

export default atrSlice.reducer