import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "fibonacciR",
    fibStart: null,
    fibEnd: null,
}

const fibR = createSlice({
    name: "fibR",
    initialState,
    reducers: {
        setFibStart: (state, action) => {
            state.fibStart = action.payload
        },
        setFibEnd: (state, action) => {
            state.fibEnd = action.payload
        },
        resetFibR: () => initialState
    }

})
export const { setFibEnd, setFibStart, resetFibR } = fibR.actions

export default fibR.reducer


export const calculateFibonacciLevels = (start, end) => {
    const levels = [0, 23.6, 38.2, 50, 61.8, 100];
    const diff = end.value - start.value;
    return levels.map(level => ({
        time: start.time,
        value: start.value + (diff * (level / 100)),
        level
    }));
};