import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "VI",
    timeFrame: 1,
    period: 14,
    styles: {
        plus: {
            styles: {
                lineVisible: true,
                priceLineVisible: false,
                color: "blue",
                lineStyle: 0,
                lineWidth: 1,
                lineType: 0,
                pointMarkersVisible: false,
                pointMarkersRadius: undefined,
                lastPriceAnimation: 1,
                lastValueVisible: true,
            }
        },
        minus: {
            styles: {
                lineVisible: true,
                priceLineVisible: false,
                color: "red",
                lineStyle: 0,
                lineWidth: 1,
                lineType: 0,
                pointMarkersVisible: false,
                pointMarkersRadius: undefined,
                lastPriceAnimation: 1,
                lastValueVisible: true,
            }
        }
    }
}

const viSlice = createSlice({
    name: "VI",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setPeriod: (state, action) => {
            state.period = action.payload;
        },
        setPlusColor: (state, action) => {
            state.styles.plus.styles.color = action.payload;
        },
        setPlusThickness: (state, action) => {
            state.styles.plus.styles.lineWidth = action.payload;
        },
        setMinusColor: (state, action) => {
            state.styles.minus.styles.color = action.payload;
        },
        setMinusThickness: (state, action) => {
            state.styles.minus.styles.lineWidth = action.payload;
        },
        setMinusLineStyle: (state, action) => {
            state.styles.minus.styles.lineStyle = action.payload;
        },setPlusLineStyle: (state, action) => {
            state.styles.plus.styles.lineStyle = action.payload;
        },
        resetVi : () => initialState
    }
})

export const { setTimeFrame, setPeriod, setPlusColor, setPlusThickness, setMinusColor, setMinusThickness, resetVi,setMinusLineStyle, setPlusLineStyle } = viSlice.actions;

export default viSlice.reducer;
