import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "BOP",
    timeFrame: 1,
    show: false,
    styles: {
        lineVisible: true,
        priceLineVisible: false,
        color: "orange",
        lineStyle: 0,
        source: "open",
        lineWidth: 2,
        lineType: 0,
        pointMarkersVisible: false,
        pointMarkersRadius: undefined,
        lastPriceAnimation: 1,
        lastValueVisible: true,
    }
};

const bopSlice = createSlice({
    name: "BOP",
    initialState,
    reducers: {
        setTimeFrame: (state, action) => {
            state.timeFrame = action.payload;
        },
        setShow: (state, action) => {
            state.show = action.payload;
        },
        setColor: (state, action) => {
            state.styles.color = action.payload;
        },
        setLineStyle: (state, action) => {
            state.styles.lineStyle = action.payload;
        },
        setSource: (state, action) => {
            state.styles.source = action.payload;
        },
        setLineWidth: (state, action) => {
            state.styles.lineWidth = action.payload;
        },
        resetBop : () => {return initialState}
    }
});

export const {
    setTimeFrame,
    setShow,
    setColor,
    setLineStyle,
    setSource,
    setLineWidth,
    resetBop
} = bopSlice.actions;

export default bopSlice.reducer;
