import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    modeRefresh: false,
    walletRefresh: false,
    watchlistRefresh: false,
    chartRefresh:false,  
    appRefresh:false ,
    showInstantOrder: true,
    
}

const appSlice = createSlice ({
    
        name: "app",
        initialState: initialState,
        reducers: {
            setShowInstantOrder : (state) => {
                state.showInstantOrder = !state.showInstantOrder
            },
            refreshMode : (state) => {
                state.modeRefresh = !state.modeRefresh
            },
            refreshWallet : (state) => {
                state.walletRefresh = !state.walletRefresh
            },
            refreshWatchlist : (state) => {
                state.watchlistRefresh = !state.watchlistRefresh
            },
            refreshChart: (state) => {
                state.chartRefresh = !state.chartRefresh
            },
            refreshApp: (state) => {
                state.appRefresh = !state.appRefresh
            },
            resetApp: (state) => {
                return initialState
            }
          
        }
        
    
})

export const {refreshChart, refreshMode, refreshWallet, refreshWatchlist, refreshApp, setShowInstantOrder, resetApp} = appSlice.actions



export default appSlice.reducer