import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setTimeFrame,
    setEmaPeriod,
    setMiPeriod,
    setColor,
    setThickness,
    setLineStyle,
    resetMi
} from '../../../store/indicators/miSlice';
import styles from '../index.module.css';
import IndicatorSettingWrapper from '../../wrappers/IndicatorSettingWrapper';
import { changeTime, fetchChartData } from '../../../store/chartSlice';

const MIComponent = ({onClose}) => {
    const dispatch = useDispatch();
    const miState = useSelector(state => state.mi);
    const [activeTab, setActiveTab] = useState('general');

    const handleInputChange = (e, actionCreator) => {
        const value = e.target.type === 'number' ? Math.max(Number(e.target.min), Number(e.target.value)) : e.target.value;
        dispatch(actionCreator(value));
        if (actionCreator === setTimeFrame) {
          dispatch(changeTime(value));
          dispatch(fetchChartData());
        }
      };

    return (
        <IndicatorSettingWrapper onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <div
                        className={`${styles.tab} ${activeTab === 'general' ? styles.active : ''}`}
                        onClick={() => setActiveTab('general')}
                    >
                        General Settings
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === 'style' ? styles.active : ''}`}
                        onClick={() => setActiveTab('style')}
                    >
                        Style Settings
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'general' ? styles.active : ''}`}>
                <div className={styles.setting}>
            <label>Time Frame:</label>
            <select
              value={miState.timeFrame}
              onChange={(e) => handleInputChange(e, setTimeFrame)}
            >
              <option value={1}>1 minute</option>
              <option value={5}>5 minutes</option>
              <option value={15}>15 minutes</option>
              <option value={30}>30 minutes</option>
              <option value={60}>1 hour</option>
              <option value={240}>4 hours</option>
              <option value={1440}>1 day</option>N
            </select>
          </div>
                    <div className={styles.setting}>
                        <label>EMA Period:</label>
                        <input
                            min="1"
                            type="number"
                            value={miState.period.emaPeriod}
                            onChange={(e) => handleInputChange(e, setEmaPeriod)}
                        />
                    </div>
                    <div className={styles.setting}>
                        <label>MI Period:</label>
                        <input
                                                         min="1"

                            type="number"
                            value={miState.period.miPeriod}
                            onChange={(e) => handleInputChange(e, setMiPeriod)}
                        />
                    </div>
                </div>

                <div className={`${styles.tabContent} ${activeTab === 'style' ? styles.active : ''}`}>
                    <div className={styles.setting}>
                        <label>Color:</label>
                        <input
                            type="color"
                            value={miState.styles.color}
                            onChange={(e) => handleInputChange(e, setColor)}
                        />
                    </div>
                    <div className={styles.setting}>
            <label>Line Style:</label>
            <select
              value={miState.styles.lineStyle}
              onChange={(e) => handleInputChange(e, setLineStyle)}
            >
              <option value={0}>Solid</option>
              <option value={1}>Dotted</option>
              <option value={2}>Dashed</option>
              <option value={3}>Large Dashed</option>
              <option value={4}>Sparse Dotted</option>
            </select>
          </div>
                    <div className={styles.setting}>
                        <label>Line Thickness:</label>
                        <input
                      min="1"

                            type="number"
                            value={miState.styles.lineWidth}
                            onChange={(e) => handleInputChange(e, setThickness)}
                        />
                    </div>
                </div>
                <button onClick={() => dispatch(resetMi())}>Reset</button>

            </div>
        </IndicatorSettingWrapper>
    );
};

export default MIComponent;
